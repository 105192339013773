import React from 'react';

export const EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORIES = ['user_inputs_and_deadends', 'commands', 'urls', 'records'];

export const EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORY_DESCRIPTIONS: Record<string, any> = {
  commands: (
    <span>
      When disabled,{' '}
      <a
        href="https://www.commandbar.com/docs/commands/overview"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'underline' }}
      >
        command
      </a>{' '}
      executions will not be reported to CommanBar analytics.
    </span>
  ),
  records: (
    <span>
      When disabled,{' '}
      <a
        href="https://www.commandbar.com/docs/bar/data-searchable"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'underline' }}
      >
        records
      </a>{' '}
      will not be reported to CommandBar analytics.
    </span>
  ),
  user_inputs_and_deadends: <span>When disabled, user inputs will not be reported to CommandBar analytics.</span>,
  urls: <span>When disabled, users&apos; current URLs will not be reported to CommandBar analytics.</span>,
};
