import styled from '@emotion/styled';
import React from 'react';
import { CB_COLORS } from '@commandbar/design-system/components';

import { CaretDownFilled } from '@ant-design/icons';

const DividerIcon = styled.div`
  justify-content: center;
  display: flex;
  position: relative;
`;

const VerticalDivider = styled.div`
  height: 43px;
  top: -16px;
  position: absolute;
  border-left: 1px solid ${CB_COLORS.neutral300};
`;

const Circle = styled.div`
  height: 15px;
  width: 15px;
  background: ${CB_COLORS.neutral300};
  border-radius: 30px;
  position: absolute;
  top: -3px;
`;

const FormFactorStepDivider = () => {
  return (
    <DividerIcon>
      <CaretDownFilled style={{ color: CB_COLORS.neutral700, zIndex: '1', fontSize: '10px' }} />
      <Circle />
      <VerticalDivider />
    </DividerIcon>
  );
};

export default FormFactorStepDivider;
