import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as RecordSelectorSvg } from '../../../../img/record_selector.svg';

import { NudgeDropdown } from '../NudgeDropdown';
import { TargetElementClickRecorder } from '../../../components/TargetElementClickRecorder';
import { PositionInputConfig } from './PositionInputConfig';

import { FORM_FACTOR_INPUT_META, POSITION_LABEL, FormFactorType, PopoverPosition, TNudgeOffset } from './metadata';
import { CB_COLORS } from '@commandbar/design-system/components';
import useIsStandaloneEditor from '../../../../hooks/useIsStandaloneEditor';
import { Tooltip } from '@commandbar/design-system/components';

const Container = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral500};

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

interface IPositionInput {
  type: FormFactorType;
  onChange: (position: PopoverPosition) => void;
  positionValue: PopoverPosition;
  onAnchorChange: (anchors: string) => void;
  anchorValue: string;
  onToggleIsOpenByDefault: () => void;
  isOpenByDefault: boolean;
  onOffsetChange: (offset: TNudgeOffset) => void;
  offsetValue: TNudgeOffset;
}

export const FormFactorPositionInput = ({
  type,
  onChange,
  positionValue,
  anchorValue,
  onAnchorChange,
  isOpenByDefault,
  onToggleIsOpenByDefault,
  onOffsetChange,
  offsetValue,
}: IPositionInput) => {
  const metadata = FORM_FACTOR_INPUT_META[type];
  const label = POSITION_LABEL[positionValue];
  const isStandaloneEditor = useIsStandaloneEditor();

  if (metadata.type === 'select') {
    return (
      <Tooltip showIf={isStandaloneEditor} content={'Set and preview your nudge location inside your app'}>
        <NudgeDropdown
          onChange={onChange}
          value={positionValue}
          options={metadata.options}
          placement="bottomRight"
          gap="8px"
          disabled={isStandaloneEditor}
        >
          <div style={{ display: 'flex', gap: '6px' }}>
            <IconContainer>
              <RecordSelectorSvg width={12} />
            </IconContainer>
            {label}
          </div>
        </NudgeDropdown>
      </Tooltip>
    );
  }

  return (
    <Container>
      <TargetElementClickRecorder value={anchorValue} onValueChange={(selectors) => onAnchorChange(selectors[0])} />
      <PositionInputConfig
        isOpenByDefault={isOpenByDefault}
        onToggleIsOpenByDefault={onToggleIsOpenByDefault}
        onOffsetChange={onOffsetChange}
        offset={offsetValue}
        target={anchorValue}
        onTargetChange={onAnchorChange}
      />
    </Container>
  );
};
