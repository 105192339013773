import { Button as AntButton } from 'antd';
import styled from '@emotion/styled';

const IconButton = styled(AntButton)`
  &&& {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    padding: 12px;
    height: revert;
    width: revert;

    .anticon {
      font-size: 18px;
      line-height: 12px;
    }
  }
`;

export default IconButton;
