/** @jsx jsx */

import SVG from '@commandbar/internal/util/SVG';
import { jsx } from '@emotion/core';

import { ISkinType } from '@commandbar/internal/middleware/types';
import { DEFAULTS } from '@commandbar/internal/client/theme';
import { CB_COLORS } from '@commandbar/design-system/components';

const SkinPreview = (props: { skin: ISkinType; onClick: () => void }) => {
  const background = props.skin.skin?.base?.background ?? DEFAULTS.background;
  const color = props.skin.skin?.base?.fontColor ?? DEFAULTS.fontColor;
  const primary = props.skin.skin?.base?.primary ?? DEFAULTS.primary;

  return (
    <div
      css={{
        background,
        color,
        border: `1px solid ${CB_COLORS.neutral300}`,
        borderRadius: '8px',
        width: '240px',
        maxWidth: '240px',
        flex: '1 1 auto',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: '12px',
        gap: '8px',
        boxShadow: '0px 2px 8px rgba(10, 10, 15, 0.15)',
      }}
      onClick={props.onClick}
    >
      <div style={{ width: '16px', height: '16px' }}>
        {!!props.skin?.logo ? (
          <SVG htmlstring={props.skin?.logo} width="16px" height="16px" useDefaultSVGColor={true} />
        ) : (
          <div style={{ width: '16px', height: '16px', background: primary, borderRadius: '100px' }} />
        )}
      </div>
      <div
        style={{
          fontSize: '13px',
          lineHeight: '20px',
          fontWeight: 500,
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{props.skin.name}</span>
      </div>
    </div>
  );
};

export default SkinPreview;
