/*******************************************************************************/
/* Imports
/*******************************************************************************/
import React from 'react';

import { IArgumentMap, IArgumentType } from '@commandbar/internal/middleware/types';

import {
  SolutionOutlined,
  ClockCircleOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

export enum ARGUMENT_TYPE {
  CUSTOM_SELECT,
  PROVIDED,
  META_DESCRIPTION,
  DASHBOARD,
}

/**************************** Argument type utils *****************************/
export interface IOptionType {
  type: ARGUMENT_TYPE;
  // parent: string | undefined;
  key: string;
  label: string;
  description: string;
  defaultPrompt?: string;
  valuePrompt?: string;
  icon: React.ReactNode;
  hasValue: boolean;
  placeholder?: string;
  default?: any;
  prefix?: string;
}

export const argumentTypes: IOptionType[] = [
  {
    type: ARGUMENT_TYPE.META_DESCRIPTION,
    key: 'select',
    label: 'List of options',
    description: 'Select from a list',
    hasValue: false,
    icon: <SolutionOutlined />,
  },
  {
    type: ARGUMENT_TYPE.PROVIDED,
    key: 'text',
    description: 'Input text',
    defaultPrompt: 'Start typing',
    label: 'Text',
    icon: <AlignLeftOutlined />,
    hasValue: false,
  },
  {
    type: ARGUMENT_TYPE.PROVIDED,
    key: 'time',
    description: 'Input a time, e.g., "Tomorrow"',
    defaultPrompt: 'Enter a date or time, like Friday at 4pm',
    label: 'Time',
    icon: <ClockCircleOutlined />,
    hasValue: false,
  },
  {
    type: ARGUMENT_TYPE.DASHBOARD,
    key: 'html',
    description: 'Show a HTML document to the user',
    defaultPrompt: 'Read Document',
    label: 'Document',
    icon: <FileTextOutlined />,
    hasValue: false,
  },
  // {
  //   type: ARGUMENT_TYPE.VIDEO,
  //   key: 'video',
  //   description: 'Show a video',
  //   defaultPrompt: 'Watch Video',
  //   label: 'Video',
  //   icon: <PlayCircleOutlined />,
  //   hasValue: false,
  // },
];

export const selectTypes: IOptionType[] = [
  {
    type: ARGUMENT_TYPE.CUSTOM_SELECT,
    key: 'context',
    label: 'From code',
    description: 'A set of options defined in context',
    hasValue: true,
    valuePrompt: 'Value',
    icon: <SolutionOutlined />,
    placeholder: '<context key>',
    default: '',
    prefix: 'context.',
  },
  {
    type: ARGUMENT_TYPE.CUSTOM_SELECT,
    key: 'set',
    label: 'Set of values',
    description: 'A static set of values, e.g., ["free", "premium"]',
    valuePrompt: 'JSON:',
    hasValue: true,
    icon: <UnorderedListOutlined />,
    placeholder: '["opt1", "op2",..]',
    default: [],
  },
];

export const isPromptTheDefault = (prompt: string | undefined) => {
  if (!prompt) return false;
  const defaultTypePrompts = [...argumentTypes, ...selectTypes]
    .map((option: IOptionType) => option.defaultPrompt)
    .filter((prompt) => typeof prompt === 'string');
  return defaultTypePrompts.includes(prompt);
};

/* Get the current argument type */
export const currentType = (arg: string, argMap: IArgumentMap) => {
  let toRet = undefined;
  const argData = argMap[arg];

  if (argData.type === 'video' || argData.type === 'html') {
    toRet = argumentTypes.find((obj) => obj.key === argData.type);
  } else if (argData.type === 'provided') {
    toRet = argumentTypes.find((obj) => obj.key === argData.value);
  } else {
    toRet = selectTypes.find((obj) => obj.key === argData.type);
  }
  return toRet;
};

export const isArgumentASelect = (argumentType: IOptionType | undefined) => {
  return argumentType?.type === ARGUMENT_TYPE.CUSTOM_SELECT;
};

/* Get the current value of an argument type */
export const argValue = (arg: string, argMap: IArgumentMap) => {
  const argData = argMap[arg];
  if (argData.type === 'set') return JSON.stringify(argData.value);
  if (argData.type === 'context') return argData.value;
  return undefined;
};

export const validateName = (name: string, oldName: string, argMap: IArgumentMap) => {
  if (name.length === 0) return { result: false, reason: 'Name must not be empty' };
  if (/\s/.test(name)) return { result: false, reason: 'Name cannot contain spaces' };
  if (name !== oldName && name in argMap)
    return { result: false, reason: 'Name cannot conflict with another argument.' };
  return { result: true, reason: '' };
};

export const parseValue = (argType: IOptionType, value: string) => {
  if (argType.key === 'set') {
    return JSON.parse(value);
  } else return value;
};

export const validateArgValue = (argType: IOptionType, newVal: string) => {
  const passed = { result: true, reason: '' };

  if (argType.key === 'set') {
    const failed = { result: false, reason: 'Array not specified correctly.' };

    if (newVal.length === 0) return passed;
    // Test if valid json
    let jsonifiedVal;
    try {
      jsonifiedVal = JSON.parse(newVal);
      if (!Array.isArray(jsonifiedVal)) {
        return failed;
      }
    } catch {
      return failed;
    }
  } else if (argType.key === 'video') {
    if (newVal.length === 0) {
      return { result: false, reason: 'Must be a valid URL or HTML Snippet.' };
    }

    return passed;
  }
  return passed;
};

/**********************/
/** Field validators **/
/**********************/
export const argumentIsEditable = (argMap: IArgumentMap, slug: string) => {
  const arg = argMap[slug];

  if (!arg) {
    return false;
  }

  // Hide all arguments that begin with a __ since they are private to us
  if (slug.startsWith('__') && arg.is_private) {
    return false;
  }

  const hideArgumentToPreventEdits = (arg: IArgumentType) => {
    // hide arguments from showing for record actions
    return arg.type === 'context' && !!arg.show_in_record_action_list && !arg.show_in_default_list;
  };

  // filter out record actions
  // fixme should handle them in a special way
  return !hideArgumentToPreventEdits(arg);
};

export const getNextSortKey = (argMap: IArgumentMap) => {
  // Hide all arguments that begin with a __ since they are private to us

  const order_key = (arg: string) => argMap[arg].order_key;
  // eslint-disable-next-line prefer-spread
  const max_order_key = Math.max.apply(
    Math,
    Object.keys(argMap)
      .filter((slug: string) => argumentIsEditable(argMap, slug))
      .map(function (arg: string) {
        return order_key(arg);
      }),
  );
  const toRet = max_order_key >= 0 ? max_order_key + 1 : 0;
  return toRet;
};
