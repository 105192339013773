import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  color: ${CB_COLORS.neutral1000};
  background: ${CB_COLORS.white};
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
`;

export default Button;
