import React from 'react';
import { COMMANDBAR_CHROME_EXTENSION_ID } from '@commandbar/internal/client/ChromeExtension';

interface UseIsChromeExtensionInstalled {
  isChromeExtensionInstalled: boolean;
}

export const useIsChromeExtensionInsalled = (): UseIsChromeExtensionInstalled => {
  const [isChromeExtensionInstalled, setIsChromeExtensionInstalled] = React.useState<boolean>(false);
  React.useEffect(() => {
    const getIsChromeExtensionInstalled = () => {
      (window as any).chrome?.runtime?.sendMessage(
        COMMANDBAR_CHROME_EXTENSION_ID,
        {
          message: 'GET_VERSION',
        },
        (response: any) => {
          if (response?.status === 'ok') {
            setIsChromeExtensionInstalled(true);
          }
        },
      );
    };
    getIsChromeExtensionInstalled();

    window.addEventListener('focus', getIsChromeExtensionInstalled);

    return () => {
      window.removeEventListener('focus', getIsChromeExtensionInstalled);
    };
  }, []);

  return { isChromeExtensionInstalled };
};
