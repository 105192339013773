import React from 'react';
import { Alert, FeatureAnnouncementCard, Row, SimplePanel } from '../../shared_components';
import Sender from '../../management/Sender';
import SkinsList from '../skins/SkinList';

const VisualStyleEditor = () => {
  const [isThemeSetProgrammatically, setIsThemeSetProgrammatically] = React.useState(false);

  React.useEffect(() => {
    const handleProgrammaticTheme = async () => {
      const { data } = await Sender.getProgrammaticTheme();

      if (['light', 'dark'].includes(data as string)) {
        setIsThemeSetProgrammatically(true);
      }
    };

    handleProgrammaticTheme();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FeatureAnnouncementCard
        identifier="skins"
        title={<Row align="middle">Editing styles</Row>}
        docsLink="https://www.commandbar.com/docs/bar/styling"
      >
        You can edit the theme of the Bar, Nudges, Questlists, and HelpHub to match the aesthetic of your app.
      </FeatureAnnouncementCard>
      <SimplePanel>
        {isThemeSetProgrammatically && (
          <div style={{ margin: '0px auto 10px auto', maxWidth: '330px', textAlign: 'center' }}>
            <Alert message="The current theme was set programmatically" type="info" showIcon />
          </div>
        )}
        <div style={{ padding: '0px 10px 20px 0px' }}>
          <SkinsList />
        </div>
      </SimplePanel>
    </div>
  );
};

export default VisualStyleEditor;
