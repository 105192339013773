import React from 'react';

import '../../../shared_components/styles/StatusSwitch.css';
import { SortableHeader } from './Header';
import { Footer } from './Footer';
import { BaseForm } from './BaseForm';
import { CTAForm } from './CTAForm';
import { MediaBlocks } from './MediaBlocks';
import { AccordionItem } from './AccordionItem';
import {
  contentBlockSortKeys,
  findButtonBlock,
  findSecondaryButtonBlock,
  findMarkdownBlock,
  isMediaBlock,
  isSurveyBlock,
} from '../utils';

import type {
  INudgeStepType,
  INudgeStepContentBlockType,
  INudgeStepContentButtonBlockType,
} from '@commandbar/internal/middleware/types';
import { SurveyBlocks } from './SurveyBlocks';

export type FormType = 'image' | 'video' | 'help_doc_command' | 'button' | 'survey_text' | 'survey_rating';

export interface INudgeContentForm {
  onAccordionClick: () => void;
  expanded?: boolean;
  step: INudgeStepType;
  index: number;
  onStepChange: (index: number) => (step: INudgeStepType) => void;
  onDelete?: (id: number, stepIndex: number) => void;
}

export const NudgeContentForm = ({
  onAccordionClick,
  expanded,
  step,
  index,
  onStepChange: _onStepChange,
  onDelete,
}: INudgeContentForm) => {
  const button = findButtonBlock(step.content);
  const markdownBlock = findMarkdownBlock(step.content);

  const button_secondary = findSecondaryButtonBlock(step.content);

  const [ctaSessionState, setCtaSessionState] = React.useState<INudgeStepContentButtonBlockType['meta']>(
    button || {
      label: 'Continue',
      action: {
        type: 'no_action',
      },
    },
  );

  const [ctaSecondarySessionState, setCtaSecondarySessionState] = React.useState<
    INudgeStepContentButtonBlockType['meta']
  >(
    button_secondary || {
      button_type: 'secondary',
      action: {
        type: 'no_action',
      },
    },
  );

  // Update the CTA state when the button changes
  React.useEffect(() => {
    if (!button) return;
    setCtaSessionState(button);
  }, [button]);

  React.useEffect(() => {
    if (!button_secondary) return;
    setCtaSecondarySessionState(button_secondary);
  }, [button_secondary]);

  const onStepChange = _onStepChange(index);

  const onBlockChange = React.useCallback(
    (block: INudgeStepContentBlockType) => {
      if (step.has_survey_response) return;

      const newStep = {
        ...step,
        content: [...step.content],
      };

      if (isSurveyBlock(block) && !button) {
        newStep.content.push({ type: 'button', meta: { label: 'Submit', ...ctaSessionState } });
      }

      let blockIndex = -1;

      if (isMediaBlock(block) || isSurveyBlock(block)) {
        blockIndex = newStep.content.findIndex(
          (contentBlock) => isMediaBlock(contentBlock) || isSurveyBlock(contentBlock),
        );
      } else {
        if (block.type === 'button') {
          blockIndex = newStep.content.findIndex(
            (content) => content.type === block.type && block.meta?.button_type === content.meta?.button_type,
          );
        } else {
          blockIndex = newStep.content.findIndex((content) => content.type === block.type);
        }
      }

      if (blockIndex === -1) {
        newStep.content.push(block);
      } else {
        newStep.content[blockIndex] = block;
      }

      newStep.content.sort((a, b) => (contentBlockSortKeys[a.type] > contentBlockSortKeys[b.type] ? 1 : -1));

      onStepChange(newStep);
    },
    [onStepChange, step],
  );

  return (
    <AccordionItem
      disabled={step.has_survey_response}
      onClick={onAccordionClick}
      expanded={expanded}
      header={
        <SortableHeader
          locked={step.has_survey_response}
          expanded={expanded}
          title={step.title}
          value={step.form_factor}
          onDelete={onDelete ? () => onDelete(step.id, index) : undefined}
          onUpdate={(formFactor) => {
            onStepChange({
              ...step,
              form_factor: formFactor,
            });
          }}
        />
      }
    >
      {markdownBlock && (
        <BaseForm
          title={step.title}
          content={markdownBlock.value}
          onTitleChange={(s) => onStepChange({ ...step, title: s })}
          onContentChange={(s) => {
            onBlockChange({ type: 'markdown', meta: { ...markdownBlock, value: s } });
          }}
        />
      )}

      <MediaBlocks step={step} onBlockChange={onBlockChange} />

      <SurveyBlocks step={step} onBlockChange={onBlockChange} />

      {!!button && (
        <CTAForm
          button={button}
          onBlockChange={(block) => {
            onBlockChange(block);
          }}
          label={button?.label || ''}
        />
      )}

      {!!button_secondary && (
        <CTAForm
          button={button_secondary}
          onBlockChange={(block) => {
            onBlockChange(block);
          }}
          label={button_secondary?.label || ''}
        />
      )}

      <Footer
        step={step}
        onStepChange={onStepChange}
        onBlockChange={onBlockChange}
        onCtaToggle={() => {
          if (step.content.find((block) => block.type === 'button')) {
            onStepChange({
              ...step,
              content: step.content.filter((block) => block.type !== 'button'),
            });
          } else {
            onBlockChange({
              type: 'button',
              meta: ctaSessionState,
            });
          }
        }}
        onSecondaryCtaToggle={() => {
          if (step.content.find((block) => block.type === 'button' && block.meta?.button_type === 'secondary')) {
            onStepChange({
              ...step,
              content: step.content.filter(
                (block) =>
                  block.type !== 'button' || (block.type === 'button' && block.meta?.button_type !== 'secondary'),
              ),
            });
          } else {
            onBlockChange({
              type: 'button',
              meta: ctaSecondarySessionState,
            });
          }
        }}
      />
    </AccordionItem>
  );
};
