/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { FormRow, Slider } from '../../shared_components';

interface IProps {
  organization: IOrganizationType;
  updateOrganizationProperty: (field: string, newVal: any) => Promise<void>;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const SearchSettings = (props: IProps) => {
  // FIXME: Add search config to internals
  const MAX_VALUE = 100;
  const DEFAULT_VALUE = 100;
  const MIN_VALUE = 0;

  const validateValue = (value: number) => Math.max(Math.min(value, 100), MIN_VALUE);
  const getIntermediateValueInRange = (minValue: number, maxValue: number) => (factor: number) => {
    return Math.round((maxValue - minValue) * factor);
  };

  const getIntermediateByFactor = getIntermediateValueInRange(MIN_VALUE, MAX_VALUE);

  const onFuzzinessChange = (value: number | string | undefined) => {
    if (typeof value === 'number') {
      props.updateOrganizationProperty('search_fuzzy_threshold', validateValue(value));
    }
  };
  const getFuzzinessValue = (organization: IOrganizationType) => {
    return organization.search_fuzzy_threshold ? validateValue(organization.search_fuzzy_threshold) : DEFAULT_VALUE;
  };

  const marks = {
    [MIN_VALUE]: 'Exact match only',
    [getIntermediateByFactor(0.25)]: '25%',
    [getIntermediateByFactor(0.5)]: '50%',
    [getIntermediateByFactor(0.75)]: '75%',
    [MAX_VALUE]: 'Fuzzy',
  };

  return (
    <FormRow
      gutter={[8, 48]}
      title="Fuzziness threshold"
      info={`Fuzziness threshold determines how strictly CommandBar's search algorithm treats typos in user queries.
      A threshold of 0% requires a perfect match between query and result. 
      A threshold of 100% is very permissive.
      A threshold of 15-25% is recommended, but you can experiment by changing this value and trying different searches.
      Note that CommandBar’s search algorithm isn’t used to filter records that have an attached search function.`}
      input={
        <Slider
          min={MIN_VALUE}
          max={MAX_VALUE}
          marks={marks}
          onAfterChange={onFuzzinessChange}
          defaultValue={getFuzzinessValue(props.organization)}
          style={{ top: '9px' }}
        />
      }
    />
  );
};

export default SearchSettings;
