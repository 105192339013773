// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const TextInput = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.8 7H17v10h1.8c1.1201 0 1.6802 0 2.108-.218a1.9996 1.9996 0 0 0 .874-.874C22 15.4802 22 14.9201 22 13.8v-3.6c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C20.4802 7 19.9201 7 18.8 7Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 7H5.2c-1.1201 0-1.6802 0-2.108.218a1.9999 1.9999 0 0 0-.874.874C2 8.5198 2 9.08 2 10.2v3.6c0 1.1201 0 1.6802.218 2.108.1917.3763.4977.6823.874.874C3.5198 17 4.08 17 5.2 17H13m4-10h1.8c1.1201 0 1.6802 0 2.108.218.3763.1917.6823.4977.874.874C22 8.5198 22 9.08 22 10.2v3.6c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C20.4802 17 19.9201 17 18.8 17H17m0 4V3m2.5 0h-5m5 18h-5"
    />
  </svg>
);
export default TextInput;
