import React from 'react';
import styled from '@emotion/styled';

import AutoCompleteTextArea from '../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { CB_COLORS } from '@commandbar/design-system/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 16px 8px 16px;
  gap: 8px;
  background: #ffffff;
`;

const resetInput = `
  resize: none;
  &:active,
  &:focus,
  &:focus-visible {
    box-shadow: none;
    border: transparent;
  }
`;

const StyledTextArea = styled(AutoCompleteTextArea)`
  ${resetInput}
  color: ${CB_COLORS.neutral1000};
`;

const TitleCodeMirrorStyles = styled.div`
  font-size: 16px;
  --codemirror-border: 0;
  --codemirror-padding: 0;
  --codemirror-margin: 0 0 -10px -4px;
  --codemirror-line-height: 19px;
  --codemirror-font-weight: 500;
`;

const ContentCodeMirrorStyles = styled.div`
  font-size: 14px;
  --codemirror-border: 0;
  --codemirror-padding: 0;
  --codemirror-margin: 0 0 0 -4px;
  --codemirror-line-height: 22px;
  --codemirror-font-weight: 400;
`;

interface IBaseForm {
  title: string;
  content: string;
  onTitleChange: (s: string) => void;
  onContentChange: (s: string) => void;
}

export const BaseForm = ({ title, content, onTitleChange, onContentChange }: IBaseForm) => {
  const { context } = useWindowInfo();
  const contextKeys = Object.keys(context).map((s) => ({
    value: `context.${s}`,
    addOn: 'Active context',
  }));
  return (
    <Container>
      <TitleCodeMirrorStyles>
        <StyledTextArea placeholder="Your title..." value={title} onChange={onTitleChange} options={[...contextKeys]} />
      </TitleCodeMirrorStyles>
      <ContentCodeMirrorStyles>
        <StyledTextArea
          placeholder="Your content (markdown supported)"
          value={content}
          onChange={onContentChange}
          options={[...contextKeys]}
          enableFormatting={true}
        />
      </ContentCodeMirrorStyles>
    </Container>
  );
};
