/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

import { IOrganizationType, IRelease } from '@commandbar/internal/middleware/types';
import { BarLauncherSettings } from './components/BarLauncherSettings';
import PlaceholderList from './settings/Placeholders/PlaceholderList';

import IconUpload from './components/IconUpload';
import { FormRow, Alert, message, Row, Panel, Select, Button, Space } from '../shared_components';

import SearchSettings from './settings/SearchSettings';
import ToggleOrganizationSetting from './components/ToggleOrganizationSetting';
import FallbackCommandsSettings from './settings/FallbackCommands';
import EventAttributeBlockListSettings from './settings/EventAttributeBlockList';
import { EndUserVerificationStatus } from './settings/EndUserVerificationAlerts';
import { AppState, useAppContext } from '../Widget';
import { Releases } from '@commandbar/internal/middleware/releases';
import { Download01 } from '@commandbar/design-system/icons/react';
import Sender from '../management/Sender';
import styled from '@emotion/styled';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  appState: AppState;
  activeKeys?: Array<SettingsPanelKey>;
}

interface IState {
  activeKeys: Array<SettingsPanelKey>;
  airgapEnabled: boolean;
}

export enum SettingsPanelKey {
  GENERAL = 'general',
  CHAT = 'chat',
  LAUNCHER = 'launcher',
  ADDITIONAL_RESOURCES = 'additional_resources',
  PLACEHOLDERS = 'placeholders',
  FALLBACK_COMMANDS = 'fallback-commands',
  MISC = 'misc',
  SEARCH = 'search',
  RELEASES = 'releases',
  ANALYTICS_CONTROL = 'analytics-control',
  END_USER = 'end-user',
  AIRGAP = 'airgap',
}

/*******************************************************************************/
/* Render
/*******************************************************************************/
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

class Settings extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);

    this.state = { airgapEnabled: false, activeKeys: [] };
  }

  public async componentDidMount() {
    this.isAirGapEnabled().then((airgapEnabled) => this.setState({ airgapEnabled }));

    const { activeKeys } = this.props;
    if (activeKeys) {
      setTimeout(() => this.setState({ activeKeys }), 500);
    }
  }

  public async isAirGapEnabled(): Promise<boolean> {
    const ALWAYS_TREAT_AS_AIRGAPPED = ['44967ad4', '01836695', 'foocorp'];
    if (ALWAYS_TREAT_AS_AIRGAPPED.includes(this.props.appState.organization.id.toString())) return true;
    return Sender.getConfiguration()
      .then(({ data: sdkConfiguration }) => {
        return !!sdkConfiguration.airgap;
      })
      .catch(() => false);
  }

  public updateOrganizationProperty = async (field: string, newVal: any, updateRemote = true) => {
    const { dispatch } = this.props.appState;

    const newOrganization = {
      ...this.props.appState.organization,
      [field]: newVal,
    };

    try {
      await dispatch.organization.update(newOrganization, updateRemote);
    } catch (e: any) {
      message.error(String(e));
      throw e;
    }

    message.success('Settings updated.');
  };

  public onUpload = (field: string) => {
    return async (fileString: string) => {
      await this.updateOrganizationProperty(field, fileString);
    };
  };

  public onRemove = (field: string) => {
    return async () => {
      await this.updateOrganizationProperty(field, '');
    };
  };

  public onCollapseChange = (setActiveKeys: (prevState: Array<SettingsPanelKey>) => Array<SettingsPanelKey>) => {
    this.setState({ activeKeys: setActiveKeys(this.state.activeKeys) });
  };

  render() {
    return (
      <Container>
        <Panel
          panelKey={SettingsPanelKey.LAUNCHER}
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header="Bar Launcher"
        >
          <BarLauncherSettings
            organization={this.props.appState.organization}
            updateOrganizationProperty={this.updateOrganizationProperty}
          />
        </Panel>

        <Panel
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header="Placeholder Text"
          panelKey={SettingsPanelKey.PLACEHOLDERS}
        >
          <PlaceholderList organization={this.props.appState.organization} />
        </Panel>
        <Panel
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header="Fallbacks"
          panelKey={SettingsPanelKey.FALLBACK_COMMANDS}
        >
          <Alert
            style={{ marginBottom: 20 }}
            message="Using fallback commands"
            description={
              <div>
                <p>
                  Fallback commands show up when a user types in text and doesn't see any matches. You can use it to
                  nudge users to common actions or support.
                </p>
              </div>
            }
            type="info"
            showIcon
          />
          <FallbackCommandsSettings
            organization={this.props.appState.organization}
            onChange={this.updateOrganizationProperty}
          />
        </Panel>
        <Panel
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header="Misc"
          panelKey={SettingsPanelKey.MISC}
        >
          <Row style={{ marginTop: '22px' }} justify="center">
            <IconUpload
              defaultIcon={
                this.props.appState.organization.icon_suggest === ''
                  ? null
                  : this.props.appState.organization.icon_suggest
              }
              onUpload={this.onUpload('icon_suggest')}
              onRemove={this.onRemove('icon_suggest')}
              title={`"Suggest" Icon`}
              value="icon_suggest"
              svgOnly
            />
          </Row>
          <Row style={{ marginTop: '22px' }} justify="center">
            <IconUpload
              defaultIcon={
                this.props.appState.organization.icon_go_forward === ''
                  ? null
                  : this.props.appState.organization.icon_go_forward
              }
              onUpload={this.onUpload('icon_go_forward')}
              onRemove={this.onRemove('icon_go_forward')}
              title={`"Go Forward" Icon (default: arrow)`}
              value="icon_go_forward"
              svgOnly
            />
          </Row>
        </Panel>
        <Panel
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header="Search"
          panelKey={SettingsPanelKey.SEARCH}
        >
          <SearchSettings
            organization={this.props.appState.organization}
            updateOrganizationProperty={this.updateOrganizationProperty}
          />
        </Panel>
        {this.props.appState.organization.releases_available && (
          <Panel
            activeKeys={this.state.activeKeys}
            setActiveKeys={this.onCollapseChange}
            header="Releases"
            panelKey={SettingsPanelKey.RELEASES}
          >
            <Alert
              style={{ marginBottom: 12 }}
              message="Using Releases for change management"
              description={
                <div>
                  <p>
                    Releases helps your team test the changes they make to the Bar commands and configuration before
                    making those changes available to your customers.
                  </p>

                  <p>
                    <a
                      style={{ textDecoration: 'dotted underline' }}
                      href="https://www.commandbar.com/docs/versioncontrol/releases"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn how to configure your integration to use Releases.
                    </a>
                  </p>
                </div>
              }
              type="info"
              showIcon
            />
            <FormRow
              title="Enable releases"
              input={
                <ToggleOrganizationSetting
                  organization={this.props.appState.organization}
                  onChange={this.updateOrganizationProperty}
                  setting="releases_enabled"
                />
              }
            />
          </Panel>
        )}
        {/* FIXME: Rebase with org settings */}
        {!this.props.appState.organizationSettings?.silent_mode && (
          <Panel
            activeKeys={this.state.activeKeys}
            setActiveKeys={this.onCollapseChange}
            header="Analytics Control"
            panelKey={SettingsPanelKey.ANALYTICS_CONTROL}
          >
            <EventAttributeBlockListSettings
              organization={this.props.appState.organization}
              onChange={this.props.appState.dispatch.organization.updateSetting}
            />
          </Panel>
        )}
        <Panel
          activeKeys={this.state.activeKeys}
          setActiveKeys={this.onCollapseChange}
          header={<span>End-user preferences</span>}
          panelKey={SettingsPanelKey.END_USER}
        >
          <EndUserPreferencesPanel
            organization={this.props.appState.organization}
            updateOrganizationProperty={this.updateOrganizationProperty}
          />
        </Panel>
        {this.state.airgapEnabled && (
          <Panel
            activeKeys={this.state.activeKeys}
            setActiveKeys={this.onCollapseChange}
            header="Airgap mode"
            panelKey={SettingsPanelKey.AIRGAP}
          >
            <AirgapControls organization={this.props.appState.organization} />
          </Panel>
        )}
      </Container>
    );
  }
}

const EndUserPreferencesPanel = (props: {
  organization: IOrganizationType;
  updateOrganizationProperty: (field: string, newVal: any, updateRemote?: boolean) => Promise<void>;
}) => {
  const { organization, updateOrganizationProperty } = props;

  return (
    <>
      <EndUserVerificationStatus />
      <FormRow
        title="Force identity verification"
        info={`Enable this setting for enhanced security`}
        input={
          <ToggleOrganizationSetting
            organization={organization}
            onChange={updateOrganizationProperty}
            setting="force_end_user_identity_verification"
            style={{ marginLeft: 15 }}
          />
        }
      />
      <FormRow
        title="Custom shortcuts"
        input={
          <ToggleOrganizationSetting
            organization={organization}
            onChange={updateOrganizationProperty}
            setting="end_user_shortcuts_enabled"
            style={{ marginLeft: 15 }}
          />
        }
      />
      <FormRow
        title="Recents"
        info={`Enable this setting to show an end-user's recently used commands and records in a separate category on top. Once enabled, you can toggle this behavior for each command category and each record.`}
        input={
          <ToggleOrganizationSetting
            organization={organization}
            onChange={updateOrganizationProperty}
            setting="end_user_recents_enabled"
            style={{ marginLeft: 15 }}
          />
        }
      />
    </>
  );
};

const AirgapControls = (props: { organization: IOrganizationType }) => {
  const { organization } = props;
  const [releases, setReleases] = React.useState<IRelease[]>([]);
  const [environments, setEnvironments] = React.useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = React.useState<string | number | undefined>(undefined);

  React.useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const view = await Releases.readView();
        const envs = view.environments.map((envInfo) => envInfo.environment);
        setEnvironments(envs);
      } catch (error) {
        console.error('Error fetching environments:', error);
      }
    };

    const fetchReleases = async () => {
      try {
        const releases = await Releases.listReleases();
        const latestReleases = releases.slice(0, 5);
        setReleases(latestReleases);
      } catch (error) {
        console.error('Error fetching releases:', error);
      }
    };

    fetchReleases();
    fetchEnvironments();
  }, [organization.id]);

  const handleConfigDownload = (envOrVersion?: string | number) => {
    let url = `${process.env.REACT_APP_API_URL}/organizations/${organization.id.toString()}/config/`;
    let fileName = 'config.json';
    if (!envOrVersion) {
      /** Default to latest */
      url += '?env=latest';
      fileName = 'latest_' + fileName;
    } else if (typeof envOrVersion === 'number') {
      /** Release version */
      url += `?version=v${envOrVersion}`;
      fileName = `v${envOrVersion}_` + fileName;
    } else {
      /** env */
      url += `?env=${envOrVersion}`;
      fileName = `${envOrVersion}_` + fileName;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
      .catch((error) => {
        message.error('Error downloading config:', error);
      });
  };

  return (
    <FormRow
      title="Download config"
      input={
        <Space size="middle">
          <Select value={selectedVersion || ''} onChange={(e) => setSelectedVersion(e)} style={{ minWidth: 125 }}>
            <Select.Option value="">Latest</Select.Option>
            {environments.map((env) => (
              <Select.Option key={`env_${env}`} value={env}>{`Env: ${env}`}</Select.Option>
            ))}
            {releases.map((release) => (
              <Select.Option
                key={`release_${release.id}`}
                value={release.history_event.version_num}
              >{`v${release.history_event.version_num}: ${release.notes}`}</Select.Option>
            ))}
          </Select>
          <Button
            ghost
            icon={<Download01 width={20} height={20} />}
            onClick={() => handleConfigDownload(selectedVersion)}
          />
        </Space>
      }
    />
  );
};

// TODO: convert Settings to a functional component and remove this wrapper
const SettingsWrapper = (props: Omit<IProps, 'appState'>) => {
  const appState = useAppContext();
  return <Settings appState={appState} {...props} />;
};

export default SettingsWrapper;
