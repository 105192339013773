import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';
import { Link04 } from '@commandbar/design-system/icons/react';

import { Tooltip, message } from '../../../shared_components';
import { ShareLinkModal, TShareLinkMeta } from './ShareLinkModal';
import useIsStandaloneEditor from '../../../hooks/useIsStandaloneEditor';
import { buildShareLink, SHARE_LINK_PARAM } from '@commandbar/internal/proxy-editor/share_links';
import Sender from '../../../management/Sender';

const Container = styled.div`
  position: relative;
`;

const Button = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  background: ${(props) => (props.disabled ? '#f5f5f5' : CB_COLORS.white)};
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.black)};
  border: 1px solid ${(props) => (props.disabled ? '#d9d9d9' : 'rgba(10, 10, 15, 0.24)')};
  box-shadow: ${(props) =>
    props.disabled ? 'none' : '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)'};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

interface IShareLinkButtonProps {
  meta: TShareLinkMeta;
}

export const ShareLinkButton = ({ meta }: IShareLinkButtonProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [modalTopOffset, setModalTopOffset] = React.useState<number>(0);
  const buttonRef = React.useRef<HTMLDivElement>(null);
  const isStandaloneEditor = useIsStandaloneEditor();
  const [sharePageUrl, setSharePageUrl] = React.useState<string>(meta.details.share_page_url);
  const [hostUrl, setHostUrl] = React.useState<string>('');
  const shareLink = buildShareLink(sharePageUrl, meta.type, meta.details.id, isStandaloneEditor);
  React.useEffect(() => {
    const getHostUrl = async () => {
      try {
        const res = await Sender.getHostUrl();
        const url = new URL(res.url);
        const searchParams = new URLSearchParams(url.search);
        searchParams.delete(SHARE_LINK_PARAM);

        setHostUrl(
          `${url.origin}${url.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}${url.hash}`,
        );
      } catch {
        setHostUrl('');
      }
    };
    getHostUrl();
  }, []);

  React.useEffect(() => {
    if (isStandaloneEditor) {
      if (hostUrl) {
        const url = new URL(hostUrl);
        setSharePageUrl(url.origin);
      }
      return;
    }
    if (!!meta.details.share_page_url) {
      setSharePageUrl(meta.details.share_page_url);
      return;
    }
    if (hostUrl) {
      setSharePageUrl(hostUrl);
      return;
    }
  }, [isStandaloneEditor, hostUrl]);

  React.useEffect(() => {
    if (!buttonRef.current) return;
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const buttonTopOffset = buttonRect.top;
    const buttonHeight = buttonRect.height;
    const padding = 8;
    setModalTopOffset(buttonTopOffset + buttonHeight + padding);
  }, [buttonRef.current]);

  const isUnsavedItem = Number(meta.details.id) < 0;

  const onCopyLink = async () => {
    if (isUnsavedItem) return;
    try {
      await navigator.clipboard.writeText(shareLink);
      message.success('Share link copied to clipboard!');
    } catch (e) {
      message.error('Unable to copy link to clipboard. Please try again');
    }
  };

  return (
    <Container>
      <Tooltip
        content={isUnsavedItem ? `Save your ${meta.type} before sharing it` : 'Copy share link'}
        placement="bottom"
        arrow={false}
      >
        <Button
          ref={buttonRef}
          disabled={isUnsavedItem}
          onClick={
            isStandaloneEditor
              ? onCopyLink
              : () => {
                  if (isUnsavedItem) return;
                  setIsModalOpen(true);
                }
          }
        >
          <Link04 width={14} color={isUnsavedItem ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.neutral700} />
        </Button>
      </Tooltip>
      {isModalOpen && (
        <ShareLinkModal
          sharePageUrl={sharePageUrl}
          onSharePageUrlChange={(e) => setSharePageUrl(e.target.value)}
          shareLink={shareLink}
          meta={meta}
          onClose={() => setIsModalOpen(false)}
          onCopyLink={() => {
            setIsModalOpen(false);
            onCopyLink();
          }}
          modalTopOffset={modalTopOffset}
        />
      )}
    </Container>
  );
};
