/* React imports */
import React, { Fragment } from 'react';

import { Typography, Divider, Tag } from '../../../../shared_components';

import { AvailabilityDependency } from './components/AvailabilityDependency';
import { countAvailabilityConditionsAndDependencies } from './helpers';
import { RulePrefix } from './components/styled';
import { IEditorCommandType, IRuleExpression } from '@commandbar/internal/middleware/types';
import RuleExpressionEditor from './components/RuleExpressionEditor';
import ReasonsEditor from './components/ReasonsEditor';
import { useNeedsToUpgradeFoobarPackage } from '../../../../pre-auth/compatibility';
import { LockOutlined } from '@ant-design/icons';

export const AvailabilityPanel = (props: {
  command: IEditorCommandType;
  onChange: (expr: IRuleExpression) => void;
}) => {
  const {
    command: { availability_expression },
    onChange,
  } = props;
  const { alwaysAvailable, dependencies } = countAvailabilityConditionsAndDependencies(props.command);
  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.3.17');

  return (
    <div>
      {needsToUpgrade && (
        <div style={{ marginBottom: '1rem' }}>
          <Tag color="warning">
            <LockOutlined /> Please upgrade the `@commandbar/foobar` package (version 0.3.18 or greater) to use this
            feature.
          </Tag>
        </div>
      )}
      <div style={{ marginBottom: 8 }}>
        {alwaysAvailable ? (
          <>
            <Typography.Text strong>Always available</Typography.Text>
            <Typography style={{ fontSize: 12 }}>
              Use conditions to define when this command should show in the search results.
            </Typography>
          </>
        ) : (
          <Typography.Text strong>Available when:</Typography.Text>
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        {dependencies.map(({ type, field, operator, message }, index) => {
          return (
            <Fragment key={`${type}${index}`}>
              <AvailabilityDependency
                type={type}
                field={field}
                operator={operator}
                message={message}
                prefixText={index !== 0 ? 'AND' : undefined}
              />
            </Fragment>
          );
        })}
      </div>
      {dependencies.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <Divider>
            <RulePrefix>AND</RulePrefix>
          </Divider>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
        <RuleExpressionEditor disabled={needsToUpgrade} onChange={onChange} expr={availability_expression} />
        <ReasonsEditor disabled={needsToUpgrade} onChange={onChange} expr={availability_expression} />
      </div>

      {/* For testing purposes
         <div style={{ fontSize: 12, padding: '0px 20px' }}>
          {parsedRules.map((rule, index) => (
            <Typography key={`${rule}${index}`}>{rule}</Typography>
          ))}
        </div> */}
    </div>
  );
};
