import React, { useEffect, useMemo } from 'react';
import { SortableMultiSelect } from '../../../shared_components/Select/SortableMultiSelect';
import * as Command from '@commandbar/internal/middleware/command';
import { useAppContext } from '../../../Widget';
import { LabeledAction } from '@commandbar/internal/middleware/types';

interface Props {
  commands: Array<string | number | LabeledAction>;
  onChange: Function;
  disabled: boolean;
}

const NextStepCommandsSelect = (props: Props) => {
  useEffect(() => {
    if (props.disabled && props.commands.length !== 0) {
      props.onChange([]);
    }
  }, [props.disabled, props.commands]);

  const allCommands = useAppContext().commands;

  const options = allCommands
    .filter((command) => !Command.isProgrammatic(command) && Command.showInDefaultList(command))
    .map((command) => ({
      label: command.text,
      value: command.id,
    }));

  const nextStepCommands = useMemo(() => {
    const commands = props.commands ?? [];

    return allCommands
      .filter((command) => commands.indexOf(command.id) !== -1)
      .sort((a, b) => commands.indexOf(a.id) - commands.indexOf(b.id));
  }, [allCommands, props.commands]);

  const onChangeOrSortEnd = async (selected: typeof options) => {
    const newNextStepCommands = selected.map((o) => o.value);
    props.onChange(newNextStepCommands);
  };

  const defaultValue = nextStepCommands.map((command) => ({
    label: command.text,
    value: command.id,
  }));

  return (
    <SortableMultiSelect
      key={props.commands.length}
      maxSelectedOptions={3}
      options={options}
      defaultValue={defaultValue}
      onSortEnd={onChangeOrSortEnd}
      onChange={onChangeOrSortEnd}
      isDisabled={props.disabled}
    />
  );
};

export default NextStepCommandsSelect;
