import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import createCache from '@emotion/cache';

import * as Sentry from '@sentry/react';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'antd/dist/antd.less';

// needed in order to use locale-specific format strings e.g. "lll"
// https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const container = document.getElementById('commandbar-editor')!;

export const EmotionCache = createCache({
  key: 'commandbar-editor',
  container: container,
});

const isProd = process.env.NODE_ENV && process.env.NODE_ENV !== 'development';
const environment = process.env?.REACT_APP_BUILD_TARGET || 'dev';
const dsn = 'https://155e8df9a2bb4ec7acfd0c428efcb580@o451734.ingest.sentry.io/5438097';
const release = process.env?.SENTRY_RELEASE || 'dev';
// FIXME: This should only run if there is a special flag set
const shouldInitSentry = isProd && (environment === 'labs' || environment === 'prod');

Sentry.init({
  dsn: shouldInitSentry ? dsn : '',
  environment,
  release,
  attachStacktrace: true,
  autoSessionTracking: true,
  debug: !isProd,
  maxBreadcrumbs: 300,
});

// Temporarily remove StrictMode to silence errors
// https://github.com/ant-design/ant-design/issues/22493
// <React.StrictMode>
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  container,
);
