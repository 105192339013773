import styled from '@emotion/styled';
import { Typography } from '../../../../shared_components';

export const Container = styled.div<{ dropdownVisible?: boolean }>`
  height: 100%;
`;

export const CurrentItemContainer = styled.div<{ dropdownVisible?: boolean }>`
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e8;
  border-radius: ${({ dropdownVisible }) => (dropdownVisible ? '8px 8px 0 0' : '8px')};
  box-shadow: ${({ dropdownVisible }) => (dropdownVisible ? 'none' : '0px 1px 0px rgba(0, 0, 0, 0.1)')};
  background-color: #fff;
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 16px;
  top: 0;
  bottom: 0;

  & svg {
    transition: transform 0.15s ease-in-out;
  }
`;

export const GroupHeading = styled(Typography)`
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 18px;
  margin: 16px 16px 4px 16px;
`;
