import React from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { MdDragHandle } from 'react-icons/md';
import styled from '@emotion/styled';
import {
  ICommandCategoryType,
  IResourceSettingsByContextKey,
  IResourceSettings,
} from '@commandbar/internal/middleware/types';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';

import { matchAll } from '../../utils/fp';

import {
  Button,
  Header,
  SortableList,
  Tooltip,
  Alert,
  Divider,
  Space,
  Spin,
  Typography,
  Tag,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
} from '../../../shared_components';
import { getNewSortkey } from '../../components/SortableTable';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { ContextSettingsModal } from '../../context/contextSettings/ContextSettingsModal';
import { useContextPartition } from '../../context/contextSettings/useContextPartition';
import { useRouter } from '../../../hooks';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { useAppContext } from '../../../Widget';

type MergedCategoryTypes = 'COMMAND' | 'RECORD' | 'SYNTHETIC';

interface IMergedCategoriesSortData {
  id: number | string | null | undefined;
  commandCategory?: ICommandCategoryType;
  resourceSettings?: IResourceSettings;
  name: string | undefined;
  type: MergedCategoryTypes;
  sort_key: number;
  pinnedToBottom: boolean;
  shownInEmptyState: boolean;
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Categories = () => {
  return (
    <>
      <FeatureAnnouncementCard
        identifier="bar-categories"
        title={<Row align="middle">Using categories</Row>}
        docsLink="https://www.commandbar.com/docs/bar/grouping-commands"
      >
        <span>
          Categories are groups of commands. You can define the order of them below.
          <br />
          <span style={{ fontWeight: 600 }}>Before a user enters a query</span>, categories will appear in the order
          below.
          <br />
          <span style={{ fontWeight: 600 }}>After a user enters a query,</span> the order below is used as a tie-breaker
          for categories that share the same search score. A {`category's`} search score is the highest search score of
          any of that {`category's`} matches.
          <br />
        </span>
      </FeatureAnnouncementCard>
      <SimplePanel>
        <MergedCategoriesList />
      </SimplePanel>
    </>
  );
};

const Container = styled.div`
  height: 40px;
  border-radius: 4px;
  padding: 4px, 12px, 4px, 4px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-grow: 1;
  cursor: grab;
  border: 1px solid #e6e6e8;
  margin: 4px 0px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;
  user-select: none;
`;

const HoverableContainer = styled(Container)`
  &:hover {
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
    transform: scale(1.02);
  }
`;

const DisabledContainer = styled(Container)`
  opacity: 0.75;
  cursor: not-allowed;
`;

const mergeCategories = (
  commandCategories: ICommandCategoryType[],
  resourceSettings: IResourceSettingsByContextKey,
  syntheticCategories: IMergedCategoriesSortData[],
): IMergedCategoriesSortData[] => {
  const commandCategorySortData: IMergedCategoriesSortData[] = commandCategories.map((c) => ({
    id: c.id,
    commandCategory: c,
    name: c.name,
    type: 'COMMAND',
    sort_key: c.sort_key != null ? c.sort_key : Number.POSITIVE_INFINITY,
    pinnedToBottom: c.setting_pin_to_bottom,
    shownInEmptyState: !c.setting_hide_before_search,
  }));
  const recordCategorySortData: IMergedCategoriesSortData[] = Object.entries(resourceSettings)
    .filter(([_key, settings]) => {
      return !!settings.search && !_key.startsWith(SDK_INTERNAL_PREFIX); // only quickfindable record categories matter for ordering)
    })
    .map(([key, settings]) => ({
      id: key,
      resourceSettings: settings,
      name: settings.name ? settings.name : capitalize(key),
      type: 'RECORD',
      sort_key: settings.sort_key != null ? settings.sort_key : Number.POSITIVE_INFINITY,
      pinnedToBottom: !!settings.setting_pin_to_bottom,
      shownInEmptyState: !!settings.showResources,
    }));
  const merged = [...commandCategorySortData, ...recordCategorySortData, ...syntheticCategories].sort(
    (a, b) => a.sort_key - b.sort_key,
  );

  for (let i = 0; i < merged.length; i++) {
    // Reissue sort_keys to merge indexes between Record and Command Category.
    merged[i].sort_key = i;
  }
  return merged;
};

const isPinnedToBottom = (c: IMergedCategoriesSortData) => !!c.pinnedToBottom;

const MergedCategoriesList = () => {
  const { dispatch, organization, categories: commandCategories } = useAppContext();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [modifiedCategories, setModifiedCategories] = React.useState<IMergedCategoriesSortData[] | null>(null);

  const recentscat: IMergedCategoriesSortData = {
    id: 'RECENTS-',
    name: 'Recents',
    type: 'SYNTHETIC',
    sort_key: organization.recents_sort_key === null ? -1 : organization.recents_sort_key,
    pinnedToBottom: false,
    shownInEmptyState: true,
  };
  const recommendedcat: IMergedCategoriesSortData = {
    id: 'RECOMMENDED-',
    name: 'Recommended',
    type: 'SYNTHETIC',
    sort_key: organization.recommended_sort_key === null ? -2 : organization.recommended_sort_key,
    pinnedToBottom: false,
    shownInEmptyState: true,
  };

  const allCategories = mergeCategories(commandCategories, organization.resource_options, [recentscat, recommendedcat]);

  const categories = modifiedCategories || allCategories.filter((v) => !isPinnedToBottom(v));

  const pinnedCategories = allCategories.filter(isPinnedToBottom);

  const saveCommandCategoryIfChanged = async (categories: IMergedCategoriesSortData[]) => {
    const sortKeyMapping: { [id: number]: number } = {};

    categories.forEach((category) => {
      const commandCategory = category.commandCategory;
      if (commandCategory && commandCategory.sort_key !== category.sort_key) {
        sortKeyMapping[commandCategory.id] = category.sort_key;
      }
    });

    if (Object.keys(sortKeyMapping).length === 0) {
      return;
    }

    await dispatch.categories.updateSortKeys(sortKeyMapping);
  };

  const saveSyntheticCategoriesIfChanged = async (categories: IMergedCategoriesSortData[]) => {
    const recentsCategory = categories.find((cat) => cat.id === 'RECENTS-');

    if (
      !!recentsCategory &&
      recentsCategory.sort_key !== organization.recents_sort_key &&
      recentsCategory.sort_key >= 0
    ) {
      await OrganizationSettings.update({
        recents_sort_key: recentsCategory.sort_key,
      });
    }

    const recommendedCategory = categories.find((cat) => cat.id === 'RECOMMENDED-');

    if (
      !!recommendedCategory &&
      recommendedCategory.sort_key !== organization.recommended_sort_key &&
      recommendedCategory.sort_key >= 0
    ) {
      await OrganizationSettings.update({
        recommended_sort_key: recommendedCategory.sort_key,
      });
    }

    return await dispatch.organization.update(
      {
        ...organization,
        ...(!!recentsCategory && { recents_sort_key: recentsCategory.sort_key }),
        ...(!!recommendedCategory && { recommended_sort_key: recommendedCategory.sort_key }),
      },
      false,
    );
  };

  const saveRecordCategoriesIfChanged = async (recordCategories: IMergedCategoriesSortData[]) => {
    let resourceOptions = organization.resource_options;

    for (const category of recordCategories) {
      if (!category.id || typeof category.id !== 'string') continue;

      const contextKey = category.id;

      if (resourceOptions[contextKey] && resourceOptions[contextKey].sort_key !== category.sort_key) {
        resourceOptions = {
          ...resourceOptions,
          [category.id]: { ...resourceOptions[contextKey], sort_key: category.sort_key },
        };
      }
    }
    if (resourceOptions !== organization.resource_options) {
      await dispatch.organization.update({
        ...organization,
        resource_options: resourceOptions,
      });
    }
  };

  const onSave = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const records: IMergedCategoriesSortData[] = [];
      const commandCategories: IMergedCategoriesSortData[] = [];
      const syntheticCategories: IMergedCategoriesSortData[] = [];

      matchAll(categories, (c) => c.type, {
        RECORD: (category) => {
          records.push(category);
        },
        COMMAND: (category) => {
          commandCategories.push(category);
        },
        SYNTHETIC: (category) => {
          syntheticCategories.push(category);
        },
      });

      // A bit hacky here how to resolve Promises to update all entities
      await saveSyntheticCategoriesIfChanged(syntheticCategories);
      await saveRecordCategoriesIfChanged(records);
      await saveCommandCategoryIfChanged(commandCategories);
    } catch (e) {
      setError(String(e));
    } finally {
      setIsLoading(false);
    }

    setModifiedCategories(null);
    setIsDirty(false);
    setIsLoading(false);
  };

  const onReorder = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newCategories = categories.map((category: IMergedCategoriesSortData, currentIndex: number) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);

      return { ...category, sort_key: newSortKey };
    });
    setIsDirty(true);
    setModifiedCategories(newCategories);
  };

  return (
    <div>
      {error && (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Error saving changes:
              <Typography.Text code>{error.slice(0, 100)}</Typography.Text>
            </span>
          }
          type="error"
        />
      )}
      <Header
        text="Categories order"
        style={{ marginTop: 0 }}
        rightActions={[
          <Button key="save" onClick={onSave} disabled={!isDirty}>
            Save
          </Button>,
        ]}
      />
      <Spin spinning={isLoading}>
        <SortableList
          nodes={categories
            .sort((a, b) => a.sort_key - b.sort_key)
            .map((c) => (
              <CategoryRow key={c.id} data={c} isSortable={true} />
            ))}
          onSort={onReorder}
        />
      </Spin>

      {pinnedCategories.length > 0 && (
        <>
          <Divider style={{ opacity: 0.5 }} orientation="left">
            <span style={{ fontSize: 12 }}>Pinned to bottom</span>
          </Divider>
          {pinnedCategories.map((c) => (
            <CategoryRow key={c.id} data={c} isSortable={false} />
          ))}
        </>
      )}
    </div>
  );
};

const CategoryRow = (props: { data: IMergedCategoriesSortData; isSortable: boolean }) => {
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const { recordsByKey } = useContextPartition();
  //  const linkedCommandHelper = useLinkedCommand();
  const { deeplink } = useRouter();

  const { data, isSortable } = props;

  const Container = isSortable ? HoverableContainer : DisabledContainer;

  // TODO: fix `useAppContext` so that `organization` cannot be undefined; for now,
  // useLinkedCommand returns `null` when `organization` is undefined so we have to
  // handle that case here.
  //if (!linkedCommandHelper) return null;

  //const { createLinkedCommand, openLinkedCommandFromCategoriesTab } = linkedCommandHelper;

  const hiddenInDefaultTooltip = (
    <Tooltip content={'This category is only visible after a user starts searching.'}>
      <EyeInvisibleOutlined />
    </Tooltip>
  );

  const canChangeSettings = (data.type === 'RECORD' && data.id != null) || data.commandCategory;

  const getTag = () => {
    switch (data.type) {
      case 'RECORD':
        return <Tag color="magenta">Record</Tag>;
      case 'SYNTHETIC':
        return <Tag color="purple">Synthetic</Tag>;
      default:
        return <Tag color="geekblue">Command</Tag>;
    }
  };

  return (
    <>
      <Container>
        <Space align="center">
          {isSortable ? <MdDragHandle style={{ paddingTop: 3 }} /> : <div style={{ width: 12 }} />}
          {data.name}
          {getTag()}
          {!data.shownInEmptyState ? hiddenInDefaultTooltip : null}
        </Space>
        {canChangeSettings && (
          <Tooltip content="Edit settings">
            <Button
              icon={<SettingOutlined />}
              type="text"
              style={{ fontSize: 14, opacity: 0.9, boxShadow: 'none' }}
              onClick={(e) => {
                if (data.commandCategory) {
                  deeplink({
                    to: editorRoutes.COMMANDS_ROUTE,
                    tab: 'commands-commands',
                    target: {
                      identifier: `category-${data.commandCategory.name}`,
                      type: 'popup',
                    },
                  });

                  return;
                }
                setShowSettings(true);
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )}
      </Container>
      {showSettings &&
        (data.type === 'RECORD' && data.id != null ? (
          <ContextSettingsModal onClose={() => setShowSettings(false)} data={recordsByKey[data.id]} isRecord={true} />
        ) : null)}
    </>
  );
};

export default Categories;
