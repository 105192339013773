export const CB_COLORS = {
  primary: '#2755f0',
  white: '#ffffff',
  black: '#000000',
  neutral0: '#F7F7F8',
  neutral100: '#EDEDEE',
  neutral200: '#E7E7E9',
  neutral300: '#DFDFE2',
  neutral400: '#CDCDD0',
  neutral500: '#A2A2A9',
  neutral600: '#7A7A85',
  neutral700: '#51515C',
  neutral800: '#42424D',
  neutral900: '#1E1E29',
  neutral1000: '#0A0A0F',
  blue0: '#F5F7FF',
  blue100: '#E6ECFE',
  blue200: '#C4D1FD',
  blue300: '#90A8F9',
  blue400: '#6887EE',
  blue500: '#456BE8',
  blue600: '#2755F0',
  blue700: '#0E3FDD',
  blue800: '#072EAB',
  blue900: '#031963',
  blue1000: '#030F38',
};

export const siteOverrides = {
  '@font-family':
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  '@font-size-base': '12px',
  '@border-radius-base': '4px',
  '@primary-color': CB_COLORS.primary,

  '@card-padding-base': '16px',
  '@card-radius': '6px',

  '@table-font-size': '12px',
  '@table-bg': 'transparent',
  '@table-header-bg': 'transparent',
  '@table-header-color': 'rgba(20,20,20,0.6)',
  '@table-header-sort-bg': 'transparent',
  '@table-body-sort-bg': 'transparent',

  '@layout-header-background': '#101029',
  '@layout-trigger-background': '@layout-header-background',
  '@menu-dark-submenu-bg': '@layout-header-background',
  '@divider-color': 'rgba(0, 0, 0, 10%)',

  '@info-color': CB_COLORS.primary,

  '@dropdown-font-size': '12px',
  '@dropdown-vertical-padding': '8px',

  '@layout-sider-background-light': '#ffffff',
  '@menu-bg': 'transparent',
  '@menu-dark-item-active-bg': 'rgba(255, 255, 255, 0.05)',
  '@menu-dark-selected-item-icon-color': '#ffffff',
  '@menu-dark-selected-item-text-color': '#ffffff',
  '@menu-dark-highlight-color': '#b3b3ea',
  '@menu-item-boundary-margin': '2px',
  '@menu-dark-color': 'rgba(255, 255, 255, 0.7)',

  '@switch-color': CB_COLORS.primary,
  '@switch-height': '16px',
  '@switch-min-width': '24px',

  '@tabs-card-active-color': 'rgba(0, 0, 0, 0.65)',
  '@tabs-active-color': 'rgba(0, 0, 0, 0.65)',

  '@badge-font-size': '9px',
  '@badge-font-weight': '700',
  '@badge-color': '#f5f5f5',
  '@badge-text-color': '#434343',

  '@alert-success-border-color': 'rgba(0, 108, 76, 0.1)',
  '@alert-success-bg-color': '#F5FCFA',
  '@alert-success-icon-color': '#006C4C',
  '@alert-info-border-color': '#73d4ff',
  '@alert-info-bg-color': '#e3f6ff',
  '@alert-text-color': '#070d1d',
  '@alert-message-color': '#070d1d',
};
