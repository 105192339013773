// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Trash01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19 17.2V6H5v11.2c0 1.6802 0 2.5202.327 3.162a2.9997 2.9997 0 0 0 1.311 1.311C7.2798 22 8.1198 22 9.8 22h4.4c1.6802 0 2.5202 0 3.162-.327a2.9994 2.9994 0 0 0 1.311-1.311C19 19.7202 19 18.8802 19 17.2Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 6v-.8c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C14.4802 2 13.9201 2 12.8 2h-1.6c-1.1201 0-1.6802 0-2.108.218a1.9999 1.9999 0 0 0-.874.874C8 3.5198 8 4.08 8 5.2V6m2 5.5v5m4-5v5M3 6h18m-2 0v11.2c0 1.6802 0 2.5202-.327 3.162a2.9994 2.9994 0 0 1-1.311 1.311C16.7202 22 15.8802 22 14.2 22H9.8c-1.6802 0-2.5202 0-3.162-.327a2.9997 2.9997 0 0 1-1.311-1.311C5 19.7202 5 18.8802 5 17.2V6"
    />
  </svg>
);
export default Trash01;
