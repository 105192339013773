import React from 'react';

import { Button as AntButton } from 'antd';
import styled from '@emotion/styled';

class AntButtonWrapper extends React.Component<React.ComponentProps<typeof AntButton>> {
  render() {
    const props = { ...this.props };
    if (props.ghost && (props.type === 'text' || props.type === 'link')) {
      // "ghost" always changes the way we style the Button (see CSS below)
      //
      // However, for "text" or "link" buttons it does nothing in the
      // underlying component. If we pass "ghost" to the AntButton component
      // for "text" or "link" buttons, we get a warning in the console.
      //
      // So, do not pass the "ghost" prop to the AntButton component if the
      // button is "text" or "link".
      delete props.ghost;
    }

    return <AntButton {...props} />;
  }
}

const Button = styled(AntButtonWrapper)`
  &&& {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: revert;
    width: revert;
    color: ${({ ghost }) => ghost && 'rgba(0, 0, 0, 0.5)'};
    border: ${({ ghost, type }) => {
      if (ghost || !type) {
        return 'none';
      }
    }};
    box-shadow: ${({ ghost, icon, type }) => {
      if (ghost) return 'none';
      if (!type) return '0px 1px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px #FFFFFF;';
      if (icon) return '0px 1px 0px rgba(0, 0, 0, 0.1)';
    }};

    // FIXME: Primary color
    &:hover {
      color: ${(props: any) => props.ghost && '#44434a'};
    }

    .anticon {
      font-size: 18px;
      line-height: 12px;
    }
  }
`;

export default Button;
