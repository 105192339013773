import Logger from '../util/Logger';
import WindowStorage from '../util/WindowStorage';

export type FRAME_TYPE = 'commandbar' | 'editor' | 'proxy';

interface IReceiverParameters {
  e: any;
  data: any;
}

export type ReceiverFunctionType = (params: IReceiverParameters) => Promise<[boolean, any]> | undefined;

const matchSubstrings = (str: string, substrings: string[]): boolean => {
  const regexpStr = `^.*(${substrings.join('|')}).*$`;
  const regexp = new RegExp(regexpStr, 'g');
  return !!str.match(regexp);
};

const evalSource = (url: string): FRAME_TYPE | null => {
  const commandBarSources = [
    'localhost:3001',
    'localhost:5001',
    `frames-commandbar-prod.commandbar.com`,
    `frames-commandbar-sandbox.commandbar.com`,
    `frames-commandbar-branch.commandbar.com`,
    `frames-commandbar-branch2.commandbar.com`,
    `frames-commandbar-labs.commandbar.com`,
    `frames-commandbar-proxy.commandbar.com`,
    `frames-commandbar-proxylabs.commandbar.com`,
    `commandbar-branch2.netlify.app`,
  ];
  const editorSources = [
    'localhost:3003',
    'localhost:5003',
    `frames-editor-prod.commandbar.com`,
    `frames-editor-labs.commandbar.com`,
    `frames-editor-sandbox.commandbar.com`,
    `frames-editor-branch.commandbar.com`,
    `frames-editor-branch2.commandbar.com`,
    `frames-editor-proxy.commandbar.com`,
    `frames-editor-proxylabs.commandbar.com`,
    `editor-branch2.netlify.app`,
    `proxy-branch2.netlify.app`,
  ];

  if (matchSubstrings(url, commandBarSources)) {
    return 'commandbar';
  }

  if (matchSubstrings(url, editorSources)) {
    return 'editor';
  }

  // Fallback
  if (['CommandBar', 'editor', 'Proxy'].includes(process.env.REACT_APP_FRAME_NAME ?? '')) {
    return 'proxy';
  }

  return null;
};

const hashCode = (str: string) => {
  return str.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
};

/**
 * Send messages to and from iframes
 */
const openChannel = (frame?: FRAME_TYPE) => {
  let target: any;
  let targetOrigin: string;
  let targetID: string;
  let child: boolean;

  if (frame === undefined) {
    // We are in a child
    targetID = 'commandbar-proxy';
    targetOrigin = document.referrer;
    target = window.parent;
    child = true;

    // Whenever navigating from https to http, referrer URLs are stripped
    // So when we are testing locally, we need to allow
    // https://stackoverflow.com/a/19455529/4717829
    if (window.location.origin.startsWith('http://localhost')) {
      targetOrigin = '*';
    }

    if (!targetOrigin) {
      // FIXME document.referrer doesnt register for NovaChat / Beeper
      targetOrigin = '*';
    }

    if (!targetOrigin || !target) {
      return (_msg: any) => {
        return Promise.resolve();
      };
    }
  } else {
    // We are in a parent
    targetID = `commandbar-${frame}`;
    targetOrigin = WindowStorage.frames?.[targetID]?.src;
    target = WindowStorage.frames?.[targetID]?.contentWindow || WindowStorage.frames?.[targetID]?.contentDocument;
    child = false;

    if (!target || !targetOrigin) {
      Logger.error('iframe not loaded yet...', frame);
      return (_msg: any) => Promise.resolve();
    }
  }

  return (msg: any) =>
    new Promise((resolve: any, reject: any) => {
      const uniqueID = hashCode(JSON.stringify(msg));

      Logger.portalsend(uniqueID, JSON.stringify(msg));
      const channel = new MessageChannel();

      channel.port1.onmessage = (props: any) => {
        channel.port1.close();
        const data = JSON.parse(props.data);
        if (data.error) {
          Logger.portalreceive(uniqueID, props.data);

          reject(data.error);
        } else {
          Logger.portalreceive(uniqueID, props.data);

          resolve(data);
        }
      };

      const payload = serializeData({
        ...msg,
        target: targetID,
        host: child ? undefined : window.location.href,
      });

      // Sometimes document.referrer misses the http protocol (e.g. getplan.co vs https://getplan.co)
      if (targetOrigin !== '*' && !targetOrigin.includes('://')) {
        if (!targetOrigin.includes('localhost')) {
          console.log('');
          // Sentry.captureException('postMessage targetOrigin updated', {
          //   contexts: { portal: { targetOrigin, payload } },
          // });
        }
        targetOrigin = `https://${targetOrigin}`;
      }

      // REFACTOR Figure out how to avoid this https://cmd-k.slack.com/archives/C012YKDT9KM/p1605486109003600
      // I think it was just because I was using a common password "asdf"
      target.postMessage(payload, targetOrigin, [channel.port2]);
    });
};

/**
 * Serialize payloads
 */
const serializeData = (msg: any) => {
  let ret;

  try {
    ret = JSON.stringify(msg);
  } catch (err) {
    Logger.warn('Invalid message type', err);
    ret = `${msg}`;
  }

  return ret;
};

/**
 * Standard response payloads
 */
const response = (event: any, success: boolean, result?: { [key: string]: any }) => {
  const payload = JSON.stringify({
    success,
    ...(result ?? {}),
  });

  event.ports[0].postMessage(payload);
};

/**
 * Message security
 */
const isMessageValid = (e: any): boolean => {
  const allowedStaticOrigins = [
    'localhost:3000', // Base
    'localhost:3001', // CommandBar
    'localhost:3002', // Proxy
    'localhost:3003', // Editor
    'localhost:5000', // Base
    'localhost:5001', // CommandBar
    'localhost:5002', // Proxy
    'localhost:5003', // Editor
    `frames-proxy-prod.commandbar.com`,
    `frames-proxy-labs.commandbar.com`,
    `frames-proxy-sandbox.commandbar.com`,
    `frames-proxy-branch.commandbar.com`,
    `frames-proxy-branch2.commandbar.com`,
    `frames-commandbar-prod.commandbar.com`,
    `frames-commandbar-labs.commandbar.com`,
    `frames-commandbar-sandbox.commandbar.com`,
    `frames-commandbar-branch.commandbar.com`,
    `frames-commandbar-branch2.commandbar.com`,
    `frames-commandbar-proxy.commandbar.com`,
    `frames-commandbar-proxylabs.commandbar.com`,
    `frames-editor-prod.commandbar.com`,
    `frames-editor-labs.commandbar.com`,
    `frames-editor-sandbox.commandbar.com`,
    `frames-editor-branch.commandbar.com`,
    `frames-editor-branch2.commandbar.com`,
    `frames-editor-proxy.commandbar.com`,
    `frames-editor-proxylabs.commandbar.com`,
    `editor-branch2.netlify.app`,
    `proxy-branch2.netlify.app`,
    `commandbar-branch2.netlify.app`,
  ];

  // FIXME Add hostOrigins dynamically via /latest (medium sec strictness approach)
  const allowedHostOrigins = WindowStorage?.hostOrigins ?? [];

  const MESSAGE_SOURCES_TO_BE_IGNORED = [
    'react-devtools-content-script',
    'react-devtools-bridge',
    'AUGURY_INSPECTED_APPLICATION',
  ];
  const shouldLogWarning = (messageSource: string) => {
    return MESSAGE_SOURCES_TO_BE_IGNORED.every((source) => messageSource !== source);
  };

  const isValidOrigin = matchSubstrings(e.origin, [...allowedStaticOrigins, ...allowedHostOrigins]);

  if (process.env.REACT_APP_FRAME_NAME === 'Proxy') {
    if (!isValidOrigin) {
      return false;
    }
  }

  if (!e.data) {
    Logger.error('Message Error: packet empty');
    return false;
  }

  let data;
  try {
    data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
  } catch (err) {
    return false;
  }

  const isValidTarget = data.target === `commandbar-${process.env.REACT_APP_FRAME_NAME?.toLowerCase()}`;
  if (!isValidTarget) {
    if (shouldLogWarning(data.source || data.messageSource)) {
      Logger.red('Either you sent this to the wrong target or its not coming from Command Bar', data);
    }
    return false;
  }

  const isFromMessageChannel = e.ports && e.ports.length === 0;
  if (isFromMessageChannel) {
    Logger.error(data);
    throw new Error('We are only accepting MessageChannel requests for now...');
  }

  return true;
};

const preMessageHandler = (e: any) => {
  let data;
  try {
    if (!!e.data) {
      data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
    } else {
      data = '';
    }
  } catch (err) {
    data = '';
  }

  return {
    isValid: isMessageValid(e),
    sender: evalSource(e.origin),
    data,
  };
};

const Portal = {
  response,
  openChannel,
  isMessageValid,
  evalSource,
  preMessageHandler,
};

export default Portal;
