/*******************************************************************************/
/* Imports
/*******************************************************************************/
import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import Widget from './Widget';
import Login from './auth/Login';
import LoginWithSSO from './auth/LoginWithSSO';
import * as Sentry from '@sentry/react';
import Logger from '@commandbar/internal/util/Logger';
import { useAuthInfo } from './store/AuthProvider';
import { Spin, Typography } from './shared_components';
import { thirdPartyCookiesEnabled, ThirdPartyCookiesWarning } from './pre-auth/compatibility';
import VerifyEmail from './auth/VerifyEmail';
import Setup from './auth/Setup';
import { UsageProvider } from './hooks/useUsage';

/*******************************************************************************/
/* Props
/*******************************************************************************/

/*******************************************************************************/
/* Render
/*******************************************************************************/

const Router = () => {
  const { user, isAuthenticating } = useAuthInfo();

  const allowsThirdPartyCookies = React.useMemo(() => {
    const _allowsThirdPartyCookies = thirdPartyCookiesEnabled();
    Sentry.setContext('compatibility', { _allowsThirdPartyCookies });
    return _allowsThirdPartyCookies;
  }, []);

  Logger.orange('ROUTER', allowsThirdPartyCookies, user);

  if (!allowsThirdPartyCookies) {
    return (
      <>
        <ThirdPartyCookiesWarning />
      </>
    );
  }

  if (isAuthenticating) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flex: 1,
            minHeight: '100vh',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgb(242, 242, 242)',
          }}
        >
          <div style={{ opacity: 0.8, position: 'relative', top: '30vh', textAlign: 'center' }}>
            <Spin style={{ marginBottom: 10 }} />
            <Typography style={{ fontSize: 12, opacity: 0.5 }}>Loading...</Typography>
          </div>
        </div>
      </>
    );
  }

  if (!!user && user.is_active && user.organization !== 'none') {
    return (
      <Switch>
        <Route path="/">
          <UsageProvider>
            <Widget user={user} />
          </UsageProvider>
        </Route>
      </Switch>
    );
  }

  if (!!user && !user.is_active) {
    return (
      <>
        <Switch>
          <Route path="/">
            <VerifyEmail />
          </Route>
        </Switch>
      </>
    );
  }

  if (!!user && user.organization === 'none') {
    return (
      <>
        <Switch>
          <Route path="/">
            <Setup />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/login/sso" exact>
          <LoginWithSSO />
        </Route>
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
