import React from 'react';
import styled from '@emotion/styled';
import { Loading01 } from '@commandbar/design-system/icons/react';
import { keyframes } from '@emotion/core';
import { Button } from '@commandbar/design-system/components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation-name: ${spin};
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

interface ICreateButton {
  onClick: () => void;
  loading: boolean;
}

export const CreateButton = ({ onClick, loading }: ICreateButton) => {
  return (
    <Button type="primary" onClick={() => onClick()} disabled={loading}>
      New Answer
      {loading && (
        <Spinner>
          <Loading01 width={16} />
        </Spinner>
      )}
    </Button>
  );
};
