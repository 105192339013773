import { Badge as AntBadge } from 'antd';
import styled from '@emotion/styled';

const StatusBadge = styled(AntBadge)`
  &&& {
    .ant-badge-status-text {
      font-size: 12px;
      font-weight: 600;
    }

    .ant-badge-status-green {
      background: radial-gradient(50% 50% at 50% 50%, #88fbc4 0%, #01dc73 100%);
      border: 0.5px solid rgba(16, 113, 0, 0.1);
      box-shadow: 0px 0px 3px rgba(1, 184, 90, 0.5), 0px 0px 1px rgba(0, 0, 0, 0.1);
    }

    .ant-badge-status-orange {
      background: radial-gradient(50% 50% at 50% 50%, #ffc14f 0%, #ff9635 100%);
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 0px 3px #ff9500;
    }
  }
`;

export default StatusBadge;
