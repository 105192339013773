import React from 'react';

import './App.css';

import { EmotionCache } from './index';
import { CacheProvider } from '@emotion/core';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Sentry from '@sentry/react';
import Router from './Router';

import AuthProvider from './store/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { IsEditorOpenProvider } from './hooks/useIsEditorOpen';

const insideIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const App = () => {
  const inIFrame = React.useMemo(insideIFrame, []);
  const devMode = React.useMemo(() => {
    return !!LocalStorage.get('dev', '');
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={<p>Something went wrong. Please reach out to team@commandbar.com if this persists.</p>}
    >
      <IsEditorOpenProvider>
        <AuthProvider>
          {!inIFrame && !devMode ? (
            <div>You will be redirected soon...</div>
          ) : (
            <CacheProvider value={EmotionCache}>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </CacheProvider>
          )}
        </AuthProvider>
      </IsEditorOpenProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
