// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const MessageChatCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13 16.5c0 3.0376-2.4035 5.5-5.3684 5.5a5.2457 5.2457 0 0 1-1.7493-.2986c-.2081-.0734-.3122-.1102-.3822-.1241a.8433.8433 0 0 0-.1887-.0193c-.0714-.0005-.1531.0107-.3166.033L2 22l.6113-2.7617c.0401-.181.0602-.2715.0648-.3516a.852.852 0 0 0-.0104-.2128c-.0125-.0792-.0504-.1952-.1261-.4272A5.6167 5.6167 0 0 1 2.2632 16.5c0-3.0376 2.4035-5.5 5.3684-5.5S13 13.4624 13 16.5Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.0944 11.2288A8.0106 8.0106 0 0 1 6 10c0-4.4183 3.6052-8 8.0526-8 4.4473 0 8.0526 3.5817 8.0526 8 0 .9981-.1839 1.9535-.52 2.8345-.0698.183-.1048.2745-.1206.3459a.897.897 0 0 0-.0235.1931c-.0017.0731.0082.1537.0281.3148l.4025 3.2702c.0436.354.0654.531.0065.6597a.4999.4999 0 0 1-.2571.2513c-.13.0559-.3065.03-.6594-.0217l-3.1852-.4669c-.1664-.0244-.2495-.0366-.3253-.0361a.8982.8982 0 0 0-.2001.0213c-.0741.0156-.1688.0511-.3581.122-.8833.3309-1.8406.5119-2.8404.5119a8.1741 8.1741 0 0 1-1.2299-.0927M7.6316 22C10.5965 22 13 19.5376 13 16.5S10.5965 11 7.6316 11c-2.965 0-5.3684 2.4624-5.3684 5.5 0 .6106.097 1.1979.2764 1.7467.0757.232.1136.348.126.4272a.852.852 0 0 1 .0105.2128c-.0046.0801-.0247.1706-.0648.3516L2 22l2.9948-.409c.1635-.0223.2452-.0335.3166-.033a.8433.8433 0 0 1 .1887.0193c.07.0139.174.0506.3822.1241A5.2457 5.2457 0 0 0 7.6316 22Z"
    />
  </svg>
);
export default MessageChatCircle;
