// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const EyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9 12c0 1.6568 1.3431 3 3 3 .8284 0 1.5784-.3358 2.1213-.8787L9.8787 9.8787C9.3357 10.4216 9 11.1716 9 12Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.7425 5.0923A8.587 8.587 0 0 1 12 5c5.105 0 8.4549 4.5048 9.5803 6.2868.1362.2157.2043.3235.2424.4899.0286.1249.0286.322 0 .4469-.0382.1663-.1068.2749-.2439.492a17.533 17.533 0 0 1-1.3627 1.8649M6.7239 6.715c-2.162 1.4667-3.6299 3.5044-4.3032 4.5703-.1368.2166-.2052.3249-.2434.4912-.0286.1249-.0286.3219 0 .4469.0381.1663.1062.2741.2424.4898C3.5451 14.4952 6.895 19 12 19c2.0584 0 3.8315-.7324 5.2884-1.7234M3 3l18 18M9.8787 9.8787C9.3357 10.4216 9 11.1716 9 12c0 1.6569 1.3431 3 3 3 .8284 0 1.5784-.3358 2.1213-.8787"
    />
  </svg>
);
export default EyeOff;
