// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const CursorClick02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m8.5 8.5 4.1111 12.7778L15.5 18.3889 19.1111 22 22 19.1111 18.3889 15.5l2.8889-2.8889L8.5 8.5Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 3.5V2M5.0607 5.0607 4 4m1.0607 9L4 14.0607m9-9L14.0607 4M3.5 9H2m6.5-.5 4.1111 12.7778L15.5 18.3889 19.1111 22 22 19.1111 18.3889 15.5l2.8889-2.8889L8.5 8.5Z"
    />
  </svg>
);
export default CursorClick02;
