import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@commandbar/design-system/components';
import { Lightning01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components';

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 18px;
  color: ${(props) => (props.disabled ? CB_COLORS.neutral500 : '#8b3eff')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.3s all ease-in-out;
`;

interface IZap {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const Zap = ({ disabled, onClick }: IZap) => {
  return (
    <Tooltip showIf={!disabled} content="Generate an answer with AI">
      <Container disabled={disabled} onClick={onClick}>
        <Lightning01 width={16} />
      </Container>
    </Tooltip>
  );
};
