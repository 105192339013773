import React from 'react';
import { Row, Col, Radio, CodeLine } from '../../../shared_components';
import { LauncherTypeOptions } from './LauncherTypeOptions';
import { LauncherPositionSelect } from './LauncherPositionSelect';
import { LauncherOffsetInput } from './LauncherOffsetInput';
import { useAppContext } from '../../../Widget';
import * as S from './styles';
import { Grid03 } from '@commandbar/design-system/icons/react';
import { TargetElementClickRecorder } from '../TargetElementClickRecorder';

export const HelpHubLauncherSettings = () => {
  const {
    organization,
    isStandaloneEditor,
    dispatch: {
      organization: { updateSetting },
    },
  } = useAppContext();

  const [targetElement, setTargetElement] = React.useState<string>(organization.helphub_launcher_anchor || '');
  const [targetElementError, setTargetElementError] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<'custom' | 'default' | 'none'>(
    organization.helphub_launcher_type === 'custom'
      ? 'custom'
      : organization.helphub_launcher_type === 'none'
      ? 'none'
      : 'default',
  );

  if (!organization) {
    return null;
  }

  const {
    helphub_launcher_type: type,
    helphub_launcher_position: position,
    helphub_launcher_offset_x: offsetX,
    helphub_launcher_offset_y: offsetY,
  } = organization;

  const onBlurX = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const intValue = parseInt(value, 10);
    if (offsetX === intValue) {
      return;
    }

    updateSetting({ helphub_launcher_offset_x: intValue });
  };
  const onBlurY = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const intValue = parseInt(value, 10);
    if (offsetY === intValue) {
      return;
    }

    updateSetting({ helphub_launcher_offset_y: intValue });
  };
  return (
    <div style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
      <S.SettingContainer
        active={selectedOption === 'default'}
        onClick={
          selectedOption !== 'default'
            ? () => {
                setSelectedOption('default');
                updateSetting({ helphub_launcher_type: 'bookOpen', helphub_launcher_position: position });
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'default'}>
          <S.Title>Default</S.Title>
          <S.Description>Add a floating button to your app to toggle HelpHub</S.Description>
        </Radio>
        {selectedOption === 'default' && (
          <div style={{ margin: '8px 24px' }}>
            <div>
              Launcher Style
              <S.LauncherBox>
                <LauncherTypeOptions
                  onSetType={(launcherType) => updateSetting({ helphub_launcher_type: launcherType })}
                  value={type}
                />
              </S.LauncherBox>
            </div>
            <div style={{ margin: '16px 0' }}>
              Screen Position
              <LauncherPositionSelect
                value={position}
                onChange={(position) => updateSetting({ helphub_launcher_position: position })}
              />
            </div>
            <div>
              Screen Offset
              <Row gutter={[16, 16]} wrap={false}>
                <Col>
                  <LauncherOffsetInput value={offsetX} onBlur={onBlurX} title="X" />
                </Col>

                <Col>
                  <LauncherOffsetInput value={offsetY} onBlur={onBlurY} title="Y" />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </S.SettingContainer>
      <S.SettingContainer
        active={selectedOption === 'custom'}
        onClick={
          selectedOption !== 'custom'
            ? () => {
                updateSetting({ helphub_launcher_type: 'custom' });
                setSelectedOption('custom');
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'custom'}>
          <S.Title>Custom</S.Title>
          <S.Description>Toggle HelpHub with an existing element in your app</S.Description>
        </Radio>
        {selectedOption === 'custom' && (
          <div
            style={{
              width: '100%',
              margin: '24px 0 0 0',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '24px',
            }}
          >
            <S.SecondaryTitle>Target Element</S.SecondaryTitle>
            <TargetElementClickRecorder
              value={targetElement}
              onValueChange={(value) => {
                setTargetElement(value[0]);
                updateSetting({ helphub_launcher_anchor: value[0], helphub_launcher_type: 'custom' });
              }}
            >
              <React.Fragment>
                <S.TargetButton disabled={isStandaloneEditor}>
                  <Grid03 height={14} />
                  Pick Element...
                </S.TargetButton>
                <S.CustomTextInput
                  textArea={true}
                  placeholder="CSS or XPath"
                  value={targetElement}
                  onBlur={(e) => setTargetElementError(!e.target.value)}
                  status={targetElementError ? 'error' : ''}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTargetElementError(!e.target.value);
                    setTargetElement(e.target.value);
                    if (!!e.target.value) {
                      updateSetting({ helphub_launcher_anchor: e.target.value, helphub_launcher_type: 'custom' });
                    }
                  }}
                />
              </React.Fragment>
            </TargetElementClickRecorder>
          </div>
        )}
      </S.SettingContainer>
      <S.SettingContainer
        active={selectedOption === 'none'}
        onClick={
          selectedOption !== 'none'
            ? () => {
                setSelectedOption('none');
                updateSetting({ helphub_launcher_type: 'none' });
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'none'}>
          <S.Title>None</S.Title>
          <S.Description>
            Toggle HelpHub with our SDK.{' '}
            <a
              href="https://www.commandbar.com/sdk#togglehelphub"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              Learn more ↗
            </a>
          </S.Description>
        </Radio>
        {selectedOption === 'none' && (
          <S.CodeWrapper>
            <CodeLine code="window.CommandBar.toggleHelpHub()" />
          </S.CodeWrapper>
        )}
      </S.SettingContainer>
    </div>
  );
};
