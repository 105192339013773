import React, { ReactNode } from 'react';
import { Container, TextContainer, IconContainer, Label, Description } from './styled';

interface IProps {
  label: string;
  description: string;
  icon: ReactNode;
  color: string;
  selected?: boolean;
  hover?: boolean;
  noBorder?: boolean;
  // TODO: replace any with proper type
  onClick?(event: any): void;
}

const ActionTypeSelectorOption = ({
  label,
  description,
  icon,
  color,
  hover = true,
  selected = false,
  noBorder = false,
  onClick,
}: IProps) => {
  return (
    <Container hover={hover} selected={selected} onClick={onClick} noBorder={noBorder}>
      <IconContainer color={color}>{icon}</IconContainer>
      <TextContainer>
        <Label selected={selected}>{label}</Label>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  );
};

export default ActionTypeSelectorOption;
