import { CB_COLORS } from '@commandbar/design-system/components';
import { capitalize } from 'lodash';
import { NotificationBox, ArrowUpRight, CheckSquareBroken, Rows01 } from '@commandbar/design-system/icons/react';
import Button from '@commandbar/design-system/components/Button';
import React from 'react';
import { useUsage } from '../../hooks/useUsage';

type UpgradeCTAProps = {
  product: 'nudges' | 'questlists' | 'commands';
};

export const UpgradeCTA: React.FC<UpgradeCTAProps> = ({ product }) => {
  const { usage, exceeding, isLoading } = useUsage();

  const isOverLimit = React.useMemo(() => {
    if (isLoading || usage === null) return false;

    if (product === 'commands') {
      return exceeding.isAtOrOverLiveCommands;
    } else if (product === 'nudges') {
      return exceeding.isAtOrOverLiveNudges;
    } else if (product === 'questlists') {
      return exceeding.isAtOrOverLiveQuestlists;
    } else {
      return false;
    }
  }, [exceeding, isLoading]);

  return isOverLimit ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: CB_COLORS.blue100,
        color: CB_COLORS.blue900,
        padding: '8px 8px 8px 12px',
        borderRadius: 4,
      }}
    >
      {product === 'nudges' && <NotificationBox width="16px" height="16px" />}
      {product === 'questlists' && <CheckSquareBroken width="16px" height="16px" />}
      {product === 'commands' && <Rows01 width="16px" height="16px" />}

      <span style={{ marginLeft: '8px', fontSize: '14px' }}>Upgrade to create unlimited {capitalize(product)}</span>
      <Button
        href="https://app.commandbar.com/billing"
        target="_blank"
        style={{
          marginLeft: 'auto',
          paddingBottom: '0px',
          border: '1px solid rgba(10, 10, 15, 0.24)',
          color: CB_COLORS.neutral700,
          background: CB_COLORS.white,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
          <span style={{ marginRight: '4px', fontWeight: 600, fontSize: '14px' }}>See Plans</span>
          <ArrowUpRight width="16px" height="16px" />
        </div>
      </Button>
    </div>
  ) : null;
};
