import { IEditorCommandTypeLite, IOrganizationSettingsType } from '../middleware/types';
import moment, { Moment } from 'moment';

/*** Unhealthy Commands ***/
const getMaxLastAvailable = (commands: IEditorCommandTypeLite[]): Moment => {
  return commands
    .map((e) => moment(e.last_available))
    .filter((e) => e.isValid())
    .reduce(
      (prev: moment.Moment, current: moment.Moment) => (moment.max(prev, current) === prev ? prev : current),
      moment('1970-01-01'),
    );
};

const getTimeDiffInHours = (a: Moment, b: Moment) => {
  return a.diff(b, 'hours');
};

const ALLOWED_HOURS_SINCE_LAST_EDIT = 2 * 24;
const ALLOWED_HOURS_SINCE_LAST_AVAILABLE = 3 * 24;

const isCommandHealthy = (command: IEditorCommandTypeLite, max: Moment) => {
  if (!command.last_available || !command.is_live) {
    // If never shown or in draft, don't mark unhealthy
    return true;
  }

  const isRecentlyEdited =
    getTimeDiffInHours(moment(new Date()), moment(command.modified)) <= ALLOWED_HOURS_SINCE_LAST_EDIT;
  if (isRecentlyEdited) {
    return true;
  }

  const isRecentlyAvailable =
    getTimeDiffInHours(max, moment(command.last_available)) <= ALLOWED_HOURS_SINCE_LAST_AVAILABLE;
  return isRecentlyAvailable;
};

export const filterUnhealthyCommands = (commands: IEditorCommandTypeLite[], settings?: IOrganizationSettingsType) => {
  if (settings?.silent_mode) {
    // Don't mark commands as unhealthy if silent mode is on (availability not being tracked)
    return [];
  }

  const max = getMaxLastAvailable(commands);
  return commands.filter((c) => !isCommandHealthy(c, max));
};
