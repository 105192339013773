import React from 'react';
import styled from '@emotion/styled';

import { useAppContext } from '../../Widget';
import axiosInstance from '@commandbar/internal/middleware/network';
import { message } from '../unmodifiedAntdComponents';
import { CB_COLORS } from '@commandbar/design-system/components';
import { Trash04 } from '@commandbar/design-system/icons/react';

import DragUpload, { RcFile } from '.';

const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

const convertToReadableSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(1)}MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(1)}GB`;
  }
};

const UploadedImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 8px;
  border-radius: 4px;
  background: ${CB_COLORS.neutral0};
`;

const UploadedImageOverview = styled.div`
  display: grid;
  grid-template-columns: auto minmax(120px, min-content);
  align-items: center;
  gap: 8px;
`;

const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

const FileName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${CB_COLORS.neutral800};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileSize = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${CB_COLORS.neutral500};
`;

const DeleteIconContainer = styled.div`
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${CB_COLORS.neutral500};
  border-radius: 4px;

  transition: 0.3s all;

  &:hover {
    color: ${CB_COLORS.neutral1000};
    background-color: ${CB_COLORS.neutral0};
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  max-height: 43px;
  object-fit: contain;
`;

interface Image {
  src: string;
  file_name: string;
  size: string;
}

interface ImageUploaderProps {
  onUpload: (image: Image) => void;
  onDelete: () => void;
  thumbnail: Image | null;
}

const ImageUploader = ({ onUpload, onDelete, thumbnail }: ImageUploaderProps) => {
  const { organization } = useAppContext();

  if (thumbnail?.src) {
    return (
      <UploadedImage>
        <UploadedImageOverview>
          <Thumbnail alt={`Thumbnail for ${thumbnail.file_name}`} height="43px" src={thumbnail.src} />
          <ImageInfo>
            <FileName>{thumbnail.file_name}</FileName>
            <FileSize>{thumbnail.size}</FileSize>
          </ImageInfo>
        </UploadedImageOverview>

        <DeleteIconContainer onClick={onDelete}>
          <Trash04 width={16} />
        </DeleteIconContainer>
      </UploadedImage>
    );
  }

  return (
    <DragUpload
      name="image"
      accept={validFileTypes.join(',')}
      action="/presigned_s3/"
      customRequest={(e) => {
        const file = e.file as RcFile;

        axiosInstance
          .post(e.action, {
            key: file.uid,
          })
          .then(({ data }) => {
            const toUpload = new File([file], `${organization.id}/${file.uid}`);

            fetch(data.presigned_url, {
              method: 'put',
              body: toUpload,
            })
              .then((response) => response)
              .then((response) => {
                if (response.ok && e.onSuccess) {
                  e.onSuccess({ ...file, url: data.object_url });
                } else {
                  if (e.onError) {
                    e.onError(response as any);
                  }
                }
              })
              .catch((err) => {
                if (e.onError) {
                  e.onError(err);
                }
              });
          });
      }}
      beforeUpload={(file) => {
        const isValidSize = file.size / 1024 / 1024 < 10;

        if (!isValidSize) {
          message.error('Image must smaller than 10MB.');
        }

        const isValidType = validFileTypes.includes(file.type);

        if (!isValidType) {
          message.error('Image must jpeg, png, or gif.');
        }

        return isValidSize && isValidType;
      }}
      onChange={(e) => {
        const { status } = e.file;
        if (status === 'done') {
          onUpload({ src: e.file.response.url, file_name: e.file.name, size: convertToReadableSize(e.file.size ?? 0) });
          message.success(`${e.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${e.file.name} file upload failed.`);
        }
      }}
      maxCount={1}
      listType="picture"
      showUploadList={{
        showRemoveIcon: false,
      }}
      supportedFilesHelperText=".jpg, .png, or .gif supported"
    />
  );
};

export default ImageUploader;
