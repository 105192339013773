import React from 'react';
import { IOrganizationSettingsType } from '@commandbar/internal/middleware/types';
import { Select } from '../../../shared_components';

type THelpHubPosition = IOrganizationSettingsType['helphub_launcher_position'];

type TPositionOption = {
  label: string;
  value: THelpHubPosition;
};

const positions: TPositionOption[] = [
  {
    label: 'Bottom right',
    value: 'bottomRight',
  },
  {
    label: 'Top right',
    value: 'topRight',
  },
  {
    label: 'Bottom left',
    value: 'bottomLeft',
  },
  {
    label: 'Top left',
    value: 'topLeft',
  },
];

interface ILauncherPositionSelect {
  value: THelpHubPosition;
  onChange: (position: THelpHubPosition) => void;
}

export const LauncherPositionSelect = ({ value, onChange }: ILauncherPositionSelect) => {
  return (
    <Select value={value} style={{ width: '100%' }} onChange={onChange}>
      {positions.map((position) => (
        <Select.Option key={position.value} value={position.value}>
          {position.label}
        </Select.Option>
      ))}
    </Select>
  );
};
