import React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Sender from '../../management/Sender';
import { ReactComponent as RecordSelectorSvg } from '../../img/record_selector.svg';
import { Tooltip } from '../../shared_components';
import { useAppContext } from '../../Widget';
import { ReactComponent as ElementNotFoundSvg } from '../../img/element_not_found.svg';

import { CB_COLORS } from '@commandbar/design-system/components';

const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

const DefaultButton = styled.div<{ disabled?: boolean; unavailable?: boolean }>`
  display: flex;
  padding: 8px;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  background-color: ${CB_COLORS.white};
  color: ${CB_COLORS.neutral500};
  box-sizing: border-box;

  cursor: pointer;
  transition: all 0.3;

  &:hover {
    background-color: ${(props) => (props.disabled ? '' : CB_COLORS.neutral100)};
    color: ${(props) => (props.unavailable ? '#A36A00' : props.disabled ? '' : CB_COLORS.neutral1000)};

    & div {
      color: ${(props) => (props.unavailable ? '#A36A00' : props.disabled ? '' : CB_COLORS.neutral1000)};
    }
  }
`;

const Label = styled.div<{ picked: boolean; unavailable: boolean; disabled?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) =>
    props.disabled
      ? CB_COLORS.neutral500
      : props.picked
      ? props.unavailable
        ? '#A36A00'
        : CB_COLORS.neutral500
      : CB_COLORS.neutral500};
`;

const ElementNotFoundIcon = styled(ElementNotFoundSvg)`
  width: 16px;
`;

interface ITargetElementClickRecorder {
  value: string | string[];
  onValueChange: (value: string[]) => void;
  children?: React.ReactNode;
}

export const TargetElementClickRecorder = ({ value, onValueChange, children }: ITargetElementClickRecorder) => {
  const id = React.useMemo(() => Math.random(), []);
  const appContext = useAppContext();

  const startRecorder = (id: number, startingSelectors: string[], singleStep?: boolean) => {
    Sender.openClickRecorder(id, startingSelectors, singleStep);
  };

  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;
    // no selectors mean the user cancelled the click recorder
    if (data.selectors.length > 0) {
      onValueChange(data.selectors);
    }
    return respondSuccess();
  };

  usePortal({
    proxy: {
      onClickRecorderComplete,
    },
  });

  const [selectorState, setSelectorState] = useState<'ok' | 'invisible' | 'not_found'>('ok');

  useEffect(() => {
    if (value === '') {
      setSelectorState('ok');
    } else {
      Sender.checkIfSelectorValid(value as string).then((result) => {
        if (result.elementFound && result.elementVisible) {
          setSelectorState('ok');
        } else if (result.elementFound && !result.elementVisible) {
          setSelectorState('invisible');
        } else {
          setSelectorState('not_found');
        }
      });
    }
  }, [value]);

  return (
    <Tooltip
      showIf={appContext?.isStandaloneEditor}
      placement="bottom"
      content="Install in your app to use the click recorder"
    >
      <Tooltip
        showIf={selectorState !== 'ok' && !appContext?.isStandaloneEditor}
        placement="bottom"
        content={
          selectorState === 'invisible'
            ? 'Target element found on this page, but is hidden'
            : 'Target element not found on this page'
        }
      >
        <Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (appContext?.isStandaloneEditor) {
              return;
            }
            startRecorder(id, [], true);
          }}
        >
          {!!children ? (
            <>{children}</>
          ) : (
            <DefaultButton>
              <Label
                disabled={appContext?.isStandaloneEditor}
                picked={value !== ''}
                unavailable={selectorState !== 'ok'}
              >
                <RecordSelectorSvg width={12} />
                {value === '' ? 'Pick Target...' : 'Change Target'}
                {selectorState !== 'ok' && <ElementNotFoundIcon />}
              </Label>
            </DefaultButton>
          )}
        </Container>
      </Tooltip>
    </Tooltip>
  );
};
