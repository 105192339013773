import React from 'react';
import { useHistory } from 'react-router-dom';

import queryString from 'query-string';

import { Form, Input } from '../shared_components';
import { Button } from './styled';
import { CB_COLORS } from '@commandbar/design-system/components';

import Auth from '@commandbar/internal/client/authentication';
import { FormLayout2 } from './FormLayout';
import { AiFillMail } from 'react-icons/ai';
import Sender from '../management/Sender';

const LoginWithSSO = (_props: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState('');
  const history = useHistory();

  React.useEffect(() => {
    const _error = queryString.parse(window.location.search)?.error;
    if (!!_error) {
      setError(error);
      history.replace('/login/sso');
    }
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);

    const auth_redirect = window.location.origin;

    let hostURL = (await Sender.getHostUrl()) as any;
    hostURL = hostURL?.url;

    // FIXME: The fallback value should be Org.base_url
    const final_redirect = hostURL ?? 'https://app.commandbar.com';

    const authorization_url = await Auth.sso(values.email, auth_redirect, final_redirect);

    if (!authorization_url) {
      setError('Invalid domain.');
      setLoading(false);
    } else {
      Sender.secureEditor();
      window.open(authorization_url);
    }
  };

  const onChange = () => {
    setLoading(false);
  };

  return (
    <FormLayout2 h1="Sign in to continue" h2="" error={error}>
      <div>
        <p>{"Single sign-on allows you to log in to CommandBar.com using your organization's identity provider."}</p>
        <p>
          {
            'If you are unsure whether your organization is using single sign-on, please reach out to your administrator.'
          }
        </p>
      </div>
      <Form layout="vertical" initialValues={{}} onFinish={onFinish} autoComplete="off">
        <Form.Item label="Email" name="email" id="email">
          <Input
            prefix={<AiFillMail />}
            placeholder="Enter your corporate email"
            allowClear={true}
            type="email"
            autoComplete="email"
            onChange={onChange}
          />
        </Form.Item>
        <Form.Item style={{ margin: '0px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={loading} block>
            {loading ? '...' : 'Single Sign-On'}
          </Button>
        </Form.Item>
        <div
          style={{
            fontWeight: 650,
            color: CB_COLORS.primary,
            cursor: 'pointer',
            marginTop: '12px',
            textAlign: 'center',
          }}
          onClick={() => history.push('/login')}
        >
          Cancel
        </div>
      </Form>
    </FormLayout2>
  );
};

export default LoginWithSSO;
