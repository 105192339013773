import {
  INudgeStepType,
  INudgeStepContentButtonBlockType,
  INudgeStepContentMarkdownBlockType,
  INudgeStepContentImageBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeContentSurveyRatingBlockType,
} from '@commandbar/internal/middleware/types';

const mediaTypes = ['image', 'video', 'help_doc_command'];

export const isMediaBlock = (
  block: INudgeStepContentBlockType,
): block is INudgeStepContentImageBlockType | INudgeStepContentVideoBlockType | INudgeStepContentHelpDocBlockType =>
  mediaTypes.includes(block.type);

export const isSurveyBlock = (
  block: INudgeStepContentBlockType,
): block is INudgeStepContentSurveyTextBlockType | INudgeContentSurveyRatingBlockType =>
  block.type.startsWith('survey');

export const contentBlockSortKeys: Record<INudgeStepContentBlockType['type'], number> = {
  markdown: 1,
  image: 2,
  video: 3,
  survey_text: 4,
  survey_rating: 5,
  help_doc_command: 6,
  button: 7,
};

export const findMarkdownBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentMarkdownBlockType['meta'] | undefined =>
  content.find((block): block is INudgeStepContentMarkdownBlockType => block.type === 'markdown')?.meta;

export const findImageBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentImageBlockType['meta'] | undefined =>
  content.find((block): block is INudgeStepContentImageBlockType => block.type === 'image')?.meta;

export const findVideoBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentVideoBlockType['meta'] | undefined =>
  content.find((block): block is INudgeStepContentVideoBlockType => block.type === 'video')?.meta;

export const findHelpDocBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentHelpDocBlockType['meta'] | undefined =>
  content.find((block): block is INudgeStepContentHelpDocBlockType => block.type === 'help_doc_command')?.meta;

export const findButtonBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentButtonBlockType['meta'] | undefined =>
  content.find(
    (block): block is INudgeStepContentButtonBlockType =>
      block.type === 'button' && block.meta?.button_type !== 'secondary',
  )?.meta;

export const findSecondaryButtonBlock = (
  content: INudgeStepType['content'],
): INudgeStepContentButtonBlockType['meta'] | undefined =>
  content.find(
    (block): block is INudgeStepContentButtonBlockType =>
      block.type === 'button' && block.meta?.button_type === 'secondary',
  )?.meta;

export const findActiveMediaContentBlock = (content: INudgeStepType['content']) =>
  content.find(
    (
      block,
    ): block is INudgeStepContentImageBlockType | INudgeStepContentVideoBlockType | INudgeStepContentHelpDocBlockType =>
      isMediaBlock(block),
  );
