import { CB_COLORS } from '@commandbar/design-system/components';
import React from 'react';

const SimplePanel = ({
  header,
  actions,
  children,
}: {
  header?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}) => (
  <div
    style={{
      background: 'white',
      borderRadius: '8px',
      border: `1px solid ${CB_COLORS.neutral400}`,
      minHeight: 0,
      overflow: 'hidden' /* prevent children from overflowing and causing the rounded corners to be cut off */,
    }}
  >
    {header || actions ? (
      <div
        style={{
          height: 48,
          padding: '16px 12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {header}
        <div>{actions}</div>
      </div>
    ) : null}
    <div
      style={{
        ...((header || actions) && { background: CB_COLORS.neutral0 }),
        padding: '16px 12px',
      }}
    >
      {children}
    </div>
  </div>
);

export default SimplePanel;
