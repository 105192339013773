import React from 'react';
import { Button, FormRow, Modal } from '../../../shared_components';
import AutoCompleteTextArea from '../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import { PlusOutlined } from '@ant-design/icons';

import useWindowInfo from '../../../hooks/useWindowInfo';

interface IProps {
  onCreate: (value: string) => void;
}

const NewCategoryModal = (props: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string | undefined>(undefined);
  const { context } = useWindowInfo();

  const { onCreate } = props;

  const MAX_WIDTH = 450;

  const onClose = () => setOpen(false);
  const onTriggerClick = () => setOpen(true);
  const onOk = () => {
    if (value !== undefined) {
      onCreate(value);
      setValue(undefined);
    }
    onClose();
  };

  const contextKeys = Object.keys(context).map((s) => ({ value: `context.${s}`, addOn: 'Active context' }));
  const modalRef = React.useRef<HTMLDivElement>(null);

  const footer = [
    <Button key="close" onClick={onClose}>
      Close
    </Button>,
    <Button key="ok" type="primary" onClick={onOk}>
      Create
    </Button>,
  ];

  return (
    <>
      <Button onClick={onTriggerClick} icon={<PlusOutlined width="12px" height="12px" />}>
        New category
      </Button>
      <Modal
        visible={open}
        onCancel={onClose}
        okText="Create"
        okButtonProps={{ disabled: !value }}
        title="Create a new category"
        width={MAX_WIDTH}
        footer={footer}
      >
        <div style={{ maxWidth: MAX_WIDTH - 50 }} ref={modalRef}>
          <FormRow
            title={'Category name:'}
            overflow="hidden"
            input={
              <AutoCompleteTextArea
                onChange={setValue}
                value={value}
                options={[...contextKeys]}
                container={modalRef.current}
              />
            }
            info={`Your category can reference context.`}
          />
        </div>
      </Modal>
    </>
  );
};

export default NewCategoryModal;
