import React from 'react';
import { Card } from 'antd';

const ClickableCard = (props: {
  title: string;
  description: string;
  onClick: () => void;
  icon: React.ReactNode;
  endIcon?: React.ReactNode;
  color: string;
  size?: 'small' | 'large';
}) => {
  const padding = props.size === 'large' ? '16px 24px' : '8px 16px';
  return (
    <Card
      onClick={props.onClick}
      hoverable={true}
      style={{
        height: '100%',
        margin: '5px',
        borderRadius: 8,
        border: `1px solid ${props.color}30` /* Adds 20% opacity by appending 30 */,
      }}
      bodyStyle={{
        backgroundColor: 'white',
        borderRadius: 8,
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 1px 1px',
        padding,
        height: '100%',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span style={{ color: props.color }}>{props.icon}</span>
        <div style={{ width: 20 }} />
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: 16, fontWeight: 600 }}>{props.title}</span>
          <span style={{ fontSize: 12, opacity: 0.8 }}>{props.description}</span>
        </div>
        <div>{props.endIcon && props.endIcon}</div>
      </div>
    </Card>
  );
};

export default ClickableCard;
