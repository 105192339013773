import { Divider } from 'antd';
import React from 'react';

const Header = (props: {
  text: string | React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const hasActions = props.leftActions || props.rightActions;

  return (
    <div style={{ width: '100%', marginTop: 20, marginBottom: 12, ...props.style }}>
      <div
        style={{
          width: '100%',
          marginBottom: 10,
          ...(hasActions && {
            display: 'flex',
            alignItems: 'flex-end',
          }),
        }}
      >
        <div style={{ fontSize: 11, fontWeight: 600, opacity: 0.5, textTransform: 'uppercase' }}>{props.text}</div>
        {hasActions && <div style={{ flexGrow: 1 }}>{props.leftActions || null}</div>}
        {hasActions && <div>{props.rightActions || null}</div>}
      </div>
      <Divider style={{ margin: 0 }} />
    </div>
  );
};

export default Header;
