// AntDesign components should be imported/exported this way
// because babel-plugin-import doesn't bundle antd styles using export declaration like:
// export { Component } from 'antd';
import {
  Breadcrumb as AntBreadcrumb,
  BreadcrumbItemProps,
  Avatar as AntAvatar,
  AvatarProps,
  Spin as AntSpin,
  SpinProps,
  Space as AntSpace,
  SpaceProps,
  Progress as AntProgress,
  ProgressProps,
  ConfigProvider as AntConfigProvider,
  Statistic as AntStatistic,
  StatisticProps,
  Empty as AntEmpty,
  EmptyProps,
  Carousel as AntCarousel,
  CarouselProps,
  Card as AntCard,
  CardProps,
  Divider as AntDivider,
  DividerProps,
  Typography as AntTypography,
  TypographyProps,
  Tag as AntTag,
  TagType,
  Skeleton as AntSkeleton,
  SkeletonProps,
  Result as AntResult,
  ResultProps,
  Col as AntCol,
  ColProps,
  Row as AntRow,
  RowProps,
  Switch as AntSwitch,
  SwitchProps,
  InputNumber as AntInputNumber,
  InputNumberProps,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { ConfigProviderProps } from 'antd/lib/config-provider';

export type { AlertProps, FormInstance, SiderProps } from 'antd';

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = AntBreadcrumb.Item;
export const Avatar: React.FC<AvatarProps> = AntAvatar;
export const Spin: React.FC<SpinProps> = AntSpin;
export const Space: React.FC<SpaceProps> = AntSpace;
export const Progress: React.FC<ProgressProps> = AntProgress;
export const ConfigProvider: React.FC<ConfigProviderProps> = AntConfigProvider;
export const Statistic: React.FC<StatisticProps> = AntStatistic;
export const Empty: React.FC<EmptyProps> = AntEmpty;
export const Carousel: React.ForwardRefExoticComponent<CarouselProps & React.RefAttributes<CarouselRef>> = AntCarousel;
export const Card: React.FC<CardProps> = AntCard;
export const Divider: React.FC<DividerProps> = AntDivider;
export const Typography: TypographyProps = AntTypography;
export const Tag: TagType = AntTag;
export const Skeleton: React.FC<SkeletonProps> = AntSkeleton;
export const Result: React.FC<ResultProps> = AntResult;
export const Col: React.ForwardRefExoticComponent<ColProps & React.RefAttributes<HTMLDivElement>> = AntCol;
export const Row: React.ForwardRefExoticComponent<RowProps & React.RefAttributes<HTMLDivElement>> = AntRow;
export const Switch: React.FC<SwitchProps> = AntSwitch;
export const InputNumber: React.FC<InputNumberProps> = AntInputNumber;
