import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Input, ITextAreaProps, Skeleton } from '../../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';

const inputResets = css`
  border: none;
  &:focus,
  &:active {
    border: none !important;
    box-shadow: none !important;
    outline: 0;
  }
`;

export const QuestionInput = styled(Input)`
  ${inputResets}
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${CB_COLORS.neutral1000};

  &::placeholder {
    color: ${CB_COLORS.neutral500};
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  ${inputResets}
  font-weight: 400;
  font-size: 14px;
  color: ${CB_COLORS.neutral1000};

  &::placeholder {
    color: ${CB_COLORS.neutral500};
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  padding-left: 16px;
  padding-right: 8px;
`;

interface IAnswerInput extends ITextAreaProps {
  loading: boolean;
}
export const AnswerInput = ({ loading, ...textareaProps }: IAnswerInput) =>
  loading ? (
    <StyledSkeleton active={true} round={true} title={false} paragraph={{ rows: 1, width: '100%' }} />
  ) : (
    <StyledTextArea {...textareaProps} />
  );
