import React from 'react';
import styled from '@emotion/styled';
import { BookmarkCheck, PlusCircle } from '@commandbar/design-system/icons/react';
import {
  DashboardCardBase,
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
} from '@commandbar/design-system/components';
import { useAppContext } from '../../Widget';
import { freshCommand } from '../useEditor';

const GettingStartedDescription = styled.div`
  padding-bottom: 16px;
`;

export const GettingStartedBanner = () => {
  const { dispatch, organization } = useAppContext();

  return (
    <>
      <DashboardCardBase
        styles={{
          height: '330px',
          iconShadow:
            '0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(160, 87, 253, 0.16), inset 1.5px -1.5px 3px rgba(160, 87, 253, 0.25);',
          background: 'linear-gradient(99.43deg, rgb(230.85, 235.68, 255) 6.02%, rgb(255, 255, 255) 26.86%)',
        }}
        icon={<BookmarkCheck width={DASHBOARD_ICON_WIDTH} color={'#2755F0'} />}
        title="Get Started with Answers"
        description={
          <>
            <GettingStartedDescription>
              Provide helpful answers to specific user questions. You can even include a CTA for things like contacting
              support, booking a demo, or joining a waitlist.
            </GettingStartedDescription>
          </>
        }
        actions={[
          {
            icon: <PlusCircle width={DASHBOARD_ACTION_ICON_WIDTH} />,
            label: 'Create Answer',
            onClick: async () => {
              const organizationId = organization?.id.toString();
              if (!organizationId) {
                return;
              }
              try {
                const newCommand = freshCommand(organization, undefined);
                newCommand.template = { type: 'helpdoc', value: '', doc_type: 'answer' };
                newCommand.text = '__untitled__';

                await dispatch.commands.save(newCommand, {
                  notify: false,
                  throttle: true,
                  setToActiveIfNew: false,
                });
              } catch {
                /**
                 * do nothing
                 */
              }
            },
          },
        ]}
      />
    </>
  );
};
