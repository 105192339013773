import _ from 'lodash';
import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Input,
  Modal,
  Space,
  Tooltip,
  StatusSwitch,
  message,
  SortableList,
  Dropdown,
  Switch,
} from '../../shared_components';
import { useAppContext } from '../../Widget';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ArrowUpRight, Trash04, Settings03 } from '@commandbar/design-system/icons/react';
import * as Command from '@commandbar/internal/middleware/command';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import Sender from '../../management/Sender';
import { SquareButton } from '../components/CommandDetailsHeader/styled';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import Logger from '@commandbar/internal/util/Logger';
import { isEveryConditionRuleValid } from '../commands/CommmandDetail/ConditionRulePanels/helpers';
import { ICommandTableState } from '../useEditor';

import { NudgeContentForm } from './NudgeContentForm';
import { AddNudgeButton } from './AddNudgeButton';

import { CB_COLORS } from '@commandbar/design-system/components';

import '../../shared_components/styles/StatusSwitch.css';

import { INudgeStepContentButtonBlockType, INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { findButtonBlock, findHelpDocBlock, findImageBlock, findVideoBlock, isSurveyBlock } from './utils';
import { useNeedsToUpgradeFoobarPackage } from '../../pre-auth/compatibility';
import Targeting from '../components/Targeting';
import {
  DetailTabPane,
  DetailTabPaneInner,
  DetailTabs,
  OverlaySettingsSection,
  OverlaySettingsContainer,
  MenuIconContainer,
  DetailLink,
} from '../components/styled';
import { PreviewButton } from '../components/PreviewButton';
import { ShareLinkButton } from '../components/ShareLinkButton';
import { HeaderCol, HeaderRow } from '../../shared_components/form';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import { useReportEvent } from '../../shared_components/useEventReporting';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { useUsage } from '../../hooks/useUsage';
import { useIsEditorOpen } from '../../hooks';

interface ValidationError {
  condition: boolean;
  message: string;
  step?: INudgeStepType;
  name?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3px;
`;

const SlugInput = styled(Input.TextArea, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: 0;
  max-width: 200px !important;
  min-height: 0 !important;
  font-size: 16px;
  line-height: 16px !important;
  color: ${CB_COLORS.neutral1000};
  border: ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};

  &:focus,
  &:hover {
    border: 1px solid ${CB_COLORS.neutral500};
  }

  &.ant-input {
    transition: all 0.3s, height 0s, font-size 0s;
  }
`;

const NudgeContentFormContainer = styled.div`
  margin-bottom: 16px;
`;

const CurrentFormAction = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${CB_COLORS.neutral600};
`;

const SlugEditor = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DeleteButton = styled(Button)`
  position: relative;
  top: 1px;
  margin-right: 8px;
`;

const NudgeForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 6px;
  right: 16px;
  z-index: 10;
`;

export const IconContainer = styled.div<{ visible?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

interface NudgeDetailProps {
  initialNudge: INudgeType;
  onClose: () => void;
  allNudges: INudgeType[];
  onDelete: (nudge: INudgeType) => void;
  onSave: (nudge: INudgeType) => Promise<INudgeType>;
}

const checkIsNudgeReferencingStaleCommand = (
  nudge: INudgeType,
  commands: ICommandTableState['commands'],
  button?: INudgeStepContentButtonBlockType['meta'],
) => {
  if (
    button?.action?.type === 'execute_command' &&
    button?.action.meta.command &&
    !commands.find(
      (c) => button?.action?.type === 'execute_command' && Command.commandUID(c) === button?.action.meta.command,
    )
  ) {
    // If the nudge references a command that no longer exists, reset the field and show an error
    return true;
  }

  return (
    nudge.trigger.type === 'on_command_execution' &&
    nudge.trigger.meta.command &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Disabling because tslint is not recognising the meta field on the nudge
    !commands.find((c) => Command.commandUID(c) === nudge.trigger.meta.command)
  );
};

const NudgeDetail = (props: NudgeDetailProps) => {
  const { commands } = useAppContext();
  const params = useParams<{ stepId?: string }>();
  const { onClose } = props;
  const [dirty, setDirty] = React.useState<INudgeType>(props.initialNudge);
  const [activeNudgeFormIndex, setActiveNudgeFormIndex] = React.useState<number | undefined>(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const { reportEvent } = useReportEvent();
  const { exceeding } = useUsage();
  const isEditorOpen = useIsEditorOpen();
  const { unsavedChangesRef } = useAppContext();
  const isDraft = !dirty.is_live;

  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.4.7');

  React.useEffect(() => {
    const stepIndex = dirty.steps.findIndex((step) => step.id === parseInt(params.stepId || ''));
    if (stepIndex !== -1) {
      setActiveNudgeFormIndex(stepIndex);
    }
  }, [params.stepId]);

  React.useEffect(() => {
    return () => {
      Sender.stopNudgePreview();
    };
  }, []);

  React.useEffect(() => {
    // If the editor opens while the nudge is being edited, start previewing it
    if (isEditorOpen) {
      Sender.previewNudge(dirty, activeNudgeFormIndex);
    }
  }, [isEditorOpen]);

  React.useEffect(() => {
    if (
      props.initialNudge.steps.some((step) =>
        checkIsNudgeReferencingStaleCommand(props.initialNudge, commands, findButtonBlock(step.content)),
      )
    ) {
      message.error('Nudge refers to command that does not exist.');
    }

    setDirty(props.initialNudge);
  }, [props.initialNudge, commands]);

  const stepDraftErrors = (step: INudgeStepType): Array<ValidationError> => {
    const button = findButtonBlock(step.content);
    const image = findImageBlock(step.content);
    const video = findVideoBlock(step.content);
    const helpDoc = findHelpDocBlock(step.content);

    return [
      {
        condition: !!button && !button?.label,
        message: 'Button must have a label.',
        step,
      },
      {
        condition: button?.action?.type === 'execute_command' && !button?.action.meta.command,
        message: 'Command must be set.',
        step,
      },
      {
        condition: button?.action?.type === 'click' && (!button?.action.value || button?.action.value.length === 0),
        message: 'Button: path to clickable element must be set.',
        step,
      },
      {
        condition: button?.action?.type === 'link' && (!button?.action.value || button?.action.value.length === 0),
        message: 'Button: link URL must be set.',
        step,
      },
      {
        condition: !!image && !image.src,
        message: 'Image src must be set.',
        step,
      },
      {
        condition: !!video && video.type === 'url' && !video.src,
        message: 'Video url must be set.',
        step,
      },
      {
        condition: !!video && video.type === 'command' && !video.command,
        message: 'Video command must be selected.',
        step,
      },
      {
        condition: !!helpDoc && !helpDoc.command,
        message: 'Help article preview must be set.',
        step,
      },
    ].filter(({ condition }) => condition);
  };

  const audienceDraftErrors = [
    {
      condition: dirty.trigger.type === 'on_command_execution' && !dirty.trigger.meta.command,
      message: 'Trigger: Command must be set.',
    },
    {
      condition: dirty.trigger.type === 'on_event' && !dirty.trigger.meta.event,
      message: 'Trigger: Event name must be set.',
    },
    {
      condition: dirty.trigger.type === 'when_element_appears' && !dirty.trigger.meta.selector,
      message: 'Trigger: Element must be set.',
    },
  ].filter(({ condition }) => condition);

  const errors = (step: INudgeStepType): Array<ValidationError> => {
    const button = findButtonBlock(step.content);
    const surveyBlock = step.content.find((block) => isSurveyBlock(block));

    return [
      {
        condition: step.form_factor.type === 'pin' && !step.form_factor.anchor,
        message: 'Anchor must be set.',
        step,
      },
      {
        condition: !step?.content && !step.title,
        message: 'Both title and content cannot be empty.',
        step,
      },
      {
        condition: !isEveryConditionRuleValid(getConditions(dirty.show_expression)),
        message: 'Some conditions are invalid.',
        step,
      },
      {
        condition: !isEveryConditionRuleValid(
          dirty.audience?.type === 'rule_expression' ? getConditions(dirty.audience.expression) : [],
        ),
        message: 'Custom Audience has invalid conditions.',
        step,
      },
      {
        condition: button?.action?.type === 'link' && !button.action.value,
        message: 'Link URL must be set.',
        step,
      },
      {
        condition: button?.action?.type === 'click' && !button.action.value,
        message: 'Path to clickable element must be set.',
        step,
      },
      {
        condition: !button?.label && !!surveyBlock,
        message: 'Survey steps must include a button to submit the response',
        step,
      },
      {
        condition: !!surveyBlock && !step.title,
        message: 'Survey steps must include a title. You will use that to identify the survey in the results.',
        step,
      },
    ].filter(({ condition }) => condition);
  };

  const allErrors = dirty.steps.flatMap((step, i) => ({
    errors: [...stepDraftErrors(step), ...errors(step)],
    index: i + 1,
  }));

  const hasError =
    allErrors.some((e) => e.errors.some(({ condition }) => condition)) || audienceDraftErrors.some((e) => e.condition);
  const hasDraftError =
    dirty.steps.flatMap(stepDraftErrors).some((e) => e.condition) || audienceDraftErrors.some((e) => e.condition);

  const errorList = (
    _errors: Array<{ errors: Array<ValidationError>; index: number }>,
    audienceErrors: ValidationError[],
  ) => {
    const text = (message: string, index?: number, step?: INudgeStepType) => {
      const stepPrefix = typeof step?.title !== 'undefined' ? `Step ${index} (${step.title}) - ` : '';

      return `${stepPrefix}${message}`;
    };

    return (
      <ul
        style={{
          listStyleType: 'none',
          padding: 0,
        }}
      >
        {_errors.map(({ errors, index }, i) =>
          errors.map(({ message, step }, j) => <li key={`steperror-${i}-${j}`}>{text(message, index, step)}</li>),
        )}
        {audienceErrors.map((error, index) => (
          <li key={`audienceerror-${index}`}>{error.message}</li>
        ))}
      </ul>
    );
  };

  const onAddStep = (formFactorType: INudgeStepType['form_factor']['type']) => {
    const steps: INudgeStepType[] = dirty.steps.map((step) => {
      const buttons = step.content.filter((block) => block.type === 'button');

      if (!!buttons.length) {
        return step;
      }

      return {
        ...step,
        content: [...step.content.filter((block) => block.type !== 'button'), ...buttons],
      };
    });

    const formFactor = ((): INudgeStepType['form_factor'] => {
      switch (formFactorType) {
        case 'modal': {
          return {
            type: formFactorType,
          };
        }
        case 'popover': {
          return {
            type: formFactorType,
            position: 'top-right',
          };
        }
        case 'pin': {
          return {
            type: formFactorType,
            anchor: '',
            is_open_by_default: true,
            offset: {
              x: '0px',
              y: '0px',
            },
          };
        }
      }
    })();

    const newStep: INudgeStepType = {
      id: -1 * new Date().getTime(),
      title: '',
      content: [
        {
          type: 'markdown',
          meta: { value: '' },
        },
        {
          type: 'button',
          meta: {
            label: 'Done',
            action: {
              type: 'no_action',
            },
          },
        },
      ],
      is_live: dirty.is_live,
      form_factor: formFactor,
    };

    reportEvent('nudge item created', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        id: dirty.id,
        form_factor: newStep.form_factor.type,
        blocks: newStep.content.filter((b) => b.type),
        action: newStep.content.filter((a) => a.meta),
      },
    });

    const updatedNudge = { ...dirty, steps: [...steps, newStep] };
    setDirty(updatedNudge);
    setActiveNudgeFormIndex(updatedNudge.steps.length - 1);
    Sender.previewNudge(updatedNudge, updatedNudge.steps.length - 1);
  };

  const onSortStep = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newSteps = [...dirty.steps];
    const removed = newSteps.splice(oldIndexOfMovedObj, 1)[0];

    newSteps.splice(newIndexOfMovedObj, 0, removed);

    setDirty({
      ...dirty,
      steps: newSteps,
    });

    /**
     * Update "active" (expanded) nudge step if necessary
     */
    if (oldIndexOfMovedObj === activeNudgeFormIndex) {
      setActiveNudgeFormIndex(newIndexOfMovedObj);
    } else if (newIndexOfMovedObj === activeNudgeFormIndex) {
      setActiveNudgeFormIndex(oldIndexOfMovedObj);
    }
  };

  const onDeleteStep = (id: number, stepIndex: number) => {
    const steps = dirty.steps.filter((step) => step.id !== id);
    const deleted = dirty.steps[stepIndex];
    setDirty({ ...dirty, steps });
    reportEvent('nudge item deleted', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        id: dirty.id,
        form_factor: deleted.form_factor.type,
        blocks: deleted.content.filter((b) => b.type),
        action: deleted.content.filter((a) => a.meta),
      },
    });
    Sender.stopNudgePreview(stepIndex);
  };

  const onToggleDismissible = () => {
    const newDirty = { ...dirty, dismissible: !dirty.dismissible };
    setDirty(newDirty);
    Sender.previewNudge(newDirty, activeNudgeFormIndex);
  };

  const onToggleShowStepCounter = () => {
    const newDirty = { ...dirty, show_step_counter: !dirty.show_step_counter };
    setDirty(newDirty);
    Sender.previewNudge(newDirty, activeNudgeFormIndex);
  };

  const onBack = React.useCallback(() => {
    if (!_.isEqual(props.initialNudge, dirty)) {
      unsavedChangesRef.current = 'backModalShown';

      Modal.confirm({
        title: 'You have unsaved changes.',
        icon: <ExclamationCircleOutlined />,
        content: 'Leaving will discard these changes.',
        onOk() {
          unsavedChangesRef.current = 'false';
          onClose();
        },
        onCancel() {
          unsavedChangesRef.current = 'true';
        },
        okText: 'Discard changes',
        cancelText: 'Keep editing',
      });
    } else {
      unsavedChangesRef.current = 'false';
      onClose();
    }
  }, [dirty, onClose, props.initialNudge, unsavedChangesRef]);

  const onSave = async (n: INudgeType) => {
    const sanitizedNudge = {
      ...n,
      steps: n.steps.map((step) => ({
        ...step,
        content: step.content.map((content) =>
          content.type === 'markdown'
            ? {
                ...content,
                meta: {
                  ...content.meta,
                  value: sanitizeHtml(content.meta.value),
                },
              }
            : content,
        ),
      })),
    };

    if ((!sanitizedNudge.is_live && !hasDraftError) || !hasError) {
      setIsSaving(true);

      try {
        const updated = await props.onSave(sanitizedNudge);
        // The id has now changed from -1 to the actual id so we should update the preview
        Sender.previewNudge(updated, activeNudgeFormIndex);

        unsavedChangesRef.current = 'false';
      } catch (error) {
        message.error('Error saving nudge.');
        Logger.red('Error saving nudge: ', error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const onChange = React.useCallback(
    (n: Partial<INudgeType>) => {
      setDirty((current) => ({ ...current, ...n }));
      if (!_.isEqual(props.initialNudge, dirty)) {
        unsavedChangesRef.current = 'true';
      }
    },
    [dirty, props.initialNudge, unsavedChangesRef],
  );

  const onResetPreview = (n: Partial<INudgeType>) => {
    const newDirty = { ...dirty, ...n };
    Sender.previewNudge(newDirty, undefined, true);
  };

  const onStepChange = React.useCallback(
    (index: number) => (step: INudgeStepType) => {
      const newVal = {
        ...dirty,
        steps: [...dirty.steps],
      };

      newVal.steps[index] = step;

      reportEvent('nudge item edited', {
        segment: true,
        highlight: true,
        slack: true,
        eventProps: {
          id: dirty.id,
          form_factor: step.form_factor.type,
          blocks: step.content.filter((b) => b.type),
          action: step.content.filter((a) => a.meta),
        },
      });

      onChange(newVal);
      Sender.previewNudge(newVal, activeNudgeFormIndex);
    },
    [dirty, onChange, activeNudgeFormIndex],
  );

  const isNewNudge = Nudge.isNew(props.initialNudge);
  const hasSurveyStep =
    !isNewNudge &&
    props.initialNudge.steps.some((step) =>
      step.content.some((content) => ['survey_rating', 'survey_text'].includes(content.type)),
    );

  React.useEffect(() => {
    Sender.previewNudge(dirty, activeNudgeFormIndex);
  }, [activeNudgeFormIndex]);

  return (
    <Container>
      <HeaderRow justify="space-between" wrap={false} align="middle">
        <HeaderCol>
          <SquareButton style={{ width: '42px' }} onClick={onBack}>
            <ArrowLeftOutlined />
          </SquareButton>

          <PageTitle>
            <CurrentFormAction>{isNewNudge ? 'Create nudge' : 'Edit nudge'}</CurrentFormAction>

            <SlugEditor>
              <SlugInput
                value={dirty.slug}
                onChange={(e) => onChange({ ...dirty, slug: e.target.value })}
                bordered={false}
                spellCheck={false}
                error={allErrors.flatMap(({ errors }) => errors).find(({ name }) => name === 'no_slug')?.condition}
                placeholder="name"
                autoSize
              />
            </SlugEditor>
          </PageTitle>
        </HeaderCol>

        <Space>
          {(!!props.initialNudge.old_nudge_id || Number(props.initialNudge.id) >= 0) && (
            <DeleteButton ghost onClick={() => props.onDelete(dirty)} icon={<Trash04 width={16} />} />
          )}

          <Tooltip
            key={`right-tooltip-status-${dirty.id}`}
            showIf={(!dirty.is_live && hasError) || exceeding.isAtOrOverLiveNudges}
            content={
              exceeding.isAtOrOverLiveNudges ? (
                <span>You have hit your organization's maximum number of allowed live nudges</span>
              ) : (
                errorList(allErrors, audienceDraftErrors)
              )
            }
            placement="top"
          >
            <StatusSwitch
              checked={!!dirty.is_live}
              onChange={(e: boolean) => {
                onSave({ ...dirty, is_live: e, steps: dirty.steps.map((step) => ({ ...step, is_live: e })) });

                if (e) {
                  reportEvent('nudge published', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    eventProps: {
                      id: dirty.id,
                      item_count: dirty.steps.length,
                      audience: dirty.audience?.type,
                      trigger: dirty.trigger,
                    },
                  });
                }
              }}
              onLabel="Live"
              offLabel="Draft"
              disabled={!dirty.is_live && (needsToUpgrade || hasError || isSaving || exceeding.isAtOrOverLiveNudges)}
            />
          </Tooltip>
          <ShareLinkButton meta={{ type: 'nudge', details: dirty, save: onSave }} />
          <PreviewButton
            onClick={() => {
              Sender.hideEditor();
              Sender.stopNudgePreview();
              Sender.previewNudge(dirty, undefined, true);
            }}
          />

          {needsToUpgrade ? (
            <Tooltip content="Please upgrade the `@commandbar/foobar` package (version 0.4.8 or greater) to save changes.">
              <Button type="primary" disabled>
                {dirty.is_live ? 'Publish' : 'Save'}
              </Button>
            </Tooltip>
          ) : (
            <Tooltip
              showIf={isDraft ? hasDraftError : hasError}
              content={
                isDraft
                  ? errorList(
                      dirty.steps.flatMap((step, i) => ({ errors: [...stepDraftErrors(step)], index: i })),
                      audienceDraftErrors,
                    )
                  : errorList(allErrors, audienceDraftErrors)
              }
              placement="left"
            >
              <Button
                type="primary"
                onClick={() => onSave(dirty)}
                disabled={(() =>
                  (isDraft ? hasDraftError : hasError) ||
                  (_.isEqual(props.initialNudge, dirty) && !isNewNudge) ||
                  (_.isEqual(props.initialNudge, dirty) && isNewNudge && !!dirty.old_nudge_id) ||
                  isSaving)()}
              >
                {dirty.is_live ? 'Publish' : 'Save'}
              </Button>
            </Tooltip>
          )}
        </Space>
      </HeaderRow>

      <div style={{ padding: '0 16px' }}>
        <UpgradeCTA product="nudges" />
      </div>

      <NudgeForm>
        <DropdownStyled
          trigger={['click']}
          placement="bottomLeft"
          overlay={
            <OverlaySettingsContainer>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Dismissible
                <Switch
                  checked={dirty.dismissible === undefined ? true : dirty.dismissible}
                  onChange={() => onToggleDismissible()}
                />
              </OverlaySettingsSection>
              {dirty.steps.length > 1 && (
                <OverlaySettingsSection
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Show step counter
                  <Switch checked={dirty.show_step_counter} onChange={() => onToggleShowStepCounter()} />
                </OverlaySettingsSection>
              )}
            </OverlaySettingsContainer>
          }
        >
          <MenuIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Settings03 />
          </MenuIconContainer>
        </DropdownStyled>
        <DetailTabs destroyInactiveTabPane={false} type="card">
          <DetailTabPane tab={'Details'} key="tab-details" style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <SortableList
                nodes={dirty.steps.map((step, index) => (
                  <NudgeContentFormContainer key={`${dirty.id}-${step.id}-NudgeStep-${index}`}>
                    <NudgeContentForm
                      onAccordionClick={() => {
                        if (activeNudgeFormIndex === index) {
                          /**
                           * Deactivates the active nudge form
                           */
                          Sender.stopNudgePreview(index);
                          setActiveNudgeFormIndex(undefined);
                        } else {
                          setActiveNudgeFormIndex(index);
                        }
                      }}
                      expanded={index === activeNudgeFormIndex}
                      index={index}
                      step={step}
                      onStepChange={onStepChange}
                      onDelete={dirty.steps.length <= 1 ? undefined : onDeleteStep}
                    />
                  </NudgeContentFormContainer>
                ))}
                onSort={onSortStep}
                useDragHandle
              />
              <AddNudgeButton onClick={(type) => onAddStep(type)} />
            </DetailTabPaneInner>
          </DetailTabPane>

          <DetailTabPane tab={'Targeting'} key="tab-targeting">
            <DetailTabPaneInner>
              <Targeting dirty={dirty} onChange={onChange} onResetPreview={onResetPreview} />
            </DetailTabPaneInner>
          </DetailTabPane>
          {hasSurveyStep && (
            <DetailTabPane
              tab={
                <DetailLink
                  href={`https://app.commandbar.com/analytics/surveys/${props.initialNudge.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Analytics&nbsp;&nbsp;
                  <ArrowUpRight width={14} height={14} />
                </DetailLink>
              }
              key="tab-analytics"
              disabled={true}
            />
          )}
        </DetailTabs>
      </NudgeForm>
    </Container>
  );
};

export default NudgeDetail;
