import slugify from '@commandbar/internal/util/slugify';
import { useMemo } from 'react';
import { useContextPartition } from '../editor/context/contextSettings/useContextPartition';
import { useContextSettings } from '../editor/context/contextSettings/useContextSettings';
import { useAppContext } from '../Widget';

const useAllSlashFilterKeywords = (excludedId: number | string | null = null) => {
  const { categories, organization } = useAppContext();
  const { records } = useContextPartition();
  const { current: contextSettings } = useContextSettings();

  const allSlashFilters = useMemo(() => {
    const allSlashFilters: string[] = ['all'];
    for (const record of records) {
      if (record.key === excludedId) {
        continue;
      }

      if (contextSettings.hasOwnProperty(record.key)) {
        const keyword = contextSettings[record.key].slash_filter_keyword;
        if (!!keyword) {
          allSlashFilters.push(keyword);
        } else {
          allSlashFilters.push(slugify(record.key));
        }
      }
    }
    for (const cat of categories) {
      if (cat.id !== excludedId) {
        allSlashFilters.push(cat.slash_filter_keyword || slugify(cat.name));
      }
    }

    //FIXME: Find a way to get current labels set in theme for these synthetic categories
    // once we use those names for slash filters
    if (organization?.end_user_recents_enabled) {
      allSlashFilters.push('recents');
    }

    if (organization?.recommendations_type !== 'None') {
      allSlashFilters.push('recommended');
    }

    return allSlashFilters;
  }, [categories, records, excludedId]);

  return allSlashFilters;
};

export default useAllSlashFilterKeywords;
