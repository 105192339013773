import { CB_COLORS } from '@commandbar/design-system/components';
import styled from '@emotion/styled';

export const SubHeading = styled.h3`
  & {
    color: ${CB_COLORS.neutral800};
    font-size: 14px;
    margin: 0;
  }
`;
