/******************************************************************************************************************
 * This file defines settings which are stored locally while a user is logged in.
 * To define a setting, you must define:
 *   - a key (e.g. "darkMode")
 *   - a return type (e.g. boolean)
 *   - a default value (to be used when the getter function is called and the setting isn't set)
 *
 * Then you must use the generic setLocalSetting and getLocalSetting functions
 * to construct setter and getter functions specific to your variable.
 ******************************************************************************************************************/

import LocalStorage from '@commandbar/internal/util/LocalStorage';

/* generic setter and getter functions */
function generateSettingFunctions<T>(
  key: string,
  defaultVal: T,
  inputParser: (val: T) => string,
  outputParser: (val: string) => T,
) {
  const setter = (value: T) => {
    return LocalStorage.set(key, inputParser(value));
  };

  const getter = (removeItem?: boolean) => {
    const storedVal = LocalStorage.get(key, '');
    if (removeItem) LocalStorage.remove(key);

    if (!storedVal) {
      return defaultVal;
    } else {
      return outputParser(storedVal.toString());
    }
  };

  return { setter, getter };
}

function clearLocalSettings() {
  Object.keys(LOCAL_SETTINGS).forEach((key) => {
    localStorage.removeItem(key);
  });
}

/******************************************************************************************************************/

const floatOParser = (value: string) => {
  return parseFloat(value);
};

const floatIParser = (value: number) => {
  return value.toString();
};

const intOParser = (value: string) => {
  return parseInt(value);
};

const intIParser = (value: number) => {
  return value.toString();
};

// eslint-disable-next-line unused-imports/no-unused-vars
const booleanOParser = (value: string) => {
  return value === 'true';
};

// eslint-disable-next-line unused-imports/no-unused-vars
const booleanIParser = (value: boolean) => {
  return value.toString();
};

const stringIOParser = (value: string) => value;

/******************************************************************************************************************/

// key: paneWidth
// return type: float. Represents the width (in pixels) of the editor panee
// defalut value: 550.0
const paneWidth = generateSettingFunctions('paneWidth', 550.0, floatIParser, floatOParser);

const handleY = generateSettingFunctions('handleY', 70, intIParser, intOParser);

const activeCommand = generateSettingFunctions('commandbar.activeCommand', 0, intIParser, intOParser);

const activeTab = generateSettingFunctions('commandbar.activeTab', '', stringIOParser, stringIOParser);

/******************************************************************************************************************/
const LOCAL_SETTINGS = {
  paneWidth,
  handleY,
  activeCommand,
  activeTab,
};

export { LOCAL_SETTINGS, clearLocalSettings };
