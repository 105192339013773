import React, { useMemo, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { SearchInput, Table, Row, Col, Typography, FeatureAnnouncementCard } from '../../shared_components';
import { useAppContext } from '../../Widget';

import { ContextSettingsModal } from './contextSettings/ContextSettingsModal';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import ActiveBadge, { KeyStatus } from './ActiveBadge';
import ReferencedCommandsCell from './ReferencedCommandsCell';
import { freshCommand } from '../useEditor';
import { ContextDataObject, useContextPartition } from './contextSettings/useContextPartition';

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
`;

export const ArgumentChoicesTable = () => {
  const { organization } = useAppContext();

  const [selected, setSelected] = useState<ContextDataObject | undefined>();

  const [searchTerm, setSearchTerm] = React.useState('');

  const { args } = useContextPartition();

  const emptyTextMessage =
    'Arguments are used to let users provide input to commands. Add a set of argument choices from code by using window.CommandBar.addArgumentChoices().';

  const locale = {
    emptyText: emptyTextMessage,
  };

  const filteredData = useMemo(() => {
    return args.filter((data: ContextDataObject) => data.key.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, args]);

  // const openCommand = (command: IEditorCommandTypeLite) => {
  //   history.push(editorRoutes.COMMANDS_ROUTE, { fromRecordsTab: true });
  //   Sender.shareEditorRoute(editorRoutes.COMMANDS_ROUTE);
  //   dispatch.commands.setActiveCommandById(command.id);
  // };

  const openRecordSettings = (record: ContextDataObject) => {
    setSelected(record);
  };

  if (!organization) {
    return null;
  }

  const depluralize = (str: string) => {
    if (str.charAt(str.length - 1) === 's') {
      return str.slice(0, str.length - 1);
    } else {
      return str;
    }
  };

  const createLinkedCommand = (contextKey: string): IEditorCommandType => ({
    ...freshCommand(organization, undefined),
    arguments: {
      [`this_${depluralize(contextKey)}`]: {
        type: 'context',
        value: contextKey,
        order_key: 1,
        label: 'Select from the list below',
      },
    },
    template: {
      type: 'link',
      value: '',
      operation: 'blank',
      commandType: 'object',
      object: contextKey,
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <FeatureAnnouncementCard
        identifier="bar-arguments"
        title={<Row align="middle">Using arguments</Row>}
        docsLink="https://www.commandbar.com/docs/commands/arguments"
      >
        <span>
          Arguments allow you to prompt users for input after a command is selected. They allow you to build custom
          workflows into the bar without needing to build a custom form.
        </span>
      </FeatureAnnouncementCard>
      <br />
      <Row align="middle" style={{ marginBottom: 20 }} gutter={8}>
        <Col flex="1 1 auto">
          <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </Col>
      </Row>
      <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
        <StyledTable
          locale={locale}
          dataSource={filteredData}
          pagination={false}
          onRow={(record: any, _rowIndex: any) => {
            return {
              onClick: (_e: React.MouseEvent) => {
                openRecordSettings(record);
              },
            };
          }}
        >
          <Table.Column
            dataIndex="key"
            title="Arguments"
            key="Title"
            width="21%"
            render={(key: string) => (
              <div style={{ fontWeight: 600, wordWrap: 'break-word', wordBreak: 'break-word' }}>{key}</div>
            )}
          />
          <Table.Column
            dataIndex="active"
            title="Active"
            key="active"
            render={(active: KeyStatus) => (
              <ActiveBadge
                active={active}
                inactiveTooltipContent={
                  <span>
                    No values have been provided for this record. Use{' '}
                    <Typography.Text code>window.CommandBar.addArgumentChoices</Typography.Text> to add values
                  </span>
                }
              />
            )}
          />
          <Table.Column
            dataIndex="commands"
            title="Commands"
            key="commands"
            render={(commands: IEditorCommandType[]) => <ReferencedCommandsCell commands={commands} />}
          />
          <Table.Column
            dataIndex="settings"
            title=""
            key="settings"
            render={(_settings: any, record: ContextDataObject) => (
              <SettingOutlined onClick={() => openRecordSettings(record)} />
            )}
          />
        </StyledTable>

        {selected && (
          <ContextSettingsModal
            isRecord={false}
            onClose={() => setSelected(undefined)}
            data={selected}
            createLinkedCommand={createLinkedCommand}
          />
        )}
      </div>
    </div>
  );
};
