import * as t from 'io-ts';

import { read } from './generics';

export const StripInfoV = t.intersection(
  [
    t.type({
      invoice_date_start: t.union([t.string, t.null]),
      invoice_date_end: t.union([t.string, t.null]),
    }),
    t.partial({}),
  ],
  'StripeInfo',
);

export const UsageV = t.intersection(
  [
    t.type({
      helphub_docs_limit: t.number,
      helphub_docs_usage: t.number,
      helphub_message_limit: t.number,
      helphub_message_usage: t.number,
      commands_live_limit: t.number,
      commands_live_usage: t.number,
      nudges_live_limit: t.number,
      nudges_live_usage: t.number,
      questlists_live_limit: t.number,
      questlists_live_usage: t.number,
    }),
    t.partial({}),
  ],
  'Usage',
);

export class Billing {
  public static readStripeInfo = read(StripInfoV, 'billing/stripe');
  public static readUsage = read(UsageV, 'billing/usage');
}
