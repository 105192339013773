// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const IntersectSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16 8v4.8c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C14.4802 16 13.9201 16 12.8 16H8v-4.8c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C9.5198 8 10.08 8 11.2 8H16Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 5.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 2 4.08 2 5.2 2h7.6c1.1201 0 1.6802 0 2.108.218.3763.1917.6823.4977.874.874C16 3.5198 16 4.08 16 5.2v7.6c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C14.4802 16 13.9201 16 12.8 16H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 14.4802 2 13.9201 2 12.8V5.2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 11.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C9.5198 8 10.08 8 11.2 8h7.6c1.1201 0 1.6802 0 2.108.218.3763.1917.6823.4977.874.874.218.4278.218.988.218 2.108v7.6c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C20.4802 22 19.9201 22 18.8 22h-7.6c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C8 20.4802 8 19.9201 8 18.8v-7.6Z"
    />
  </svg>
);
export default IntersectSquare;
