import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import * as Command from '@commandbar/internal/middleware/command';
import { IChecklistItem } from '@commandbar/internal/middleware/types';
import React from 'react';
import { FormRow, Input, Select, Tooltip } from '../../shared_components';
import { useAppContext } from '../../Widget';
import { ClickRecorder, isSelectorFragile, SELECTOR_WARNING_TEXT } from '../commands/CommmandDetail/ActionDetailPanel';
import { CategorySelect } from '../commands/CommmandDetail/CategorySelect';
import AutoCompleteTextArea from '../components/AutoCompleteTextArea/AutoCompleteTextArea';
import RouterWarning from '../RouterWarning';
import { nudgeTitleDisplay } from '../nudges/NudgeList';

const ActionEditor = (props: {
  action: IChecklistItem['action'];
  onActionChange: (action: IChecklistItem['action']) => void;
  cta: IChecklistItem['cta'];
  onCTAChange: (cta: IChecklistItem['cta']) => void;
  showRouterWarning?: boolean;
}) => {
  const { action, onActionChange, cta, onCTAChange } = props;

  const { commands, nudges } = useAppContext();

  const ref = React.useRef(action.type === 'link' ? action.operation : undefined);
  const categoryRef = React.useRef(action.type === 'open_bar' ? action.categoryFilter : undefined);

  return (
    <>
      <FormRow
        title="When clicked..."
        input={
          <Select
            value={action.type}
            style={{ width: '100%' }}
            onChange={(e) => {
              switch (e) {
                case 'execute_command':
                  onActionChange({ type: 'execute_command', meta: { command: '' } });
                  break;
                case 'link':
                  onActionChange({ type: 'link', operation: undefined, value: '' });
                  break;
                case 'click':
                  onActionChange({ type: 'click', value: [] });
                  break;
                case 'nudge':
                  onActionChange({ type: 'nudge', value: -1 });
                  break;
                case 'open_bar':
                  onActionChange({ type: 'open_bar', value: '' });
                  break;
              }
            }}
          >
            <Select.Option value={'link'}>Visit page</Select.Option>
            <Select.Option value={'click'}>Click element</Select.Option>
            <Select.Option value={'execute_command'}>Execute command</Select.Option>
            <Select.Option value={'nudge'}>Trigger nudge</Select.Option>
            <Select.Option value={'open_bar'}>Open Bar</Select.Option>
          </Select>
        }
      />
      {action.type === 'execute_command' && (
        <FormRow
          title="Command to execute:"
          input={
            <Select
              value={commands.find((c) => Command.commandUID(c) === action.meta.command) ? action.meta.command : ''}
              style={{ width: '100%' }}
              onChange={(e) => {
                if (!e) {
                  return;
                }
                onActionChange({ type: 'execute_command', meta: { command: e } });
              }}
              showSearch
              filterOption={(input, option) =>
                !!option?.children && (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {commands.map((c, i) => (
                <Select.Option key={i} value={Command.commandUID(c)}>
                  {c.text}
                </Select.Option>
              ))}
            </Select>
          }
        />
      )}
      {action.type === 'open_bar' && (
        <div style={{ marginTop: 15 }}>
          <FormRow
            title="Initial input:"
            input={
              <Input
                value={action.value as string}
                onChange={(e) => {
                  onActionChange({ type: 'open_bar', value: e.target.value, categoryFilter: categoryRef.current });
                }}
              />
            }
          />
          <FormRow
            title="Category Filter:"
            input={
              <CategorySelect
                emptyLabel="No Category Filter"
                selectedCategoryId={action.categoryFilter}
                onCategorySelect={(id: number) => {
                  onActionChange({ type: 'open_bar', value: action.value, categoryFilter: id >= 0 ? id : undefined });
                  categoryRef.current = id;
                }}
              />
            }
          />
        </div>
      )}
      {/** fixme: add context autocomplete to link selector*/}
      {action.type === 'link' && (
        <div style={{ marginTop: 15 }}>
          <FormRow
            title="Url"
            overflow="hidden"
            input={
              <AutoCompleteTextArea
                onChange={(value: string) => onActionChange({ type: 'link', operation: ref.current, value })}
                value={action.value as string}
                placeholder={'/path/to/page'}
                options={[]}
              />
            }
          />
          {props.showRouterWarning && <RouterWarning />}
          <FormRow
            title="Behavior"
            keepTooltipSpace
            input={
              <Select
                defaultValue={action.operation}
                style={{ width: '100%' }}
                onChange={(e) => {
                  const newOperation = e as 'router' | 'blank' | 'self';
                  onActionChange({ type: 'link', operation: newOperation, value: action.value });
                  ref.current = newOperation;
                }}
              >
                <Select.Option value="blank">New tab</Select.Option>
                <Select.Option value="self">Same tab</Select.Option>
                <Select.Option value="router">Use router</Select.Option>
              </Select>
            }
          />
        </div>
      )}
      {action.type === 'click' && (
        <FormRow
          keepTooltipSpace
          title="Element to click:"
          info="Add an element to click (CSS or XPath) below. For more complicated click actions, create a command and use the 'Execute command' action."
          input={
            <>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    placeholder="CSS selector or XPath"
                    onKeyDown={(e) => e.stopPropagation()}
                    value={action.value[0]}
                    onChange={(e) => onActionChange({ type: 'click', value: [e.target.value] })}
                    suffix={
                      <ClickRecorder
                        onValueChange={([e]) => onActionChange({ type: 'click', value: [e] })}
                        value={action.value[0] || ''}
                        singleStep
                      />
                    }
                  />
                  {isSelectorFragile(action.value[0]) && (
                    <Tooltip content={SELECTOR_WARNING_TEXT}>
                      <WarningOutlined style={{ color: 'rgb(216, 150, 20)', marginLeft: '8px' }} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </>
          }
          prefixedInfo
        />
      )}
      {action.type === 'nudge' && (
        <FormRow
          title="Nudge to trigger:"
          input={
            <Select
              value={nudges.find((n) => n.id === action.value) ? action.value : ''}
              style={{ width: '100%' }}
              onChange={(e) => {
                if (!e || typeof e !== 'number') {
                  return;
                }
                onActionChange({ type: 'nudge', value: e });
              }}
              showSearch
              filterOption={(input, option) =>
                !!option?.children && (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {nudges.map((n, i) => (
                <Select.Option key={i} value={n.id}>
                  {nudgeTitleDisplay(n)}
                </Select.Option>
              ))}
            </Select>
          }
        />
      )}
      <FormRow
        title="Action label:"
        input={
          <Input
            value={cta}
            onChange={(e) => {
              onCTAChange(e.target.value);
            }}
          />
        }
      />
    </>
  );
};

export default ActionEditor;
