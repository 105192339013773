import styled from '@emotion/styled';
import { Divider as AntdDivider, List, Row } from '../../shared_components';

export const Divider = styled(AntdDivider)`
  height: 90%;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
`;

export const Container = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border: 2px solid #e5e4e7;
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  margin: 15px 0px;
  height: 80vh;
  overflow: hidden;
`;

export const ContainerHeader = styled.div`
  padding: 12px 32px;
  background-color: #f5f5f5;
  line-height: 32px;
`;

export const ContainerBody = styled.div`
  padding: 34px 32px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  height: 100%;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const Description = styled.div`
  color: #425a70;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  max-width: 100px;
`;

export const Time = styled.div`
  color: #425a70;
  font-size: 12px;
  line-height: 20px;
  min-width: 60px;
  text-align: left;
`;

export const StyledListItem = styled(List.Item)`
  border-bottom: 1px solid rgba(67, 90, 111, 0.08);
  cursor: pointer;
  height: 36px;
  padding: 6px 12px 6px 16px !important;
  background: transparent;
  transition: background 0.3s ease-in;

  &:hover {
    background: #fff;
  }

  & .ant-list-item-action {
    margin-bottom: 4px;
  }
`;

export const StyledRow = styled(Row)`
  flex-flow: nowrap;
  align-items: center;
`;
