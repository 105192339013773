/* React imports */
import React from 'react';

import { ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';

import { Modal, Switch, Tag, Typography } from '../../../../shared_components';
import { getConditions, isCompoundExpression } from '@commandbar/internal/middleware/helpers/rules';
import { IEditorCommandType, IRuleExpression } from '@commandbar/internal/middleware/types';
import RuleExpressionEditor from './components/RuleExpressionEditor';
import { useNeedsToUpgradeFoobarPackage } from '../../../../pre-auth/compatibility';
import { isEveryConditionRuleValid } from './helpers';

export const RecommendationPanel = (props: {
  command: IEditorCommandType;
  onChange: (expr: IRuleExpression) => void;
  onChangeAlwaysRecommend: (isAlwaysRecommended: boolean) => void;
}) => {
  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.3.17');

  const expr = props.command.recommend_expression;
  const alwaysRecommend = props.command.always_recommend;

  // NOTE: for recommendation rules, an empty compound expression is changed to "false"
  // because the default is to NOT recommend the command
  const _onChange = (newExpr: IRuleExpression) => {
    if (isCompoundExpression(newExpr) && newExpr.exprs.length === 0) {
      return props.onChange({ type: 'LITERAL', value: false });
    }

    props.onChange(newExpr);
  };

  const handleAlwaysRecommendSwitchChange = async (checked: boolean) => {
    if (checked && isCompoundExpression(expr) && expr.exprs.length > 0) {
      const confirmed = await showConfirmationModal();
      if (confirmed) {
        const isEveryRecommendationRuleValid = isEveryConditionRuleValid(getConditions(expr));

        if (!isEveryRecommendationRuleValid) {
          // if any of the recommendation rules are invalid, we clear the rules
          _onChange({ type: 'AND', exprs: [] });
        }
        props.onChangeAlwaysRecommend(true);
      }
    } else {
      props.onChangeAlwaysRecommend(checked);
    }
  };

  const showConfirmationModal = () =>
    new Promise((resolve) => {
      Modal.confirm({
        title: 'Turning this on will disable your current rules. Are you sure?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });

  return (
    <div>
      {needsToUpgrade && (
        <div style={{ marginBottom: '1rem' }}>
          <Tag color="warning">
            <LockOutlined /> Please upgrade the `@commandbar/foobar` package (version 0.3.18 or greater) to use this
            feature.
          </Tag>
        </div>
      )}
      <div>
        <Typography.Text strong>Always recommend</Typography.Text>
        <Switch
          checked={alwaysRecommend}
          disabled={needsToUpgrade}
          onChange={handleAlwaysRecommendSwitchChange}
          style={{ marginLeft: 10 }}
        />
      </div>
      <div style={{ fontSize: 12, marginTop: 12 }}>
        {<RuleExpressionEditor expr={expr} disabled={alwaysRecommend || needsToUpgrade} onChange={_onChange} />}

        {/* <div style={{ fontSize: 12, padding: '0px 20px' }}>
            {rules.map((rule, index) => (
              <div key={`${index}`}>
                <div>
                  <Typography.Text>
                    <pre>{JSON.stringify(rule, null, 2)}</pre>
                  </Typography.Text>
                </div>
              </div>
            ))}
          </div> */}
      </div>
    </div>
  );
};
