import React from 'react';

import Sender from '../../management/Sender';
import { useAppContext } from '../../Widget';
import { Alert } from '../../shared_components';

const EnduserVerificationWarning = () => {
  const { hasVerificationProblem } = useCheckEndUserVerification();

  if (!hasVerificationProblem) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 12 }}
      type="warning"
      showIcon
      message="Identity verification is not configured properly"
      description={
        <div>
          <p>
            When force identity verification is enabled, you must provide the correct HMAC for a user when booting
            CommandBar. Without it, features like Nudges, Questlists, and end-user shortcuts, will not work as expected.
          </p>
          <p>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://app.commandbar.com/identity-verification"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </p>
        </div>
      }
    />
  );
};

const EndUserVerificationInfo = () => (
  <Alert
    style={{ marginBottom: 12 }}
    type="info"
    showIcon
    message="Identity Verification"
    description={
      <div>
        <p>
          For enhanced security, we recommend setting up identity verification. This simple process makes sure that your
          users' settings are kept private.
        </p>
        <p>
          <a
            style={{ textDecoration: 'underline' }}
            href="https://app.commandbar.com/identity-verification"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn how to configure end-user identity verification.
          </a>
        </p>
      </div>
    }
  />
);

export const EndUserVerificationBanner = () => {
  const { hasVerificationProblem } = useCheckEndUserVerification();

  if (!hasVerificationProblem) {
    return null;
  }

  return (
    <Alert
      type="warning"
      message={
        <>
          <span>
            Identity verification is not configured properly.&nbsp;
            <a
              style={{ textDecoration: 'underline' }}
              href="https://app.commandbar.com/identity-verification"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </span>
        </>
      }
      style={{
        padding: '8px 10px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        border: 'none',
      }}
      showIcon
    />
  );
};

export const EndUserVerificationStatus = () => {
  const { hasVerificationProblem, hasForcedEndUserIdentityVerification } = useCheckEndUserVerification();

  if (hasVerificationProblem) {
    return <EnduserVerificationWarning />;
  }

  if (!hasForcedEndUserIdentityVerification) {
    return <EndUserVerificationInfo />;
  }

  return null;
};

export const useCheckEndUserVerification = () => {
  const { isStandaloneEditor, organization } = useAppContext();
  const [hasVerificationProblem, setHasVerificationProblem] = React.useState(false);

  React.useEffect(() => {
    Sender.isUserVerified().then((isEndUserVerified) => {
      setHasVerificationProblem(
        !isStandaloneEditor && !isEndUserVerified && !!organization?.force_end_user_identity_verification,
      );
    });
  }, [isStandaloneEditor, organization]);

  return {
    hasVerificationProblem,
    hasForcedEndUserIdentityVerification: !!organization?.force_end_user_identity_verification,
  };
};
