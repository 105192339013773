import React from 'react';

import { Input, Typography } from '../../../../../shared_components';

import { IEditorAvailabilityRule, isCompoundExpression } from '@commandbar/internal/middleware/helpers/rules';
import { IRuleExpression } from '@commandbar/internal/middleware/types';
import { useAppContext } from '../../../../../Widget';
import { invertAvailabilityRuleOperator } from '../helpers';
import { builtInRules } from '../../../RulesTab/builtInRulesDefintions';

const RuleNotMatchingText = ({ rule }: { rule: IEditorAvailabilityRule }) => {
  const { rules } = useAppContext();

  const displayType: { [key in IEditorAvailabilityRule['type']]: string } = {
    context: '',
    url: 'the current URL',
    element: 'there is a DOM element with',
    named_rule: 'rule',
    executions: 'the users number of executed commands',
    shortcuts: 'the users number of executed shortcuts',
    last_seen: 'the users last seen at',
    first_seen: 'the users first seen at',
    sessions: 'the users number of sessions',
    opens: 'the users number of searches',
    deadends: 'the users number of deadends',
    heap: 'the Heap Segment the user is in',
  };

  const type = displayType[rule.type];

  if (rule.type === 'named_rule') {
    return (
      <>
        {type}{' '}
        <i style={{ textDecoration: 'underline' }}>
          {rules.find(({ id }) => id === rule.rule_id)?.name ||
            builtInRules.find(({ id }) => id === rule.rule_id)?.name ||
            '<unknown>'}
        </i>{' '}
        does not match
      </>
    );
  }

  const { field, operator, value } = rule;
  const valueDisplay = () => {
    if (operator === 'isBefore' || operator === 'isAfter') {
      return ` ${value} days ago`;
    } else if (operator === 'isTrue' || operator === 'isFalse') {
      return;
    }

    return value;
  };

  return (
    <>
      {type} {rule.type === 'context' ? field : null} {invertAvailabilityRuleOperator(operator)} {valueDisplay()}
    </>
  );
};

const ReasonsEditor = ({
  expr,
  disabled = false,
  onChange,
}: {
  expr: IRuleExpression;
  disabled?: boolean;
  onChange: (expr: IRuleExpression) => void;
}) => {
  if (isCompoundExpression(expr)) {
    const updateByIndex = (index: number) => (e: IRuleExpression) => {
      onChange({
        ...expr,
        exprs: [...expr.exprs.slice(0, index), e, ...expr.exprs.slice(index + 1)],
      });
    };

    return (
      <div>
        {expr.exprs.map((e, index) => (
          <ReasonsEditor key={index} disabled={disabled} expr={e} onChange={updateByIndex(index)} />
        ))}
      </div>
    );
  }
  if (expr.type === 'CONDITION') {
    const condition = expr.condition;
    return (
      <div>
        <Typography.Text strong>If </Typography.Text>

        <Typography.Text style={{ background: '#e6e6ff' }}>
          <RuleNotMatchingText rule={condition} />
        </Typography.Text>
        <Input
          disabled={disabled}
          placeholder="(optional) enter a reason to display"
          value={condition.reason}
          onChange={(e) => {
            onChange({ ...expr, condition: { ...condition, reason: e.target.value } });
          }}
          style={{ width: '100%' }}
        />
      </div>
    );
  }

  return null;
};

export default ReasonsEditor;
