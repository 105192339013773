import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';
import { Dropdown } from '../../../../../shared_components';
import { DotsVertical, Check, Grid03 } from '@commandbar/design-system/icons/react';
import { PositionOffsetForm } from './PositionOffsetForm';
import { TNudgeOffset } from '../metadata';
import * as S from './styles';
import { TextInput } from './TextInput';
import { OverlayContainer } from '../../../styled';

const MenuIconContainer = styled.div`
  padding: 8px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 8px;
  color: ${CB_COLORS.neutral800};
  border-top: 1px solid ${CB_COLORS.neutral300};
  &:first-child {
    border-top: none;
  }
`;

const OpenByDefaultSection = styled(FormSection)`
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

const CustomOverlayContainer = styled(OverlayContainer)`
  width: 230px;
`;

const CustomTextInput = styled(TextInput)`
  height: 70px;
`;

interface IPositionInputConfig {
  onToggleIsOpenByDefault: () => void;
  isOpenByDefault: boolean;
  onOffsetChange: (offset: TNudgeOffset) => void;
  offset: TNudgeOffset;
  onTargetChange: (target: string) => void;
  target: string;
}

export const PositionInputConfig = ({
  onToggleIsOpenByDefault,
  isOpenByDefault,
  offset,
  onOffsetChange,
  target,
  onTargetChange,
}: IPositionInputConfig) => {
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <CustomOverlayContainer>
          <OpenByDefaultSection
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggleIsOpenByDefault();
            }}
          >
            <S.IconContainer visible={isOpenByDefault}>
              <Check width={15} />
            </S.IconContainer>
            Open by default
          </OpenByDefaultSection>
          <FormSection
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <S.Col>
              <S.SectionHeader>
                <S.IconContainer visible>
                  <Grid03 width={15} />
                </S.IconContainer>
                Target Element
              </S.SectionHeader>
              <CustomTextInput
                placeholder="CSS or XPath"
                value={target}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onTargetChange(e.target.value);
                }}
                textArea
              />
            </S.Col>
          </FormSection>
          <FormSection>
            <PositionOffsetForm x={offset.x} y={offset.y} onChange={onOffsetChange} />
          </FormSection>
        </CustomOverlayContainer>
      }
    >
      <MenuIconContainer
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <DotsVertical />
      </MenuIconContainer>
    </Dropdown>
  );
};
