import React from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { IUserContext } from '@commandbar/internal/middleware/types';

import '@fontsource/ibm-plex-mono';

import { CheckOutlined } from '@ant-design/icons';
import { Alert, Button } from '../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';

interface IProps {
  contextID: number;
  json: IUserContext;
  onChange: (obj: any) => void;
  readOnly: boolean;
  height?: number;
  outerBoxStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}

class JSONEditor extends React.Component<IProps, { error: any }> {
  public constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  public onChange = (obj: any) => {
    this.setState({ error: obj.error });
    this.props.onChange(obj);
  };

  public render() {
    const alert = this.state.error && (
      <Alert
        message={`JSON formatting error at line: ${this.state.error.line}: ${this.state.error.reason}`}
        type="error"
        showIcon
      />
    );
    return (
      <>
        {alert}
        <JSONInput
          id={`jsoneditor-${this.props.contextID}`}
          key={`jsoneditor-${this.props.contextID}`}
          placeholder={this.props.json}
          locale={locale}
          height={this.props.height || '100%'}
          width={'100%'}
          confirmGood={false}
          waitAfterKeyPress={1000}
          onKeyPressUpdate={false}
          onBlur={this.onChange}
          viewOnly={this.props.readOnly}
          theme="light_mitsuketa_tribute"
          colors={{
            default: CB_COLORS.neutral600,
            background: '#fff',
            string: '#ee1223',
            number: '#2b9e10',
            keys: CB_COLORS.primary,
          }}
          style={{
            outerBox: {
              boxShadow: '0px 4px 8px rgba(10, 10, 15, 0.15)',
              borderRadius: '8px',
              ...this.props.outerBoxStyles,
            },
            container: {
              display: 'flex',
              maxHeight: this.props.height || '300px',
              flexDirection: 'column-reverse',
              padding: '8px',
              ...this.props.containerStyles,
            },
            body: {
              backgroundColor: 'transparent',
              overflow: 'auto',
              fontSize: '12px',
              lineHeight: '20px',
              fontFamily: 'IBM Plex Mono',
            },
            contentBox: { height: 'auto', overflow: 'visible' },
            labelColumn: { overflow: 'visible !important' },
            labels: {
              fontSize: '14px',
              lineHeight: '20px',
            },
          }}
        />
      </>
    );
  }
}

// Enforce validation
export class ValidatedJSONEditor extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public onChange = (e: any) => {
    if (!e.error) {
      this.props.onChange(e);
    }
  };

  public render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button shape="circle" icon={<CheckOutlined />} />
        </div>
        <JSONEditor {...this.props} onChange={this.onChange} />
      </div>
    );
  }
}

export default JSONEditor;
