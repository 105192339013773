import styled from '@emotion/styled';
import { Button, Typography } from '../../../shared_components';

const { Text } = Typography;

export const Container = styled.div`
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, #fafafa 0%, #ffffff 100%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  border-bottom: 1px solid #ddd;
`;

export const Subtitle = styled(Text)`
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  cursor: default;

  color: #141414;
  opacity: 0.5;
`;

export const InnerContainer = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SquareButton = styled(Button)`
  &&& {
    width: 32px;
    height: 32px;
    background: #f2f2f2;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`;

export const Title = styled(Text, { shouldForwardProp: (prop) => prop !== 'placeholder' })<{ placeholder?: boolean }>`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #141414;
  opacity: ${(props) => (props.placeholder ? 0.5 : 1)};
  margin-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ActionContainer = styled.div`
  margin-left: 16px;
`;
