import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as S from '../styled';

import { CursorClick01, Check } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';
import { Dropdown } from '../../../shared_components';

const FooterButton = styled.button<{ active?: boolean; disabled?: boolean }>`
  all: unset;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  font-size: 14px;
  background-color: ${(props) => (props.active ? 'transparent' : CB_COLORS['neutral0'])};
  color: ${(props) => (props.active ? CB_COLORS.neutral1000 : CB_COLORS.neutral600)};
  box-sizing: border-box;
  cursor: pointer;

  transition: all 0.3s;
  font-weight: 500;
  user-select: none;

  &:hover {
    background-color: transparent;
    color: ${CB_COLORS.neutral1000};
  }
`;

const FooterCTAButton = styled(FooterButton)<{ active?: boolean }>`
  &::before {
    content: '';
    position: absolute;
    left: -0.5px;
    width: 1px;
    background-color: ${CB_COLORS.neutral500};
    height: 16px;
  }

  ${(props) =>
    props.active
      ? `
    background-color: transparent;
    color: ${CB_COLORS.neutral1000};
  `
      : ''}
`;

const IconContainer = styled.div`
  margin-right: 6px;
  box-sizing: border-box;
  padding-top: 4px;
`;

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  overflow: hidden;
  font-weight: 500;
`;

interface CtaDropdownProps {
  step: INudgeStepType;
  onCtaToggle: () => void;
  onSecondaryCtaToggle: () => void;
}

export const CtaDropdown = ({ step, onCtaToggle, onSecondaryCtaToggle }: CtaDropdownProps) => {
  const [isCtaVisible, setIsCtaVisible] = useState(false);
  const isDisabled = !step.content.find((block) => block.type === 'button' && block.meta?.button_type !== 'secondary');

  return (
    <Dropdown
      visible={isCtaVisible}
      onVisibleChange={(visible) => setIsCtaVisible(visible)}
      placement="bottomCenter"
      overlay={
        <OverlayContainer>
          <S.DropDownOption
            onClick={onCtaToggle}
            active={!!step.content.find((block) => block.type === 'button' && block.meta?.button_type !== 'secondary')}
          >
            {!!step.content.find((block) => block.type === 'button' && block.meta?.button_type !== 'secondary') && (
              <Check height={16} />
            )}
            Primary button
          </S.DropDownOption>
          <S.DropDownOption
            onClick={() => {
              if (!isDisabled) {
                onSecondaryCtaToggle();
                setIsCtaVisible(false);
              }
            }}
            active={!!step.content.find((block) => block.type === 'button' && block.meta?.button_type === 'secondary')}
            disabled={isDisabled}
          >
            {!!step.content.find((block) => block.type === 'button' && block.meta?.button_type === 'secondary') && (
              <Check height={16} />
            )}
            Secondary button
          </S.DropDownOption>
        </OverlayContainer>
      }
    >
      <FooterCTAButton active={!!step.content.find((block) => block.type === 'button')}>
        <IconContainer>
          <CursorClick01 height={15} width={15} />
        </IconContainer>
      </FooterCTAButton>
    </Dropdown>
  );
};
