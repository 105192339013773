import React from 'react';
import styled from '@emotion/styled';
import { Trash04 } from '@commandbar/design-system/icons/react';
import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';
import { Switch, StatusBadge } from '../../../shared_components';
import { CB_COLORS, Tooltip } from '@commandbar/design-system/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled(Row)`
  width: calc(100% - 30px);
`;

const ArrowIconContainer = styled.div<{ expanded: boolean }>`
  margin-right: 10px;
  color: ${CB_COLORS.neutral500};
  transform: rotateX(${(props) => (props.expanded ? '180deg' : '0deg')});
  transition: 0.2s all;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeleteIconContainer = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${CB_COLORS.neutral500};

  transition: 0.3s all;

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

const StyledSwitch = styled(Switch)`
  width: 32px;
  height: 16px;
`;
interface IHeader {
  expanded: boolean;
  title?: string;
  isLive: boolean;
  onIsLiveToggle: (checked: boolean) => void;
  onDelete: () => void;
}
export const Header = ({ title, expanded, isLive, onIsLiveToggle, onDelete }: IHeader) => {
  const onIsLiveToggleHandler = (checked: boolean, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onIsLiveToggle(checked);
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };
  return (
    <Container>
      <TitleRow>
        <ArrowIconContainer expanded={expanded}>
          <CaretDown />
        </ArrowIconContainer>
        <Title>{!!title && title.length > 0 && !expanded ? title : 'Q&A'}</Title>
      </TitleRow>
      <Row>
        {expanded ? (
          <>
            <DeleteIconContainer onClick={onDeleteHandler}>
              <Trash04 width={16} />
            </DeleteIconContainer>
            <Tooltip
              content={"You can't publish an answer without a title."}
              showIf={!title && !isLive}
              placement="left"
            >
              <StyledSwitch onChange={onIsLiveToggleHandler} checked={isLive} disabled={!title && !isLive} />
            </Tooltip>
          </>
        ) : (
          <StatusBadge color={isLive ? 'green' : 'orange'} />
        )}
      </Row>
    </Container>
  );
};
