import React from 'react';
import { IArgumentType } from '@commandbar/internal/middleware/types';
import { FormRow, Input } from '../../../../../../shared_components';
import { Caption as CaptionComponent } from './styled';
import styled from '@emotion/styled';

interface IProps {
  argumentName: string;
  data: IArgumentType;
  onUpdate: (arg: string, val: any) => void;
}

const Caption = styled(CaptionComponent)`
  margin-left: 16px;
  margin-top: -10px;
`;

export const ArgumentPrompt = ({ argumentName, data, onUpdate }: IProps) => {
  return (
    <>
      <FormRow
        title="Prompt"
        description={<Caption>The placeholder label you see when opening this argument</Caption>}
        input={
          <>
            <Input
              value={data.label}
              placeholder={'The prompt users see when opening this argument'}
              onChange={(e: any) => {
                onUpdate(argumentName, { label: e.target.value });
              }}
            />
          </>
        }
      />
    </>
  );
};
