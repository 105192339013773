import { Slider as AntSlider } from 'antd';
import styled from '@emotion/styled';

const Slider = styled(AntSlider)`
  & {
    .ant-slider-track,
    .ant-slider-rail {
      border-radius: 0;
    }

    .ant-slider-step {
      left: 4px;
    }

    .ant-slider-dot {
      width: 0;
      border-radius: 0;
      border-width: 1px;
      height: 12px;
      top: -4px;

      &.ant-slider-dot-active {
      }
    }

    .ant-slider-mark-text {
      max-width: 65px;
    }
  }
`;

export default Slider;
