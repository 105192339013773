import React, { useEffect, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { SortableMultiSelect } from '../../shared_components/Select/SortableMultiSelect';
import { Button } from '../../shared_components';
import * as Command from '@commandbar/internal/middleware/command';
import { useAppContext } from '../../Widget';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

interface Props {
  organization: IOrganizationType;
  onChange: Function;
}

const FallbackCommandsSettings = (props: Props) => {
  const { commands } = useAppContext();
  const [showSelect, setShowSelect] = React.useState<boolean>(false);

  const options = commands
    .filter((command) => !Command.isProgrammatic(command) && Command.showInDefaultList(command))
    .map((command) => ({
      label: command.text,
      value: command.id,
    }));

  const fallbackCommands = useMemo(() => {
    const fallbacks = props.organization.fallback_commands;

    return commands
      .filter((command) => fallbacks.indexOf(command.id) !== -1)
      .sort((a, b) => fallbacks.indexOf(a.id) - fallbacks.indexOf(b.id));
  }, [props.organization, commands]);

  useEffect(() => {
    if (fallbackCommands.length > 0) {
      setShowSelect(true);
    }
  }, [fallbackCommands]);

  const onChangeOrSortEnd = async (selected: typeof options) => {
    const newFallbackCommands = selected.map((o) => o.value);
    await OrganizationSettings.update({
      fallback_commands: newFallbackCommands,
    });
    props.onChange('fallback_commands', newFallbackCommands, false);
  };

  return (
    <>
      {fallbackCommands.length || showSelect ? (
        <SortableMultiSelect
          options={options}
          defaultValue={fallbackCommands.map((command) => ({
            label: command.text,
            value: command.id,
          }))}
          onSortEnd={onChangeOrSortEnd}
          onChange={onChangeOrSortEnd}
          menuPortalTarget={document.body}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="default" icon={<PlusOutlined />} size="large" onClick={() => setShowSelect(true)}>
            Add a fallback command
          </Button>
        </div>
      )}
    </>
  );
};

export default FallbackCommandsSettings;
