/*******************************************************************************/
/* Imports
/*******************************************************************************/

import { createObject, updateObject, deleteObject, decodeThrowing, createObjectWithDecoder } from './generics';
import { defaults, NamedRuleV } from './helpers/rules';

export class Rule {
  public static decode = (data: any) => decodeThrowing(NamedRuleV, { ...defaults, ...data });

  public static create = createObjectWithDecoder(Rule.decode, NamedRuleV, 'rules', { replace_existing_uses: 'false' });
  public static createFromExistingCondition = createObject(NamedRuleV, NamedRuleV, 'rules', {
    replace_existing_uses: 'true',
  });
  public static update = updateObject(NamedRuleV, NamedRuleV, 'rules');
  public static delete = deleteObject(NamedRuleV, 'rules');
}
