import React from 'react';
import { Button, FormRow, Modal } from '../../../shared_components';
import { PlusOutlined } from '@ant-design/icons';
import { CategorySelect } from '../CommmandDetail/CategorySelect';

interface IProps {
  onCreate: (categoryID: number) => void;
  disabled?: boolean;
}

const NewCommandModal = (props: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number | undefined>(undefined);

  const { onCreate } = props;

  const MAX_WIDTH = 450;

  const onClose = () => setOpen(false);
  const onTriggerClick = () => {
    if (props.disabled) {
      return;
    }
    setOpen(true);
  };
  const onOk = () => {
    if (value !== undefined) {
      onCreate(value);
    }
    onClose();
  };

  return (
    <>
      <Button
        onClick={onTriggerClick}
        icon={<PlusOutlined width="12px" height="12px" />}
        type="primary"
        disabled={props.disabled}
      >
        New command
      </Button>
      <Modal
        visible={open}
        onCancel={onClose}
        onOk={onOk}
        okText="Create"
        okButtonProps={{ disabled: !value }}
        title="Create a new command"
        width={MAX_WIDTH}
      >
        <div style={{ maxWidth: MAX_WIDTH - 50 }}>
          <FormRow
            title={'Category:'}
            input={<CategorySelect selectedCategoryId={undefined} onCategorySelect={(id: number) => setValue(id)} />}
          />
        </div>
      </Modal>
    </>
  );
};

export default NewCommandModal;
