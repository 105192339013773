import { IChecklist, INudgeType } from '@commandbar/internal/middleware/types';
import _ from 'lodash';
import React from 'react';
import AutoComplete from '../../../shared_components/Autocomplete';
import { Description, TextContainer } from './ActionTypeSelector/components/styled';

type Props = {
  onChange: (eventname: string) => void;
  initialEventName: string;
  checklists: IChecklist[];
  nudges: INudgeType[];
};

export const EventAutoComplete = (props: Props) => {
  const allRreferencedEvents: string[] = [];

  for (const checklist of props.checklists) {
    if (checklist.trigger.type === 'on_event' && !checklist.trigger.meta.event.startsWith('commandbar-')) {
      allRreferencedEvents.push(checklist.trigger.meta.event);
    }

    for (const item of checklist.items) {
      if (item.goal.type === 'event_tracked' && !item.goal.event.startsWith('commandbar-')) {
        allRreferencedEvents.push(item.goal.event);
      }
    }
  }

  for (const nudge of props.nudges) {
    if (nudge.trigger.type === 'on_event' && !nudge.trigger.meta.event.startsWith('commandbar-')) {
      allRreferencedEvents.push(nudge.trigger.meta.event);
    }
  }

  const referencedEvents = _.uniq(allRreferencedEvents);

  return (
    <AutoComplete
      size="small"
      style={{ width: '100%' }}
      onChange={props.onChange}
      options={[
        {
          label: 'Custom events already referenced by nudges or questlists',
          options:
            referencedEvents.length > 0
              ? referencedEvents.map((c) => ({ label: c, value: c }))
              : [
                  {
                    label: (
                      <span
                        style={{
                          opacity: 0.6,
                          marginRight: 5,
                          fontSize: 10,
                        }}
                      >
                        No event triggers used yet
                      </span>
                    ),
                    value: '',
                  },
                ],
        },
        {
          label: 'Built-in events',
          options: [
            {
              value: 'commandbar-opened',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Bar opened</div>
                  <Description>
                    Name: <code>commandbar-opened</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-closed',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Bar closed</div>
                  <Description>
                    Name: <code>commandbar-closed</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-abandoned_search',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Deadend in the bar</div>
                  <Description>
                    Name: <code>commandbar-abandoned_search</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-command_suggestion',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a user suggests a command</div>
                  <Description>
                    Name: <code>commandbar-command_suggestion</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-command_execution',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>On command execution</div>
                  <Description>
                    Name: <code>commandbar-command_execution</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-no_results_for_query',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a search in the bar returns no results</div>
                  <Description>
                    Name: <code>commandbar-no_results_for_query</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-nudge_shown',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Nudge shown</div>
                  <Description>
                    Name: <code>commandbar-nudge_shown</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-nudge_clicked',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Nudge clicked</div>
                  <Description>
                    Name: <code>commandbar-nudge_clicked</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-preview_shown',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Preview shown</div>
                  <Description>
                    Name: <code>commandbar-preview_shown</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-shortcut_edited',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a user assigns or edits a shortcut</div>
                  <Description>
                    Name: <code>commandbar-shortcut_edited</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-client_error',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>An error encountered by CommandBar</div>
                  <Description>
                    Name: <code>commandbar-client_error</code>
                  </Description>
                </TextContainer>
              ),
            },
          ],
        },
      ]}
      value={props.initialEventName}
    />
  );
};
