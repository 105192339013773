import React from 'react';
import styled from '@emotion/styled';
import { Check } from '@commandbar/design-system/icons/react';
import { Dropdown, DropdownProps } from '../../../shared_components';
import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';
import {
  OverlayCheckIconContainer,
  OverlayDisplayValue,
  OverlaySettingsContainer,
  OverlaySettingsOption,
} from '../../components/styled';

const Container = styled.div`
  position: relative;
`;

const CaretIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export type TOption<T> = {
  label: React.ReactNode;
  value: T;
  disabled?: boolean;
};

export interface INudgeDropdown<T> {
  children: React.ReactNode;
  value?: T;
  onChange?: (value: T) => void;
  customOverlay?: React.ReactNode;
  options?: TOption<T>[];
  gap?: React.CSSProperties['gap'];
  placement?: DropdownProps['placement'];
  caret?: boolean;
  overlayWidth?: string;
  disabled?: boolean;

  /**
   * If customOverlay is used then you must manage the
   * visible state of the overlay manually via visible and onVisibleChange
   * to close the overlay on option click
   */
  visible?: DropdownProps['visible'];
  onVisibleChange?: DropdownProps['onVisibleChange'];
}

export const NudgeDropdown = <T extends string | undefined>({
  onChange,
  value,
  disabled,
  children,
  options,
  customOverlay,
  gap = '10px',
  placement = 'bottomRight',
  caret = true,
  overlayWidth,
  visible,
  onVisibleChange,
}: INudgeDropdown<T>) => {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dropdown
        trigger={['click']}
        onVisibleChange={(isVisible) => {
          if (onVisibleChange !== undefined) {
            onVisibleChange(isVisible);
          } else {
            setIsOverlayVisible(isVisible);
          }
        }}
        disabled={disabled}
        visible={visible !== undefined ? visible : isOverlayVisible}
        overlay={
          <OverlaySettingsContainer width={overlayWidth}>
            {customOverlay ||
              options?.map((option, index) => (
                <OverlaySettingsOption
                  key={`${option.label}${index}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (option.disabled || disabled) {
                      return;
                    }
                    setIsOverlayVisible(false);
                    if (onChange) {
                      onChange(option.value);
                    }
                  }}
                  disabled={option.disabled || disabled}
                >
                  <OverlayCheckIconContainer visible={value === option.value}>
                    <Check width={13} />
                  </OverlayCheckIconContainer>
                  {option.label}
                </OverlaySettingsOption>
              ))}
          </OverlaySettingsContainer>
        }
        placement={placement}
      >
        <OverlayDisplayValue gap={gap}>
          {children}
          {caret && (
            <CaretIconContainer>
              <CaretDown />
            </CaretIconContainer>
          )}
        </OverlayDisplayValue>
      </Dropdown>
    </Container>
  );
};
