import {
  BlockOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { IChecklist } from '@commandbar/internal/middleware/types';
import { useLocation, useParams, useHistory } from 'react-router';
import * as questlistSearchParams from '@commandbar/internal/proxy-editor/questlist_search_params';
import { CHECKLIST_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import React from 'react';
import {
  Button,
  DropdownMenu,
  Empty,
  FeatureAnnouncementCard,
  message,
  Modal,
  Row,
  StatusBadge,
  Table,
} from '../../shared_components';
import { useAppContext } from '../../Widget';
import questlistAnnouncementGif from '../../img/questlistAnnouncement.gif';
import ChecklistDetail from './ChecklistDetail';
import { PaddingContainer } from '../../shared_components/layout';
import * as templates from './checklist_templates';
import WidgetTemplate from '../components/templates/WidgetTemplate';
import { template_cards } from './templates';
import { UpgradeCTA } from '../components/UpgradeCTA';

const ChecklistList = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ checklistId?: string }>();
  const { loading: appContextLoading, checklists, organization } = useAppContext();
  const [activeChecklist, setActiveChecklist] = React.useState<IChecklist | undefined>(undefined);
  const [showTemplate, setShowTemplate] = React.useState<boolean | undefined>(false);
  const { dispatch } = useAppContext();

  const updateActiveChecklist = (checklist: IChecklist | undefined) => {
    setActiveChecklist(checklist);
    if (!!checklist) {
      history.replace(`${CHECKLIST_ROUTE}/${checklist.id}`);
    } else {
      history.replace(CHECKLIST_ROUTE);
    }
  };

  const createFromTemplate = (checklist?: IChecklist) => {
    if (!organization) {
      return;
    }

    const newChecklist = !!checklist ? checklist : templates.emptyChecklist;

    updateActiveChecklist({
      ...newChecklist,
    });
  };

  React.useEffect(() => {
    if (appContextLoading || !organization) return;
    if (!params.checklistId && !location.search) {
      setActiveChecklist(undefined);
      return;
    }
    const questlist = checklists.find((c) => c.id === Number(params.checklistId));
    if (!questlist && !!params.checklistId && Number(params.checklistId) >= 0) {
      history.replace(CHECKLIST_ROUTE);
    }
    if (questlist) {
      if (activeChecklist === undefined || activeChecklist.id !== questlist.id) updateActiveChecklist(questlist);
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const template = searchParams.get(questlistSearchParams.QUESTLIST_TEMPLATE);
    switch (template) {
      case questlistSearchParams.QUESTLIST_EMPTY_TEMPLATE_PARAM_VALUE: {
        return updateActiveChecklist(templates.emptyChecklist);
      }
      default: {
        break;
      }
    }
  }, [organization, location.search, activeChecklist, params.checklistId, appContextLoading]);

  if (appContextLoading || !organization) {
    return null;
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, { is_live = false }) =>
        is_live ? (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
        ) : (
          <StatusBadge color="orange" text="Draft" />
        ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, checklist: IChecklist) => {
        return (
          <DropdownMenu
            keyName="checklist-actiions"
            items={[
              {
                name: 'Edit',
                icon: <EditOutlined />,
                onClick: () => setActiveChecklist(checklist),
              },
              {
                name: 'Duplicate',
                icon: <BlockOutlined />,
                onClick: () => {
                  const newCheckList = {
                    ...checklist,
                    id: -1,
                    title: `Copy of ${checklist.title}`,
                    is_live: false,
                    items: checklist.items.map((item) => ({
                      ...item,
                      id: -1,
                    })),
                  };
                  dispatch.checklists.save(newCheckList);
                },
              },
              {
                name: 'Copy id',
                icon: <CopyOutlined />,
                onClick: () => {
                  const element = document.createElement('textarea');
                  element.value = checklist.id.toString();
                  document.body.appendChild(element);
                  element.select();
                  document.execCommand('copy');
                  document.body.removeChild(element);
                  message.info('Questlist id copied to clipboard.');
                },
              },
              {
                name: 'Delete',
                icon: <DeleteOutlined />,
                onClick: () => dispatch.checklists.delete(checklist.id),
              },
            ]}
          />
        );
      },
    },
  ];

  const onDelete = (id: number) => {
    if (id > 0) {
      Modal.confirm({
        icon: <WarningOutlined />,
        title: 'Are you sure to delete this checklist? This cannot be undone.',
        async onOk() {
          await dispatch.checklists.delete(id);
          updateActiveChecklist(undefined);
        },
      });
    }
  };

  if (activeChecklist) {
    return (
      <ChecklistDetail
        key={activeChecklist.id}
        initialChecklist={activeChecklist}
        setInitial={updateActiveChecklist}
        onClose={() => {
          updateActiveChecklist(undefined);
          if (activeChecklist.id > -1) setShowTemplate(undefined);
        }}
        onDelete={onDelete}
        allChecklists={checklists}
      />
    );
  } else if (showTemplate) {
    return (
      <PaddingContainer>
        <WidgetTemplate
          header="New Questlist"
          templates={template_cards}
          onBack={() => setShowTemplate(undefined)}
          createFromTemplate={createFromTemplate}
        />
      </PaddingContainer>
    );
  } else {
    return (
      <PaddingContainer>
        <FeatureAnnouncementCard
          identifier="questlists"
          title={<Row align="middle">Introducing Questlists</Row>}
          img={{
            src: questlistAnnouncementGif,
            alt: 'Onboard your customers with feature-rich, interactive checklists',
          }}
          docsLink="https://www.commandbar.com/docs/questlists/overview"
        >
          <span>Onboard your customers with feature-rich, interactive checklists.</span>
        </FeatureAnnouncementCard>
        <br />

        <UpgradeCTA product="questlists" />

        <br />
        <Row justify="end">
          <Button onClick={() => setShowTemplate(true)} icon={<PlusCircleOutlined />} type="primary">
            Create Questlist
          </Button>
        </Row>
        <Table
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
          }}
          rowClassName="editable-row"
          columns={columns}
          dataSource={checklists.map((n) => ({ ...n, key: n.id }))}
          onRow={(checklist: IChecklist, _rowIndex: any) => {
            return {
              onClick: (_e: React.MouseEvent) => {
                updateActiveChecklist(checklist);
              },
            };
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  "You don't have any questlists yet. Create one by clicking the 'Create Questlist' button above."
                }
              />
            ),
          }}
        />
      </PaddingContainer>
    );
  }
};

export default ChecklistList;
