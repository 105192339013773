// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Trash04 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.2987 16.5193 19 6H5l.7013 10.5193c.1052 1.5782.1578 2.3674.4987 2.9657a3 3 0 0 0 1.2983 1.2147C8.118 21 8.909 21 10.4907 21h3.0186c1.5818 0 2.3727 0 2.9924-.3003A3.0002 3.0002 0 0 0 17.8 19.485c.3409-.5983.3935-1.3875.4987-2.9657Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 3h6M3 6h18m-2 0-.7013 10.5193c-.1052 1.5782-.1578 2.3674-.4987 2.9657a3.0002 3.0002 0 0 1-1.2983 1.2147C15.882 21 15.0911 21 13.5093 21h-3.0186c-1.5818 0-2.3727 0-2.9924-.3003A3 3 0 0 1 6.2 19.485c-.3409-.5983-.3935-1.3875-.4987-2.9657L5 6"
    />
  </svg>
);
export default Trash04;
