import { CB_COLORS } from '@commandbar/design-system/components';
import styled from '@emotion/styled';

const Button = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 6px;

  width: 100%;
  height: 40px;

  background: ${(props) => (props.disabled ? '#E0E2DF' : CB_COLORS.neutral1000)};
  color: ${(props) => (props.disabled ? '#858585' : CB_COLORS.white)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  user-select: none;
`;

export default Button;
