import React, { useEffect, useMemo, useState } from 'react';
import useWindowInfo from '../../../../../hooks/useWindowInfo';
import { useContextPartition } from '../../../../context/contextSettings/useContextPartition';
import getAllValidPathsOfObjectProps from '../../../../utils/getAllValidPathsOfObjectProps';
import _ from 'lodash';
import { AutoComplete } from '../../../../../shared_components';
import { DefaultOptionType } from 'antd/lib/select';

interface IProps {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (newValue: string) => void;
  onBlur?: React.FocusEventHandler;
}

export const ConditionRuleContextAutoComplete = (props: IProps) => {
  const { context } = useWindowInfo();
  const { metadata } = useContextPartition();

  const options = useMemo(() => {
    const paths = metadata.flatMap(({ key }) => {
      const paths = getAllValidPathsOfObjectProps(context[key], key);
      if (paths.length > 0) return paths;
      else return [key];
    });

    // paths can be duplicated;
    // metadata paths referenced by commands are included in `metadata` so e.g.
    // there can be a "user" object in metadata which expands above to
    // ['user.id', 'user.name', 'user.email'] and then a command references
    // 'user.id', we will have a duplicate 'user.id' path
    return _.uniq(paths).map((path) => ({ label: path, value: path }));
  }, [metadata, context]);

  const { value, disabled = false, placeholder = 'field', onChange, onBlur } = props;
  const [searchValue, setSearchValue] = useState<string>(value || '');

  const [filteredOptions, setFilteredOptions] = useState<DefaultOptionType[]>(options);

  const filter = (searchText: string) => {
    const filtered = options.filter((option) => option.label.includes(searchText));

    if (filtered.length > 0) {
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  useEffect(() => {
    filter(searchValue);
  }, [options.length]);

  const onSearch = (searchText: string) => {
    setSearchValue(searchText);
    filter(searchText);
  };

  const handleChange = (fieldName: string) => {
    onChange(fieldName);
  };

  return (
    <AutoComplete
      style={{ width: '100%', minWidth: 80 }}
      size="small"
      options={filteredOptions}
      disabled={disabled}
      dropdownStyle={{ minWidth: 200 }}
      onSearch={onSearch}
      filterOption={false}
      onChange={handleChange}
      placeholder={placeholder}
      onBlur={onBlur}
      defaultValue={value}
    />
  );
};
