import { DEFAULTS_DARK, DEFAULTS_LIGHT } from '@commandbar/internal/client/theme';
import styled from '@emotion/styled';
import { Button } from '../../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';

export const WhiteButton = styled(Button)`
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral300};
  border-radius: 4px;
  padding: 4px 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: ${CB_COLORS.neutral1000};
  line-height: 20px;
  margin-bottom: 16px;
`;

export const CardRowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  gap: 16px;
`;

export const Card = styled.div<{ active?: boolean }>`
  padding: 16px;
  border: 1px solid ${(props) => (props.active ? CB_COLORS.primary : CB_COLORS.neutral300)};
  border-radius: 8px;
  cursor: pointer;
  box-shadow: ${(props) => (props.active ? '0px 0px 16px rgb(39 85 240 / 25%)' : '0 4px 8px rgb(0 0 0 / 5%)')};
  transition: border-color, box-shadow 0.2s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-basis: 100%;
`;

export const DarkModeCard = styled(Card)`
  background-color: ${DEFAULTS_DARK.background};
  color: ${DEFAULTS_DARK.fontColor};
  font-size: 14px;
`;

export const LightModeCard = styled(Card)`
  background-color: ${DEFAULTS_LIGHT.background};
  color: ${DEFAULTS_LIGHT.fontColor};
  font-size: 14px;
`;

export const CardTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${CB_COLORS.primary};
  text-transform: capitalize;
`;

export const CardDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #858b9a;
`;

export const AdvancedStylesButton = styled.div<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => (props.disabled ? CB_COLORS.neutral0 : 'FFF')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid ${CB_COLORS.neutral300};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
`;
