import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${CB_COLORS.neutral500};
  overflow: hidden;
`;

const Header = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${CB_COLORS.neutral300};
`;

const Body = styled.div<{ expanded?: boolean }>`
  max-height: ${(props) => (props.expanded ? '100vh' : '0')};
  transition: max-height 0.3s;
`;

interface IAccordionItem {
  onClick: () => void;
  header: React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
}

export const AccordionItem = ({ header, onClick, children, expanded }: IAccordionItem) => {
  return (
    <Container>
      <Header onClick={onClick}>{header}</Header>
      <Body expanded={expanded}>{children}</Body>
    </Container>
  );
};
