import React from 'react';
import styled from '@emotion/styled';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { SortableHandle } from 'react-sortable-hoc';

import { getIcon } from '../../iconHelper';
import { Button, Header, Collapse as SharedCollapse } from '../../../../../../shared_components';
import { IArgumentRow } from '../../ArgumentList';
import {
  ArgumentOptionAllowCreate,
  ArgumentOptionLabel,
  ArgumentOptionMultiSelect,
  ArgumentOptionTextInputType,
  ArgumentOptionPreselectedKey,
  ArgumentOptionType,
  ArgumentOptionTypeValue,
  ArgumentOptionAutoChoose,
  ArgumentOptionVideoTypeValue,
  ArgumentOptionHtmlTypeValue,
} from './ArgumentDetailOptions';
import { IArgumentMap, IResourceSettingsByContextKey } from '@commandbar/internal/middleware/types';
import { ArgumentName } from './ArgumentName';
import { ArgumentPrompt } from './ArgumentPrompt';
import { currentType, IOptionType } from '../../argumentUtils';
import { CB_COLORS } from '@commandbar/design-system/components';
import { DragIcon, PanelHeader, Prompt } from '../../../../../components/styled';

const { Panel: AntdPanel } = SharedCollapse;

const Collapse = styled(SharedCollapse)`
  z-index: 2147483648;
  &.ant-collapse {
    background-color: transparent;
  }
`;

const GhostCollapse = styled(SharedCollapse)`
  z-index: 2147483648;

  & .ant-collapse-header {
    border: none;
    box-shadow: none !important;
  }

  & .ant-collapse-content {
    padding: 0px;
  }

  & .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const Panel = styled(AntdPanel)`
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &.ant-collapse-item-active {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  &.ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }

  & .ant-collapse-content {
    padding: 32px 0 48px 0;
  }
`;

const KeyTag = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #000000a6;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 16px;
`;

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: ${CB_COLORS.primary};
  font-size: 14px;
`;

export const FormContainer = styled.div`
  margin: 0 60px;
`;

interface IProps {
  argument: IArgumentRow;
  arguments: IArgumentMap;
  recordSettings: IResourceSettingsByContextKey;
  activeArgName?: string;
  updateName: (oldName: string, newName: string) => void;
  onUpdate: (arg: string, val: any, replaceValue?: boolean) => void;
  onDelete: (argName: string) => void;
}

const DragHandle = SortableHandle(() => <DragIcon />);

export const CollapsibleArgumentEditor = (props: IProps) => {
  const { argument, activeArgName, arguments: args, updateName, onUpdate, onDelete, recordSettings } = props;
  const { name, ...argumentData } = argument;
  const { label } = argumentData;
  const icon = getIcon(argument);
  const type: IOptionType | undefined = name ? currentType(name, args) : undefined;

  const header = (
    <PanelHeader style={{ padding: '0 42px 0 16px' }}>
      <DragHandle />
      <IconContainer>{icon?.icon}</IconContainer>
      <Prompt>{label || name}</Prompt>
      {name && <KeyTag>{name}</KeyTag>}

      <Button
        icon={<DeleteOutlined />}
        ghost
        onClick={(e) => {
          e.stopPropagation();
          onDelete(name);
        }}
      />
    </PanelHeader>
  );

  return (
    <Collapse
      ghost
      bordered={false}
      expandIconPosition="end"
      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
      defaultActiveKey={activeArgName === name ? 'open' : undefined}
    >
      <Panel header={header} key="open">
        <FormContainer>
          <ArgumentName argumentName={name} arguments={args} updateName={updateName} />

          {!['video', 'html'].includes(type?.key || '') && (
            <ArgumentPrompt argumentName={name} data={argumentData} onUpdate={onUpdate} />
          )}
          <ArgumentOptionType
            data={argumentData}
            argumentName={name}
            type={type}
            onUpdate={onUpdate}
            arguments={args}
          />
          <ArgumentOptionTypeValue
            data={argumentData}
            argumentName={name}
            type={type}
            onUpdate={onUpdate}
            arguments={args}
          />
          <ArgumentOptionVideoTypeValue
            data={argumentData}
            argumentName={name}
            type={type}
            onUpdate={onUpdate}
            arguments={args}
          />
          <ArgumentOptionHtmlTypeValue
            data={argumentData}
            argumentName={name}
            type={type}
            onUpdate={onUpdate}
            arguments={args}
          />
        </FormContainer>
        {!['video', 'html'].includes(type?.key || '') ? (
          <GhostCollapse defaultActiveKey={['1']} ghost bordered={false} expandIconPosition="end">
            <AntdPanel
              header={
                <div style={{ margin: '0px 16px', flex: 1 }}>
                  <Header text="Optional" />
                </div>
              }
              key="1"
            >
              <FormContainer style={{ marginTop: 24 }}>
                <ArgumentOptionMultiSelect
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                />

                <ArgumentOptionTextInputType
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                />

                <ArgumentOptionPreselectedKey
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                />

                <ArgumentOptionLabel
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                  recordSettings={recordSettings}
                />

                <ArgumentOptionAllowCreate
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                />

                {argumentData.input_type === undefined && (
                  <ArgumentOptionAutoChoose
                    data={argumentData}
                    argumentName={name}
                    type={type}
                    onUpdate={onUpdate}
                    arguments={args}
                  />
                )}
              </FormContainer>
            </AntdPanel>
          </GhostCollapse>
        ) : null}
      </Panel>
    </Collapse>
  );
};
