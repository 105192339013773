import * as React from 'react';
import { Switch } from '../../shared_components';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

interface IProps {
  organization: IOrganizationType;
  onChange: any;
  setting:
    | 'releases_enabled'
    | 'end_user_shortcuts_enabled'
    | 'end_user_recents_enabled'
    | 'force_end_user_identity_verification'
    | 'releases_available'
    | 'in_bar_feedback'
    | 'allow_event_handlers'; // FIXME: boolean settings on Organization object
  style?: React.CSSProperties;
}

const ToggleOrganizationSetting = (props: IProps) => {
  const [value, setValue] = React.useState(props.organization[props.setting] ?? false);

  const onChange = async (checked: boolean) => {
    setValue(checked);

    const _settings = await OrganizationSettings.update({ [props.setting]: checked });
    props.onChange(props.setting, checked, false);

    // Toggle the switch eagerly, but flip it back if the request fails
    if (_settings?.[props.setting] !== checked) {
      setValue(!checked);
    }
  };

  return <Switch style={props.style ?? {}} checked={value} onChange={onChange} />;
};

export default ToggleOrganizationSetting;
