import React from 'react';
import moment from 'moment';
import { Empty, Row } from '../../shared_components';
import { ILog } from './types';
import JSONEditor from '../components/JSONEditor';
import { Time } from './styled';
import MethodTag from './MethodTag';

interface IProps {
  log: ILog | undefined;
}

const trimJSON = (json: any) => {
  const isContextBig = (context: any) => {
    const MAX_CONTEXT_SIZE = 2500;
    return JSON.stringify(context).length > MAX_CONTEXT_SIZE;
  };

  if (isContextBig(json)) return { error: 'Arguments too large to render.' };
  return json;
};

const SDKLogDetail = ({ log }: IProps) => {
  if (!log) {
    return (
      <Row
        align="middle"
        justify="center"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Click on an event to the left to view details'} />{' '}
      </Row>
    );
  }
  return (
    <div>
      <Row justify="space-between" style={{ marginTop: 35 }}>
        <MethodTag str={log.name} />
        <Time>{moment(log.time).local().format('HH:mm:ss')}</Time>
      </Row>
      <br />
      <JSONEditor
        contextID={log.time}
        json={trimJSON(log.args)}
        onChange={() => {
          return {};
        }}
        readOnly={true}
        outerBoxStyles={{
          boxShadow: 'none',
        }}
        containerStyles={{
          maxHeight: '100%',
        }}
      />
    </div>
  );
};

export default SDKLogDetail;
