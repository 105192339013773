import React from 'react';

import { useAppContext } from '../../../Widget';
import * as S from '../styled';
import { Select } from '../../../shared_components';
import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';
import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

interface ICommandSelect {
  value: number | null | undefined;
  onChange: (value: number) => void;
  placeholder?: string;
  commandFilter?: (command: IEditorCommandTypeLite) => boolean;
  type?: 'video' | 'help doc';
}

export const CommandSelect = ({
  value,
  onChange,
  placeholder = 'Select a command...',
  commandFilter = Boolean,
  type,
}: ICommandSelect) => {
  const { commands } = useAppContext();

  return (
    <S.StyledCommandSelect
      value={value}
      onChange={onChange}
      suffixIcon={<CaretDown />}
      style={{
        flex: 1,
        minWidth: 0 /* need this, otherwise the select will be too wide if its contents are too big, see  https://stackoverflow.com/a/38383437/20718465 */,
      }}
      filterOption={(input, option) =>
        !!option?.children && (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      placeholder={placeholder}
      showSearch
      notFoundContent={`No ${type ? type + ' ' : ''}commands found`}
    >
      {commands.filter(commandFilter).map((c, i) => (
        <Select.Option key={i} value={c.id}>
          {c.text}
        </Select.Option>
      ))}
    </S.StyledCommandSelect>
  );
};
