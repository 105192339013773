/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { SearchInput, Tooltip, Alert, Button, Col, Row, Spin, Select, SimplePanel } from '../shared_components';
import Sender from '../management/Sender';
import useSDKUsageMonitoring from './debugger/useSDKUsageMonitoring';
import { ILog } from './debugger/types';
import SDKLogTable from './debugger/SDKLogTable';
import SDKLogDetail from './debugger/SDKLogDetail';
import _ from 'lodash';
import { ContainerHeader, Container, ScrollContainer } from './debugger/styled';
import { InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { TChecksResult, ChecksRenderer } from './ChecksRenderer';
import { InspectContext } from './debugger/ContextDebugger/InspectContext';
import { parseContextForError } from './debugger/ContextDebugger/ContextError';
import { useHistory } from 'react-router';
import ContextList from './debugger/ContextDebugger/ContextList';
import { useAppContext } from '../Widget';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';

export enum DebuggerView {
  CHECKS = 'checks',
  CONTEXT_INSPECTOR = 'context-inspector',
  CONTEXT_SIMULATOR = 'context-simulator',
  SDK_LOGS = 'sdk-logs',
}

const DebuggerPageSelector = (props: { activeView: DebuggerView }) => {
  const history = useHistory();
  const onPageChange = (page: DebuggerView) => {
    history.push(editorRoutes.DEBUGGER_ROUTE + '/' + page);
  };
  return (
    <div>
      <Select
        value={props.activeView}
        options={[
          { label: 'SDK Logs', value: DebuggerView.SDK_LOGS },
          { label: 'Context Inspector', value: DebuggerView.CONTEXT_INSPECTOR },
          { label: 'Context Simulator', value: DebuggerView.CONTEXT_SIMULATOR },
          { label: 'Environment Checks', value: DebuggerView.CHECKS },
        ]}
        onChange={onPageChange}
        style={{ minWidth: 200 }}
      />
    </div>
  );
};

export const DebugChecks = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [checksResult, setChecksResult] = React.useState<TChecksResult>([]); // TODO: Add type
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  const runChecks = () => {
    // We set a timeout to make re-runs feel more natural
    setLoading(true);
    setTimeout(() => {
      Sender.shareChecks()
        .then((data: any) => {
          setChecksResult(data.data);
          setLoading(false);
        })
        .catch(() => {
          setErrorMsg('Error running checks');
          setLoading(false);
        });
    }, 1500);
  };

  React.useEffect(() => {
    runChecks();
  }, []);

  return (
    <div>
      <DebuggerPageSelector activeView={DebuggerView.CHECKS} />

      <Container style={{ height: '100%' }}>
        <ContainerHeader>
          <Row align="middle">
            Environment Checks&nbsp;&nbsp;
            <Tooltip
              content={
                'These checks flag any browser environment or configuration issues that can affect CommandBar behavior.'
              }
              placement="bottom"
            >
              <InfoCircleOutlined style={{ fontSize: 16 }} />
            </Tooltip>
          </Row>
        </ContainerHeader>
        <ScrollContainer style={{ padding: '34px 32px' }}>
          <div
            css={css`
              flex-grow: 1;
              width: 100%;
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              style={{
                backgroundColor: '#797979',
                color: '#ffffff',
              }}
              icon={<RedoOutlined />}
              onClick={runChecks}
              disabled={loading}
            >
              {loading ? 'Running' : 'Run'}
            </Button>
          </div>
          {loading && (
            <div
              css={css`
                display: flex;
                width: 100%;
                padding: 40px;
                justify-content: center;
              `}
            >
              <Spin tip="Running..." />
            </div>
          )}
          {!loading && (
            <div>
              {!errorMsg && <ChecksRenderer checksResult={checksResult} />}
              {errorMsg && (
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    padding: 40px;
                    justify-content: center;
                  `}
                >
                  {errorMsg}
                </div>
              )}
            </div>
          )}
        </ScrollContainer>
      </Container>
    </div>
  );
};

export const DebugSDKLogs = () => {
  const [logs, setLogs] = React.useState<ILog[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [activeRow, setActiveRow] = React.useState<ILog | undefined>(undefined);
  const { apiCallsExceeded } = useSDKUsageMonitoring();

  const API_CALLS_EXCEEDED_WARNING = `A large number of window.CommandBar calls have been made on this page.
This could be the result of api calls being made during rendering, which may have a slight impact on search performance.`;

  const refresh = () => {
    Sender.shareLogs().then((data: any) => {
      if (!_.isEqual(data.logs, logs) && !!data.logs) {
        setLogs([...(data.logs as ILog[])]);
      }
    });
  };

  React.useEffect(() => {
    refresh();
    const intervalID = setInterval(refresh, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const trimJSON = (json: any) => {
    const error = parseContextForError(json);
    if (error) return { error: 'Arguments too large to render.' };
    return json;
  };

  const hideSDKCall = (method: string) => {
    return method.startsWith('share');
  };

  const shouldShowLog = (log: ILog, searchTerm: string) => {
    // FIXME: this could be a lot faster
    if (hideSDKCall(log.name)) return false;
    if (!searchTerm) return true;
    if (log.name.toLowerCase().includes(searchTerm.toLowerCase())) return true;
    if (trimJSON(log.args).error) return false;
    return JSON.stringify(log.args).toLowerCase().includes(searchTerm.toLowerCase());
  };

  const dataSource = React.useMemo(
    () =>
      logs
        .sort((a: ILog, b: ILog) => b.time - a.time)
        .map((log, index) => ({ ...log, key: index }))
        .filter((log: ILog) => shouldShowLog(log, searchTerm)),
    [logs],
  );

  const isEmpty = dataSource.length === 0;

  return (
    <div>
      <DebuggerPageSelector activeView={DebuggerView.SDK_LOGS} />
      {apiCallsExceeded && (
        <React.Fragment>
          <Alert message={<span>{API_CALLS_EXCEEDED_WARNING}</span>} type="warning" showIcon />
          <br />
        </React.Fragment>
      )}
      <Container>
        <ContainerHeader>
          <Row align="middle">
            <Col span={16}>{`Live SDK calls (${dataSource.length})`}</Col>
            <Col span={8}>
              <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </Col>
          </Row>
        </ContainerHeader>
        <div style={{ display: 'flex', gap: 16, background: 'white', height: 'calc(100% - 58px)' }}>
          <ScrollContainer style={{ flex: 1, minWidth: 350 }}>
            <SDKLogTable logs={dataSource} setActiveRow={(row: ILog) => setActiveRow(row)} />
          </ScrollContainer>
          {!isEmpty && (
            <ScrollContainer style={{ flex: 2 }}>
              <SDKLogDetail log={activeRow} />
            </ScrollContainer>
          )}
        </div>
      </Container>
    </div>
  );
};

export const DebugContext = () => {
  const { organization } = useAppContext();

  if (!organization) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <DebuggerPageSelector activeView={DebuggerView.CONTEXT_INSPECTOR} />
      <SimplePanel>
        <div style={{ padding: '8px 0px' }}>
          <a href="https://www.commandbar.com/docs/metadata/inspector" target="_blank" rel="noopener noreferrer">
            <Alert
              message={
                <span>
                  You can use this tab to inspect the currently defined context, as well as simulate context for
                  testing. To learn more about context, click here.
                </span>
              }
              type="info"
              showIcon
            />
          </a>
        </div>
        <ContextList />
      </SimplePanel>
    </div>
  );
};

export const DebugContextSimulator = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <DebuggerPageSelector activeView={DebuggerView.CONTEXT_SIMULATOR} />
      <SimplePanel>
        <InspectContext />
      </SimplePanel>
    </div>
  );
};
