import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import { CreatableSelect } from '../../../../shared_components/Select';

import { useContextPartition } from '../../../context/contextSettings/useContextPartition';

export const Container = styled.div`
  margin-top: 15px;
`;

interface IProps {
  value: string | undefined;
  onChange: (newValue: string) => void;
}

const useContextKeysSelectOptions = (selectedOptionValue?: string) => {
  const { args, records } = useContextPartition();

  const options = [...args, ...records].map(({ key }) => ({
    label: key,
    value: key,
  }));

  const option = useMemo(() => {
    if (!selectedOptionValue) return undefined;

    const option = options.find((option) => option.value === selectedOptionValue);
    if (option) return option;

    return { value: selectedOptionValue, label: selectedOptionValue };
  }, [selectedOptionValue, options]);

  return {
    option,
    options,
  };
};

export const ContextKeysSelect = (props: IProps) => {
  const { value, onChange } = props;
  const { options, option } = useContextKeysSelectOptions(value);

  const handleChange = (option: OptionTypeBase | null) => {
    onChange(option ? option.value : '');
  };

  return (
    <CreatableSelect
      placeholder={'Select a key or type to add a new one'}
      onChange={handleChange}
      options={options}
      value={option}
      isClearable
      menuPortalTarget={document.body}
      menuShouldBlockScroll
    />
  );
};
