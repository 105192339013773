import React from 'react';
import styled from '@emotion/styled';

import { Col, Row, Select } from '../../../shared_components';
import * as S from '../styled';
import * as Command from '@commandbar/internal/middleware/command';
import { CB_COLORS } from '@commandbar/design-system/components';
import { useAppContext } from '../../../Widget';

import { Link05, ZapFast } from '@commandbar/design-system/icons/react';
import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';

import type {
  INudgeStepContentBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import { NudgeDropdown } from './NudgeDropdown';
import { CommandSelect } from './CommandSelect';
import { findHelpDocBlock, findImageBlock, findVideoBlock } from '../utils';
import { InputContainer, StyledLabel } from '../../../shared_components/form';
import ImageUploader from '../../../shared_components/DragUpload/ImageUploader';

const DragUploadContainer = styled(S.MediaBlockContainer)`
  & > span {
    width: 100%;
  }
`;

const ConnectHelpCenterButton = styled.button`
  all: unset;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-decoration: underline;
  color: ${CB_COLORS.blue900};
`;

const videoTypeOptions = {
  url: {
    dropdownLabel: (
      <>
        <Link05 width={16} height={16} />
        <S.DropdownLabelDetail>URL</S.DropdownLabelDetail>
      </>
    ),
    selectionLabel: <Link05 width={16} height={16} />,
  },
  command: {
    dropdownLabel: (
      <>
        <ZapFast width={16} height={16} />
        <S.DropdownLabelDetail>Command</S.DropdownLabelDetail>
      </>
    ),
    selectionLabel: <ZapFast width={16} height={16} />,
  },
};

type VideoPartial = { type: 'url'; src: string } | { type: 'command'; command: string };

interface ISelectVideoType {
  video: INudgeStepContentVideoBlockType['meta'];
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

const SelectVideoType = ({ video, onBlockChange }: ISelectVideoType) => {
  return (
    <NudgeDropdown
      value={video.type}
      onChange={(e) => {
        const emptyVideoPartial = ((): VideoPartial => {
          switch (e) {
            case 'url':
              return { type: 'url', src: '' };
            case 'command':
            default:
              return { type: 'command', command: '' };
          }
        })();

        onBlockChange({
          type: 'video',
          meta: {
            ...video,
            ...emptyVideoPartial,
          },
        });
      }}
      options={(
        Object.entries(videoTypeOptions) as Array<
          [keyof typeof videoTypeOptions, (typeof videoTypeOptions)[keyof typeof videoTypeOptions]]
        >
      ).map(([value, { dropdownLabel }]) => ({
        value,
        label: dropdownLabel,
      }))}
      gap="4px"
    >
      {videoTypeOptions[video.type].selectionLabel}
    </NudgeDropdown>
  );
};

interface IMediaBlocks {
  step: INudgeStepType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

export const MediaBlocks = ({ step, onBlockChange }: IMediaBlocks) => {
  const { commands } = useAppContext();
  const helpDocCommands = commands.filter((command) => command.template.type === 'helpdoc');

  const image = findImageBlock(step.content);
  const video = findVideoBlock(step.content);
  const helpDoc = findHelpDocBlock(step.content);

  if (!!image) {
    return (
      <DragUploadContainer>
        <ImageUploader
          onUpload={({ src, file_name, size }) => {
            onBlockChange({
              type: 'image',
              meta: {
                ...image,
                src,
                file_name,
                size,
              },
            });
          }}
          onDelete={() => {
            onBlockChange({
              type: 'image',
              meta: {
                ...image,
                src: '',
                file_name: '',
                size: '',
              },
            });
          }}
          thumbnail={image}
        />
      </DragUploadContainer>
    );
  }

  if (!!video) {
    return (
      <S.MediaBlockContainer>
        <S.MediaBlockInnerContainer>
          <S.SectionContainer>
            <InputContainer>
              <StyledLabel htmlFor="video">Video</StyledLabel>
              <S.CombinationInput>
                <SelectVideoType video={video} onBlockChange={onBlockChange} />
                {video.type === 'command' ? (
                  <CommandSelect
                    type="video"
                    commandFilter={(c) => c.template.type === 'video'}
                    value={commands.find((c) => video.type === 'command' && c.id.toString() === video.command)?.id}
                    onChange={(e) => {
                      onBlockChange({
                        type: 'video',
                        meta: {
                          ...video,
                          type: 'command',
                          command: String(e),
                        },
                      });
                    }}
                  />
                ) : (
                  <S.SuffixInput
                    name="video"
                    placeholder="Paste a video link (YouTube, Vimeo, Loom, or .MP4)"
                    value={video.src.length > 0 ? video.src : undefined}
                    onChange={(e) => {
                      onBlockChange({
                        type: 'video',
                        meta: {
                          ...video,
                          type: 'url',
                          src: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              </S.CombinationInput>
            </InputContainer>
          </S.SectionContainer>
        </S.MediaBlockInnerContainer>
      </S.MediaBlockContainer>
    );
  }

  if (!!helpDoc) {
    if (helpDocCommands && helpDocCommands?.length > 0) {
      return (
        <S.MediaBlockContainer>
          <S.MediaBlockInnerContainer>
            <S.SectionContainer>
              <InputContainer>
                <StyledLabel htmlFor="help_doc">Help Doc</StyledLabel>
                <S.StyledSelect
                  placeholder="Select a help doc..."
                  suffixIcon={<CaretDown />}
                  value={
                    helpDocCommands?.find((c) => c.id.toString() === helpDoc.command) ? helpDoc.command : undefined
                  }
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      onBlockChange({
                        type: 'help_doc_command',
                        meta: {
                          ...helpDoc,
                          command: e,
                        },
                      });
                    }
                  }}
                  filterOption={(input, option) =>
                    (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                >
                  {helpDocCommands?.map((c, i) => (
                    <Select.Option key={i} value={Command.commandUID(c)}>
                      {c.text}
                    </Select.Option>
                  ))}
                </S.StyledSelect>
              </InputContainer>
            </S.SectionContainer>
          </S.MediaBlockInnerContainer>
        </S.MediaBlockContainer>
      );
    }

    return (
      <Row style={{ marginBottom: '8px' }}>
        <Col span={18} offset={6} style={{ height: '40px', borderRadius: '4px', background: CB_COLORS.blue100 }}>
          <ConnectHelpCenterButton
            onClick={() => {
              window.open('https://app.commandbar.com/integrations/help-sync');
            }}
          >
            <span>Connect your Help Center ↗</span>
          </ConnectHelpCenterButton>
        </Col>
      </Row>
    );
  }

  return null;
};
