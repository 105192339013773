/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';
import classnames from 'classnames';

import Z from '@commandbar/internal/client/Z';
import { Button } from '../../shared_components';
import { getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import { CB_COLORS } from '@commandbar/design-system/components';

/*******************************************************************************/

interface IProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string | React.ReactElement;
  rightAction?: React.ReactNode;
  leftAction?: React.ReactNode;
}

/*******************************************************************************/

const SideBarOverlay = (props: IProps) => {
  const ref = React.useRef(null);
  const closeStyle = {
    width: '100%',
    minHeight: '100%',
    height: '100%',
    position: 'fixed' as any,
    zIndex: Z.Z_EDITOR_OVERLAY,
    left: 0,
    top: 0,
    opacity: 0,
    transform: 'translateX(100%)',
    pointerEvents: 'none' as any,
    transition: 'transform 0.2s ease 0s, opacity 0.1s ease 0s',
  };
  const openStyle = {
    width: '100%',
    minHeight: '100%',
    height: '100%',
    position: 'fixed' as any,
    zIndex: Z.Z_EDITOR_OVERLAY,
    left: 0,
    top: 0,
    opacity: 1,
    transform: 'translateX(0%)',
    pointerEvents: 'auto' as any,
    transition: 'transform 0.2s ease 0s, opacity 0.2s ease 0s',
  };

  return (
    <div
      id="commandbar-editor-overlay"
      style={{
        overflow: 'hidden',
      }}
    >
      <div
        style={props.open ? openStyle : closeStyle}
        ref={ref}
        className={classnames({ 'side-bar--windows': getOperatingSystem() === 'windows' })}
      >
        <div
          style={{
            height: '100%',
            minHeight: '100%',
            overflow: 'auto',
            background: '#FFFFFF',
          }}
        >
          <div style={{ padding: '16px 24px', background: CB_COLORS.neutral0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                {props.leftAction || <Button onClick={props.onClose}>Done</Button>}
                <div style={{ fontSize: 14, lineHeight: '20px', fontWeight: 600 }}>{props.title}</div>
              </div>
              {props.rightAction || <div />}
            </div>
          </div>
          <div>{typeof props.children === 'function' ? props.children() : props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default SideBarOverlay;
