import React from 'react';
import styled from '@emotion/styled';
import { Header } from './Header';
import { AccordionItem } from './AccordionItem';
import { Zap } from './Zap';
import { QuestionInput, AnswerInput } from './Input';
import { IEditorCommandType, LabeledAction } from '@commandbar/internal/middleware/types';
import { useAppContext } from '../../../Widget';
import { Input, message, SubHeading } from '../../../shared_components';
import Sender from '../../../management/Sender';
import axiosInstance from '@commandbar/internal/middleware/network';
import { useReportEvent } from '../../../shared_components/useEventReporting';
import { ActionEditor } from '../../nudges/NudgeContentForm/ActionEditor';
import { CB_COLORS } from '@commandbar/design-system/components';

const Body = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

export const MediaBlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;
  background: #ffffff;
`;

export const MediaBlockInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 16px;
  background: ${CB_COLORS.neutral0};
  border-radius: 8px;
`;

const debounce = (fn: Function, ms = 1000) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

interface IQAForm {
  expanded: boolean;
  initialQA: IEditorCommandType;
  onAccordionClick: () => void;
}

export const QAForm = ({ expanded, onAccordionClick, initialQA }: IQAForm) => {
  const [dirty, setDirty] = React.useState<IEditorCommandType>(initialQA);
  const [isGeneratingAnswer, setIsGeneratingAnswer] = React.useState<boolean>(false);
  const { dispatch } = useAppContext();
  const debouncedSave = React.useRef(
    debounce(async (qa: IEditorCommandType) => {
      await dispatch.commands.save(qa, { notify: false, throttle: true, setToActiveIfNew: false });
    }),
  );
  const { reportEvent } = useReportEvent();

  const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    if (!text) {
      text = '__untitled__';
    }
    const newQA: IEditorCommandType = { ...dirty, text };
    setDirty(newQA);
    debouncedSave.current(newQA);
  };

  const onAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newQA: IEditorCommandType = {
      ...dirty,
      content: { type: 'markdown', value: e.target.value },
      explanation: e.target.value,
    };
    setDirty(newQA);
    debouncedSave.current(newQA);
  };

  const onIsLiveToggle = async (checked: boolean) => {
    const updated: IEditorCommandType = {
      ...dirty,
      is_live: checked,
    };
    setDirty(updated);
    await dispatch.commands.save(updated, { notify: false, throttle: true, setToActiveIfNew: false });
    reportEvent('answer published', {
      segment: true,
      highlight: true,
      slack: true,
    });
  };

  const onDelete = async () => {
    await dispatch.commands.delete(dirty);
  };

  const generateAnswer = async (question: string) => {
    return await await axiosInstance.post('ml/answers/live', { question }).then((res) => {
      return res.data;
    });
  };

  const onGenerateInstantAnswer = async () => {
    if (!isAnswerGenerationAvailable) {
      return;
    }
    setIsGeneratingAnswer(true);
    try {
      const res = await generateAnswer(dirty.text);
      if (res.answer === '' || res.answer === "I don't know.") {
        message.error('Unable to find a good answer.');
        setIsGeneratingAnswer(false);
        return;
      }
      const newQA: IEditorCommandType = {
        ...dirty,
        content: { type: 'markdown', value: res.answer },
      };
      setDirty(newQA);
      await dispatch.commands.save(newQA, { notify: false, throttle: true, setToActiveIfNew: false });
      setIsGeneratingAnswer(false);
    } catch {
      setIsGeneratingAnswer(false);
    }
  };

  React.useEffect(() => {
    if (expanded && dirty.is_live) {
      setTimeout(() => {
        Sender.openHelpHub({ query: dirty.text });
      }, 1000);
    }
  }, [dirty.text, expanded, dirty.is_live]);

  const isAnswerGenerationAvailable = dirty.text.length > 5;

  let stringValue = '';

  if (Array.isArray(dirty.content)) {
    if (typeof dirty.content[0] === 'object') {
      if (dirty.content[0]?.type === 'markdown') {
        stringValue = dirty.content[0].value;
      }
    }
  } else if (typeof dirty.content === 'object') {
    if (dirty.content?.type === 'markdown') {
      stringValue = dirty.content.value;
    }
  }

  function isAction(o: string | number | LabeledAction): o is LabeledAction {
    return (o as LabeledAction).action !== undefined;
  }

  let action: LabeledAction['action'] = { type: 'link', value: '' };
  let actionLabel = '';

  if (dirty.next_steps.length > 0 && isAction(dirty.next_steps[0])) {
    action = dirty.next_steps[0].action;
    actionLabel = dirty.next_steps[0].cta;
  }

  const title = dirty.text === '__untitled__' ? '' : dirty.text;

  return (
    <AccordionItem
      onClick={onAccordionClick}
      expanded={expanded}
      header={
        <Header
          expanded={expanded}
          title={title}
          isLive={dirty.is_live}
          onIsLiveToggle={onIsLiveToggle}
          onDelete={onDelete}
        />
      }
    >
      <Body>
        <QuestionInput placeholder="Question" value={title} onChange={onQuestionChange} />
        <Row>
          <AnswerInput
            placeholder="Answer"
            autoSize
            value={stringValue}
            onChange={onAnswerChange}
            loading={isGeneratingAnswer}
          />
          {!isGeneratingAnswer && <Zap disabled={!isAnswerGenerationAvailable} onClick={onGenerateInstantAnswer} />}
        </Row>
        <MediaBlockContainer>
          <MediaBlockInnerContainer>
            <div style={{ display: 'flex', gap: 8, flexDirection: 'column', width: '100%' }}>
              <SubHeading>CTA</SubHeading>

              <Input
                value={actionLabel}
                onChange={(e) => {
                  const newQA: IEditorCommandType = {
                    ...dirty,
                    next_steps: [{ cta: e.target.value, action: action }],
                  };
                  setDirty(newQA);
                  debouncedSave.current(newQA);
                }}
                style={{ height: '32px', fontSize: 14 }}
                placeholder="Label"
                size="small"
              />

              <ActionEditor
                label={false}
                button={{
                  action: action,
                  label: '__unused__',
                }}
                onBlockChange={(block) => {
                  if (block.meta?.action) {
                    const newQA: IEditorCommandType = {
                      ...dirty,
                      next_steps: [{ cta: actionLabel, action: block.meta?.action }],
                    };
                    setDirty(newQA);
                    debouncedSave.current(newQA);
                  }
                }}
              />
            </div>
          </MediaBlockInnerContainer>
        </MediaBlockContainer>
      </Body>
    </AccordionItem>
  );
};
