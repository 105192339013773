import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as S from '../styled';

import {
  CursorClick01,
  Image01,
  VideoRecorder,
  TextInput,
  Star01,
  BookOpen02,
} from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components';
import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';

import type {
  INudgeContentSurveyRatingBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeStepContentImageBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import type { FormType } from './index';
import { Dropdown } from '../../../shared_components';
import { CtaDropdown } from './CtaDropdown';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAppContext } from '../../../Widget';

const FORM_TYPE_OPTIONS_OPTIONS: IFooterOption<FormType>[] = [
  {
    type: 'survey_text',
    label: 'Text',
    Icon: TextInput,
  },
  {
    type: 'survey_rating',
    label: 'Rating',
    Icon: Star01,
  },
  {
    type: 'image',
    label: 'Image',
    Icon: Image01,
  },
  {
    type: 'video',
    label: 'Video',
    Icon: VideoRecorder,
  },
  {
    type: 'help_doc_command',
    label: 'Doc',
    Icon: BookOpen02,
  },
];

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  border-top: 1px solid ${CB_COLORS.neutral200};
`;

const FooterButton = styled.button<{ active?: boolean; disabled?: boolean }>`
  all: unset;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  font-size: 14px;
  background-color: ${(props) => (props.active ? 'transparent' : CB_COLORS['neutral0'])};
  color: ${(props) => (props.active ? CB_COLORS.neutral1000 : CB_COLORS.neutral600)};
  box-sizing: border-box;
  cursor: pointer;

  transition: all 0.3s;
  font-weight: 500;
  user-select: none;

  &:hover {
    background-color: transparent;
    color: ${CB_COLORS.neutral1000};
  }
`;

const FooterCTAButton = styled(FooterButton)<{ active?: boolean }>`
  &::before {
    content: '';
    position: absolute;
    left: -0.5px;
    width: 1px;
    background-color: ${CB_COLORS.neutral500};
    height: 16px;
  }

  ${(props) =>
    props.active
      ? `
    background-color: transparent;
    color: ${CB_COLORS.neutral1000};
  `
      : ''}
`;

const IconContainer = styled.div`
  margin-right: 6px;
  box-sizing: border-box;
  padding-top: 4px;
`;

const CaretIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  overflow: hidden;
  font-weight: 500;
`;

interface IFooterOption<T> {
  type: T;
  label: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface IFooter {
  step: INudgeStepType;
  onStepChange: (step: INudgeStepType) => void;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onCtaToggle: () => void;
  onSecondaryCtaToggle: () => void;
}

export const Footer = ({ step, onStepChange, onBlockChange, onCtaToggle, onSecondaryCtaToggle }: IFooter) => {
  const { organization } = useAppContext();

  const [blocksSessionState, setBlocksSessionState] = React.useState<{
    image: INudgeStepContentImageBlockType['meta'];
    video: INudgeStepContentVideoBlockType['meta'];
    help_doc_command: INudgeStepContentHelpDocBlockType['meta'];
    survey_text: INudgeStepContentSurveyTextBlockType['meta'];
    survey_rating: INudgeContentSurveyRatingBlockType['meta'];
  }>(
    FORM_TYPE_OPTIONS_OPTIONS.reduce(
      (acc, curr) =>
        step.content.find((block) => block.type === curr.type)
          ? { ...acc, [curr.type]: step.content.find((block) => block.type === curr.type)?.meta }
          : acc,
      {
        image: {
          src: '',
          file_name: '',
          size: '',
        },
        video: {
          type: 'url',
          src: '',
        },
        help_doc_command: {
          command: '',
        },
        survey_text: {
          prompt: '',
        },
        survey_rating: {
          type: 'stars',
          lower_label: '',
          upper_label: '',
          options: 5,
        },
      },
    ),
  );

  React.useEffect(() => {
    setBlocksSessionState((blocks) => step.content.reduce((acc, curr) => ({ ...acc, [curr.type]: curr.meta }), blocks));
  }, [step.content]);

  const onFormTypeSelect = (type: FormType) => {
    if (step.content.find((block) => block.type === type)) {
      onStepChange({ ...step, content: step.content.filter((block) => block.type !== type) });
    } else {
      switch (type) {
        case 'image':
          onBlockChange({
            type: 'image',
            meta: blocksSessionState['image'],
          });
          break;
        case 'video':
          onBlockChange({
            type: 'video',
            meta: blocksSessionState['video'],
          });
          break;
        case 'help_doc_command':
          onBlockChange({
            type: 'help_doc_command',
            meta: blocksSessionState['help_doc_command'],
          });
          break;
        case 'survey_text':
          onBlockChange({
            type: 'survey_text',
            meta: blocksSessionState['survey_text'],
          });
          break;
        case 'survey_rating':
          onBlockChange({
            type: 'survey_rating',
            meta: blocksSessionState['survey_rating'],
          });
          break;
      }
    }
  };

  const stepHasSurvey = !!step.content.find((block) => block.type.startsWith('survey'));
  const stepHasImageOrVideo = !!step.content.find((block) => ['image', 'video'].includes(block.type));

  const [isMediaDropdownVisible, setisMediaDropdownVisible] = useState(false);
  const [isSurveyDropdownVisible, setisSurveyDropdownVisible] = useState(false);

  const createSecondaryCta = LocalStorage.get('can_create_secondary_nudge_cta', false);
  const canCreateSecondaryNudgeCta = organization.id === 'ac965263' || !!createSecondaryCta;

  return (
    <Container>
      <Dropdown
        visible={isSurveyDropdownVisible}
        onVisibleChange={(visible) => setisSurveyDropdownVisible(visible)}
        placement="bottomCenter"
        overlay={
          <OverlayContainer>
            <S.DropDownOption
              onClick={() => {
                onFormTypeSelect('survey_rating');
                setisSurveyDropdownVisible(false);
              }}
              active={!!step.content.find((block) => block.type === 'survey_rating')}
            >
              Rating
            </S.DropDownOption>
            <S.DropDownOption
              onClick={() => {
                onFormTypeSelect('survey_text');
                setisSurveyDropdownVisible(false);
              }}
              active={!!step.content.find((block) => block.type === 'survey_text')}
            >
              Short Answer
            </S.DropDownOption>
          </OverlayContainer>
        }
      >
        <FooterButton active={stepHasSurvey}>
          {!!step.content.find((block) => block.type === 'survey_text') ? (
            <IconContainer>
              <TextInput height={15} width={15} />
            </IconContainer>
          ) : (
            <IconContainer>
              <Star01 height={15} width={15} />
            </IconContainer>
          )}

          <CaretIconContainer>
            <CaretDown />
          </CaretIconContainer>
        </FooterButton>
      </Dropdown>

      <Dropdown
        visible={isMediaDropdownVisible}
        onVisibleChange={(visible) => setisMediaDropdownVisible(visible)}
        placement="bottomCenter"
        overlay={
          <OverlayContainer>
            <S.DropDownOption
              onClick={() => {
                onFormTypeSelect('image');
                setisMediaDropdownVisible(false);
              }}
              active={!!step.content.find((block) => block.type === 'image')}
            >
              Image
            </S.DropDownOption>
            <S.DropDownOption
              onClick={() => {
                onFormTypeSelect('video');
                setisMediaDropdownVisible(false);
              }}
              active={!!step.content.find((block) => block.type === 'video')}
            >
              Video
            </S.DropDownOption>
          </OverlayContainer>
        }
      >
        <FooterButton active={stepHasImageOrVideo}>
          {!!step.content.find((block) => block.type === 'video') ? (
            <IconContainer>
              <VideoRecorder height={15} width={15} />
            </IconContainer>
          ) : (
            <IconContainer>
              <Image01 height={15} width={15} />
            </IconContainer>
          )}
          <CaretIconContainer>
            <CaretDown />
          </CaretIconContainer>
        </FooterButton>
      </Dropdown>

      <FooterButton
        active={!!step.content.find((block) => block.type === 'help_doc_command')}
        onClick={() => onFormTypeSelect('help_doc_command')}
      >
        <IconContainer>
          <BookOpen02 height={15} width={15} />
        </IconContainer>
      </FooterButton>

      {canCreateSecondaryNudgeCta ? (
        <CtaDropdown step={step} onCtaToggle={onCtaToggle} onSecondaryCtaToggle={onSecondaryCtaToggle} />
      ) : (
        <FooterCTAButton onClick={onCtaToggle} active={!!step.content.find((block) => block.type === 'button')}>
          <IconContainer>
            <CursorClick01 height={15} width={15} />
          </IconContainer>
        </FooterCTAButton>
      )}
    </Container>
  );
};
