import { Tabs as AntTabs } from 'antd';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';

const TabsBase = styled(AntTabs, { shouldForwardProp: (prop) => prop !== 'isStandaloneEditor' })`
  && .ant-tabs-nav-wrap {
    padding: 0;
  }

  && .ant-tabs-content-holder {
    background-color: transparent;
  }

  && .ant-tabs-content {
    position: relative;
    height: 100%;
  }

  .ant-tabs-nav::before {
    border-bottom: ${(props: { isStandaloneEditor?: boolean }) =>
      props?.isStandaloneEditor ? '1px solid #e5e4e7' : ''};
  }

  .ant-tabs-tab {
    border: ${(props: { isStandaloneEditor?: boolean }) =>
      props?.isStandaloneEditor ? '1px solid transparent !important' : ''};
    transition: ${(props: { isStandaloneEditor?: boolean }) =>
      props?.isStandaloneEditor ? 'border-bottom 0s !important' : 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'};
    padding: 12px 16px;
    margin: 0px;
    margin-left: 4px;
  }

  .ant-tabs-tab-active {
    border: ${(props: { isStandaloneEditor?: boolean }) =>
      props?.isStandaloneEditor ? '1px solid #e5e4e7 !important' : ''};
    border-bottom: ${(props: { isStandaloneEditor?: boolean }) => (props?.isStandaloneEditor ? 'none !important' : '')};
  }
`;

export const FlatStyledTabs = styled(TabsBase)``;
export const Tabs = styled(TabsBase)`
  .ant-tabs-tab {
    color: ${CB_COLORS.neutral600};
  }

  && .ant-tabs-tab-active {
    background: ${CB_COLORS.neutral0};
    color: #000;
  }

  .ant-tabs-tab-btn {
    transition: none;
  }
`;

export const TabPane = styled(AntTabs.TabPane)`
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${CB_COLORS.neutral0};
`;
