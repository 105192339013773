import React, { useEffect, useRef, useState } from 'react';
import Hotkey from '@commandbar/internal/client/Hotkey';

import {
  BlockOutlined,
  CodeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import {
  DropdownMenu,
  IDropdownMenuRow,
  StatusBadge,
  Tooltip,
  commandDefault,
  Code,
  Icon,
  Tag,
  Modal,
  message,
} from '../../shared_components';
import { getOperatingSystem, PlatformType } from '@commandbar/internal/util/operatingSystem';
import { deleteCommandWithWarnings } from './utils';
import * as Command from '@commandbar/internal/middleware/command';
import { ContextDataObject } from '../context/contextSettings/useContextPartition';
import { ICommandRow } from './types';
import { AppState } from '../../Widget';

const { info } = Modal;

const NEEDS_REVIEW_TEXT = `This command hasn't been available for the past few days and needs review to make sure it's not broken`;

const generateCommandCode = (command: IEditorCommandTypeLite) => {
  // Custom parsing is applied here in order to compose object structure we need.
  // The following solution is used instead of pretty simple trick with JSON.stringify(command, null, 2) to have more control on output string,
  // namely using single quotes instead of double, do not use quotes for object key names, set custom line breaks and white spaces

  const argWhitelist = [
    'type',
    'input_type',
    'preselected_key',
    'value',
    'order_key',
    'label',
    'dateTimeArgumentTypeId',
  ];

  const formatArguments = (args: { [key: string]: any }): string =>
    Object.entries(args).reduce((acc, [key, value], index, arr) => {
      acc += `\n   ${key}: ${JSON.stringify(value, argWhitelist, 4).replace(/"/g, "'").replace('\n}', '\n   }')}${
        index < arr.length - 1 ? ',' : '\n'
      }`;
      return acc;
    }, '') + '  }';

  return `window.CommandBar.addCommand({
  text: '${command.text}',
  name: '${command.text.toLowerCase().trim().replace(/\s/g, '_')}',
  arguments: {${Object.keys(command.arguments).length ? formatArguments(command.arguments) : '}'},
  template: {
    type: '${command.template.type}',
    value: '${command.template.value}',
    operation: '${command.template.operation}',
  }${!!command.icon ? `,\n icon: '${command.icon}'` : ''}
});`;
};

const checkOverflow = (el: HTMLElement) => {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  const isOverflowing = el.clientWidth < el.scrollWidth;

  el.style.overflow = curOverflow;

  return isOverflowing;
};

const Hotkeys = ({ children }: { children: React.ReactNode }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }

    setShowTooltip(checkOverflow(ref.current as HTMLElement));
  }, []);

  const content = (
    <div
      ref={ref}
      style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 600 }}
    >
      {children}
    </div>
  );

  if (showTooltip) {
    return <Tooltip content={children}>{content}</Tooltip>;
  }

  return content;
};

export const renderHotkeysColumn = (_: any, record: ICommandRow) => {
  const defaultKey = 'hotkey_mac';
  const os = getOperatingSystem();

  const keys: Record<PlatformType, 'hotkey_mac' | 'hotkey_win'> = {
    mac: 'hotkey_mac',
    ios: 'hotkey_mac',
    windows: 'hotkey_win',
    linux: 'hotkey_win',
    android: 'hotkey_win',
  };

  const key = keys[os] || defaultKey;
  const object = record.object as Record<string, any>;

  const hotkey = Hotkey.toArray(object[key]).map((k: string, i, arr) => {
    return Hotkey.translateToPlatformSymbol(
      k,
      ['windows', 'linux', 'android'].includes(os) ? 'win' : 'mac',
      i === arr.length - 1 || arr[i + 1] === 'then',
    );
  });

  return <Hotkeys>{hotkey}</Hotkeys>;
};

export const renderThirdPartySourceColumn = (third_party_source: string | null | undefined) => {
  if (third_party_source === undefined || third_party_source === null || third_party_source === '') {
    return;
  }
  return <Tag color="geekblue">{third_party_source}</Tag>;
};

export const renderTextColumn = (
  text: string,
  command: IEditorCommandTypeLite,
  records: ContextDataObject[],
  category: ICommandCategoryType | null,
) => {
  const icon = category && category.icon ? category.icon : command.icon;
  const icon_color = category && category.icon_color ? category.icon_color : command.icon_color;

  // FIXME: TMP for command category migration
  const objectKey = command.template.object;
  const isObjectCommandWithoutCategory = !!objectKey && !command.category;
  const CATEGORY_WARNING_MESSAGE = `This command is missing a category.
  Please assign this command to a category by clicking on it and updating the "Category" field.`;

  const isRecordAction = !!records.find((v) => Command.isRecordAction(command, v.key));
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        icon={icon || commandDefault(command)}
        style={{ fontSize: 12, flexShrink: 0, color: icon_color || undefined }}
        useDefaultSVGColor
        allowDefaultSVGColorOverride
      />
      <span style={{ marginLeft: '5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {text}
      </span>
      {isRecordAction && <Tag style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>Record action</Tag>}
      &nbsp;&nbsp;&nbsp;
      {isObjectCommandWithoutCategory && (
        <Tooltip content={CATEGORY_WARNING_MESSAGE}>
          <WarningOutlined style={{ color: 'orange' }} />
        </Tooltip>
      )}
    </div>
  );
};

export const renderStatusColumn = (
  is_live: boolean,
  record: ICommandRow,
  unhealthyCommands: IEditorCommandTypeLite[],
) => {
  const {
    object: { id },
  } = record;

  const isUnhealthy = unhealthyCommands.find((c) => c.id === id);

  return (
    <span style={{ display: 'flex', alignItems: 'center', minWidth: 45 }}>
      {is_live ? (
        <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
      ) : (
        <StatusBadge color="orange" text="Draft" />
      )}
      {isUnhealthy ? (
        <Tooltip content={NEEDS_REVIEW_TEXT}>
          <ExclamationCircleOutlined style={{ color: 'rgb(216, 150, 20)', marginLeft: '8px', fontSize: '14px' }} />
        </Tooltip>
      ) : null}
    </span>
  );
};

export const renderOptionsColumn = ({ record, appState }: { record: ICommandRow; appState: AppState }) => {
  const commandToDelete = record.object;
  const dispatch = appState.dispatch;

  const menuItems: IDropdownMenuRow[] = [
    {
      name: 'Edit',
      icon: <EditOutlined />,
      onClick: () => {
        dispatch.commands.setActiveCommandById(record.object.id);
      },
    },
    {
      name: 'Duplicate',
      icon: <BlockOutlined />,
      onClick: async () => {
        const c = await Command.get(record.object.id.toString());
        dispatch.commands.save({
          ...c,
          id: -1,
          text: `Copy of ${c.text}`,
          is_live: false,
        });
      },
    },
    {
      name: 'Copy id',
      icon: <CopyOutlined />,
      onClick: () => {
        const element = document.createElement('textarea');
        element.value = record.id.toString();
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        message.info('Command id copied to clipboard.');
      },
    },
    {
      name: 'Copy code',
      icon: <CodeOutlined />,
      onClick: () => {
        const { object: command } = record;
        const commandCode = generateCommandCode(command);

        info({
          title: 'Copy code',
          icon: null,
          maskClosable: true,
          content: (
            <div>
              {`Below is a code example to recreate this command from the API. For more information about
              programmatic commands, check out `}
              <a
                href="https://www.commandbar.com/docs/commands/link"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: 'underline' }}
              >
                this page
              </a>
              .
              <div
                style={{
                  padding: '10px',
                  margin: '10px 0 0 0',
                  background: 'rgb(242, 242, 242)',
                }}
              >
                <Code content={commandCode} />
              </div>
            </div>
          ),
        });
      },
    },
    {
      name: 'Delete',
      icon: <DeleteOutlined />,
      onClick: async () => {
        await deleteCommandWithWarnings({
          commandToDelete,
          appState,
        });
      },
    },
  ];

  return <DropdownMenu keyName="command-actions" items={menuItems} />;
};
