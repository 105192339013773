import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { GettingStartedBanner } from './GettingStartedBanner';
import { QAForm } from './QAForm/form';
import { useAppContext } from '../../Widget';
import { CreateButton } from './CreateButton';
import { useLocation } from 'react-router';
import { freshCommand } from '../useEditor';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionButtonsContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

const HEADER_HEIGHT = '56px';
const Header = styled.div`
  color: #000;
  font-weight: 500;
  font-size: 18px;
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 4px;
  margin-bottom: 18px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
`;

export const InstantAnswers = () => {
  const { answers, dispatch, organization } = useAppContext();
  const [loading, setLoading] = React.useState(false);

  const [activeFormKey, setActiveFormKey] = React.useState<number | null>(!!answers.length ? answers[0].id : null);

  useEffect(() => {
    if (answers.length && answers.find((answer) => answer.id === activeFormKey) === undefined) {
      setActiveFormKey(answers[0].id);
    }
  }, [answers.length]);

  const location = useLocation();

  const onCreate = async (question?: string, answer?: string) => {
    const organizationId = organization?.id.toString();
    if (!organizationId) {
      return;
    }
    try {
      setLoading(true);
      const newCommand = freshCommand(organization, undefined);
      newCommand.template = { type: 'helpdoc', value: '', doc_type: 'answer' };
      newCommand.text = question ?? '__untitled__';
      if (answer) {
        newCommand.content = { type: 'markdown', value: answer };
      }

      const qa = await dispatch.commands.save(newCommand, { notify: false, throttle: true, setToActiveIfNew: false });
      setActiveFormKey(qa.id);
    } catch {
      /**
       * do nothing
       */
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const question = decodeURIComponent(searchParams.get('question') || '');
    const answer = decodeURIComponent(searchParams.get('answer') || '');

    const existing = answers.find((answer) => answer.text === question);
    if (existing) {
      setActiveFormKey(existing.id);
    } else if (question && answer) {
      onCreate(question, answer);
    }
  }, [location.search]);

  return (
    <Container>
      {!!answers.length ? (
        <>
          <Header>
            Answers{' '}
            <ActionButtonsContainer>
              <CreateButton loading={loading} onClick={onCreate} />
            </ActionButtonsContainer>
          </Header>
          <Body>
            {answers.map((answer) => (
              <QAForm
                key={answer.id}
                initialQA={answer}
                expanded={answer.id === activeFormKey}
                onAccordionClick={() => setActiveFormKey(activeFormKey === answer.id ? null : answer.id)}
              />
            ))}
          </Body>
        </>
      ) : (
        <GettingStartedBanner />
      )}
    </Container>
  );
};
