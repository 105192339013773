import styled from '@emotion/styled';
import { Button as SharedButton } from '../shared_components';

export const Button = styled(SharedButton)`
  &&& {
    .anticon {
      display: inline-flex;
      margin-top: 1px;
      font-size: 12px;
      line-height: 12px;
    }
  }
`;
