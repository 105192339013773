import React from 'react';
import chroma from 'chroma-js';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from '../../shared_components';

export const MINIMUM_CONTRAST_RATIO = 4.5;

interface ICONFLICTING_PROPERTIES {
  [key: string]: {
    conflictingProperty: string;
    categories: string[];
  };
}

export const CONFLICTING_PROPERTIES: ICONFLICTING_PROPERTIES = {
  activeColor: {
    conflictingProperty: 'activeBackground',
    categories: ['searchTab'],
  },
  breadCrumbsColor: {
    conflictingProperty: 'background',
    categories: ['option', 'optionSelected', 'optionDisabled'],
  },
  color: {
    conflictingProperty: 'background',
    categories: ['categoryHeader', 'input', 'option', 'optionSelected', 'optionDisabled', 'searchableFieldMatch'],
  },
  fontColor: {
    conflictingProperty: 'background',
    categories: ['instruction'],
  },
};

export const isAccessibleColor = (fontColor: string, backgroundColor: string) => {
  if (fontColor === null || backgroundColor === null) {
    return {
      contrastRatio: null,
      isAccessible: true,
    };
  }

  if (!chroma.valid(fontColor) || !chroma.valid(backgroundColor)) {
    return {
      contrastRatio: null,
      isAccessible: false,
    };
  }

  const contrastRatio = parseFloat(chroma.contrast(fontColor, backgroundColor).toFixed(2));

  return {
    contrastRatio,
    isAccessible: contrastRatio > MINIMUM_CONTRAST_RATIO,
  };
};

export const isAccessible = (category: string, attribute: string, getter: any) => {
  const attributeChecks = CONFLICTING_PROPERTIES[attribute];

  if (attributeChecks && attributeChecks.categories.includes(category)) {
    const { conflictingProperty } = attributeChecks;

    const fontColor = getter(category, attribute);
    const backgroundColor = getter(category, conflictingProperty);

    return {
      ...isAccessibleColor(fontColor, backgroundColor),
      conflictingProperty,
    };
  }

  return {
    isAccessible: true,
    contrastRatio: null,
    conflictingProperty: null,
  };
};

interface IProps {
  conflictingProperty: string;
  contrastRatio: number | null;
}

export const AccessibilityWarningTooltip = ({ conflictingProperty, contrastRatio }: IProps) => {
  return (
    <Tooltip
      content={
        <>
          Low color contrast with the{' '}
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {conflictingProperty}
          </span>{' '}
          may cause accessibility issues.{` `}
          {contrastRatio && ` Current contrast is ${contrastRatio}, it should be > 4.5`}{' '}
          <a
            href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
            target="_blank"
            style={{
              textDecoration: 'underline',
            }}
            rel="noreferrer"
          >
            (more info)
          </a>
          .
        </>
      }
      interactive={true}
    >
      <WarningOutlined
        style={{
          fontSize: 16,
          color: 'red',
        }}
      />
    </Tooltip>
  );
};
