import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axiosInstance from '../middleware/network';

const authorize = async (authorization_token: string): Promise<IBasicResponse> => {
  return await axiosInstance
    .post(`/auth/authorize/?ts=${Date.now().toString()}`, {
      authorization_token,
    })
    .then((result: any) => {
      const { refresh, access, user } = result.data;
      axiosInstance.defaults.headers.common['Authorization'] = 'JWT ' + access;
      return { success: true, refresh, access, user };
    })
    .catch((err: any) => {
      const error = err?.response?.data?.detail ?? 'Invalid login.';
      return { success: false, error };
    });
};

interface IBasicResponse {
  success: boolean;
  error?: string;
  refresh?: string;
  access?: string;
  user?: any;
}

const basic = async (username: string, password: string): Promise<IBasicResponse> => {
  return await axiosInstance
    .post('/auth/basic/', {
      username,
      password,
    })
    .then((result: any) => {
      const { authorization_token } = result.data;

      if (!authorization_token) {
        throw new Error('Invalid response.');
      }

      return authorization_token;
    })
    .then((authorization_token: string) => {
      return axiosInstance.post('/auth/authorize/', { authorization_token });
    })
    .then((result: any) => {
      const { refresh, access, user } = result.data;
      axiosInstance.defaults.headers.common['Authorization'] = 'JWT ' + access;
      return { success: true, refresh, access, user };
    })
    .catch((err: any) => {
      const error = err?.response?.data?.detail ?? 'Invalid login.';
      return { success: false, error };
    });
};

const sso = async (email: string, auth_redirect: string, final_redirect?: string): Promise<string | null> => {
  return await axiosInstance
    .post('/auth/sso/', {
      email,
      auth_redirect,
      final_redirect,
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((_err: any) => {
      return null;
    });
};

interface GoogleOauthInput {
  auth_redirect: string;
  final_redirect: string | undefined;
  is_signup: boolean | undefined;
}

const google = async (input: GoogleOauthInput): Promise<string | null> => {
  const auth_redirect = input.auth_redirect;
  const final_redirect = input.final_redirect;
  const is_signup = input.is_signup;

  return await axiosInstance
    .post('/auth/google/', {
      auth_redirect,
      final_redirect,
      is_signup,
    })
    .then((result: any) => {
      return result.data;
    });
};

const sendVerificationEmail = async (email: string) => {
  return await axiosInstance
    .post('/auth/send-verification/', {
      email,
    })
    .then((result: any) => {
      return result.data;
    });
};

const getLoginType = async (email: string) => {
  return await axiosInstance
    .post('/auth/check/', {
      email,
    })
    .then((result: any) => {
      return result.data;
    });
};

const verifyEmail = async (token: string) => {
  return await axiosInstance
    .post('/auth/verify-email/', {
      token,
    })
    .then((result: any) => {
      return result.data;
    });
};

const setupOrg = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/new/', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

const register = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/basic/register/', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

const acceptInvite = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/basic/register/?accept=1', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useAuthorizationCode = () => {
  const [authorization, setAuthorization] = React.useState<any>({
    access: undefined,
    refresh: undefined,
    finalRedirect: '',
  });

  const [error, setError] = React.useState<string | undefined>(undefined);

  const query = useQuery();
  const history = useHistory();

  const check = async () => {
    setAuthorization({ access: undefined, refresh: undefined, finalRedirect: '' });
    setError(undefined);

    const code = query.get('cbcode');

    if (!code) {
      return;
    }

    const { success, error: err, access: accessToken, refresh: refreshToken } = await authorize(code);

    // Remove the 'cbcode' query parameter after authorizing
    query.delete('cbcode');
    history.replace({
      search: query.toString(),
    });

    if (!success || !accessToken) {
      setError(err ?? 'Authorization error');
      return;
    }

    const _finalRedirect = query.get('final_redirect');

    query.delete('final_redirect');
    history.replace({
      search: query.toString(),
    });

    // FIXME: This fallback value should really be Org.base_url
    setAuthorization({
      access: accessToken,
      refresh: refreshToken,
      finalRedirect: _finalRedirect ?? 'https://app.commandbar.com/dashboard',
    });
  };

  React.useEffect(() => {
    check();
  }, []);

  return { ...authorization, error };
};

interface IUserResponse {
  success: boolean;
  error?: string;
  user?: any;
}

const user = async (): Promise<IUserResponse> => {
  return axiosInstance
    .get('/auth/current/')
    .then((result: any) => {
      return { success: true, user: result.data };
    })
    .catch((err: any) => {
      const error = err?.response?.data?.detail ?? 'Invalid.';
      return { success: false, error };
    });
};

const Auth = {
  basic,
  sso,
  google,
  user,
  sendVerificationEmail,
  verifyEmail,
  getLoginType,
  setupOrg,
  register,
  acceptInvite,
};

export default Auth;
