/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React, { useState } from 'react';
import {
  IArgumentMap,
  IArgumentType,
  IEditorCommandType,
  IResourceSettingsByContextKey,
} from '@commandbar/internal/middleware/types';

import ArgumentsModal from './ArgumentsModal/ArgumentsModal';
import { getNextSortKey, argumentIsEditable } from './argumentUtils';
import { ICommandDetailDispatch } from '../CommandDetail';
import { ArgumentsEmptyState } from './EmptyState';
import styled from '@emotion/styled';
import { SortableList } from '../../../../shared_components';
import { ArgumentListItem } from './ArgumentListItem';
import NewObjectForm from './NewObjectForm';
import * as Command from '@commandbar/internal/middleware/command';

interface IProps {
  arguments: IArgumentMap;
  updateCommandState: ICommandDetailDispatch;
  recordSettings: IResourceSettingsByContextKey;
  command: IEditorCommandType;
}
export type IArgumentRow = IArgumentType & {
  name: string;
  id?: string;
};

const Container = styled.div`
  padding: 34px 0;
`;

const ArgumentList = (props: IProps) => {
  const [activeArg, setActiveArg] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = (activeArg?: string) => {
    if (activeArg) {
      setActiveArg(activeArg);
    }

    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setActiveArg(undefined);
  };

  const argumentsList: IArgumentRow[] = Object.keys(props.arguments)
    .filter((slug: string) => argumentIsEditable(props.arguments, slug))
    .map((name: string) => ({
      name,
      ...props.arguments[name],
    }))
    .sort((a: IArgumentType, b: IArgumentType) => a.order_key - b.order_key);

  return (
    <Container>
      {argumentsList.length ? (
        <>
          <SortableList
            nodes={argumentsList.map(({ name, ...arg }) => (
              <ArgumentListItem
                key={`arg-${name}`}
                arg={arg}
                argName={name}
                onDelete={props.updateCommandState.argument.delete}
                onClick={() => openModal(name)}
              />
            ))}
            onSort={props.updateCommandState.argument.reorder}
          />
          {Object.keys(props.arguments).length > 0 && (
            <NewObjectForm
              buttonText="Add an argument"
              placeholderText="Argument name"
              onCreate={(newArg: string) => {
                const new_key = getNextSortKey(props.arguments);
                props.updateCommandState.argument.new(newArg, new_key);
                openModal(newArg);
              }}
            />
          )}
        </>
      ) : (
        <>
          <ArgumentsEmptyState isRecordAction={Command.isRecordAction(props.command)} />
          <NewObjectForm
            buttonText="Add an argument"
            placeholderText="Argument name"
            onCreate={(newArg: string) => {
              const new_key = getNextSortKey(props.arguments);
              props.updateCommandState.argument.new(newArg, new_key);
              openModal(newArg);
            }}
          />
        </>
      )}
      <ArgumentsModal
        visible={isModalVisible}
        activeArgName={activeArg}
        commandName={props.command.text}
        arguments={props.arguments}
        argumentsList={argumentsList}
        onUpdate={props.updateCommandState.argument.updateValue}
        updateName={(oldName: string, newName: string) => {
          props.updateCommandState.argument.updateName(oldName, newName);
        }}
        onClose={() => closeModal()}
        recordSettings={props.recordSettings}
        onSort={props.updateCommandState.argument.reorder}
        onDelete={props.updateCommandState.argument.delete}
        onCreate={props.updateCommandState.argument.new}
      />
    </Container>
  );
};

export default ArgumentList;
