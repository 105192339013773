/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Form, Table, Empty } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { FormInstance } from 'antd/es/form';
import { Button, Input } from '.';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface EditableRowProps {
  index: number;
}

interface EditableCellProps<T extends IBaseRow> {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof T;
  record: T;
  handleSave: (record: T) => void;
  validator: (val: string) => { result: boolean; reason: string };
}

interface IBaseRow {
  key: number | string;
  text: string;
}

interface IEditableTableProps<T extends IBaseRow> {
  columns: Array<ColumnProps<T> & { editable?: boolean }>;
  dataSource: T[];
  newValue: T;
  addButtonText?: string;
  onSave: (row: T) => void;
  validator: (val: string) => { result: boolean; reason: string };
}

/*******************************************************************************/

const EditableContext = React.createContext<FormInstance | undefined>(undefined);

// eslint-disable-next-line
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

/*******************************************************************************/

function EditableCell<T extends IBaseRow>({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  validator,
  ...restProps
}: EditableCellProps<T>) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  // Focus new commands
  React.useEffect(() => {
    if (record && record.text.length === 0) {
      setEditing(true);
    }
  }, []);

  useEffect(() => {
    if (editing && inputRef) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (inputRef.current! as any).focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  };

  const save = async (_e: React.KeyboardEvent | React.FocusEvent) => {
    try {
      if (form) {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex as string}
        rules={[
          {
            required: true,
            message: `This is required.`,
          },
          () => ({
            validator(rule, value) {
              const res = validator(value);
              if (res.result) {
                return Promise.resolve();
              }
              return Promise.reject(res.reason);
            },
          }),
        ]}
      >
        <Input.TextArea ref={inputRef} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  // @ts-expect-error: typeerror
  return <td {...restProps}>{childNode}</td>;
}

/*******************************************************************************/

export function EditableTable<T extends IBaseRow>(props: IEditableTableProps<T>) {
  const [dataSource, setDataSource] = React.useState(props.dataSource);

  React.useEffect(() => {
    if (props.dataSource !== dataSource) {
      setDataSource(props.dataSource);
    }
  }, [dataSource, props.dataSource]);

  const handleAdd = () => {
    const newData = {
      ...props.newValue,
    };
    setDataSource([newData, ...dataSource]);
  };

  if (dataSource.length === 0) {
    return (
      <span>
        <br />
        <br />
        <Empty
          description={
            <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
              {props.addButtonText || 'Add a row'}
            </Button>
          }
        />
      </span>
    );
  }

  const handleSave = (row: T) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    // Eagerly update table
    setDataSource(newData);

    // Save
    props.onSave(row);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const newColumns = props.columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: record.key as string,
        handleSave: handleSave,
        validator: props.validator,
      }),
    };
  });
  return (
    <div>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        {props.addButtonText || 'Add a row'}
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={newColumns}
        pagination={dataSource.length < 5 ? false : undefined}
      />
    </div>
  );
}

EditableTable.Row = EditableRow;

export default EditableTable;
