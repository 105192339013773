import React from 'react';
import { CB_COLORS } from '@commandbar/design-system/components';

import FormLayout from './FormLayout';

const Setup = () => {
  return (
    <FormLayout h1="Please Finish Setting up your Account" h2="" error={''}>
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://app.commandbar.com/setup"
          style={{ color: CB_COLORS.primary, cursor: 'pointer', fontWeight: 650 }}
          target="_blank"
          rel="noreferrer"
        >
          Finish setup at commandbar.com
        </a>
      </div>
    </FormLayout>
  );
};

export default Setup;
