import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';
import { EyeOff } from '@commandbar/design-system/icons/react';
const Container = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${CB_COLORS.neutral700};
`;

const IconContainer = styled.div`
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background: ${CB_COLORS.neutral100};
  border-radius: 50%;
`;

export const DraftModeWarning = () => (
  <Container>
    <IconContainer>
      <EyeOff width={13} color={CB_COLORS.neutral500} />
    </IconContainer>
    Only team members can view draft experiences
  </Container>
);
