import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import classnames from 'classnames';
import React from 'react';
import './styles/StatusSwitch.css';
import { CB_COLORS } from '@commandbar/design-system/components';

const StatusSwitch = (props: {
  checked: boolean;
  onChange: SwitchChangeEventHandler;
  onLabel: string;
  offLabel: string;
  disabled: boolean;
}) => {
  const { disabled = false } = props;

  return (
    <label
      style={{
        color: props.checked ? CB_COLORS.blue1000 : '#CC6200',
        width: 70,
        display: 'flex',
        alignItems: 'center',
        fontSize: '11px',
        lineHeight: '14px',
        fontWeight: 500,
      }}
    >
      <Switch
        disabled={disabled}
        checked={props.checked}
        className={classnames('status-switch', { off: !props.checked })}
        onChange={props.onChange}
      />
      {props.checked ? props.onLabel : props.offLabel}
    </label>
  );
};

export default StatusSwitch;
