import { CB_COLORS } from '@commandbar/design-system/components';

export const selectStyles = {
  // eslint-disable-next-line unused-imports/no-unused-vars
  option: (provided: any, { isDisabled, isFocused, isSelected }: any) => ({
    ...provided,
    backgroundColor: isSelected ? CB_COLORS.blue100 : isFocused ? CB_COLORS.blue0 : null,
    borderBottom: '0.5px solid rgba(3, 3, 56, 0.1)',
    minHeight: '40px',
    fontSize: '12px',
    fontFamily: 'Inter',
    cursor: 'pointer',
    color: isSelected ? CB_COLORS.blue600 : null,
    transition: 'all 0.3s ease',
    display: 'flex',
    alignItems: 'center',
  }),
  menuList: (provided: any): any => ({
    ...provided,
    padding: 0,
  }),
  control: (provided: any): any => ({
    ...provided,
    border: '1px solid #d9d9d9',
    boxShadow: 'none',
    minHeight: '0px',
    cursor: 'pointer',
    ':hover': {
      ...provided[':hover'],
      borderColor: '#44434a', // from `antd-overrides.js` FIXME to a standard file
    },
  }),
  input: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided: any, _state?: any) => ({
    ...provided,
    padding: '5px 11px',
    cursor: 'text',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 2147483646,
  }),
  group: (provided: any) => ({
    ...provided,
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    marginBottom: 0,
  }),
};
