import Portal from '@commandbar/internal/client/Portal';
import axiosInstance from '@commandbar/internal/middleware/network';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { IChecklist, INudgeType, IOrganizationType, ISkinType } from '@commandbar/internal/middleware/types';
import { setHasUnreleasedEdits } from '../editor/useEditor';
import { SDKConfig } from '@commandbar/internal/client/CommandBarSDK';

const logout = async () => {
  const msg = {
    action: 'logout',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const synToken = async (access: string, refresh: string) => {
  const msg = {
    action: 'synToken',
    data: { access, refresh },
  };

  return await Portal.openChannel()(msg);
};

const requestToken = async () => {
  const msg = {
    action: 'requestToken',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const secureEditor = async () => {
  const msg = {
    action: 'secureEditor',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const onCMDK = async () => {
  const msg = {
    action: 'onCMDK',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const showEditor = async () => {
  const msg = {
    action: 'showEditor',
  };

  return await Portal.openChannel()(msg);
};

const hideEditor = async () => {
  const msg = {
    action: 'hideEditor',
  };

  return await Portal.openChannel()(msg);
};

const getHostUrl = async (): Promise<{ url: string }> => {
  const msg = {
    action: 'shareHostURL',
  };

  return (await Portal.openChannel()(msg)) as { url: string };
};

const getConfiguration = async (): Promise<{ data: SDKConfig }> => {
  const msg = {
    action: 'shareConfiguration',
  };

  return (await Portal.openChannel()(msg)) as { data: SDKConfig };
};

const shareLogs = async () => {
  const msg = {
    action: 'shareLogs',
  };
  return await Portal.openChannel()(msg);
};

const shareChecks = async () => {
  const msg = {
    action: 'shareChecks',
  };
  return await Portal.openChannel()(msg);
};

const shareCallbacks = async (): Promise<any> => {
  const msg = {
    action: 'shareCallbacks',
  };
  return await Portal.openChannel()(msg);
};

const shareComponentNamesByKey = async (): Promise<Record<string, string>> => {
  const msg = {
    action: 'shareComponentNamesByKey',
  };
  const result = (await Portal.openChannel()(msg)) as any;

  return result.components as Record<string, string>;
};

const openClickRecorder = async (id: number, startingSelectors: string[], singleStep?: boolean) => {
  const msg = {
    action: 'openClickRecorder',
    selectors: startingSelectors,
    singleStep,
    id,
  };
  return await Portal.openChannel()(msg);
};

const getProgrammaticTheme = async () => {
  return (await Portal.openChannel()({ action: 'getProgrammaticTheme' })) as Promise<{ data?: string }>;
};

const shareUser = async () => {
  const msg = {
    action: 'shareUser',
  };
  return await Portal.openChannel()(msg);
};

const shareIsOpen = async () => {
  const msg = {
    action: 'shareIsOpen',
  };
  return await Portal.openChannel()(msg);
};

const setTheme = async (skin: string | Pick<ISkinType, 'logo' | 'skin'>) => {
  const msg = {
    action: 'setTheme',
    data: skin,
  };

  return await Portal.openChannel()(msg);
};

const shareEditorRoute = (route: string) => {
  const msg = {
    action: 'shareEditorRoute',
    data: route,
  };

  return Portal.openChannel()(msg);
};

const shareUserAuthStatus = (data: boolean) => {
  const msg = {
    action: 'shareUserAuthStatus',
    data,
  };

  return Portal.openChannel()(msg);
};

const shareOrganization = (organization: IOrganizationType | null) => {
  const msg = {
    action: 'shareOrganization',
    data: { organization },
  };

  return Portal.openChannel()(msg);
};

const getOrganizationID = () => {
  const msg = {
    action: 'shareOrganizationID',
  };

  return Portal.openChannel()(msg);
};

const shareReleaseBadgeStatus = (status: boolean) => {
  const msg = {
    action: 'shareReleaseBadgeStatus',
    data: status,
  };

  return Portal.openChannel()(msg);
};

const shareInitialEditorPath = async (): Promise<{ data: string | null; success: boolean }> => {
  const msg = {
    action: 'shareInitialEditorPath',
  };
  return (await Portal.openChannel()(msg)) as { data: string | null; success: boolean };
};

const checkIfSelectorValid = async (selector: string): Promise<{ elementFound: boolean; elementVisible: boolean }> => {
  const msg = {
    action: 'checkIfSelectorValid',
    data: selector,
  };

  const result = (await Portal.openChannel()(msg)) as any;

  return result;
};

//////////////////////////////////////
// CommandBar Client

const client = async (name: string, data?: any) => {
  const msg = {
    action: 'client',
    method: name,
    data: data,
  };

  // @ts-expect-error: FIXME portal type
  return (await Portal.openChannel()(msg))?.result;
};

const isUserVerified = async () => {
  return await client('isUserVerified');
};

const setContext = async (context: any, meta?: any) => {
  return await client('setContext', [context, meta]);
};

const shareContext = async () => {
  return await client('shareContext');
};

const instanceAttributes = async () => {
  return await client('instanceAttributes');
};

let initialVersion: number | null = null;
let hasEdits = false;
// HACK: until we refactor Editor state management, we pull the Releases feature flag state here
let isReleasesAvailable: boolean | null = null;
export const checkReleaseBadge = async () => {
  if (isReleasesAvailable == null) {
    const orgSettings = await OrganizationSettings.read();
    isReleasesAvailable = !!orgSettings.releases_enabled;
  }

  if (!isReleasesAvailable) return;

  const { data } = await axiosInstance.get(`/releases/view/`);
  const lastEnv = data.environments[data.environments.length - 1];
  if (!lastEnv) return;
  if (!data.latest_history_event) return;

  const deployedVersionNum = lastEnv.release?.history_event.version_num;
  const latestVersionNum: number = data.latest_history_event.version_num;
  initialVersion = initialVersion ?? latestVersionNum;

  if (deployedVersionNum != null && latestVersionNum > deployedVersionNum) {
    shareReleaseBadgeStatus(true);
  } else {
    shareReleaseBadgeStatus(false);
  }
  if (deployedVersionNum != null && latestVersionNum > Math.max(initialVersion, deployedVersionNum)) {
    hasEdits = true;
    setHasUnreleasedEdits(hasEdits);
  } else {
    hasEdits = false;
    setHasUnreleasedEdits(hasEdits);
  }
};

export const clearUnreleasedEdits = () => {
  hasEdits = false;
  setHasUnreleasedEdits(hasEdits);
};

export const hasUnreleaasedEdits = () => hasEdits;

const openBar = async (text?: string) => {
  return await client('open', [text]);
};

const closeBar = async () => {
  return await client('close');
};

const closeHelpHub = async () => {
  return await client('closeHelpHub');
};

const showMessage = async (guideEvent: string, show: boolean) => {
  return await client('showMessage', [guideEvent, show]);
};

const reload = async (
  toReload: (
    | 'reloadCommands'
    | 'reloadOrganization'
    | 'reloadPlaceholders'
    | 'reloadNudges'
    | 'reloadChecklists'
    | 'reloadHelpHub'
  )[],
) => {
  if (
    toReload.some((item) =>
      ['reloadCommands', 'reloadOrganization', 'reloadPlaceholders', 'reloadNudges', 'reloadChecklists'].includes(item),
    )
  ) {
    checkReleaseBadge();
  }
  return await client('reload', [toReload]);
};

const setTestMode = async (on: boolean) => {
  return await client('setTestMode', [on]);
};

const setPreviewMode = async (on: boolean) => {
  return await client('setPreviewMode', [on]);
};

const onboard = async () => {
  return await client('onboard');
};

const shareProgrammaticCommands = async () => {
  return await client('shareProgrammaticCommands');
};

const shareContextSettings = async () => {
  return await client('shareContextSettings');
};

const previewNudge = async (nudge: INudgeType, step?: number, clearData = false) => {
  return await client('previewNudge', [{ nudge, step, clearData }]);
};

const stopNudgePreview = async (index?: number) => {
  return await client('stopNudgePreview', [{ index }]);
};

const previewChecklist = async (checklist: IChecklist, clearData: boolean) => {
  return await client('previewChecklist', [{ checklist, clearData }]);
};

const stopChecklistPreview = async () => {
  return await client('stopChecklistPreview', []);
};

const shareEditorRouteWithBar = async (path: string) => {
  return await client('shareEditorRouteWithBar', [path]);
};

const openHelpHub = async (options?: { query?: string; articleId?: number | null }) => {
  return await client('openHelpHub', options ? [options] : []);
};

const previewRecommendationSet = async (recommendationSetId?: number) => {
  return await client('previewRecommendationSet', [{ recommendationSetId }]);
};

const stopRecommendationSetPreview = async () => {
  return await client('previewRecommendationSet', []);
};

const Sender = {
  logout,
  openClickRecorder,
  synToken,
  requestToken,
  secureEditor,
  onCMDK,
  shareUser,
  shareIsOpen,
  openBar,
  closeBar,
  openHelpHub,
  closeHelpHub,
  previewRecommendationSet,
  stopRecommendationSetPreview,
  showMessage,
  setTestMode,
  isUserVerified,
  setPreviewMode,
  hideEditor,
  setContext,
  shareContext,
  instanceAttributes,
  shareCallbacks,
  shareComponentNamesByKey,
  showEditor,
  getHostUrl,
  shareLogs,
  shareChecks,
  checkIfSelectorValid,
  shareContextSettings,
  shareProgrammaticCommands,
  onboard,
  getProgrammaticTheme,
  setTheme,
  shareEditorRoute,
  shareUserAuthStatus,
  getConfiguration,
  shareOrganization,
  shareReleaseBadgeStatus,
  checkReleaseBadge,
  getOrganizationID,
  previewNudge,
  stopNudgePreview,
  previewChecklist,
  stopChecklistPreview,
  shareEditorRouteWithBar,
  shareInitialEditorPath,
  reload,
};

export default Sender;
