import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import _ from 'lodash';

export interface ICommandsByContextKey {
  [contextKey: string]: IEditorCommandTypeLite[];
}

export const getCommandsByContextKey = (commands: IEditorCommandTypeLite[]): ICommandsByContextKey => {
  return commands.reduce((acc: ICommandsByContextKey, command) => {
    const regexWithContext = /(?!{{context\.)([^{{]+)(?=}})+/g; // get variables of syntax {{context.}}

    for (const arg of Object.keys(command.arguments)) {
      if (command.arguments[arg].type === 'context') {
        const contextKey = command.arguments[arg].value as string;
        acc[contextKey] ? acc[contextKey].push(command) : (acc[contextKey] = [command]);
      }
    }

    // get any context variables in link if the command is a link command
    const template = _.get(command, 'template.type');
    // Typescript fails to infer that template is a string | undefined here
    const templateValue = _.get(command, 'template.value') as any;
    if (template && template === 'link' && templateValue) {
      let linkVars = templateValue.match(regexWithContext) || [];
      linkVars = linkVars.map((match: string) => {
        return match.split('context.').pop();
      });

      for (const contextKey of linkVars) {
        acc[contextKey] ? acc[contextKey].push(command) : (acc[contextKey] = [command]);
      }
    }
    return acc;
  }, {});
};
