import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { DropdownMenu, Alert } from '../../../../shared_components';
import { IArgumentType } from '@commandbar/internal/middleware/types';
import { getIcon } from './iconHelper';
import { ListItem } from '../../../../shared_components/ListItem';

type Props = {
  argName: string;
  arg: IArgumentType;
  onDelete: (value: string) => void;
  onClick: () => void;
};

export const ArgumentListItem = (props: Props) => {
  const { argName, arg, onDelete, onClick } = props;

  const icon = getIcon(arg);

  const prompt = arg.label ?? argName;

  const extra = (
    <div>
      <DropdownMenu
        vertical
        keyName="options"
        items={[{ name: 'Delete', icon: <DeleteOutlined />, onClick: () => onDelete(argName) }]}
      />
    </div>
  );

  return (
    <div>
      <ListItem
        onClick={onClick}
        icon={icon}
        text={prompt}
        // Do not show the tag for dashboard arguments as those cannot be used for interpolation
        tag={arg.type !== 'html' && arg.type !== 'video' ? argName : undefined}
        extra={extra}
      />
      {!['context', 'provided', 'set'].includes(arg.type) && (
        <Alert
          type="error"
          message={`Arguments of this type (${arg.type}) are no longer supported. Please remove or update this argument before making further changes.`}
          showIcon
          style={{ margin: '10px 0 20px 0' }}
        />
      )}
    </div>
  );
};
