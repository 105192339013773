// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Globe01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 2a15.2996 15.2996 0 0 1 4 10 15.2997 15.2997 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 12h20M2 12c0 5.5228 4.4771 10 10 10M2 12C2 6.4771 6.4771 2 12 2m10 10c0 5.5228-4.4772 10-10 10m10-10c0-5.5229-4.4772-10-10-10m0 0a15.2996 15.2996 0 0 1 4 10 15.2997 15.2997 0 0 1-4 10m0-20a15.3 15.3 0 0 0-4 10 15.3 15.3 0 0 0 4 10"
    />
  </svg>
);
export default Globe01;
