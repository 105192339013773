import React, { useEffect } from 'react';
import { MdDragHandle } from 'react-icons/md';

import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import {
  Button,
  commandDefault,
  Empty,
  Header,
  Icon,
  SortableList,
  Space,
  Spin,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
} from '../../../shared_components';
import styled from '@emotion/styled';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { useAppContext } from '../../../Widget';
import useLinkedCommand from '../../context/useLinkedCommand';

const Recommendations = () => {
  const { commands } = useAppContext();

  const recommendedCommands = React.useMemo(
    () =>
      commands.filter(
        (c) => !!c.recommend_rules.length || !!getConditions(c.recommend_expression).length || c.always_recommend,
      ),
    [commands],
  );

  return (
    <>
      <FeatureAnnouncementCard
        identifier="bar-recommendations"
        title={<Row align="middle">Recommending commands</Row>}
        docsLink="https://www.commandbar.com/docs/commands/recommendations"
      >
        <span>
          Suggest commands to users based on who they are, where they are, and what they're doing inside your app.
        </span>
      </FeatureAnnouncementCard>
      <SimplePanel>
        {!!recommendedCommands.length ? (
          <RecommendedCommandsList recommendedCommands={recommendedCommands} />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              "You don't have any recommendations yet. Create one by clicking a command and selecting the 'Recommendations' tab."
            }
          />
        )}
      </SimplePanel>
    </>
  );
};

const Container = styled.div`
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-grow: 1;
  cursor: grab;
  border: 1px solid #e6e6e8;
  margin: 4px 0px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;

  &:hover {
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
    transform: scale(1.02);
  }
`;

const RecommendedCommandsList = ({ recommendedCommands }: { recommendedCommands: IEditorCommandTypeLite[] }) => {
  const { dispatch } = useAppContext();
  const [list, setList] = React.useState<IEditorCommandTypeLite[] | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { openExistingLinkedCommand } = useLinkedCommand();

  const isDirty = list !== null;

  useEffect(() => {
    // if the commands change and we're not loading, reset the list
    if (!isLoading) {
      setList(null);
    }
  }, [recommendedCommands]);

  const hasCommandChanged = (newCommand: IEditorCommandTypeLite) => {
    const oldCommand = recommendedCommands.find((c) => c.id === newCommand.id);
    return newCommand.recommend_sort_key !== oldCommand?.recommend_sort_key;
  };

  const onSave = async () => {
    if (!list) return;

    setIsLoading(true);
    try {
      await Promise.all(
        list.map((c) => {
          if (hasCommandChanged(c)) {
            return dispatch.commands.savePartial({ id: c.id, recommend_sort_key: c.recommend_sort_key });
          }
          return null;
        }),
      );

      setList(null);
    } finally {
      setIsLoading(false);
    }
  };

  const orderedList = (list ?? recommendedCommands).sort((a, b) =>
    compareObjs({ sort_key: a.recommend_sort_key, id: a.id }, { sort_key: b.recommend_sort_key, id: b.id }),
  );

  const onReorder = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newList = [...orderedList];
    const [movedItem] = newList.splice(oldIndexOfMovedObj, 1);
    newList.splice(newIndexOfMovedObj, 0, movedItem);

    setList(
      newList.map((command, idx) => ({
        ...command,
        recommend_sort_key: idx,
      })),
    );
  };

  return (
    <div>
      <Header
        text="Recommended commands order"
        style={{ marginTop: 0 }}
        rightActions={[
          <Button key="save" onClick={onSave} disabled={!isDirty}>
            Save
          </Button>,
        ]}
      />
      <Spin spinning={isLoading}>
        <SortableList
          nodes={orderedList.map((c) => (
            <Container key={c.id} onClick={() => openExistingLinkedCommand(c)}>
              <Space align="center">
                <MdDragHandle />
                <Icon
                  icon={c.icon || commandDefault(c)}
                  style={{ fontSize: 12, marginRight: 10, color: c.icon_color || undefined }}
                />
                {c.text}
              </Space>
            </Container>
          ))}
          onSort={onReorder}
        />
      </Spin>
    </div>
  );
};

export default Recommendations;
