import React from 'react';
import { Header, StyledCollapse, StyledHeader, StyledPanel, StyledSubPanel } from './form';
import { ReactComponent as CaretUp } from '../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../img/caret_down.svg';
import { CB_COLORS } from '@commandbar/design-system/components';

const PanelHeader = ({
  isActive,
  children,
  actions,
}: {
  isActive: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <StyledHeader>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Header>{children}</Header>
          {isActive ? (
            <CaretUp style={{ color: CB_COLORS.neutral600 }} />
          ) : (
            <CaretDown style={{ color: CB_COLORS.neutral600 }} />
          )}
        </div>
        <div
          onClick={(e) => e.stopPropagation() /* we don't want clicks on actions to close or open the panel */}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {actions}
        </div>
      </div>
    </StyledHeader>
  );
};

const Panel = <PanelKey extends string | number>({
  activeKeys,
  setActiveKeys,
  panelKey,
  header,
  subpanel = false,
  children,
  actions,
  accordion = false,
}: {
  activeKeys: PanelKey[];
  setActiveKeys: React.Dispatch<(prevState: PanelKey[]) => PanelKey[]>;
  panelKey: PanelKey;
  header: React.ReactNode;
  subpanel?: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  accordion?: boolean;
}) => {
  const PanelComponent = subpanel ? StyledSubPanel : StyledPanel;
  return (
    <StyledCollapse
      onChange={() => {
        if (accordion) {
          setActiveKeys((ks) => {
            if (ks.includes(panelKey)) {
              return [];
            } else {
              return [panelKey];
            }
          });
        } else {
          setActiveKeys((ks) => {
            if (ks.includes(panelKey)) {
              return ks.filter((k) => k !== panelKey);
            } else {
              return [...ks, panelKey];
            }
          });
        }
      }}
      activeKey={activeKeys}
      expandIcon={() => null}
      accordion={accordion}
    >
      <PanelComponent
        key={panelKey}
        header={
          <PanelHeader isActive={activeKeys.includes(panelKey)} actions={actions}>
            {header}
          </PanelHeader>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: 16, gap: 16 }}>{children}</div>
      </PanelComponent>
    </StyledCollapse>
  );
};

export default Panel;
