/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import snarkdown from '@bpmn-io/snarkdown';
import { Tabs } from 'antd';
import { Input } from '.';
import { defineProcess } from '@commandbar/internal/middleware/utils';
import styled from '@emotion/styled';

defineProcess();

const MarkdownPreview = styled.div`
  padding: 6px 11px;
  & > img {
    max-width: 200px;
    text-align: center;
    display: block;
    margin: auto;
  }
`;

const MarkdownEditor = (props: { value: string; onChange: (e: any) => void }) => {
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane key="1" tab="Edit">
        <Input.TextArea
          defaultValue={props.value}
          onChange={props.onChange}
          placeholder="Write text (Markdown supported)"
          autoSize={{ minRows: 10, maxRows: 12 }}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab="Preview">
        <MarkdownPreview dangerouslySetInnerHTML={{ __html: snarkdown(props.value) }} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MarkdownEditor;
