import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Input, Col, Row } from '../../../../../shared_components';
import { IAvailabilityDependency } from '../types';
import { RulePrefix } from './styled';

interface IProps extends IAvailabilityDependency {
  prefixText?: string;
  inputStyle?: React.CSSProperties;
}

export const AvailabilityDependency = ({ type, field, operator, message, prefixText, inputStyle }: IProps) => (
  <Row gutter={4} align="middle" wrap={false} style={{ overflow: 'hidden', marginBottom: '8px' }}>
    <Col>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {prefixText && <RulePrefix>{prefixText}</RulePrefix>}

        <div style={{ display: 'flex', flex: 1 }}>
          <Input disabled value={type} placeholder="type" style={inputStyle} />
        </div>
      </div>
    </Col>
    <Col flex="128px">
      <Tooltip placement="top" content={field}>
        <Input disabled value={field} placeholder="field" style={inputStyle} />
      </Tooltip>
    </Col>

    <Col flex="auto">
      <Input disabled value={operator} placeholder="operator" style={inputStyle} />
    </Col>
    <Col flex="52px" style={{ textAlign: 'center' }}>
      <Tooltip placement="top" content={message}>
        <span style={{ cursor: 'pointer' }}>
          <InfoCircleOutlined />
        </span>
      </Tooltip>
    </Col>
  </Row>
);
