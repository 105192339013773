/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { Tooltip, Badge, Space, Modal, message } from '../../../shared_components';
import { useHistory } from 'react-router';
import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import { freshCommand } from '../../useEditor';
import { Button, DropdownMenu } from '../../../shared_components';

import CommandGroup from '../CommandGroup';
import EmptyCategoryActionCard from './EmptyCategoryActionCard';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { IDropdownMenuRow } from '../../../shared_components/DropdownMenu';
import { useAppContext } from '../../../Widget';
import { COMMANDS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

const { confirm } = Modal;

const CategoryGroup = (props: {
  category: ICommandCategoryType | undefined;
  commands: IEditorCommandTypeLite[];
  unhealthyCommands: IEditorCommandTypeLite[];
  searchText: string;
  openSettings: (() => void) | null;
}) => {
  const { dispatch, organization } = useAppContext();
  const history = useHistory();
  const { commands, unhealthyCommands } = props;

  const { hasRouter } = useWindowInfo();

  const handleAdd = (e: any) => {
    const newCommand = freshCommand(organization, props.category?.id, hasRouter, props.category?.icon);
    history.replace(`${COMMANDS_ROUTE}/${newCommand.id}${history.location.search}`);
    dispatch.commands.setActive(newCommand);
    e.stopPropagation();
  };

  const onDelete = () => {
    if (props.category === undefined) return;

    confirm({
      title: 'Are you sure you want to delete this category?',
      icon: <ExclamationCircleOutlined />,
      content: `All commands in this group will also be deleted.`,
      onOk() {
        if (props.category?.id) {
          dispatch.categories.delete(props.category.id);
        }
      },
    });
  };

  const categoryName = props.category?.name || 'Uncategorized';

  const menuItems: IDropdownMenuRow[] = [
    {
      name: 'Copy id',
      icon: <CopyOutlined />,
      onClick: () => {
        if (props.category?.id) {
          const element = document.createElement('textarea');
          element.value = props.category?.id.toString() || '';
          document.body.appendChild(element);
          element.select();
          document.execCommand('copy');
          document.body.removeChild(element);
          message.info('Category id copied to clipboard.');
        }
      },
    },
    { name: 'Delete', icon: <DeleteOutlined />, onClick: onDelete },
  ];

  const rightActions = (
    <Space align="center" size="large">
      <Tooltip content="Create a new command">
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          style={{ fontSize: 14, opacity: 0.9, boxShadow: 'none' }}
          onClick={handleAdd}
        />
      </Tooltip>
      {!!props.openSettings && (
        <Tooltip content="Edit settings">
          <Button
            icon={<SettingOutlined />}
            type="text"
            style={{ fontSize: 14, opacity: 0.9, boxShadow: 'none' }}
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              props.openSettings!();
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
      {props.category && <DropdownMenu keyName="category-actions" items={menuItems} />}
    </Space>
  );

  const leftActions = (
    <Space align="center">
      <span style={{ fontWeight: 600 }}>{categoryName}</span>
      <Badge count={commands.length} style={{ background: '#1890ff', opacity: 0.7, lineHeight: '16px' }} />
    </Space>
  );

  return (
    <CommandGroup
      category={props.category || null}
      commands={commands}
      unhealthyCommands={unhealthyCommands}
      searchText={props.searchText}
      emptyActions={<EmptyCategoryActionCard category={props.category} />}
      rightActions={rightActions}
      leftActions={leftActions}
    />
  );
};

export default CategoryGroup;
