// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Link05 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17 17h-1.2222A.7778.7778 0 0 1 15 16.2222V7.7778A.7778.7778 0 0 1 15.7778 7H17c2.7614 0 5 2.2386 5 5s-2.2386 5-5 5Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h10m-9 5H7c-2.7614 0-5-2.2386-5-5s2.2386-5 5-5h2m6.7778 10H17c2.7614 0 5-2.2386 5-5s-2.2386-5-5-5h-1.2222A.7778.7778 0 0 0 15 7.7778v8.4444c0 .4296.3482.7778.7778.7778Z"
    />
  </svg>
);
export default Link05;
