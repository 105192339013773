import { Button, CB_COLORS } from '@commandbar/design-system/components';
import styled from '@emotion/styled';
import { TextInput } from '../../nudges/NudgeContentForm/FormFactorPositionInput/PositionInputConfig/TextInput';

export const SettingContainer = styled.div<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 16px;
  width: 100%;
  background: ${({ active }) => (!!active ? `${CB_COLORS.blue0}` : `${CB_COLORS.white}`)};
  border: ${({ active }) => (!!active ? `2px solid ${CB_COLORS.blue600}` : `1px solid ${CB_COLORS.neutral500}`)};
  border-radius: 4px;

  .ant-radio-checked .ant-radio-inner {
    border-color: ${CB_COLORS.blue600};
  }

  .ant-radio-inner::after {
    background-color: ${CB_COLORS.blue600};
  }
`;

export const CodeWrapper = styled.div`
  width: 100%;
  padding: 16px 0 0 24px;
`;

export const TargetButton = styled(Button)`
  display: flex;
  flex-direction: row;
  color: ${CB_COLORS.neutral900};
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 8px;

  width: 100% !important;
  height: 32px;

  background: ${CB_COLORS.white};
  border: 1px solid rgba(10, 10, 15, 0.24);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
`;

export const CustomTextInput = styled(TextInput)`
  min-height: 70px;
  width: 100% !important;
  margin-top: 8px;
  padding: 8px;
  gap: 8px;
  width: 356px;
  background: ${CB_COLORS.white};
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 14px;
`;

export const SecondaryTitle = styled(Title)`
  font-weight: 500;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${CB_COLORS.neutral800};
`;

export const LauncherBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 16px;
  width: 100%;
  height: 72px;
  background: ${CB_COLORS.white};
  border-radius: 8px;
`;
