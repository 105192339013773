import React from 'react';
import styled from '@emotion/styled';
import {
  Check,
  CursorClick02,
  InfoCircle,
  Link05,
  MessageChatCircle,
  Settings04,
  Target04,
  ZapFast,
} from '@commandbar/design-system/icons/react';

import { Select, Tooltip } from '../../../shared_components';
import * as S from '../styled';
import { NudgeDropdown } from './NudgeDropdown';
import { useAppContext } from '../../../Widget';
import Sender from '../../../management/Sender';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import { CommandSelect } from './CommandSelect';
import { CB_COLORS } from '@commandbar/design-system/components';

import type {
  IEditorCommandTypeLite,
  INudgeStepContentBlockType,
  INudgeStepContentButtonBlockType,
} from '@commandbar/internal/middleware/types';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { InputContainer, StyledLabel } from '../../../shared_components/form';
import { OverlayCheckIconContainer, OverlaySettingsOption } from '../../components/styled';
import { CloseCircleFilled } from '@ant-design/icons';

const SuffixContainer = styled.div`
  & .ant-dropdown-trigger {
    padding: 0;
  }
`;

const RouterOption = styled(OverlaySettingsOption)`
  border-top: 1px solid ${CB_COLORS.neutral300};
`;

const RouterTooltip = styled(Tooltip)`
  width: 204px !important;
  padding: 8px !important;
  border-radius: 4px !important;
  background: #000000 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
`;

const openChatOptions = [
  { value: 'intercom', title: 'Intercom' },
  { value: 'helpscout', title: 'Helpscout' },
  { value: 'freshdesk', title: 'Freshdesk' },
  { value: 'crisp', title: 'Crisp' },
  { value: 'zendesk', title: 'Zendesk' },
  { value: 'liveChat', title: 'LiveChat' },
  { value: 'olark', title: 'Olark' },
];

const actionTypeOptions = {
  link: {
    dropdownLabel: 'Visit a page',
    selectionLabel: <Link05 width={16} height={16} />,
  },
  click: {
    dropdownLabel: 'Click an element',
    selectionLabel: <CursorClick02 width={16} height={16} />,
  },
  execute_command: {
    dropdownLabel: 'Execute a command',
    selectionLabel: <ZapFast width={16} height={16} />,
  },
  open_chat: {
    dropdownLabel: 'Open Chat',
    selectionLabel: <MessageChatCircle width={16} height={16} />,
  },
  dismiss: {
    dropdownLabel: 'Dismiss',
    selectionLabel: <CloseCircleFilled width={16} height={16} color="" />,
  },
};

type ActionType = 'link' | 'click' | 'execute_command' | 'open_chat' | 'dismiss';
type OpenChatType = 'intercom' | 'helpscout' | 'freshdesk' | 'crisp' | 'zendesk' | 'livechat' | 'olark' | '';
type ActionPartial =
  | { type: 'link'; operation: 'router' | 'self' | 'blank'; value: string }
  | { type: 'click'; value: string }
  | { type: 'execute_command'; meta: { command: string } }
  | { type: 'open_chat'; meta: { type: OpenChatType } }
  | { type: 'dismiss' }
  | { type: 'no_action' };

interface ISelectActionType {
  onBlockChange: (block: Extract<INudgeStepContentBlockType, { type: 'button' }>) => void;
  button?: INudgeStepContentButtonBlockType['meta'];
}

const SelectActionType = ({ button, onBlockChange }: ISelectActionType) => {
  const { hasRouter } = useWindowInfo();
  const hasAction = (actionType: ActionType | 'no_action' | undefined): actionType is ActionType =>
    !!actionType && actionType !== 'no_action';

  const actionType = button?.action?.type;

  return (
    <NudgeDropdown
      overlayWidth="180px"
      value={actionType}
      onChange={(e) => {
        const emptyActionPartial = ((): ActionPartial => {
          switch (e) {
            case 'link':
              return { type: 'link', operation: hasRouter ? 'router' : 'self', value: '' };
            case 'click':
              return { type: 'click', value: '' };
            case 'execute_command':
              return { type: 'execute_command', meta: { command: '' } };
            case 'open_chat':
              return { type: 'open_chat', meta: { type: '' } };
            case 'dismiss':
              return { type: 'dismiss' };
            default:
              return { type: 'no_action' };
          }
        })();

        onBlockChange({
          type: 'button',
          meta: {
            ...button,
            action: emptyActionPartial,
          },
        });
      }}
      options={(
        Object.entries(actionTypeOptions) as Array<
          [keyof typeof actionTypeOptions, (typeof actionTypeOptions)[keyof typeof actionTypeOptions]]
        >
      ).map(([value, { dropdownLabel }]) => ({
        value,
        label: dropdownLabel,
      }))}
      gap="4px"
    >
      {hasAction(actionType) && actionTypeOptions[actionType].selectionLabel}
    </NudgeDropdown>
  );
};

const SelectChatType = ({ button, onBlockChange }: IComboInput) => {
  return (
    <Select
      style={{ width: '100%' }}
      defaultValue={button?.action?.type === 'open_chat' && button?.action?.meta?.type}
      onChange={(e) => {
        onBlockChange({
          type: 'button',
          meta: {
            ...button,
            label: button?.label || '',
            action: { type: 'open_chat', meta: { type: e.toString() } },
          },
        });
      }}
    >
      {openChatOptions.map(({ value, title }) => (
        <Select.Option key={value} value={value}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

interface IClickInput {
  onBlockChange: (block: Extract<INudgeStepContentBlockType, { type: 'button' }>) => void;
  button?: INudgeStepContentButtonBlockType['meta'];
}

const ClickInput = ({ button, onBlockChange }: IClickInput) => {
  const appContext = useAppContext();
  const id = React.useMemo(() => Math.random(), []);
  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;

    onBlockChange({
      type: 'button',
      meta: {
        ...button,
        action: { type: 'click', value: data.selectors[0] },
      },
    });
    return respondSuccess();
  };

  usePortal({
    proxy: {
      onClickRecorderComplete,
    },
  });

  const startRecorder = (id: number, startingSelectors: string[], singleStep?: boolean) => {
    Sender.openClickRecorder(id, startingSelectors, singleStep);
  };

  if (button?.action?.type !== 'click') {
    return null;
  }

  return (
    <S.SuffixInput
      value={button.action.value}
      onChange={(e) => {
        onBlockChange({
          type: 'button',
          meta: {
            ...button,

            action: { type: 'click', value: e.target.value },
          },
        });
      }}
      suffix={
        appContext?.isStandaloneEditor ? (
          <Tooltip placement="auto" content="Install in your app to use the click recorder">
            <Target04 width={16} height={16} />
          </Tooltip>
        ) : (
          <button
            style={{ all: 'unset', cursor: 'pointer', position: 'relative', top: '2px' }}
            onClick={() => startRecorder(id, [], true)}
          >
            <Target04 width={16} height={16} />
          </button>
        )
      }
      placeholder="Pick element..."
    />
  );
};

const DummyInput = styled.div`
  height: 100%;
  flex: 1 1 0%;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  background: white;
  border-left: 1px solid #a2a2a9;
  border-radius: 0 4px 4px 0;
`;

interface IComboInput {
  onBlockChange: (block: Extract<INudgeStepContentBlockType, { type: 'button' }>) => void;
  button?: INudgeStepContentButtonBlockType['meta'];
}

const ComboInput = ({ button, onBlockChange }: IComboInput) => {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  if (button?.action?.type === 'link') {
    return (
      <S.SuffixInput
        value={button.action.value}
        onChange={(e) => {
          onBlockChange({
            type: 'button',
            meta: {
              ...button,
              action: { ...button.action, type: 'link', value: e.target.value },
            },
          });
        }}
        suffix={
          <SuffixContainer>
            <NudgeDropdown
              visible={isOverlayVisible}
              onVisibleChange={(visible) => setIsOverlayVisible(visible)}
              customOverlay={
                <>
                  {(
                    [
                      { label: 'Open in same tab', value: 'self' },
                      { label: 'Open in a new tab', value: 'blank' },
                    ] as const
                  ).map((option, index) => (
                    <OverlaySettingsOption
                      key={`${option.label}${index}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (button?.action?.type === 'link') {
                          onBlockChange({
                            type: 'button',
                            meta: {
                              ...button,
                              action: { type: 'link', operation: option.value, value: button.action.value },
                            },
                          });
                        }
                        setIsOverlayVisible(false);
                      }}
                    >
                      <OverlayCheckIconContainer
                        visible={
                          button?.action?.type === 'link' &&
                          ((button.action.operation === 'router' && option.value === 'self') ||
                            button.action.operation === option.value)
                        }
                      >
                        <Check width={13} />
                      </OverlayCheckIconContainer>
                      {option.label}
                    </OverlaySettingsOption>
                  ))}
                  <RouterOption
                    key="Use router"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (button?.action?.type === 'link') {
                        if (button.action.operation === 'router') {
                          onBlockChange({
                            type: 'button',
                            meta: {
                              ...button,
                              action: { type: 'link', operation: 'self', value: button.action.value },
                            },
                          });
                        } else {
                          onBlockChange({
                            type: 'button',
                            meta: {
                              ...button,
                              action: { type: 'link', operation: 'router', value: button.action.value },
                            },
                          });
                        }
                      }
                    }}
                  >
                    <OverlayCheckIconContainer
                      visible={
                        (button?.action?.type === 'link' ? button.action.operation || 'blank' : 'blank') === 'router'
                      }
                    >
                      <Check width={13} />
                    </OverlayCheckIconContainer>
                    Use router
                    <RouterTooltip
                      content="Supply a router function to allow page changes without a reload."
                      arrow={false}
                      placement="bottom"
                    >
                      <InfoCircle
                        width={10}
                        height={10}
                        color={CB_COLORS.neutral1000}
                        style={{
                          marginLeft: '8px',
                        }}
                      />
                    </RouterTooltip>
                  </RouterOption>
                </>
              }
              caret={false}
            >
              <Settings04 width={16} height={16} />
            </NudgeDropdown>
          </SuffixContainer>
        }
        placeholder="URL"
      />
    );
  }

  if (button?.action?.type === 'click') {
    return <ClickInput button={button} onBlockChange={onBlockChange} />;
  }

  return null;
};

interface IActionEditor {
  onBlockChange: (step: Extract<INudgeStepContentBlockType, { type: 'button' }>) => void;
  button?: INudgeStepContentButtonBlockType['meta'];
  label?: boolean;
  lightBorder?: boolean;
  commandFilter?: (command: IEditorCommandTypeLite) => boolean;
}

export const ActionEditor = ({
  commandFilter,
  button,
  onBlockChange,
  label = true,
  lightBorder = false,
}: IActionEditor) => {
  const { commands } = useAppContext();
  const Container = label ? S.SectionContainer : React.Fragment;

  const command = commands.find(
    (c) => button?.action?.type === 'execute_command' && c.id.toString() === button?.action.meta.command,
  );
  const commandId = command?.id;

  const renderActionInput = () => {
    switch (button?.action?.type) {
      case 'execute_command':
        return (
          <CommandSelect
            value={commandId}
            commandFilter={commandFilter}
            onChange={(e) => {
              onBlockChange({
                type: 'button',
                meta: {
                  ...button,
                  label: button?.label || '',
                  action: { type: 'execute_command', meta: { command: e.toString() } },
                },
              });
            }}
          />
        );
      case 'open_chat':
        return <SelectChatType button={button} onBlockChange={onBlockChange} />;
      case 'dismiss':
        return (
          <DummyInput>
            <span>Dismiss</span>
          </DummyInput>
        );
      default:
        return <ComboInput button={button} onBlockChange={onBlockChange} />;
    }
  };

  return (
    <Container>
      <InputContainer>
        {label && <StyledLabel>Action</StyledLabel>}
        <S.CombinationInput lightBorder={lightBorder}>
          <SelectActionType button={button} onBlockChange={onBlockChange} />
          {renderActionInput()}
        </S.CombinationInput>
      </InputContainer>
    </Container>
  );
};
