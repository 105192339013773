// This file gets loaded during the webpack loading to affect antd global styles.
// Changes in this file won’t propagate during hot-reloading, so you need to re-run 'yarn watch' to apply changes.

const CB_FONTS = {
  inter:
    "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  avenirNext: 'Avenir Next, proxima-nova, sans-serif',
};

const editorOverrides = {
  '@font-family': CB_FONTS.inter,
  '@font-size-base': '14px',
  '@border-radius-base': '4px',
  '@primary-color': '#44434a',

  '@btn-primary-color': '#fff',
  '@btn-primary-bg': '#2755f0',
  '@btn-default-color': '#2755f0',
  '@btn-default-bg': 'linear-gradient(0deg, rgba(39, 85, 240, 0.1), rgba(39, 85, 240, 0.1)), #FFFFFF',

  '@card-padding-base': '16px',
  '@card-radius': '6px',

  '@table-font-size': '14px',
  '@table-bg': 'transparent',
  '@table-header-bg': 'transparent',
  '@table-header-color': 'rgba(20,20,20,0.6)',
  '@table-header-sort-bg': 'transparent',
  '@table-body-sort-bg': 'transparent',
  '@table-padding-vertical': '8px',
  '@table-padding-horizontal': '8px',
  '@table-row-hover-bg': 'transparent',

  '@input-padding-vertical-base': '6px',
  '@input-border-color': '#e6e6e8',

  '@info-color': '#97ccfc',
  '@alert-info-border-color': '#1890ff',

  '@dropdown-font-size': '14px',
  '@dropdown-vertical-padding': '8px',

  '@select-border-color': '#E6E6E8',
  '@select-item-selected-font-weight': '500',
  '@select-item-selected-bg': 'lighten(@primary-color, 50%)',

  '@divider-color': 'rgba(0, 0, 0, 10%)',

  '@badge-height': '15px',
  '@badge-font-size': '10px',

  '@switch-color': '#2755f0',
  '@switch-height': '16px',
  '@switch-min-width': '24px',

  '@zindex-modal': '2147483646',
  '@zindex-modal-mask': '2147483646',
  '@zindex-message': '2147483647',
  '@zindex-dropdown': '2147483647',
  '@zindex-tooltip': '2147483647',
  '@zindex-popover': '2147483647',
};

exports.editorOverrides = editorOverrides;
exports.CB_FONTS = CB_FONTS;
