import React from 'react';
import styled from '@emotion/styled';

import {
  Table,
  Empty,
  StatusBadge,
  Radio,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Checkbox,
  message,
} from '../../shared_components';
import { useAppContext } from '../../Widget';
import { ArrowNarrowUpRight, Settings01, Trash04 } from '@commandbar/design-system/icons/react';
import {
  Button,
  SearchInput,
  CB_COLORS,
  Typography,
  SyncProgress,
  Tooltip,
} from '@commandbar/design-system/components';
import { renderThirdPartySourceColumn } from '../commands/CommandTableColumns';
import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import * as Command from '@commandbar/internal/middleware/command';
import axiosInstance from '@commandbar/internal/middleware/network';
import { WarningOutlined } from '@ant-design/icons';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import useRecommendationSets from '../useEditor/useRecommendationSets';
import { useAuthInfo } from '../../store/AuthProvider';
import useSyncProgress from '@commandbar/internal/util/useSyncProgress';
import Logger from '@commandbar/internal/util/Logger';
import GettingStartedBanner from './GettingStartedBanner';
import Button2 from '../../shared_components/Button2';
import { useReportEvent } from '../../shared_components/useEventReporting';
import useRouter from '../../hooks/useRouter';
import { HELPHUB_PARENT_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0px;
  gap: 8px;
`;

const Body = styled.div<{ padded: boolean }>`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  gap: 16px;
  padding: ${(props) => (props.padded ? '0 16px 12px' : '0')};
  width: 100%;
  overflow-y: auto;
`;

const SyncProgressContainer = styled.div`
  border: 1px solid rgba(10, 10, 15, 0.24);
  border-radius: 8px;
  overflow: hidden;
`;

const HelpHub = () => {
  const router = useRouter();
  const { dispatch, commands, organization } = useAppContext();
  const [searchText, setSearchText] = React.useState('');
  const {
    progress,
    numFetched,
    numProcessed,
    ping: checkSyncProgress,
  } = useSyncProgress('web', dispatch.commands.reload);

  const defaultDocFilter = () => {
    const _commands = commands.filter((c) => c.template.type === 'helpdoc');
    if (_commands.some((c) => c.third_party_source && !c.third_party_source.startsWith('web'))) return 'syncs';
    else if (_commands.some((c) => c.third_party_source?.startsWith('web'))) return 'sites';
    else return 'custom';
  };

  const [docFilterType, setDocFilterType] = React.useState<'custom' | 'sites' | 'syncs'>(defaultDocFilter);

  const [isCustomDocModalVisible, setIsCustomDocModalVisible] = React.useState(false);
  const [isAddSiteModalVisible, setIsAddSiteModalVisible] = React.useState(false);

  const allHelpdocs = React.useMemo(() => commands.filter((c) => c.template.type === 'helpdoc'), [commands]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const helpdocs = React.useMemo(() => {
    const _commands = commands.filter((c) => c.template.type === 'helpdoc');

    switch (docFilterType) {
      case 'custom':
        return _commands.filter((c) => !c.third_party_source);
      case 'sites':
        return _commands.filter((c) => c.third_party_source?.startsWith('web'));
      case 'syncs':
        return _commands.filter((c) => c.third_party_source && !c.third_party_source.startsWith('web'));
      default:
        return _commands;
    }
  }, [commands, docFilterType]);

  const columns = React.useMemo(() => {
    switch (docFilterType) {
      case 'custom':
        return [
          {
            title: <div>Article title</div>,
            dataIndex: 'title',
            key: 'text',
            width: '50%',
            ellipsis: true,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
          },
          {
            title: '',
            dataIndex: 'options',
            key: 'options',
            width: '10%',
          },
        ];
      case 'sites':
        return [
          {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: '2%',
          },
          {
            title: <div>Article Title</div>,
            dataIndex: 'title',
            key: 'title',
            width: '60%',
            ellipsis: {
              showTitle: false,
            },
            render: (title: string, record: { link: string }) => (
              <Tooltip inline={true} content={record.link}>
                <span>{title}</span>
              </Tooltip>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
          },
          {
            title: '',
            dataIndex: 'options',
            key: 'options',
            width: '10%',
          },
        ];
      case 'syncs':
      default:
        return [
          {
            title: <div>Article title</div>,
            dataIndex: 'title',
            key: 'text',
            width: '50%',
            ellipsis: true,
          },
          {
            title: '',
            dataIndex: 'third_party_source',
            key: 'third_party_source',
            align: 'center' as const,
            render: (third_party_source: string) => {
              return renderThirdPartySourceColumn(third_party_source);
            },
            width: '18%',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
          },
          {
            title: '',
            dataIndex: 'options',
            key: 'options',
            width: '10%',
          },
        ];
    }
  }, [docFilterType]);

  if (!organization) {
    return null;
  }

  const actions = () => {
    switch (docFilterType) {
      case 'custom':
        return <Button onClick={() => setIsCustomDocModalVisible(true)}>Add custom</Button>;
      case 'sites':
        return (
          <Button disabled={progress > 0} onClick={() => setIsAddSiteModalVisible(true)}>
            Add site
          </Button>
        );
      case 'syncs':
      default:
        return (
          <a href="https://app.commandbar.com/integrations" target="_blank" rel="noreferrer">
            <Button type="primary">
              Syncs&nbsp;
              <ArrowNarrowUpRight height="14px" />
            </Button>
          </a>
        );
    }
  };

  const renderContent = () => {
    const shouldDisplayProgress = !!progress || !!numFetched;

    if (allHelpdocs.length === 0) {
      if (shouldDisplayProgress) {
        return (
          <SyncProgressContainer>
            <SyncProgress progress={progress} numFetched={numFetched} numProcessed={numProcessed} />
          </SyncProgressContainer>
        );
      }

      return (
        <GettingStartedBanner
          setIsCustomDocModalVisible={setIsCustomDocModalVisible}
          setIsAddSiteModalVisible={setIsAddSiteModalVisible}
        />
      );
    }

    return (
      <div
        style={{
          background: 'white',
          borderRadius: '8px',
          border: `1px solid ${CB_COLORS.neutral400}`,
          minHeight: 0,
          overflow: 'hidden',
        }}
      >
        <Header>
          <Row justify="space-between" align="middle">
            <Radio.Group
              value={docFilterType}
              onChange={(e) => setDocFilterType(e.target.value)}
              optionType="button"
              buttonStyle="solid"
              size="middle"
            >
              <Radio value="syncs">Syncs</Radio>
              <Radio value="sites">Sites</Radio>
              <Radio value="custom">Custom</Radio>
            </Radio.Group>

            <Space direction="horizontal" align="center">
              {actions()}
            </Space>
          </Row>
          {!progress && <SearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />}
        </Header>
        <Body padded={!shouldDisplayProgress}>
          {shouldDisplayProgress ? (
            <SyncProgress progress={progress} numFetched={numFetched} numProcessed={numProcessed} />
          ) : (
            <StyledTable>
              <Table
                pagination={{
                  defaultPageSize: 20,
                  hideOnSinglePage: true,
                }}
                onRow={(command: IEditorCommandTypeLite, _rowIndex?: number) => ({
                  onClick: () => {
                    dispatch.commands.setActiveCommandById(command.id);
                    router.push(`${HELPHUB_PARENT_ROUTE}/edit/${command.id}`);
                  },
                })}
                rowClassName="editable-row"
                columns={columns}
                rowSelection={
                  docFilterType === 'sites'
                    ? {
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys) => {
                          setSelectedRowKeys(newSelectedRowKeys);
                        },
                        selections: [
                          {
                            key: 'delete_selection',
                            text: (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Trash04 height={12} />
                                <span style={{ marginLeft: '8px' }}>Delete Selected</span>
                              </div>
                            ),
                            onSelect: () => {
                              if (!selectedRowKeys.length) {
                                message.error('Please select docs to delete');
                              } else {
                                Modal.confirm({
                                  icon: <WarningOutlined />,
                                  title: 'Are you sure you want to delete these docs? This cannot be undone.',
                                  async onOk() {
                                    await dispatch.commands.bulkDelete(selectedRowKeys.map(Number));
                                  },
                                });
                              }
                            },
                          },
                        ],
                      }
                    : undefined
                }
                dataSource={helpdocs
                  .filter(
                    (h) =>
                      h.text.toLowerCase().includes(searchText.toLowerCase()) ||
                      h.third_party_source?.toLowerCase().includes(searchText.toLowerCase()),
                  )
                  .map((h) => {
                    return {
                      ...h,
                      key: h.id,
                      link: h.template.value,
                      title: <span>{h.text}&nbsp;</span>,
                      status: <RowStatus is_live={h.is_live} />,
                      options: <Settings01 height="16px" style={{ verticalAlign: 'text-bottom' }} />,
                    };
                  })}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        !!allHelpdocs.length
                          ? 'No search results.'
                          : "You don't have any helpdocs. Connect one by visiting the Integrations page."
                      }
                    />
                  ),
                }}
              />
            </StyledTable>
          )}
        </Body>
      </div>
    );
  };

  return (
    <Container>
      <AddSiteModal
        visible={isAddSiteModalVisible}
        onDone={() => {
          setIsAddSiteModalVisible(false);
          checkSyncProgress();
        }}
      />
      <CustomDocModal visible={isCustomDocModalVisible} onCancel={() => setIsCustomDocModalVisible(false)} />
      {renderContent()}
    </Container>
  );
};

const RowStatus = (props: { is_live: boolean }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', minWidth: 45 }}>
      {props.is_live ? (
        <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
      ) : (
        <StatusBadge color="orange" text="Draft" />
      )}
    </span>
  );
};

const CustomDocModal = (props: { visible: boolean; onCancel: () => void }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [form] = Form.useForm();
  const { dispatch, organization, categories } = useAppContext();
  const onCancel = () => {
    props.onCancel();
  };

  const { defaultRecommendationSet, actions } = useRecommendationSets();

  const onFinish = (values: { title: string; content: string; url: string }) => {
    setIsSaving(true);
    dispatch.commands
      .save(
        Command.decodeEditorCommand({
          id: -1,
          organization: organization.id,
          text: values.title,
          content: values.content,
          is_live: true,
          template: { type: 'helpdoc', operation: 'blank', value: values.url || '' },
          category: categories.find((c) => c.name === 'Help')?.id || categories[0]?.id,
        }),
        { setToActiveIfNew: false, notify: false, throttle: true },
      )
      .then((command) => {
        message.success('New custom doc created.');
        props.onCancel();
        form.resetFields();

        if (defaultRecommendationSet && defaultRecommendationSet.command_ids.length < 6) {
          const command_ids = [...defaultRecommendationSet.command_ids, command.id];
          actions.recommendationSets.update({
            ...defaultRecommendationSet,
            command_ids,
          });
        }
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Modal title="Create custom doc" visible={props.visible} onCancel={onCancel} footer={null} destroyOnClose={true}>
        <Typography.Paragraph>
          To sync your help center provider, please visit the{' '}
          <Typography.Link
            href="https://app.commandbar.com/integrations"
            target="_blank"
            style={{ color: CB_COLORS.blue600 }}
          >
            Integration settings page
          </Typography.Link>
          . Use this form only if you want to add a custom doc or explore HelpHub with test content.
        </Typography.Paragraph>
        <br />
        <Form form={form} onFinish={onFinish} labelCol={{ span: 5 }} labelAlign="left">
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title is required. ' }]}>
            <Input placeholder={'Installing CommandBar'} />
          </Form.Item>
          <Form.Item label="Content" name="content" rules={[{ required: true, message: 'Content is required.' }]}>
            <Input.TextArea placeholder={'Doc content. Accepts text or html.'} autoSize={{ minRows: 8, maxRows: 16 }} />
          </Form.Item>
          <Form.Item label="Source URL" name="url" rules={[{ required: false }]}>
            <Input placeholder={'https://www.commandbar.com/docs/dev/installation'} />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit" disabled={isSaving}>
                Create
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

interface AddSiteModalProps {
  visible: boolean;
  onDone: () => void;
}

const FormStyles = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const FormItemHorizontal = styled(Form.Item)`
  .ant-row.ant-form-item-row {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 8px;
  }

  .ant-form-item-label {
    white-space: nowrap;
    text-align: end;
    padding: 0;
    flex: none !important;
  }

  .ant-form-item-control {
    flex: none !important;
    width: auto !important;
  }

  .ant-form-item-label > label {
    height: 32px;
    cursor: pointer;

    &::after {
      display: block;
      content: '';
      position: relative;
      margin-block: 0;
      margin-inline-start: 2px;
      margin-inline-end: 8px;
    }
  }
`;

const AddSiteForm = ({ onDone }: { onDone: () => void }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { reportEvent } = useReportEvent();
  const [form] = Form.useForm();

  const useBrowser = Form.useWatch('use_browser', form);

  const { user } = useAuthInfo();

  const allowSuperscraper = user?.email?.endsWith('@commandbar.com');

  const onFinish = async ({
    link,
    training_only,
    single_page,
    use_browser,
    use_googlebot,
  }: {
    link: string;
    training_only: boolean;
    single_page: boolean;
    use_browser: boolean;
    use_googlebot: boolean;
  }) => {
    setIsSaving(true);

    onDone();

    try {
      await axiosInstance.post('/integrations/syncs/web/', {
        single_urls: single_page ? [link] : [],
        recursive_urls: !single_page ? [link] : [],
        use_browser,
        use_googlebot,
        training_only,
      });
      reportEvent('site created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: `Created site ${link}`,
      });
    } catch (error) {
      message.error('Error syncing sites.');
      Logger.error('Error with web sync: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FormStyles>
      <Form form={form} layout={'vertical'} requiredMark={false} onFinish={onFinish}>
        <Form.Item
          label={'Site URL'}
          name={'link'}
          rules={[{ required: true, message: 'Missing link' }]}
          style={{ flexGrow: 1 }}
        >
          <Input placeholder="https://commandbar.com/blog" />
        </Form.Item>
        <FormItemHorizontal
          tooltip={{
            icon: <InfoCircle height={16} />,
            title: "Content will only be used to improve AI generated answers and won't be shown in search results.",
          }}
          label={'Use for training only'}
          name={'training_only'}
          valuePropName="checked"
        >
          <Checkbox />
        </FormItemHorizontal>
        <FormItemHorizontal label={'Sync only this URL'} name={'single_page'} valuePropName="checked">
          <Checkbox />
        </FormItemHorizontal>

        {allowSuperscraper && (
          <>
            <FormItemHorizontal
              valuePropName="checked"
              label="[Internal] Use browser"
              name="use_browser"
              rules={[{ required: false }]}
            >
              <Checkbox />
            </FormItemHorizontal>
            {useBrowser && (
              <FormItemHorizontal
                valuePropName="checked"
                label="[Internal] Spoof Googlebot"
                name="use_googlebot"
                rules={[{ required: false }]}
              >
                <Checkbox />
              </FormItemHorizontal>
            )}
          </>
        )}
        <Form.Item>
          <div style={{ paddingTop: 16 }}>
            <Button2 type="submit" disabled={isSaving}>
              Add Site
            </Button2>
          </div>
        </Form.Item>
      </Form>
    </FormStyles>
  );
};

const AddSiteModal = ({ visible, onDone }: AddSiteModalProps) => (
  <Modal title="New Site" visible={visible} onCancel={onDone} footer={null} destroyOnClose={true}>
    <AddSiteForm onDone={onDone} />
  </Modal>
);

export default HelpHub;

const StyledTable = styled.div`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f0f6ff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${CB_COLORS.primary};
    border-color: ${CB_COLORS.primary};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${CB_COLORS.primary};
  }
`;
