import React from 'react';
import { Alert, FormRow, Switch } from '../../shared_components';
import {
  EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORIES,
  EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORY_DESCRIPTIONS,
} from '../settings/AnalyticsControl';
import _ from 'lodash';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

interface Props {
  organization: IOrganizationType;
  onChange: Function;
}

const EventAttributeBlockList = (props: Props) => {
  const isSettable =
    props.organization.branding !== 'branded' || props.organization.event_attribute_block_list.length > 0;

  const eventAttributeBlockList = props.organization.event_attribute_block_list;

  const toggleOption = async (checked: boolean, category: string) => {
    if (!isSettable) return;

    const newEventAttributeBlockList = checked
      ? eventAttributeBlockList.filter((c) => c !== category)
      : [...eventAttributeBlockList, category];
    props.onChange({ event_attribute_block_list: newEventAttributeBlockList });
  };

  return (
    <>
      <Alert
        style={{ marginBottom: 20, paddingBottom: 0 }}
        message={!isSettable && 'Upgrade for access'}
        description={
          <div>
            <p>
              These settings control different types of data sent to CommandBar's analytics service. When enabled, their
              respective data will be sent.{' '}
              {!isSettable && (
                <p style={{ marginTop: 10 }}>
                  You must upgrade your organization to access this feature.&nbsp;
                  <a
                    style={{ textDecoration: 'dotted underline' }}
                    href="https://www.commandbar.com/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more on our pricing page
                  </a>
                  .
                </p>
              )}
            </p>
          </div>
        }
        type="info"
        showIcon
      />
      {EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORIES.map((category) => {
        const isBlackListed = eventAttributeBlockList.includes(category);
        return (
          <FormRow
            key={category}
            title={
              category === 'user_inputs_and_deadends'
                ? 'User inputs and deadends'
                : category === 'urls'
                ? 'URLs'
                : _.startCase(_.camelCase(category))
            }
            input={
              <Switch
                checked={!isBlackListed}
                style={{ marginLeft: 15 }}
                disabled={!isSettable}
                onChange={(checked) => toggleOption(checked, category)}
              />
            }
            info={EVENT_ATTRIBUTE_BLOCK_LIST_CATEGORY_DESCRIPTIONS[category]}
            interactive
          />
        );
      })}
    </>
  );
};

export default EventAttributeBlockList;
