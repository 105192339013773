import { WarningOutlined } from '@ant-design/icons';
import * as Command from '@commandbar/internal/middleware/command';
import { IChecklistItem } from '@commandbar/internal/middleware/types';
import React from 'react';
import { FormRow, Input, Select, Tooltip } from '../../shared_components';
import { useAppContext } from '../../Widget';
import { ClickRecorder, isSelectorFragile, SELECTOR_WARNING_TEXT } from '../commands/CommmandDetail/ActionDetailPanel';
import RuleExpressionEditor from '../commands/CommmandDetail/ConditionRulePanels/components/RuleExpressionEditor';
import { EventAutoComplete } from '../commands/CommmandDetail/EventAutoComplete';
import { BorderedContainer } from '../components/styled';

const GoalEditor = ({
  goal,
  onChange = () => {
    return null;
  },
}: {
  goal: IChecklistItem['goal'];
  onChange?: (goal: IChecklistItem['goal']) => void;
}) => {
  const { checklists, commands, nudges } = useAppContext();

  return (
    <div>
      <FormRow
        title="Mark complete when..."
        input={
          <Select
            value={goal.type}
            style={{ width: '100%' }}
            onChange={(e: IChecklistItem['goal']['type'] | null | undefined) => {
              if (e === 'cta_clicked') {
                onChange({ type: 'cta_clicked' });
              } else if (e === 'command_executed') {
                onChange({ type: 'command_executed', meta: { command: '' } });
              } else if (e === 'page_visited') {
                onChange({ type: 'page_visited', value: '' });
              } else if (e === 'element_clicked') {
                onChange({ type: 'element_clicked', value: '' });
              } else if (e === 'conditions_met') {
                onChange({ type: 'conditions_met', expression: { type: 'LITERAL', value: true } });
              } else if (e === 'event_tracked') {
                onChange({ type: 'event_tracked', event: '' });
              }
            }}
          >
            <Select.Option value={'cta_clicked'}>CTA clicked</Select.Option>
            <Select.Option value={'command_executed'}>Command executed</Select.Option>
            <Select.Option value={'page_visited'}>Page visited</Select.Option>
            <Select.Option value={'element_clicked'}>Element clicked</Select.Option>
            <Select.Option value={'conditions_met'}>When conditions are met</Select.Option>
            <Select.Option value={'event_tracked'}>When an event is tracked</Select.Option>
          </Select>
        }
      />

      {goal.type === 'command_executed' && (
        <FormRow
          title="Command:"
          input={
            <Select
              value={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Disabling because tslint is not recognising the meta field on the trigger
                commands.find((c) => c.id.toString() === goal.meta.command) ? goal.meta.command : ''
              }
              style={{ width: '100%' }}
              onChange={(e) => !!e && onChange({ type: 'command_executed', meta: { command: e } })}
              showSearch
              filterOption={(input, option) =>
                !!option?.children && (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {commands.map((c, i) => (
                <Select.Option key={i} value={Command.commandUID(c)}>
                  {c.text}
                </Select.Option>
              ))}
            </Select>
          }
        />
      )}
      {goal.type === 'page_visited' && (
        <FormRow
          title="Page location contains:"
          input={
            <Input
              value={goal.value}
              onChange={(e) => !!e && onChange({ type: 'page_visited', value: e.target.value })}
            />
          }
        />
      )}
      {goal.type === 'element_clicked' && (
        <>
          <FormRow
            title="Element:"
            input={
              <>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      onKeyDown={(e) => e.stopPropagation()}
                      value={goal.value}
                      onChange={(e) => onChange({ type: 'element_clicked', value: e.target.value })}
                      placeholder="CSS selector or XPath"
                      suffix={
                        <ClickRecorder
                          onValueChange={([e]) => onChange({ type: 'element_clicked', value: e })}
                          value={goal.value}
                          singleStep
                        />
                      }
                    />
                    {isSelectorFragile(goal.value) && (
                      <Tooltip content={SELECTOR_WARNING_TEXT}>
                        <WarningOutlined style={{ color: 'rgb(216, 150, 20)', marginLeft: '8px' }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
      {goal.type === 'conditions_met' && (
        <FormRow
          title="Conditions:"
          pushInputIntoNextLine
          input={
            <BorderedContainer>
              <RuleExpressionEditor
                onChange={(expr) => onChange({ type: 'conditions_met', expression: expr })}
                expr={goal.expression}
              />
            </BorderedContainer>
          }
        />
      )}
      {goal.type === 'event_tracked' && (
        <FormRow
          title="Event:"
          input={
            <EventAutoComplete
              onChange={(event) => onChange({ type: 'event_tracked', event: event })}
              initialEventName={goal.event}
              checklists={checklists}
              nudges={nudges}
            />
          }
        />
      )}
    </div>
  );
};

export default GoalEditor;
