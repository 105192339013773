/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React, { useEffect, useState } from 'react';

import { ExclamationCircleTwoTone } from '@ant-design/icons';

import { IArgumentMap, IResourceSettingsByContextKey, isContextArgument } from '@commandbar/internal/middleware/types';
import { getNextSortKey } from '../argumentUtils';
import { Tooltip, Button, SortableList, Modal as SharedModal, Space } from '../../../../../shared_components';
import { ArgumentDetailHeader } from './components/ArgumentDetailHeader';
import styled from '@emotion/styled';
import { CollapsibleArgumentEditor } from './components/CollapsibleArgumentEditor';
import { IArgumentRow } from '../ArgumentList';
import { AddButton } from '../../../../components/styled';

const Modal = styled(SharedModal)`
  &.ant-modal {
    top: 16px !important;
    max-width: calc(100vw - 32px) !important;
    max-height: calc(100vh - 32px);
    margin: auto;
  }

  & .ant-modal-header {
    padding: 0;
    border-bottom: none;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  & .ant-modal-content {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 32px);
  }

  & .ant-modal-body {
    padding: 16px;
    background-color: #f2f2f2;
  }
`;

const getNextNewArgumentName = (args: IArgumentRow[]) => {
  const newArgs = args.filter(({ name }) => name.match(/^(New)(-\d*)?$/));

  if (newArgs.length > 0) {
    for (let i = 1; i <= newArgs.length; i++) {
      const index = args.findIndex(({ name }) => name === `New-${i}`);

      if (index === -1) {
        return `New-${i}`;
      }
    }
  }

  return 'New';
};

/**
 * Sidebar to edit the details of an argument
 * FIXME: Need to clean up and standardize these styles
 */
const ArgumentsModal = (props: {
  visible: boolean;
  argumentsList: IArgumentRow[];
  activeArgName?: string;
  commandName?: string;
  arguments: IArgumentMap;
  recordSettings: IResourceSettingsByContextKey;
  onUpdate: (arg: string, val: any, replaceValue?: boolean) => void;
  updateName: (oldName: string, newName: string) => void;
  onClose: () => void;
  onSort: (oldIndex: number, newIndex: number) => void;
  onDelete: (argName: string) => void;
  onCreate: (nameToAdd: string, order_key: number) => void;
}) => {
  const [activeArgName, setActiveArgName] = useState(props.activeArgName);

  useEffect(() => {
    setActiveArgName(props.activeArgName);
  }, [props.activeArgName]);

  /* Argument validation */
  const validator = (arg: IArgumentRow) => {
    const errors: string[] = [];

    if (arg.name.startsWith('__') && !arg.is_private) errors.push('Argument names cannot start with __');

    if (!arg.type) errors.push('Argument type must be selected.');

    if (arg.type === 'video' && !arg.value.source) {
      errors.push('Video argument must specify a source.');
    }

    if (arg.type === 'html' && !arg.value.source) {
      errors.push('HTML argument must specify a source.');
    }

    if (arg.type === 'html' && !arg.value.source) {
      errors.push('HTML argument must specify a source');
    }

    if (arg.value === 'time' && arg.type === 'provided' && !arg.dateTimeArgumentTypeId)
      errors.push('Time argument should be with specific type');
    if (isContextArgument(arg) && arg.value.length === 0) errors.push('Context variable cannot be empty.');
    return errors;
  };

  const errors = props.argumentsList
    .map((argument) => ({ argName: argument.name, errors: validator(argument) }))
    .filter(({ errors }) => errors.length);

  const addNewArgument = () => {
    const newArgKey = getNextSortKey(props.arguments);
    const newArgName = getNextNewArgumentName(props.argumentsList);

    props.onCreate(newArgName, newArgKey);
    setActiveArgName(newArgName);
  };

  const actions = (
    <Space>
      <Button onClick={props.onClose} disabled={!!errors.length}>
        Done
      </Button>
      {!!errors.length && (
        <Tooltip
          content={
            <div>
              Please fix the following before saving:
              <div style={{ marginTop: 6 }}>
                {errors.map((e) => (
                  <div key={e.argName}>
                    <b>{e.argName}:</b>
                    <ul>
                      {e.errors.map((error, index) => (
                        <li key={`${e.argName}${index}`}>{error}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          }
        >
          <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
        </Tooltip>
      )}
    </Space>
  );

  return (
    <Modal
      visible={props.visible}
      closable={false}
      onCancel={props.onClose}
      maskClosable={errors.length === 0}
      footer={null}
      style={{ top: 16, left: 0, right: 0 }}
      width="100%"
      title={<ArgumentDetailHeader title={props.commandName} subtitle="Arguments" actions={actions} />}
      destroyOnClose
    >
      <SortableList
        nodes={props.argumentsList.map((argument) => {
          return (
            <CollapsibleArgumentEditor
              key={argument.id}
              argument={argument}
              activeArgName={activeArgName}
              arguments={props.arguments}
              recordSettings={props.recordSettings}
              updateName={props.updateName}
              onUpdate={props.onUpdate}
              onDelete={props.onDelete}
            />
          );
        })}
        onSort={props.onSort}
        useDragHandle
      />

      <AddButton onClick={addNewArgument}>
        {props.argumentsList.length ? 'Add another argument' : 'Add an argument'}
      </AddButton>
    </Modal>
  );
};

export default ArgumentsModal;
