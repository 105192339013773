/*****************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useEffect, useMemo } from 'react';

import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import slugify from '@commandbar/internal/util/slugify';
import { filterUnhealthyCommands } from '@commandbar/internal/util/configHealthChecks';

import { freshCommand } from '../../useEditor';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import CategoryGroup from './CategoryGroup';
import { SearchInput, Col, Row, Table } from '../../../shared_components';

import CategorySettings from './CategorySettings';
import NewCategoryModal from './NewCategoryModal';
import NewCommandModal from './NewCommandModal';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { useRouter } from '../../../hooks';
import { useAppContext } from '../../../Widget';
import { COMMANDS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

const columns = [
  {
    title: <div style={{ paddingLeft: '30px' }}>Title</div>,
    dataIndex: 'text',
    key: 'text',
    width: '50%',
  },
  {
    title: <div style={{ paddingRight: '20px' }}>Shortcuts</div>,
    width: '15%',
  },
  {
    title: 'Status',
    dataIndex: 'is_live',
    key: 'is_live',
    width: '15%',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    width: '10%',
  },
];

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  commands: IEditorCommandTypeLite[];
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const CommandList = ({ commands }: IProps) => {
  const router = useRouter();
  const storedScrollPosition = parseFloat(window.sessionStorage.getItem('commandListScrollPosition') || '0');
  const [scrollPosition] = React.useState(storedScrollPosition);
  const [searchText, setSearchText] = React.useState('');
  const [activeCategory, setActiveCategory] = React.useState<ICommandCategoryType | undefined>(undefined);
  const { hasRouter } = useWindowInfo();
  const { dispatch, categories, organization, organizationSettings } = useAppContext();

  useEffect(() => {
    const { identifier, type } = router.cbEditorParams;

    if (identifier && type) {
      const categoryName = identifier.split('-')[1];
      const category = categories.find((c) => c.name === categoryName);
      setActiveCategory(category);
    }
  }, [router.cbEditorParams]);

  React.useEffect(() => {
    scrollToCommand();
    window.sessionStorage.removeItem('commandListScrollPosition');
  }, []);

  const commandsByCategory = useMemo(() => {
    return commands.reduce<Record<string | number, IEditorCommandTypeLite[]>>(
      (acc, command) => {
        const categoryId = command.category;

        if (categoryId === null) {
          acc.uncategorized.push(command);

          return acc;
        }

        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }

        acc[categoryId].push(command);

        return acc;
      },
      { uncategorized: [] },
    );
  }, [commands]);

  const scrollToCommand = () => {
    const categoryGroupWrapper = document.getElementById('category-group_wrapper');
    categoryGroupWrapper?.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    });
  };

  const scrollToNewCategory = () => {
    const scrolledTabsWrapper = document.getElementById('rc-tabs-0-panel-commands-commands');
    // Scroll tab panel to bottom
    scrolledTabsWrapper?.scrollTo({
      top: scrolledTabsWrapper?.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const unhealthyCommands = React.useMemo(
    () => filterUnhealthyCommands(commands, organizationSettings),
    [commands, organizationSettings],
  );

  const categoryCards = categories.sort(compareObjs).map((category) => {
    return (
      <CategoryGroup
        key={`category-${category.id}`}
        category={category}
        unhealthyCommands={unhealthyCommands}
        commands={commandsByCategory[category.id] || []}
        searchText={searchText}
        openSettings={() => setActiveCategory(category)}
      />
    );
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
      <Row style={{ marginBottom: 8, padding: '0 8px', rowGap: '4px' }} align="middle" gutter={8}>
        <Col flex="1 1 auto">
          <SearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </Col>
        <Col>
          <NewCategoryModal
            onCreate={(name: string) => {
              const newCategory: ICommandCategoryType = {
                id: -1,
                organization: organization.id.toString(),
                name,
                sort_key: null,
                icon: null,
                icon_color: null,
                image_color: null,
                image: null,
                setting_hide_before_search: false,
                setting_max_options_count: null,
                setting_pin_to_bottom: false,
                search_tab_enabled: false,
                search_tab_name: '',
                slash_filter_enabled: true,
                slash_filter_keyword: slugify(name),
                search_tab_instruction: '',
                render_as: 'list',
                track_recents: false,
              };
              dispatch.categories.save(newCategory).then(() => scrollToNewCategory());
            }}
          />
        </Col>
        <Col>
          <NewCommandModal
            onCreate={(categoryID: number) => {
              const category = categories.find((obj) => obj.id === categoryID);
              const newCommand = freshCommand(organization, categoryID, hasRouter, category?.icon, category?.image);

              router.push(`${COMMANDS_ROUTE}/${newCommand.id}${router.history.location.search}`);
              dispatch.commands.setActive(newCommand);
            }}
          />
        </Col>
      </Row>
      <Table className="dummy-table" columns={columns} dataSource={[]} locale={{ emptyText: <span /> }} />
      <div style={{ flexGrow: 1, overflowY: 'auto', borderTop: '10px solid #fff' }} id="category-group_wrapper">
        {!!commandsByCategory.uncategorized.length && (
          <CategoryGroup
            key={`category-uncategorized`}
            category={undefined}
            commands={commandsByCategory.uncategorized}
            unhealthyCommands={unhealthyCommands}
            searchText={searchText}
            openSettings={null}
          />
        )}
        {searchText ? categoryCards : categoryCards.map((card) => <div key={card.key}>{card}</div>)}
      </div>
      {activeCategory && (
        <CategorySettings
          activeCategory={activeCategory}
          onClose={() => {
            setActiveCategory(undefined);

            if (router.cbEditorParams.from) {
              router.history.replace(router.cbEditorParams.from);
            }
          }}
        />
      )}
    </div>
  );
};

export default CommandList;
