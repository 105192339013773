/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

import { Select, Row, Col, InputNumber, Switch, Tooltip } from '../../shared_components';

import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { KeyboardShortcutInput } from '../commands/CommmandDetail/KeyboardShortcutEditor/components/KeyboardShortcutInput';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { getOperatingSystem } from '@commandbar/internal/util/operatingSystem';

interface IProps {
  organization: IOrganizationType;
  updateOrganizationProperty: (field: string, newVal: any, updateRemote?: boolean) => Promise<void>;
}

interface IState {
  updatedOrganization: IOrganizationType;
  invalidX: boolean;
  invalidY: boolean;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

function isInt(value: any) {
  if (typeof value === 'number') {
    return Number.isInteger(value);
  }

  if (typeof value === 'string') {
    const intRegexPattern = /^-?[0-9]+$/;
    return intRegexPattern.test(value);
  }

  return false;
}

export class BarLauncherSettings extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      updatedOrganization: this.props.organization,
      invalidX: false,
      invalidY: false,
    };
  }

  private os = getOperatingSystem();

  public onChangeType = async (value: any) => {
    this.props.updateOrganizationProperty('launcher_type', value);
  };

  public onChangeShortcut = async (value: string | null) => {
    await OrganizationSettings.update({ summon_hotkey_override: value });

    this.props.updateOrganizationProperty('summon_hotkey_override', value, false);
  };

  public onChangePosition = async (value: any) => {
    this.props.updateOrganizationProperty('launcher_position', value);
  };

  public onBlurX = async (e: any) => {
    const { value } = e.target;
    if (!isInt(value)) {
      return this.setState({ invalidX: true });
    }
    this.setState({ invalidX: false });

    const intValue = parseInt(value, 10);
    if (this.props.organization.launcher_offset_x === intValue) {
      return;
    }

    this.props.updateOrganizationProperty('launcher_offset_x', intValue);
  };

  public onBlurY = async (e: any) => {
    const { value } = e.target;
    if (!isInt(value)) {
      return this.setState({ invalidY: true });
    }
    this.setState({ invalidY: false });

    const intValue = parseInt(value, 10);
    if (this.props.organization.launcher_offset_y === intValue) {
      return;
    }

    this.props.updateOrganizationProperty('launcher_offset_y', intValue);
  };

  render() {
    return (
      <div>
        <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '8px' }}>
          <Col span={6}>Type:</Col>
          <Col span={18}>
            <Select
              defaultValue={this.props.organization.launcher_type}
              style={{ width: '100%' }}
              onChange={this.onChangeType}
            >
              <Select.Option value="minimal">Default</Select.Option>
              <Select.Option value="alternate">Alternate</Select.Option>
              <Select.Option value="none">None</Select.Option>
            </Select>
          </Col>
          <Col span={6}>Position:</Col>
          <Col span={18}>
            <Select
              defaultValue={this.props.organization.launcher_position}
              style={{ width: '100%' }}
              onChange={this.onChangePosition}
            >
              <Select.Option value="bottomRight">Bottom right</Select.Option>
              <Select.Option value="topRight">Top right</Select.Option>
              <Select.Option value="bottomLeft">Bottom left</Select.Option>
              <Select.Option value="topLeft">Top left</Select.Option>
            </Select>
          </Col>
          <Col span={6}>Offset X (px):</Col>
          <Col span={18}>
            <InputNumber
              style={{ border: this.state.invalidX ? '2px solid red' : '' }}
              defaultValue={this.props.organization.launcher_offset_x}
              onFocus={() => this.setState({ invalidX: false })}
              onBlur={this.onBlurX}
              step={10}
            />
          </Col>
          <Col span={6}>Offset Y (px):</Col>
          <Col span={18}>
            <InputNumber
              style={{ border: this.state.invalidY ? '2px solid red' : '' }}
              defaultValue={this.props.organization.launcher_offset_y}
              onFocus={() => this.setState({ invalidY: false })}
              onBlur={this.onBlurY}
              step={10}
            />
          </Col>
        </Row>

        <Row gutter={[8, 8]} align="top">
          <Col span={6}>Hotkey:</Col>
          <Col span={18}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={this.props.organization.summon_hotkey_override !== 'none'}
                  onChange={(checked: boolean) => {
                    if (checked) {
                      this.onChangeShortcut(null);
                    } else {
                      this.onChangeShortcut('none');
                    }
                  }}
                />
                <span style={{ marginLeft: 24, marginRight: 16 }}>
                  {' '}
                  <Tooltip
                    content={"If disabled, the Bar will only open via a launcher or programmatic calls of '.open()'."}
                    placement="bottom"
                  >
                    <InfoCircleOutlined style={{ fontSize: 16 }} />
                  </Tooltip>
                </span>
              </div>

              {this.props.organization.summon_hotkey_override !== 'none' && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <KeyboardShortcutInput
                    value={
                      this.props.organization.summon_hotkey_override === null
                        ? 'mod+k'
                        : this.props.organization.summon_hotkey_override
                    }
                    onChange={(value) => this.onChangeShortcut(Hotkey.toModString(value))}
                    platform={['mac', 'ios'].includes(this.os) ? 'mac' : 'win'}
                    enableRecording={true}
                    defaultValue={'mod+k'}
                    allowTypingUpdates={false}
                    showModString={true}
                  />
                  <span style={{ marginLeft: 24, marginRight: 16 }}>
                    {' '}
                    <Tooltip
                      content={
                        "The recorded shortcut is automatically translated into a platform-independent representation. 'mod' represents Command on macOS and Ctrl on Windows/Linux"
                      }
                      placement="bottom"
                    >
                      <InfoCircleOutlined style={{ fontSize: 16 }} />
                    </Tooltip>
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
