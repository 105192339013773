/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

import { Tooltip, message } from '../../../shared_components';
import { IContextType } from '@commandbar/internal/middleware/types';

import { CopyOutlined, DeleteOutlined, EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  context: IContextType;
  canEdit: boolean;
  onEdit: () => void;
  onSave: () => Promise<void>;
  onDelete: () => Promise<void>;
  onCancel: () => void;
  isDirty: boolean;

  /*
   * Args defined in commands that are included or missing for this context panel
   */
  missingArgs: string[];
  includedArgs: string[];
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

export const ContextPanelActions = (props: IProps) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(props.context.value)).then(function () {
      message.success('Context copied to clipboard.');
    });
  };
  let actions;
  if (!props.canEdit) {
    actions = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingBottom: '10px',
          borderBottom: '1px solid rgba(255,255,255,0.4)',
          gap: '15px',
        }}
      >
        <Tooltip content="Copy">
          <CopyOutlined
            onClick={(event) => {
              copyToClipboard();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Tooltip>
        <Tooltip content="Edit">
          <EditOutlined
            onClick={(event) => {
              props.onEdit();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Tooltip>
      </div>
    );
  } else {
    actions = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingBottom: '10px',
          borderBottom: '1px solid rgba(255,255,255,0.4)',
          gap: '15px',
        }}
      >
        <Tooltip content="Delete">
          <DeleteOutlined
            onClick={(event) => {
              props.onDelete();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Tooltip>
        <Tooltip content="Copy">
          <CopyOutlined
            onClick={(event) => {
              copyToClipboard();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Tooltip>
        <Tooltip content="Revert edits">
          <RollbackOutlined
            onClick={(event) => {
              props.onCancel();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Tooltip>
        <Tooltip content="Save edits">
          <SaveOutlined
            className={`save-context-${props.context.id}`}
            onClick={(event) => {
              props.onSave();
              event.stopPropagation();
              event.preventDefault();
            }}
            style={{ color: props.isDirty ? '#177DDC' : 'rgba(0, 0, 0, 0.65)' }}
          />
        </Tooltip>
      </div>
    );
  }

  return <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 16 }}>{actions}</div>;
};
