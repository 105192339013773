import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '.';
import { CB_COLORS } from '@commandbar/design-system/components';

const Container = styled.div<{ disabled?: boolean }>`
  height: 40px;
  border-radius: 8px;
  background: ${CB_COLORS.white};
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-grow: 1;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '50%' : '100%')};
  border: 1px solid #e6e6e8;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
    transform: scale(1.02);
  }
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.span`
  color: ${CB_COLORS.primary};
  line-height: 1;
`;

const Prompt = styled.div`
  margin-left: 17px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
`;

const KeyTag = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: ${CB_COLORS.white};
  background: #000000a6;
  padding: 2px 4px;
  border-radius: 4px;
`;

const DropdownMenuContainer = styled.div`
  margin-left: 30px;
`;

type Props = {
  text: string | JSX.Element;
  disabled?: boolean;
  tooltipText?: string;
  tag?: string;
  icon: { tooltipText?: string; icon: JSX.Element };
  extra?: JSX.Element;
  onClick: () => void;
};

export const ListItem = (props: Props) => {
  const { disabled, tag, text, tooltipText, icon, extra, onClick } = props;

  return (
    <Tooltip content={tooltipText} showIf={!!tooltipText && disabled}>
      <MainContainer>
        <Container onClick={() => !disabled && onClick()} disabled={disabled}>
          <LeftContent>
            <Tooltip content={icon?.tooltipText}>
              <IconContainer>{icon?.icon}</IconContainer>
            </Tooltip>
            <Prompt>{text}</Prompt>
          </LeftContent>
          {tag && (
            <RightContent>
              <KeyTag>{tag}</KeyTag>
            </RightContent>
          )}
        </Container>
        <DropdownMenuContainer>{extra}</DropdownMenuContainer>
      </MainContainer>
    </Tooltip>
  );
};
