import React from 'react';
import { Alert, Space } from '../shared_components';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from '../img/auth/logo.svg';

const FormLayout = (props: any) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        margin: '0px',
        backgroundColor: 'rgba(21, 21, 21, 0.03)',
        fontFamily:
          'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      }}
    >
      <div style={{ padding: '50px 20px 20px', margin: '0px auto', maxWidth: '448px' }}>
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <img src="https://staticassets.commandbar.com/brand/wordmark.png" height="40px" alt="commandbar logo" />
        </div>
        <div
          style={{
            width: '100%',
            margin: '0px',
            padding: '32px',
            borderRadius: '4px',
            backgroundColor: 'white',
            border: '1px solid #dedede',
          }}
        >
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontWeight: 900, fontSize: '24px' }}>{props.h1}</div>
              <div style={{}}>{props.h2}</div>
            </div>
            {!!props.error && <Alert message={props.error} type="error" />}
            {props.children}
          </Space>
        </div>
      </div>
    </div>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0px;
  display: flex;
  background-color: #fafafa;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const LeftPane = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  margin: 0px auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoPulseRing1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid #224fee;
  opacity: 0.75;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ping;
  transform-origin: center;

  @keyframes ping {
    75%,
    to {
      /* Controls size of pulse: */
      // transform: scale(6);
      width: 140px;
      height: 140px;
      opacity: 0;
    }
  }
`;

const LogoPulseRing2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #224fee;
  opacity: 0.5;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ping;
  transform-origin: center;

  @keyframes ping {
    75%,
    to {
      /* Controls size of pulse: */
      // transform: scale(6);
      width: 128px;
      height: 128px;
      opacity: 0;
    }
  }
`;

export const FormLayout2 = (props: any) => {
  return (
    <Container>
      <LeftPane>
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <LogoContainer>
              <Logo style={{ zIndex: '1' }} />
              <LogoPulseRing1></LogoPulseRing1>
              <LogoPulseRing2></LogoPulseRing2>
            </LogoContainer>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 900, fontSize: '24px' }}>{props.h1}</div>
            <div style={{}}>{props.h2}</div>
          </div>
          {!!props.error && <Alert message={props.error} type="error" />}
          {props.children}
        </Space>
      </LeftPane>
    </Container>
  );
};

export default FormLayout;
