/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* internal imports */
import {
  identifyUserBase,
  reportEventBase,
  IReportEventOptions,
  IIdentifyUserOptions,
} from '@commandbar/internal/util/eventReporting';
import { IUserType } from '@commandbar/internal/middleware/types';
import { useAuthInfo } from '../store/AuthProvider';

/* external imports */
import React from 'react';

/*******************************************************************************/
/* Meat and Potatoes
/*******************************************************************************/

export const useIdentifyUser = () => {
  const identifyUser = (user: IUserType, options: IIdentifyUserOptions) => identifyUserBase(user, options);
  return { identifyUser };
};
export const useReportEvent = () => {
  const { user } = useAuthInfo();
  const userRef = React.useRef(user);

  // use a ref to avoid the `reportEvent` function always changing
  // this way, if `user` changes, the new value will be used for
  // future calls to `reportEvent`
  React.useEffect(() => {
    userRef.current = user;
  }, [user]);

  const reportEvent = React.useCallback(
    (event: string, options: IReportEventOptions) => reportEventBase(userRef.current, event, options),
    [],
  );
  return { reportEvent };
};
