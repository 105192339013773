import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Input from './Input';

const SearchInput = (props: React.ComponentProps<typeof Input>) => (
  <Input
    prefix={<SearchOutlined style={{ fontSize: 18, marginRight: 5 }} />}
    placeholder={'Search'}
    style={{ fontSize: 12 }}
    {...props}
  />
);

export default SearchInput;
