import * as t from 'io-ts';

import * as generics from './generics';
import { LabeledActionV } from './helpers/actions';

const OrganizationSettingsKeys = {
  end_user_limit: t.number,
  user_seat_limit: t.number,
  analytics_dashboard_execution_limit: t.number,
  analytics_dashboard_deadend_limit: t.number,
  silent_mode: t.boolean,
  allow_event_handlers: t.boolean,
  event_attribute_block_list: t.array(t.string),
  skins_limit: t.number,
  skins_field_set: t.union([t.literal('pro'), t.literal('basic'), t.literal('none')]),
  show_countdown_banner: t.boolean,
  can_install_foobar: t.boolean,
  releases_available: t.boolean,
  releases_enabled: t.boolean,
  releases_environments: t.array(t.string),
  in_bar_feedback: t.boolean,
  force_end_user_identity_verification: t.boolean,
  force_end_user_identity_verification_for_helphub: t.boolean,
  summon_hotkey_override: t.union([t.string, t.null]),
  end_user_shortcuts_enabled: t.boolean,
  fallback_commands: t.array(t.number),
  end_user_recents_enabled: t.boolean,
  tab_direction: t.union([t.literal('horizontal'), t.literal('vertical')]),
  recommended_tab_enabled: t.boolean,
  recents_tab_enabled: t.boolean,
  recommended_tab_instruction: t.string,
  recents_tab_instruction: t.string,
  hotload_help_docs: t.boolean,
  slash_filters_enabled: t.boolean,
  recents_sort_key: t.union([t.number, t.null]),
  recommended_sort_key: t.union([t.number, t.null]),
  num_live_commands: t.number,
  help_center_sync: t.union([t.literal('upload-once'), t.literal('manual'), t.literal('automatic')]),
  show_suggested_synonyms: t.boolean,
  bar_enabled: t.boolean,
  in_bar_doc_search: t.boolean,
  featured_item_cards: t.boolean,
  helphub_ai_enabled: t.boolean,
  chat_system_prompt_modifier: t.string,
  helphub_chat_welcome_message: t.string,
  helphub_suggested_queries_enabled: t.boolean,
  helphub_continuations_enabled: t.boolean,
  helphub_manual_suggested_queries: t.array(t.string),
  helphub_chat_only_mode: t.boolean,
  helphub_enabled: t.boolean,
  helphub_launcher_type: t.union([
    t.literal('bookOpen'),
    t.literal('graduationHat'),
    t.literal('bookClosed'),
    t.literal('bookmark'),
    t.literal('chatCircle'),
    t.literal('askAI'),
    t.literal('custom'),
    t.literal('none'),
  ]),
  helphub_launcher_position: t.union([
    t.literal('topRight'),
    t.literal('topLeft'),
    t.literal('bottomRight'),
    t.literal('bottomLeft'),
  ]),
  helphub_launcher_offset_x: t.number,
  helphub_launcher_offset_y: t.number,
  helphub_launcher_anchor: t.string,
  helphub_chat_fallback_message: t.string,
  helphub_chat_fallback_actions: t.array(LabeledActionV),
  nudge_rate_limit: t.union([t.null, t.number]),
  bar_hide_completed_nudges_questlists: t.boolean,
  nudge_rate_period: t.union([t.literal('day'), t.literal('week'), t.literal('session')]),
};

export const OrganizationSettingsV = t.type(OrganizationSettingsKeys);
const OrganizationSettingsPartialV = t.partial(OrganizationSettingsKeys);

export const read = generics.read(OrganizationSettingsV, 'org-settings');
export const update = generics.update(OrganizationSettingsV, OrganizationSettingsPartialV, 'org-settings');
