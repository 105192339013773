import { useState, useEffect, useRef } from 'react';
import { HelpDocsIntegration } from '../middleware/helpDocsIntegration';

// We have no idea how long the fetch will take, so we just estimate it to be 120 seconds
const ESTIMATED_FETCH_TIME_S = 120;
// The progress bar should be no more than 65% complete when fetching finishes
const FETCH_PROGRESS_PERCENTAGE = 65;
// We check the whether the sync is still running every second
const SERVER_UPDATE_INTERVAL_MS = 1000;

export type SyncState = {
  progress: number;
  numFetched: number;
  numProcessed: number;
  progressAtProcessing?: number;
};

const initialState: SyncState = {
  progress: 0,
  numFetched: 0,
  numProcessed: 0,
};

const useSyncProgress = (integrationName: string, reload?: () => void) => {
  const [syncState, setSyncState] = useState<SyncState>(initialState);
  const progressAtProcessing = useRef<number>(0);
  const [ping, setPing] = useState<boolean>(false);

  useEffect(() => {
    const updateWebSync = async () => {
      const { running, numFetched, numProcessed } = await HelpDocsIntegration.readSyncStatus(integrationName);

      let progress;
      if (running && !numFetched) {
        progress = Math.min(
          syncState.progress + FETCH_PROGRESS_PERCENTAGE / ESTIMATED_FETCH_TIME_S,
          FETCH_PROGRESS_PERCENTAGE,
        );
      } else if (running && numFetched) {
        if (syncState.numFetched === 0) {
          progressAtProcessing.current = syncState.progress;
        }

        const progressRemaining = 100 - progressAtProcessing.current;
        const progressPerItem = progressRemaining / numFetched;

        progress = Math.min(progressAtProcessing.current + progressPerItem * numProcessed, 100);
      } else if (!running && numProcessed === numFetched && syncState.progress) {
        progress = 100;
      } else if (!running && !progress) {
        clearInterval(serverIntervalId);
        return;
      } else {
        progress = syncState.progress;
      }

      if (progress >= 100) {
        reload?.();
        setSyncState(initialState);
        clearInterval(serverIntervalId);
      } else {
        setSyncState({
          progress,
          numFetched,
          numProcessed,
          progressAtProcessing: progressAtProcessing.current,
        });
      }
    };

    const serverIntervalId = setInterval(updateWebSync, SERVER_UPDATE_INTERVAL_MS);

    return () => {
      clearInterval(serverIntervalId);
    };
  }, [integrationName, reload, syncState.numFetched, syncState.progress, ping]);

  return {
    numProcessed: syncState.numProcessed,
    numFetched: syncState.numFetched,
    progress: syncState.progress,
    ping: () => setPing((x) => !x),
  };
};

export default useSyncProgress;
