import React from 'react';
import { SortableContainer, SortableElement, SortStartHandler, SortEndHandler } from 'react-sortable-hoc';
import { container } from '..';

const SortableListItem = SortableElement((props: any) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { value, key, ...restProps } = props;

  return <div {...restProps}>{value}</div>;
});

const SortableListContainer = SortableContainer((props: { items: any; style?: React.CSSProperties }) => {
  return (
    <div style={props.style}>
      {props.items.map((value: any, index: any) => (
        <SortableListItem key={`item-${value.key || index}`} index={index} value={value} />
      ))}
    </div>
  );
});

interface IProps {
  nodes: any[];
  useDragHandle?: boolean;
  onSort: (oldIndex: number, newIndex: number) => void;
  onSortEnd?: SortEndHandler;
  updateBeforeSortStart?: SortStartHandler;
  pressDelay?: number;
  style?: React.CSSProperties;
}

class SortableList extends React.Component<IProps> {
  public onSortEnd: SortEndHandler = (sort, event) => {
    const { oldIndex, newIndex } = sort;

    if (oldIndex !== newIndex) {
      this.props.onSort(oldIndex, newIndex);
    }

    if (this.props.onSortEnd) {
      this.props.onSortEnd(sort, event);
    }
  };

  public render() {
    return (
      <SortableListContainer
        style={this.props.style}
        helperClass="row-dragging-no-border"
        onSortEnd={this.onSortEnd}
        helperContainer={() => container}
        items={this.props.nodes}
        distance={this.props.pressDelay ? 0 : 5}
        pressDelay={this.props.pressDelay}
        useDragHandle={this.props.useDragHandle}
        updateBeforeSortStart={this.props.updateBeforeSortStart}
      />
    );
  }
}
export default SortableList;
