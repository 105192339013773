// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Pin01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6.8777 4.1942c-.294-.735-.441-1.1026-.3797-1.3977a1 1 0 0 1 .4265-.63C7.1757 2 7.5715 2 8.3633 2h7.2734c.7918 0 1.1876 0 1.4388.1665.2196.1456.3729.372.4266.63.0613.295-.0858.6626-.3798 1.3977L16.1144 6.714c-.0427.1069-.0641.1603-.0791.2155a.9972.9972 0 0 0-.0288.1493C16 7.1354 16 7.193 16 7.308v2.1307c0 .208 0 .312.0204.4115.0181.0882.0481.1736.0891.2539.0462.0904.1112.1716.2411.3341l1.5698 1.9622c.6657.8322.9986 1.2482.999 1.5984a1 1 0 0 1-.3762.7826C18.2696 15 17.7367 15 16.671 15H7.329c-1.0657 0-1.5985 0-1.8722-.2185a.9997.9997 0 0 1-.3762-.7826c.0004-.3502.3333-.7662.999-1.5984l1.5698-1.9622c.13-.1625.195-.2437.2411-.3341a.9961.9961 0 0 0 .089-.254C8 9.7509 8 9.6469 8 9.4389V7.308c0-.115 0-.1727-.0065-.2294a.9996.9996 0 0 0-.0288-.1493c-.015-.0552-.0364-.1086-.0791-.2155l-1.008-2.5197Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15v7M8 7.3081v2.1307c0 .208 0 .312-.0204.4115a1.0014 1.0014 0 0 1-.089.2539c-.0463.0904-.1113.1716-.2412.3341l-1.5698 1.9622c-.6657.8322-.9986 1.2482-.999 1.5984a.9997.9997 0 0 0 .3762.7826C5.7304 15 6.2633 15 7.329 15h9.342c1.0657 0 1.5986 0 1.8722-.2185a1 1 0 0 0 .3762-.7826c-.0004-.3502-.3333-.7662-.999-1.5984l-1.5698-1.9622c-.1299-.1625-.1949-.2437-.2411-.3341a1.0003 1.0003 0 0 1-.0891-.254C16 9.7509 16 9.6469 16 9.4389V7.308c0-.115 0-.1727.0065-.2294a.9972.9972 0 0 1 .0288-.1493c.015-.0552.0364-.1086.0791-.2155l1.0079-2.5197c.294-.735.4411-1.1026.3798-1.3977a1.0006 1.0006 0 0 0-.4266-.63C16.8243 2 16.4285 2 15.6367 2H8.3633c-.7918 0-1.1876 0-1.4388.1665a1 1 0 0 0-.4265.63c-.0614.295.0857.6626.3797 1.3977L7.8856 6.714c.0427.1069.0641.1603.0791.2155a.9996.9996 0 0 1 .0288.1493C8 7.1354 8 7.193 8 7.308Z"
    />
  </svg>
);
export default Pin01;
