/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';
import styled from '@emotion/styled';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { Input, Button } from '../../../../shared_components';
import AutocompleteTextArea from '../../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from '../../../../hooks/useWindowInfo';

const StyledButton = styled(Button)`
  &&& {
    display: flex;
    margin: 24px auto 0;
  }
`;

/*******************************************************************************/

const NewObjectForm = (props: {
  onCreate: (toAdd: string) => void;
  buttonText: string | null;
  placeholderText: string;
  autocomplete?: boolean;
}) => {
  const [isActive, setActive] = React.useState(false);
  const { context } = useWindowInfo();

  const contextKeys = Object.keys(context).map((s) => ({ value: `context.${s}`, addOn: 'Active context' }));

  const onSave = (newVal: string) => {
    setActive(false);
    props.onCreate(newVal);
  };

  if (!isActive && props.buttonText) {
    return (
      <StyledButton
        onClick={() => {
          setActive(true);
        }}
        icon={<PlusOutlined width="12px" height="12px" />}
      >
        {props.buttonText}
      </StyledButton>
    );
  }

  return (
    <SaveInput
      placeholder={props.placeholderText}
      onSave={onSave}
      defaultValue={undefined}
      onCancel={() => {
        setActive(false);
      }}
      contextKeys={contextKeys}
      autocomplete={props.autocomplete}
    />
  );
};

export class SaveInput extends React.Component<
  {
    onSave: (newVal: string) => void;
    placeholder?: string;
    defaultValue?: string;
    onCancel: () => void;
    contextKeys?: Array<{ value: string; addOn: string }>;
    autocomplete?: boolean;
  },
  { value: string }
> {
  public constructor(props: any) {
    super(props);
    this.state = {
      value: props.defaultValue || '',
    };
  }

  public render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {this.props.autocomplete ? (
          <div style={{ width: '100%' }}>
            <AutocompleteTextArea
              onChange={(value) => {
                this.setState({ value });
              }}
              options={this.props.contextKeys ? [...this.props.contextKeys] : []}
              value={this.state.value}
              placeholder={this.props.placeholder}
            />
          </div>
        ) : (
          <Input
            onPressEnter={() => this.props.onSave(this.state.value || '')}
            value={this.state.value}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            onChange={(e) => {
              this.setState({ value: e.target.value });
            }}
            autoFocus={true}
          />
        )}
        <Button icon={<CheckOutlined />} onClick={() => this.props.onSave(this.state.value || '')} />
        <Button icon={<CloseOutlined />} onClick={this.props.onCancel} />
      </div>
    );
  }
}

export default NewObjectForm;
