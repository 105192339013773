import React from 'react';
import {
  AlignLeftOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  PlayCircleOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

export const ICONS: Record<string, { icon: JSX.Element; tooltipText: string }> = {
  time: {
    icon: <ClockCircleOutlined />,
    tooltipText: 'Date time',
  },
  text: {
    icon: <AlignLeftOutlined />,
    tooltipText: 'Text',
  },
  context: {
    icon: <SolutionOutlined />,
    tooltipText: 'Context record',
  },
  set: {
    icon: <UnorderedListOutlined />,
    tooltipText: 'Set',
  },
  function: {
    icon: <ClockCircleOutlined />,
    tooltipText: 'Function generated',
  },
  dependent: {
    icon: <ClockCircleOutlined />,
    tooltipText: 'Dependent',
  },
  video: {
    icon: <PlayCircleOutlined />,
    tooltipText: 'Video',
  },
  html: {
    icon: <FileTextOutlined />,
    tooltipText: 'HTML',
  },
};

export const getIcon = (arg: any) => {
  if (arg.type === 'provided') {
    return ICONS[arg.value];
  }

  return ICONS[arg.type];
};
