/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IHistoryEventType } from '@commandbar/internal/middleware/types';
import { HistoryEvent } from '@commandbar/internal/middleware/historyEvent';

import {
  Button,
  Skeleton,
  List,
  Avatar,
  Space,
  Divider,
  Empty,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
} from '../shared_components';

import moment from 'moment';
import chroma from 'chroma-js';
import { useReportEvent } from '../shared_components/useEventReporting';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const History = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [historyEvents, setHistoryEvents] = React.useState<IHistoryEventType[]>([]);
  const { reportEvent } = useReportEvent();

  React.useEffect(() => {
    setLoading(true);
    HistoryEvent.list().then((histories: IHistoryEventType[]) => {
      setHistoryEvents(histories);
      setLoading(false);
    });
    reportEvent('Visited History Tab', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, []);

  if (loading) {
    return <Skeleton active={true} />;
  }

  const userColors: any = {};
  let count = 10;
  const listData = historyEvents.map((historyEvent) => {
    // Use chroma.brewer.Set3 colors to give diff avatars diff colors
    let avatarColor = '#cccccc';
    if (count < 0) {
      avatarColor = '#cccccc';
    } else if (userColors.hasOwnProperty(historyEvent.changed_by)) {
      avatarColor = userColors[historyEvent.changed_by];
    } else {
      avatarColor = chroma.brewer.Set3[count];
      userColors[historyEvent.changed_by] = avatarColor;
      count = count - 1;
    }

    const timestamp = moment(historyEvent.created).format('LLL');
    return {
      ...historyEvent,
      historyEvent,
      stamp: `${historyEvent.changed_by || 'CommandBar Update'} on ${timestamp}`,
      avatarColor,
    };
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FeatureAnnouncementCard
        identifier="history"
        title={<Row align="middle">Viewing history</Row>}
        docsLink="https://www.commandbar.com/docs/versioncontrol/history"
      >
        <span>You can view changes made to your configuration below.</span>
      </FeatureAnnouncementCard>
      <SimplePanel>
        <List
          itemLayout="vertical"
          size="small"
          locale={{
            emptyText: (
              <Empty
                description={
                  <span style={{ color: 'rgba(0,0,0,0.6)' }}>
                    This tab will catalog all changes made to your CommandBar config, and allow you to roll back to a
                    previous state. Try creating a command and then return to this tab.
                  </span>
                }
              />
            ),
          }}
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
          }}
          dataSource={listData}
          renderItem={(item) => {
            return (
              <List.Item key={`history-${item.id}`} actions={[]} style={{ border: '0px', padding: '0px' }}>
                <Divider style={{ flexGrow: 1, margin: '0px' }} orientation="right">
                  <Space>
                    <Button size="small" disabled={true}>
                      {item.sha1.substring(0, 7)}
                    </Button>
                  </Space>
                </Divider>
                <List.Item.Meta
                  avatar={<Avatar style={{ backgroundColor: item.avatarColor }} size="small" icon={<UserOutlined />} />}
                  title={item.description}
                  description={item.stamp}
                />
              </List.Item>
            );
          }}
        />
      </SimplePanel>
    </div>
  );
};

export default History;
