import * as S from '../styled';

import type {
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextBlockType,
} from '@commandbar/internal/middleware/types';
import React from 'react';

interface IButton {
  block: INudgeStepContentSurveyTextBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

export const SurveyTextInputBlockForm = ({ block, onBlockChange }: IButton) => {
  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.InputContainer>
          <S.StyledLabel htmlFor="prompt">Text Input</S.StyledLabel>
          <S.MediaBlockStyledTextArea
            rows={2}
            name="prompt"
            value={block.meta.prompt}
            onChange={(e) => {
              onBlockChange({
                type: 'survey_text',
                meta: {
                  prompt: e.target.value,
                },
              });
            }}
            placeholder="Your placeholder text..."
          />
        </S.InputContainer>
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
