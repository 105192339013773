import React from 'react';

import { Divider, Form, Input } from '../shared_components';
import { Button } from './styled';
import { GoogleOutlined } from '@ant-design/icons';
import { FormLayout2 } from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useHistory } from 'react-router';
import { useAuthInfo } from '../store/AuthProvider';
import Sender from '../management/Sender';

const Login = () => {
  const { handleLogin } = useAuthInfo();
  const history = useHistory();
  const [authType, setAuthType] = React.useState('unknown');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const [emailValidateType, setEmailValidateType] = React.useState('onBlur');

  const checkAuthType = async (email: string) => {
    try {
      const data = await Auth.getLoginType(email);
      setAuthType(data);

      if (data === 'google') {
        signInWithGoogle();
      }
    } catch {
      setAuthType('basic');
    }
  };

  const signin = async (username: string, password: string) => {
    const { success, error, refresh, access } = await Auth.basic(username, password);
    if (!success || !refresh || !access) {
      setError(error ?? 'Invalid Email or Password.');
      LocalStorage.remove('access');
      LocalStorage.remove('refresh');
    } else {
      LocalStorage.set('refresh', refresh);
      LocalStorage.set('access', access);
      handleLogin(access);
    }
    return true;
  };

  const onFinish = async (values: any) => {
    if (authType === 'unknown') {
      checkAuthType(values.email);
    } else {
      setLoading(true);
      await signin(values.email, values.password);
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);

    const auth_redirect = window.location.origin;

    let hostURL = (await Sender.getHostUrl()) as any;
    hostURL = hostURL?.url;
    const final_redirect = hostURL ?? 'https://app.commandbar.com';
    const authorization_url = await Auth.google({
      auth_redirect: auth_redirect,
      final_redirect: final_redirect,
      is_signup: false,
    });

    if (!authorization_url) {
      setError('Something went wrong.');
      setLoading(false);
    } else {
      Sender.secureEditor();
      window.open(authorization_url);
    }
    setLoading(false);
  };

  return (
    <FormLayout2 error={error}>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          id="email"
          validateTrigger={emailValidateType}
          rules={[{ type: 'email', max: 30, message: 'Invalid Email Address' }]}
        >
          <Input
            onBlur={() => setEmailValidateType('onChange')}
            placeholder="Enter your email"
            type="email"
            autoComplete="email"
          />
        </Form.Item>

        {authType === 'basic' && (
          <Form.Item label="Password">
            <Form.Item name="password" noStyle>
              <Input.Password placeholder="Enter your password" autoFocus />
            </Form.Item>
            <div style={{ textAlign: 'end' }}>
              <a href="https://auth.commandbar.com/auth/password_reset/">Forgot your password?</a>
            </div>
          </Form.Item>
        )}

        <Form.Item style={{ margin: '0px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={loading}>
            {loading ? '...' : 'Continue'}
          </Button>
        </Form.Item>
      </Form>
      <Divider style={{ margin: '4px 0px' }}>OR</Divider>
      <Button icon={<GoogleOutlined />} style={{ width: '100%' }} type="default" onClick={signInWithGoogle}>
        Sign in with Google
      </Button>
      <Button onClick={() => history.push('/login/sso')} style={{ width: '100%' }} type="default">
        Sign in with SSO
      </Button>
      <div style={{ padding: '24px 20% 0px', overflowWrap: 'break-word', color: '#8e8e8e', textAlign: 'center' }}>
        By signing in, you agree to our{' '}
        <a href="https://commandbar.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://commandbar.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div>
    </FormLayout2>
  );
};

export default Login;
