/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import _ from 'lodash';

import {
  CheckCircleFilled,
  InfoCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { Tooltip, Collapse, Modal, message, Table, Tag } from '../../../shared_components';
import { IContextType, IUserContext } from '@commandbar/internal/middleware/types';
import { ContextPanelHeader } from './ContextPanelHeader';
import { ContextPanelActions } from './ContextPanelActions';
import JSONEditor from '../../components/JSONEditor';

import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { container } from '../../../index';
import { ICommandsByContextKey } from '../../context/helpers';
import { Button } from '../../../shared_components';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  item: IContextType;
  deleteItem: (id: number) => Promise<void>;
  saveItem: (obj: IContextType) => Promise<void>;

  /* List of context arguments in commands */
  commandsByContextKey: ICommandsByContextKey;
  isWindowContext: boolean;
  reloadWindowContext?: (e: any) => void;
}

interface IState {
  editMode: boolean;
  hasError: boolean;
  newContext: IUserContext;
  newName: string;
  isDirty: boolean;
  showModal: boolean;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

class ContextPanel extends React.Component<IProps, IState> {
  private mounted: boolean;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      editMode: false,
      hasError: false,
      newContext: this.props.item.value,
      newName: this.props.item.name,
      isDirty: false,
      showModal: false,
    };
    this.mounted = true;
  }

  public componentDidMount = () => {
    document.addEventListener('keydown', this.handleShortcuts);
  };

  public componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleShortcuts);
    this.mounted = false;
  };

  public handleShortcuts = (e: any) => {
    const triggerKey = getTriggerKey(e);

    if (triggerKey && e.key === 's') {
      // We must trigger a blur event in order for the context to save
      const id = `save-context-${this.props.item.id}`;
      const saveButton = container.getElementsByClassName(id);
      const jsoneditor = container.querySelector(`#jsoneditor-${this.props.item.id}`);

      if (saveButton.length > 0 && jsoneditor !== null) {
        // @ts-expect-error: jsoneditor types
        jsoneditor.blur();
        // @ts-expect-error: FIXME types
        saveButton[0].click();

        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  public onSave = async () => {
    if (!this.state.hasError) {
      const obj = {
        id: this.props.item.id,
        organization: this.props.item.organization,
        name: this.state.newName,
        value: this.state.newContext,
      };
      await this.props.saveItem(obj);
      if (!this.mounted) return;
      this.setState({ editMode: false, isDirty: false });
    } else {
      message.error('Please fix the JSON formatting error before saving.');
    }
  };

  public onDelete = async () => {
    return await this.props.deleteItem(this.props.item.id);
  };

  public onCancel = () => {
    // reset context and name
    this.setState({
      newContext: this.props.item.value,
      newName: this.props.item.name,
      hasError: false,
      editMode: false,
    });
  };

  public onEdit = () => {
    this.setState({ editMode: true });
  };

  // *************************************************************************************
  // *************************** Other state changes *************************************
  // *************************************************************************************
  public onChange = (obj: any) => {
    const newContext = obj.jsObject;
    const hasError = obj.error !== false;

    const isDirty = newContext !== this.props.item.value;
    this.setState({ newContext, hasError, isDirty });
  };

  public onNameChange = (e: any) => {
    this.setState({ newName: e.target.value });
  };

  public openModal = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showModal: true });
  };

  public render() {
    const missingArgs = Object.keys(this.props.commandsByContextKey).filter(
      (arg: string) => !_.get(this.state.newContext, arg),
    );
    const includedArgs = Object.keys(this.props.commandsByContextKey).filter((arg: string) =>
      Boolean(_.get(this.state.newContext, arg)),
    );

    const variableTags = (
      <span key="context-tags" style={{ marginTop: '-3px' }} onClick={this.openModal}>
        {missingArgs.length > 0 ? (
          <Tooltip
            content={`There are ${missingArgs.length} key${
              missingArgs.length > 1 ? 's' : ''
            } referenced by commands that are missing in this context. Click to learn more.`}
          >
            <Tag color="red" style={{ cursor: 'pointer' }} icon={<InfoCircleFilled />}>
              {`${missingArgs.length} missing`}
            </Tag>
          </Tooltip>
        ) : (
          <Tag color="green" style={{ cursor: 'pointer' }} icon={<CheckCircleFilled />}>
            All context keys included
          </Tag>
        )}
      </span>
    );

    const reloadButton = (
      <ReloadOutlined
        key="context-reload"
        onClick={(e) => {
          if (!this.props.reloadWindowContext) return;
          e.stopPropagation(); // prevent the context panel from closing
          this.props.reloadWindowContext(e);
        }}
      />
    );

    // *************************************************************************************
    // ************************************ Render *****************************************
    // *************************************************************************************

    const content = (
      <JSONEditor
        key={`${this.props.item.id}-${this.state.editMode.toString()}`}
        contextID={this.props.item.id}
        json={this.props.item.value}
        onChange={this.onChange}
        readOnly={!this.state.editMode}
      />
    );

    const header = this.props.isWindowContext ? (
      <ContextPanelHeader
        key="context-panel-header-0"
        id={0}
        name="Current context (readonly)"
        canEdit={false}
        onNameChange={(_e) => {
          return {};
        }}
        action={[variableTags, reloadButton]}
      />
    ) : (
      <ContextPanelHeader
        key={`context-panel-header-${this.props.item.id}`}
        canEdit={this.state.editMode}
        id={this.props.item.id}
        name={this.props.item.name}
        onNameChange={this.onNameChange}
        action={variableTags}
      />
    );

    return (
      <React.Fragment>
        <Collapse.Panel
          {...this.props}
          header={header}
          key={this.props.item.id}
          style={{ border: '0px solid transparent' }}
        >
          {!this.props.isWindowContext && (
            <ContextPanelActions
              context={this.props.item}
              canEdit={this.state.editMode}
              onEdit={this.onEdit}
              onSave={this.onSave}
              onDelete={this.onDelete}
              onCancel={this.onCancel}
              missingArgs={missingArgs}
              includedArgs={includedArgs}
              isDirty={this.state.isDirty}
            />
          )}
          {content}
        </Collapse.Panel>
        <Modal
          visible={this.state.showModal}
          closable={false}
          footer={[
            <Button
              key="context-footer"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Close
            </Button>,
          ]}
          okText={null}
          mask={false}
          width={400}
        >
          <CommandsByContextKeyTable
            commandsByContextKey={this.props.commandsByContextKey}
            context={this.state.newContext}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export const CommandsByContextKeyTable = (props: {
  commandsByContextKey: ICommandsByContextKey;
  context: IUserContext;
}) => {
  const columns = [
    {
      title: 'Context key',
      dataIndex: 'contextKey',
      key: 'contextKey',
    },
    {
      title: 'Included?',
      dataIndex: 'isInContext',
      key: 'isInContext',
    },
    {
      title: 'Commands',
      dataIndex: 'commands',
      key: 'commands',
    },
  ];

  const okIcon = <CheckCircleOutlined style={{ fontSize: 20, width: 20, marginLeft: 5, color: 'green' }} />;
  const missingIcon = <CloseCircleOutlined style={{ fontSize: 20, width: 20, marginLeft: 5, color: 'red' }} />;

  const data = Object.keys(props.commandsByContextKey).map((contextKey: string) => {
    const commands = props.commandsByContextKey[contextKey];
    const commandsAsString = commands.map((c) => c.text).join(', ');
    const isInContext = _.get(props.context, contextKey) ? okIcon : missingIcon;
    return {
      key: contextKey,
      contextKey,
      isInContext,
      commands: commandsAsString,
    };
  });

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default ContextPanel;
