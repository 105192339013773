// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const FolderPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2 6.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 3 4.08 3 5.2 3h3.8223c.718 0 1.0769 0 1.3938.1093.2802.0967.5355.2544.7473.4619.2395.2345.4.5556.7211 1.1977L13 7H2v-.8Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13 7-1.1155-2.231c-.3211-.6422-.4816-.9633-.7211-1.1978a2.0005 2.0005 0 0 0-.7473-.4619C10.0992 3 9.7402 3 9.0223 3H5.2c-1.1201 0-1.6802 0-2.108.218a1.9999 1.9999 0 0 0-.874.874C2 4.5198 2 5.08 2 6.2V7m0 0h15.2c1.6802 0 2.5202 0 3.162.327a2.9997 2.9997 0 0 1 1.311 1.311C22 9.2798 22 10.1198 22 11.8v4.4c0 1.6802 0 2.5202-.327 3.162a2.9994 2.9994 0 0 1-1.311 1.311C19.7202 21 18.8802 21 17.2 21H6.8c-1.6802 0-2.5202 0-3.162-.327a2.9997 2.9997 0 0 1-1.311-1.311C2 18.7202 2 17.8802 2 16.2V7Zm10 10v-6m-3 3h6"
    />
  </svg>
);
export default FolderPlus;
