import { PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Collapse } from 'antd';
import React from 'react';

const StyledCollapse = styled(Collapse)`
  border: 1px solid #e3e8ee !important;
  border-radius: 16px !important;
  margin-right: auto !important;
  transition: all 0.2s ease;
  background: #fff !important;

  .ant-collapse-item {
    display: flex;
    flex-direction: column;
  }

  .ant-collapse-header {
    padding: 6px 30px 6px 16px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse-arrow {
    right: 8px !important;
  }
`;

type IProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const ButtonCollapse = (props: IProps) => {
  return (
    <StyledCollapse
      ghost
      expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}
      expandIconPosition="end"
      style={{ maxWidth: 'calc(100%)' }}
    >
      <Collapse.Panel header={props.header} key="1">
        {props.children}
      </Collapse.Panel>
    </StyledCollapse>
  );
};
