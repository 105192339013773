import React from 'react';
import styled from '@emotion/styled';

import { Switch, Tooltip } from '../../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';
import { ActionEditor } from './ActionEditor';
import * as S from '../styled';
import useWindowInfo from '../../../hooks/useWindowInfo';
import RouterWarning from '../../RouterWarning';
import { findButtonBlock } from '../utils';

import type { INudgeStepContentBlockType } from '@commandbar/internal/middleware/types';
import { InputContainer, MediaBlockStyledInput, StyledLabel } from '../../../shared_components/form';

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
  flex-grow: 1;
`;

const ActionToggle = styled(Switch)`
  width: 32px;
  height: 16px;

  .ant-switch.status-switch {
    border-radius: 9px;
    margin-right: 8px;
    background: ${CB_COLORS.blue500};
    border: 1px solid ${CB_COLORS.blue600};
    box-sizing: border-box;
    box-shadow: 0 0 3px rgba(66, 66, 203, 0.2), 0 0 1px rgba(0, 0, 0, 0.1);
  }

  .ant-switch.status-switch.off {
    background: ${CB_COLORS.neutral400};
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ant-switch.status-switch .ant-switch-handle {
    top: 1px;
    left: calc(100% - 12px - 1px);
  }

  .ant-switch.status-switch.off .ant-switch-handle {
    left: 1px;
  }

  .ant-switch.status-switch.neutral .ant-switch-handle {
    left: 1px;
  }

  & .ant-switch-handle {
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
  }
`;

interface IButton {
  button: ReturnType<typeof findButtonBlock>;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  label: string;
}

export const CTAForm = ({ button, onBlockChange, label }: IButton) => {
  const [action, setAction] = React.useState(button?.action?.type !== 'no_action');

  const { hasRouter } = useWindowInfo();
  const showRouterWarning = button?.action?.type === 'link' && button.action.operation === 'router' && !hasRouter;

  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.SectionContainer>
          <InputContainer width="300px">
            <StyledLabel htmlFor="label">
              {button?.button_type === 'secondary' ? 'Secondary button' : 'Primary button'}
            </StyledLabel>
            <MediaBlockStyledInput
              name="Label"
              value={label}
              onChange={(e) => {
                onBlockChange({
                  type: 'button',
                  meta: {
                    ...button,
                    label: e.target.value,
                  },
                });
              }}
              placeholder="Continue"
            />
          </InputContainer>
          <ActionContainer>
            <Tooltip content="Optionally trigger an action before this nudge is dismissed or the tour advances">
              <StyledLabel htmlFor="action">Action?</StyledLabel>
            </Tooltip>
            <ActionToggle
              checked={action}
              onChange={(e) => {
                if (!e) {
                  onBlockChange({
                    type: 'button',
                    meta: {
                      ...button,
                      label,
                      action: { type: 'no_action' },
                    },
                  });
                } else {
                  onBlockChange({
                    type: 'button',
                    meta: {
                      ...button,
                      label,
                      action: { type: 'link', value: '', operation: 'self' },
                    },
                  });
                }

                setAction(e);
              }}
            />
          </ActionContainer>
        </S.SectionContainer>
        {action && <ActionEditor button={button} onBlockChange={onBlockChange} />}
        {showRouterWarning && <RouterWarning />}
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
