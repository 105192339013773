import { IResourceSettings, IResourceSettingsByContextKey } from '@commandbar/internal/middleware/types';
import { message } from '../../../shared_components';
import * as lodash from 'lodash';
import React from 'react';
import Sender from '../../../management/Sender';
import { useAppContext } from '../../../Widget';
import { useAsyncCallback } from '@commandbar/internal/util/useAsyncCallback';
import * as Organization from '@commandbar/internal/middleware/organization';

export const useContextSettings = () => {
  const [current, setCurrent] = React.useState<IResourceSettingsByContextKey>({});
  const [modified, setModified] = React.useState<IResourceSettingsByContextKey>({});
  const local = React.useRef<IResourceSettingsByContextKey>({});
  const { organization, dispatch } = useAppContext();

  const refreshMergedSettings = (server: IResourceSettingsByContextKey, local: IResourceSettingsByContextKey) => {
    const newSettings = lodash.merge({}, server, local);
    setCurrent(newSettings);
    setModified(newSettings);
  };

  React.useEffect(() => {
    if (organization) {
      refreshMergedSettings(organization?.resource_options, local.current);
    }
  }, [organization]);

  const fetchContextSettings = useAsyncCallback(function* () {
    const { local: newLocal } = yield Sender.shareContextSettings();

    if (!lodash.isEqual(local.current, newLocal)) {
      local.current = newLocal;
      refreshMergedSettings(organization?.resource_options || {}, newLocal);
    }
  });

  React.useEffect(() => {
    fetchContextSettings();
    const interval = setInterval(() => fetchContextSettings(), 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isSetFromSDK = (contextKey: string, setting?: keyof IResourceSettings) => {
    const localSettings = local.current[contextKey];
    if (!setting) {
      return !!localSettings;
    } else {
      return !!localSettings && localSettings.hasOwnProperty(setting) && localSettings[setting] !== undefined;
    }
  };

  const isDirty = React.useMemo(() => !lodash.isEqual(current, modified), [current, modified]);

  const save = useAsyncCallback(function* (contextKey: string) {
    if (!organization) return;

    const updated = {
      ...organization,
      resource_options: { ...organization.resource_options, [contextKey]: modified[contextKey] },
    };
    const newOrg = yield dispatch.organization.update(updated);
    message.success('Settings updated');
    refreshMergedSettings(newOrg.resource_options, local.current);
  });

  const update = (contextKey: string, setting: keyof IResourceSettings, value: any) => {
    setModified((prevState) => {
      if (!prevState) {
        return prevState;
      }
      const existingOpts = prevState[contextKey];
      return {
        ...prevState,
        [contextKey]: { ...existingOpts, [setting]: value },
      };
    });
  };

  const deleteKey = useAsyncCallback(function* (contextKey: string) {
    if (!organization) return;

    const { [contextKey]: remove, ...newContextSettings } = modified;
    const updated = { ...organization, resource_options: newContextSettings };

    if (typeof updated.integrations?.algolia?.indexes?.[contextKey] !== 'undefined') {
      delete updated.integrations.algolia.indexes[contextKey];

      yield Organization.updateInternal({
        id: String(updated.id),
        integrations: {
          ...updated.integrations,
          algolia: {
            ...(updated.integrations.algolia as any),
            indexes: updated.integrations.algolia.indexes,
          },
        },
      });
    }

    const newOrg = yield dispatch.organization.update(updated) as any;

    message.success(`Deleted settings for ${contextKey}`);

    refreshMergedSettings(newOrg.resource_options, local.current);
  });

  const clear = () => setModified({ ...current });

  return {
    current,
    modified,
    update,
    save,
    clear,
    isDirty,
    isSetFromSDK,
    deleteKey,
  };
};
