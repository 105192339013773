import * as S from '../styled';
import { Settings03 } from '@commandbar/design-system/icons/react';

import type {
  INudgeStepContentBlockType,
  INudgeContentSurveyRatingBlockType,
} from '@commandbar/internal/middleware/types';
import React, { useState } from 'react';
import { Button, Dropdown, Icon, Popover, Select } from '../../../shared_components';
import { BaseEmoji, Picker } from 'emoji-mart';
import { SectionHeader } from './FormFactorPositionInput/PositionInputConfig/styles';
import { CB_COLORS } from '@commandbar/design-system/components';
import styled from '@emotion/styled';
import { TextInput } from './FormFactorPositionInput/PositionInputConfig/TextInput';
import emojiImageBackground from '../../../img/emojis-32px.png';

interface IButton {
  block: INudgeContentSurveyRatingBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  gap: 8px;
  color: ${CB_COLORS.neutral800};
  border-top: 1px solid ${CB_COLORS.neutral300};
  &:first-child {
    border-top: none;
  }
`;

const MenuIconContainer = styled.div`
  padding: 4px;
  width: 24x;
  height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

export const SurveyRatingInputBlock = ({ block, onBlockChange }: IButton) => {
  const blockRef = React.useRef(block);

  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState(-1);

  const innerOnBlockChange: (block: INudgeContentSurveyRatingBlockType) => void = (newBlock) => {
    blockRef.current = newBlock;
    onBlockChange(newBlock);
  };

  const countOptions = {
    stars: [3, 5],
    emojis: [2, 3, 5],
    numbers: [5, 10],
  };

  const emojiDefaults = (count: number): string[] => {
    switch (count) {
      case 3:
        return ['🙁', '🙂', '🤩'];
      case 5:
        return ['😡', '🙁', '🙂', '😀', '🤯'];
      default:
        return Array.from({ length: count }, () => '');
    }
  };

  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.InputContainer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <S.StyledLabel htmlFor="prompt">Rating</S.StyledLabel>

            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <S.OverlayContainer>
                  <FormSection>
                    <SectionHeader>Rating labels</SectionHeader>
                    <TextInput
                      name="upper_label"
                      value={block.meta.lower_label}
                      onChange={(e) => {
                        innerOnBlockChange({
                          type: blockRef.current.type,
                          meta: {
                            ...blockRef.current.meta,
                            lower_label: e.target.value,
                          },
                        });
                      }}
                      placeholder="E.g. Bad"
                      textArea={false}
                    />
                    <TextInput
                      name="upper_label"
                      value={block.meta.upper_label}
                      onChange={(e) => {
                        innerOnBlockChange({
                          type: blockRef.current.type,
                          meta: {
                            ...blockRef.current.meta,
                            upper_label: e.target.value,
                          },
                        });
                      }}
                      placeholder="E.g. Great"
                      textArea={false}
                    />
                  </FormSection>

                  <FormSection>
                    <SectionHeader>{block.meta.type.charAt(0).toUpperCase() + block.meta.type.slice(1)}</SectionHeader>
                    <S.StyledSelect
                      value={block.meta.options}
                      onChange={(e) => {
                        const newOptionsCount = e as number;
                        innerOnBlockChange({
                          type: blockRef.current.type,
                          meta: {
                            ...blockRef.current.meta,
                            options: newOptionsCount,
                            ...(blockRef.current.meta.type === 'emojis'
                              ? { emojis: emojiDefaults(newOptionsCount) }
                              : {}),
                          },
                        });
                      }}
                    >
                      {countOptions[block.meta.type].map((option) => {
                        return (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        );
                      })}
                    </S.StyledSelect>
                  </FormSection>
                </S.OverlayContainer>
              }
            >
              <MenuIconContainer>
                <Settings03 style={{ cursor: 'pointer' }} width={15} />
              </MenuIconContainer>
            </Dropdown>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: '8px',
              alignContent: 'flex-start',
            }}
          >
            <S.StyledSelect
              style={{ width: '-webkit-fill-available' }}
              defaultValue={block.meta.type}
              onChange={(e) => {
                const newType = e as 'stars' | 'numbers' | 'emojis';

                const meta: INudgeContentSurveyRatingBlockType['meta'] = {
                  type: newType,
                  upper_label: blockRef.current.meta.upper_label,
                  lower_label: blockRef.current.meta.lower_label,
                  options: countOptions[newType][0],
                  emojis: [],
                };

                switch (meta.type) {
                  case 'emojis':
                    meta.options = countOptions['emojis'][1];
                    meta.emojis = emojiDefaults(meta.options);
                    break;
                  case 'numbers':
                    meta.options = countOptions['numbers'][0];
                    break;
                  case 'stars':
                    meta.options = countOptions['stars'][1];
                    break;
                  default:
                    break;
                }

                innerOnBlockChange({
                  type: 'survey_rating',
                  meta,
                });
              }}
            >
              <Select.Option value="stars">Stars</Select.Option>
              <Select.Option value="numbers">Numbers</Select.Option>
              <Select.Option value="emojis">Emojis</Select.Option>
            </S.StyledSelect>

            {block.meta.type === 'emojis' && (
              <React.Fragment>
                {[...Array(block.meta.options)].map((_, i) => {
                  return (
                    <Popover
                      key={i}
                      visible={visibleEmojiPicker === i}
                      placement="left"
                      trigger="click"
                      onVisibleChange={(visible) => {
                        setVisibleEmojiPicker(visible ? i : -1);
                      }}
                      content={
                        <Picker
                          autoFocus={true}
                          showSkinTones={false}
                          showPreview={false}
                          emojiSize={20}
                          onSelect={(emoji: BaseEmoji) => {
                            if (blockRef.current.meta.type !== 'emojis') return;

                            innerOnBlockChange({
                              type: blockRef.current.type,
                              meta: {
                                ...blockRef.current.meta,
                                emojis: blockRef.current.meta.emojis.map((e, j) =>
                                  i === j ? (emoji.native as any) : e,
                                ),
                              },
                            });
                            setVisibleEmojiPicker(-1);
                          }}
                          backgroundImageFn={() => emojiImageBackground}
                        />
                      }
                    >
                      <span
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'fit-content' }}
                      >
                        <Button
                          icon={<Icon icon={block.meta.type === 'emojis' ? block.meta.emojis[i] : ''} />}
                          style={{
                            height: 32,
                            width: 32,
                            background: '#fff',
                            border: '1px solid #E6E6E8',
                            color: '#706E78',
                            lineHeight: '14px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </span>
                    </Popover>
                  );
                })}
              </React.Fragment>
            )}
          </div>
        </S.InputContainer>
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
