import styled from '@emotion/styled';
import { Collapse } from 'antd';

export const StickyHeaderCollapsePanel = styled(Collapse.Panel)`
  & {
    .ant-collapse-header {
      position: sticky !important;
      top: 0;
      z-index: 1;
    }
  }
`;
