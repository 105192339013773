import { useEffect, useState } from 'react';

import Sender from '../management/Sender';

import { isStandaloneEditorURL } from '@commandbar/internal/util/location';

const useIsStandaloneEditor = () => {
  const [isStandaloneEditor, setIsStandaloneEditor] = useState<boolean>();

  useEffect(() => {
    Sender.getHostUrl().then(({ url }) => {
      setIsStandaloneEditor(isStandaloneEditorURL(url));
    });
  }, [setIsStandaloneEditor]);

  return isStandaloneEditor;
};

export default useIsStandaloneEditor;
