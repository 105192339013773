/* React imports */
import * as React from 'react';

import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from 'antd';

const AutoComplete = (props: AutoCompleteProps) => {
  // Enables filtering by default using filterOption=true
  return <AntdAutoComplete dropdownClassName="commandbar-select-dropdown" filterOption {...props} />;
};
export default AutoComplete;
