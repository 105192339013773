import type { INudgeStepType } from '@commandbar/internal/middleware/types';
import type { TOption } from '../NudgeDropdown';

export type TNudgeOffset = {
  x: string;
  y: string;
};
export type FormFactorType = INudgeStepType['form_factor']['type'];
export type PopoverPosition = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'center';

type FormFactorSelectOption = TOption<PopoverPosition>;

type FormFactorSelectInput = {
  type: 'select';
  options: FormFactorSelectOption[];
};

type FormFactorTargetInput = {
  type: 'target';
};

type FormFactorInputMetadata = FormFactorSelectInput | FormFactorTargetInput;

export const POSITION_LABEL: Record<PopoverPosition, string> = {
  'top-left': 'Top Left',
  'top-right': 'Top Right',
  'bottom-left': 'Bottom Left',
  'bottom-right': 'Bottom Right',
  center: 'Center',
};

export const FORM_FACTOR_INPUT_META: Record<FormFactorType, FormFactorInputMetadata> = {
  modal: {
    type: 'select',
    options: [
      {
        label: POSITION_LABEL['top-right'],
        value: 'top-right',
        disabled: true,
      },
      {
        label: POSITION_LABEL['bottom-right'],
        value: 'bottom-right',
        disabled: true,
      },
      {
        label: POSITION_LABEL['bottom-left'],
        value: 'bottom-left',
        disabled: true,
      },
      {
        label: POSITION_LABEL['top-left'],
        value: 'top-left',
        disabled: true,
      },
      {
        label: POSITION_LABEL['center'],
        value: 'center',
        disabled: false,
      },
    ],
  },
  popover: {
    type: 'select',
    options: [
      {
        label: POSITION_LABEL['top-right'],
        value: 'top-right',
        disabled: false,
      },
      {
        label: POSITION_LABEL['bottom-right'],
        value: 'bottom-right',
        disabled: false,
      },
      {
        label: POSITION_LABEL['bottom-left'],
        value: 'bottom-left',
        disabled: false,
      },
      {
        label: POSITION_LABEL['top-left'],
        value: 'top-left',
        disabled: false,
      },
      {
        label: POSITION_LABEL['center'],
        value: 'center',
        disabled: false,
      },
    ],
  },
  pin: {
    type: 'target',
  },
};
