import { Button as AntButton } from 'antd';
import styled from '@emotion/styled';
import { CB_COLORS } from './antd/colors';

const Button: typeof AntButton = styled(AntButton)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: revert;
    width: revert;
    color: ${(props: any) => props.ghost && 'rgba(0, 0, 0, 0.5)'};
    border: ${(props: any) => props.ghost && '0px'};
    box-shadow: ${(props: any) => {
      if (props.ghost) return 'none';
      else if (props.icon) return '0px 1px 0px rgba(0, 0, 0, 0.1)';
    }};

    // FIXME: Primary color still not working
    &:hover {
      color: ${(props: any) => props.ghost && `${CB_COLORS.primary}`};
    }

    .anticon {
      font-size: 18px;
      line-height: 12px;
    }
  }
` as unknown as typeof AntButton;

export default Button;
