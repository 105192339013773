/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';

import { Card, message } from 'antd';

import { CopyTwoTone } from '@ant-design/icons';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import './styles/code.css';
import Button from './Button';
import { atomOneDark as dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('javascript', javascript);

interface IProps {
  content: string;
  id?: string;
  background?: string;
  isDarkTheme?: boolean;
  title?: string;
}

const Code = (props: IProps) => {
  const language = 'javascript';

  const copyCodeToClipboard = () => {
    if (props.content) {
      const element = document.createElement('textarea');
      element.value = props.content;
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
      message.info('Code copied to clipboard.');
    }
  };

  const regularTheme = (
    <Card
      id={props.id}
      style={{ backgroundColor: props.background || '#f2f2f2', border: '0px' }}
      bodyStyle={{ paddingTop: 6, paddingBottom: 6 }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <span style={{ fontStyle: 'italic', marginRight: 20, marginTop: 5 }}>Javascript</span>
        <Button type="link" onClick={copyCodeToClipboard} style={{ padding: 0 }}>
          <CopyTwoTone />
        </Button>
      </div>
      <SyntaxHighlighter language={language} useInlineStyles={false} wrapLines={true} showLineNumbers={true}>
        {props.content}
      </SyntaxHighlighter>
    </Card>
  );

  const darkTheme = (
    <Card id={props.id} style={{ border: '0px', marginBottom: '24px' }} bodyStyle={{ padding: 0, borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 14,
          backgroundColor: '#4f566b',
          padding: '12px 20px 10px 20px',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <div style={{ color: '#ccc' }}>{props.title}</div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 14, color: '#ccc' }}>{language.toUpperCase()}</div>
          <Button
            type="link"
            onClick={copyCodeToClipboard}
            style={{ padding: '0 5px', fontWeight: 700, background: 'white', display: 'flex', placeItems: 'center' }}
          >
            COPY
          </Button>
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'rgb(40, 44, 52)',
          padding: '12px 0px',
          borderRadius: '0px 0px 8px 8px',
        }}
      >
        <SyntaxHighlighter
          language={language}
          useInlineStyles={true}
          style={dark}
          wrapLines={true}
          showLineNumbers={true}
          lineNumberStyle={{ marginRight: 10, opacity: 0.4, marginLeft: 15 }}
        >
          {props.content}
        </SyntaxHighlighter>
      </div>
    </Card>
  );

  return <>{props.isDarkTheme ? darkTheme : regularTheme}</>;
};

export default Code;
