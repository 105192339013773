import { InputNumber } from '@commandbar/design-system/components';
import React from 'react';

interface ILauncherOffsetInput {
  value: number;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  title: string;
}

export const LauncherOffsetInput = ({ value, onBlur, title }: ILauncherOffsetInput) => {
  return (
    <InputNumber addonBefore={title} addonAfter="px" defaultValue={value} onBlur={onBlur} step={10} controls={false} />
  );
};
