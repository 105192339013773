import React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import * as S from '../styled';
import { Trash04, Lock01 } from '@commandbar/design-system/icons/react';
import {
  FormFactorDropdown,
  FORM_FACTORS,
  IconContainer as FormFactorIconContainer,
  DISPLAY_VALUE_GAP,
} from './FormFactorDropdown';
import { FormFactorPositionInput } from './FormFactorPositionInput';
import { PopoverPosition, TNudgeOffset } from './FormFactorPositionInput/metadata';
import { SortableHandle } from 'react-sortable-hoc';
import Sender from '../../../management/Sender';
import { useAppContext } from '../../../Widget';

import { CB_COLORS } from '@commandbar/design-system/components';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';
import { Tooltip } from '../../../shared_components';
import { ReactComponent as ElementNotFoundSvg } from '../../../img/element_not_found.svg';
import { OverlayDisplayValue } from '../../components/styled';

const Container = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${CB_COLORS.white};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled(OverlayDisplayValue)`
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
`;

const ElementNotFoundIcon = styled(ElementNotFoundSvg)`
  width: 16px;
  color: #a36a00 !important;
`;

type FormFactor = INudgeStepType['form_factor'];
type FormFactorType = INudgeStepType['form_factor']['type'];

export interface IHeader {
  expanded?: boolean;
  title: string;
  value: FormFactor;
  onUpdate: (formFactor: FormFactor) => void;
  onDelete?: () => void;
  locked?: boolean;
}

export const Header = ({ expanded, title, value, onDelete, onUpdate, locked }: IHeader) => {
  const { Icon: FormFactorIcon, label: FormFactorTypeLabel } = FORM_FACTORS[value.type];
  const [mostRecentAnchor, setMostRecentAnchor] = React.useState<string>(value.type === 'pin' ? value.anchor : '');
  const appContext = useAppContext();

  const onFormFactorChange = (type: FormFactorType) => {
    switch (type) {
      case 'modal': {
        return onUpdate({ type });
      }
      case 'popover': {
        return onUpdate({ type, position: 'center' });
      }
      case 'pin': {
        return onUpdate({
          type,
          anchor: mostRecentAnchor,
          is_open_by_default: true,
          offset: {
            x: '0px',
            y: '0px',
          },
        });
      }
    }
  };

  const onFormFactorPositionChange = (position: PopoverPosition) => {
    if (value.type === 'popover')
      onUpdate({
        ...value,
        position,
      });
  };

  const onFormFactorAnchorChange = (anchor: string) => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        anchor,
      });

      setMostRecentAnchor(anchor);
    }
  };

  const onToggleIsOpenByDefault = () => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        is_open_by_default: !value.is_open_by_default,
      });
    }
  };

  const onOffsetChange = (offset: TNudgeOffset) => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        offset,
      });
    }
  };

  const [selectorState, setSelectorState] = useState<'ok' | 'invisible' | 'not_found'>('ok');

  useEffect(() => {
    if (value.type !== 'pin' || value.anchor === '') {
      setSelectorState('ok');
    } else {
      Sender.checkIfSelectorValid(value.anchor).then((result) => {
        if (result.elementFound && result.elementVisible) {
          setSelectorState('ok');
        } else if (result.elementFound && !result.elementVisible) {
          setSelectorState('invisible');
        } else {
          setSelectorState('not_found');
        }
      });
    }
  }, [value]);

  return (
    <Container>
      {expanded ? (
        <FormFactorDropdown value={value.type} onChange={onFormFactorChange} />
      ) : (
        <TitleRow gap={DISPLAY_VALUE_GAP}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px',
            }}
          >
            <FormFactorIconContainer>
              <FormFactorIcon />
            </FormFactorIconContainer>
            <div>{title === '' ? FormFactorTypeLabel : title}</div>
          </div>
          {selectorState !== 'ok' && !appContext?.isStandaloneEditor && (
            <Tooltip
              placement="bottom"
              content={
                selectorState === 'invisible'
                  ? 'Target element found on this page, but is hidden'
                  : 'Target element not found on this page'
              }
            >
              <ElementNotFoundIcon style={{ marginTop: '8px' }} />
            </Tooltip>
          )}
        </TitleRow>
      )}

      <Row style={{ gap: '8px' }}>
        {locked && (
          <Tooltip
            content={
              "A response was already collected for this step's survey. Therefore, it can't be edited anymore. Create a new step to ask a new question."
            }
          >
            <Lock01 width={16} style={{ marginRight: '8px', boxSizing: 'border-box', paddingTop: '8px' }} />
          </Tooltip>
        )}

        {expanded && (
          <FormFactorPositionInput
            type={value.type}
            onChange={onFormFactorPositionChange}
            positionValue={value.type === 'popover' ? value.position : 'center'}
            onAnchorChange={onFormFactorAnchorChange}
            anchorValue={value.type === 'pin' ? value.anchor : ''}
            onToggleIsOpenByDefault={onToggleIsOpenByDefault}
            isOpenByDefault={
              value.type === 'pin' ? (value.is_open_by_default === undefined ? true : value.is_open_by_default) : true
            }
            onOffsetChange={onOffsetChange}
            offsetValue={
              value.type === 'pin'
                ? value.offset === undefined
                  ? { x: '0px', y: '0px' }
                  : value.offset
                : { x: '', y: '' }
            }
          />
        )}
        {!!onDelete ? (
          <S.DeleteIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <Trash04 width={16} />
          </S.DeleteIconContainer>
        ) : null}
      </Row>
    </Container>
  );
};

export const SortableHeader = SortableHandle(Header);
