import styled from '@emotion/styled';

const PaddingContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default PaddingContainer;
