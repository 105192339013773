import React from 'react';
import { Row, Col, Typography } from 'antd';
import { InfoCircleFilled, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import Tooltip from './Tooltip';
import styled from '@emotion/styled';
import { Tag } from './Tag';
import { CB_COLORS } from '@commandbar/design-system/components';

interface IProps {
  title: string | React.ReactNode;
  subtitle?: string;
  input: React.ReactNode;
  info?: string | React.ReactNode;
  tag?: string | React.ReactNode;
  gutter?: [number, number];
  overflow?: string;
  keepTooltipSpace?: boolean;
  description?: string | React.ReactNode;
  required?: boolean;
  satisfied?: boolean;
  interactive?: boolean;
  align?: 'middle' | 'top' | 'bottom' | 'stretch';
  prefixedInfo?: boolean;
  pushInputIntoNextLine?: boolean;
}

const Description = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.65);
  flex: 1 1 auto;
`;

const FormRow = (props: IProps) => {
  const { overflow = '' } = props;

  const DEFAULT_GUTTER: [number, number] = [8, 16];

  const tooltip = !!props.info && (
    <Tooltip content={props.info} placement="bottom" interactive={props.interactive}>
      {!!props.required && !props.satisfied ? (
        <WarningOutlined style={{ fontSize: 16, color: 'red' }} />
      ) : props.prefixedInfo ? (
        <InfoCircleFilled style={{ color: '#A6A5AE' }} />
      ) : (
        <InfoCircleOutlined style={{ fontSize: 16 }} />
      )}
    </Tooltip>
  );

  const tag =
    typeof props.tag === 'string' ? (
      <Tag style={{ fontSize: '10px', padding: '0 0.5em', textTransform: 'none' }} color={CB_COLORS.primary}>
        {props.tag}
      </Tag>
    ) : (
      props.tag
    );

  const requiredWarning = !props.satisfied && (
    <Tooltip content={'Field is required.'} placement="bottom">
      <WarningOutlined style={{ fontSize: 16, color: 'red' }} />
    </Tooltip>
  );

  const showTooltipContainer = !props.prefixedInfo && (props.keepTooltipSpace || !!props.info);
  const showPrefixTooltipContainer = props.prefixedInfo && !!props.info;

  const gutter = props.gutter || DEFAULT_GUTTER;
  return (
    <>
      <Row
        gutter={gutter}
        style={
          /* Newer versions antd changed vertical gutter behavior; I filed https://github.com/ant-design/ant-design/issues/34342 to track.
             This hack preserves the old vertical gutter behavior. */
          gutter[1] !== undefined ? { marginBottom: gutter[1] } : undefined
        }
        justify="center"
        align={props.align || 'middle'}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
            rowGap: 0,
            fontWeight: 600,
            flexWrap: 'wrap',
          }}
          span={props.pushInputIntoNextLine ? 24 : 6}
        >
          {props.title}
          {showPrefixTooltipContainer ? (
            <span style={{ width: 16, height: 16, marginRight: 16, display: 'contents' }}>{tooltip}</span>
          ) : (
            props.required && (
              <span style={{ width: 16, height: 16, marginRight: 16, display: 'contents' }}>{requiredWarning}</span>
            )
          )}
          {!!props.subtitle && <Typography.Text type={'secondary'}>{props.subtitle}</Typography.Text>}
        </Col>
        <Col span={props.pushInputIntoNextLine ? 24 : 18}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flexGrow: 1, overflow: overflow, minWidth: 0 }}>{props.input}</span>
            {tag}
            {showTooltipContainer ? (
              <span style={{ width: 16, height: 16, marginLeft: 24, marginRight: 16 }}>{tooltip}</span>
            ) : (
              props.required && (
                <span style={{ width: 16, height: 16, marginLeft: 24, marginRight: 16 }}>{requiredWarning}</span>
              )
            )}
          </span>
        </Col>
        {props.description && (
          <>
            <Col span={6} />
            <Col span={18}>
              <span style={{ alignItems: 'center' }}>
                <Description>{props.description}</Description>
                {showTooltipContainer && <div style={{ width: 16, height: 16, marginLeft: 24, marginRight: 16 }} />}
              </span>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default FormRow;
