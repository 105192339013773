import React from 'react';
import styled from '@emotion/styled';
import { IntersectSquare } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components';
import { TNudgeOffset } from '../metadata';
import { useAppContext } from '../../../../../Widget';
import * as S from './styles';
import { TextInput } from './TextInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px;
`;

const Label = styled.div`
  color: ${CB_COLORS.neutral500};
  text-transform: uppercase;
`;

interface IPositionOffsetForm {
  onChange: (offset: TNudgeOffset) => void;
  x: string;
  y: string;
}

export const PositionOffsetForm = ({ x, y, onChange }: IPositionOffsetForm) => {
  const { isStandaloneEditor } = useAppContext();
  const preventEventBubbling = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Container onClick={preventEventBubbling}>
      <S.SectionHeader>
        <S.IconContainer visible>
          <IntersectSquare width={14} />
        </S.IconContainer>
        Target Offset
      </S.SectionHeader>
      <S.Row style={{ gap: '8px' }}>
        <TextInput
          addonBefore={<Label onClick={preventEventBubbling}>Y</Label>}
          value={y}
          placeholder="0px"
          disabled={isStandaloneEditor}
          onChange={(e) => {
            preventEventBubbling(e);
            onChange({
              x,
              y: e.target.value,
            });
          }}
          textArea={false}
        />
        <TextInput
          addonBefore={<Label onClick={preventEventBubbling}>X</Label>}
          value={x}
          placeholder="0px"
          disabled={isStandaloneEditor}
          onChange={(e) => {
            preventEventBubbling(e);
            onChange({
              x: e.target.value,
              y,
            });
          }}
          textArea={false}
        />
      </S.Row>
    </Container>
  );
};
