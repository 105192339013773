// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Settings01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.6236 15.6005a1.5005 1.5005 0 0 1 .1037-.8732 1.4995 1.4995 0 0 1 1.3727-.9091h.0818a1.8183 1.8183 0 0 0 0-3.6364h-.1545a1.4992 1.4992 0 0 1-1.3728-.909V9.2a1.4999 1.4999 0 0 1 .3-1.6545l.0546-.0546a1.8189 1.8189 0 0 0 .3946-1.9826 1.8189 1.8189 0 0 0-.3946-.5901 1.8188 1.8188 0 0 0-1.2864-.5333 1.818 1.818 0 0 0-1.2863.5333l-.0546.0545a1.4998 1.4998 0 0 1-1.6545.3A1.4997 1.4997 0 0 1 13.8182 3.9v-.0818a1.8182 1.8182 0 0 0-3.6364 0v.1545a1.4992 1.4992 0 0 1-.909 1.3727H9.2a1.5 1.5 0 0 1-1.6545-.3l-.0546-.0545a1.818 1.818 0 0 0-2.5727 0 1.8181 1.8181 0 0 0 0 2.5727l.0545.0546a1.5 1.5 0 0 1 .3 1.6545 1.5 1.5 0 0 1-1.3727.9818h-.0818a1.8182 1.8182 0 0 0 0 3.6364h.1545a1.5005 1.5005 0 0 1 1.3727.9091 1.5001 1.5001 0 0 1-.3 1.6545l-.0545.0546a1.8186 1.8186 0 0 0-.3946 1.9826c.0915.2207.2256.4213.3946.5901a1.8174 1.8174 0 0 0 1.2864.5333 1.8182 1.8182 0 0 0 1.2863-.5333l.0546-.0545a1.5002 1.5002 0 0 1 1.6545-.3 1.4995 1.4995 0 0 1 .9818 1.3727v.0818a1.8183 1.8183 0 0 0 3.6364 0v-.1545a1.5005 1.5005 0 0 1 .9091-1.3728 1.5 1.5 0 0 1 1.6545.3l.0546.0546a1.8195 1.8195 0 0 0 1.2864.5332 1.818 1.818 0 0 0 1.2863-.5332 1.8181 1.8181 0 0 0 0-2.5727l-.0545-.0546a1.5 1.5 0 0 1-.4037-.7813ZM15 12c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3Z"
      clipRule="evenodd"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.7273 14.7273a1.5 1.5 0 0 0 .3 1.6545l.0545.0546a1.818 1.818 0 0 1 0 2.5727 1.8184 1.8184 0 0 1-2.5727 0l-.0546-.0546a1.5003 1.5003 0 0 0-1.6545-.3 1.5001 1.5001 0 0 0-.9091 1.3728v.1545a1.8182 1.8182 0 1 1-3.6364 0V20.1a1.4996 1.4996 0 0 0-.9818-1.3727 1.5002 1.5002 0 0 0-1.6545.3l-.0546.0545a1.8176 1.8176 0 0 1-1.9826.3947 1.8174 1.8174 0 0 1-.59-.3947 1.8176 1.8176 0 0 1-.5333-1.2863 1.818 1.818 0 0 1 .5332-1.2864l.0545-.0546a1.4996 1.4996 0 0 0 .3-1.6545 1.4996 1.4996 0 0 0-1.3727-.9091h-.1545a1.818 1.818 0 0 1-1.2857-3.1038 1.8182 1.8182 0 0 1 1.2857-.5326H3.9a1.4997 1.4997 0 0 0 1.3727-.9818 1.5 1.5 0 0 0-.3-1.6545l-.0545-.0546a1.8182 1.8182 0 0 1 0-2.5727 1.818 1.818 0 0 1 2.5727 0l.0545.0545a1.5 1.5 0 0 0 1.6546.3h.0727a1.5 1.5 0 0 0 .9091-1.3727v-.1545a1.8182 1.8182 0 0 1 3.6364 0V3.9a1.4997 1.4997 0 0 0 .9091 1.3727 1.4998 1.4998 0 0 0 1.6545-.3l.0546-.0545a1.8167 1.8167 0 0 1 1.2863-.5333 1.818 1.818 0 0 1 1.2864.5333 1.8189 1.8189 0 0 1 .3946 1.9826 1.8189 1.8189 0 0 1-.3946.5901l-.0546.0545a1.5007 1.5007 0 0 0-.3 1.6546v.0727a1.5004 1.5004 0 0 0 1.3728.9091h.1545a1.8183 1.8183 0 0 1 0 3.6364H20.1a1.4995 1.4995 0 0 0-1.3727.9091Z"
    />
  </svg>
);
export default Settings01;
