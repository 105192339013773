import React from 'react';
import styled from '@emotion/styled';

import { File05, Globe01, PlusCircle, Lightning01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components';

import { ReactComponent as VideoCover } from '../../img/video_cover.svg';
import {
  DashboardCardBase,
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
} from '@commandbar/design-system/components';

export const GettingStartedBanner = ({
  setIsCustomDocModalVisible,
  setIsAddSiteModalVisible,
}: {
  setIsCustomDocModalVisible: (val: boolean) => void;
  setIsAddSiteModalVisible: (val: boolean) => void;
}) => {
  return (
    <>
      <DashboardCardBase
        styles={{
          height: '330px',
          iconShadow:
            '0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(160, 87, 253, 0.16), inset 1.5px -1.5px 3px rgba(160, 87, 253, 0.25);',
          background: 'linear-gradient(99.43deg, #f4ebff 6.02%, #ffffff 26.86%)',
        }}
        icon={<Lightning01 width={DASHBOARD_ICON_WIDTH} color="#a057fd" />}
        title="AI-powered Help"
        description={
          <>
            <GettingStartedDescription>Add AI chat and rich help docs to your app</GettingStartedDescription>
            <VideoBox href="https://www.youtube.com/watch?v=nefAUrtFfbc" target="_blank" rel="noreferrer">
              <VideoCover style={{ height: '58px', width: '82px', marginTop: '8px', marginLeft: '-1px' }} />
              <div>See how it works</div>
            </VideoBox>
          </>
        }
        actions={[
          {
            icon: <PlusCircle width={DASHBOARD_ACTION_ICON_WIDTH} />,
            label: 'Connect help center',
            onClick: () => {
              window?.open('https://app.commandbar.com/integrations', '_blank')?.focus();
            },
          },
          {
            icon: <Globe01 width={DASHBOARD_ACTION_ICON_WIDTH} />,
            label: 'Add site URL',
            onClick: () => {
              setIsAddSiteModalVisible(true);
            },
          },
          {
            icon: <File05 width={DASHBOARD_ACTION_ICON_WIDTH} />,
            label: 'Add custom doc',
            onClick: () => {
              setIsCustomDocModalVisible(true);
            },
          },
        ]}
      />
    </>
  );
};

const VideoBox = styled.a`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral800};
  height: 48px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  width: 225px;
  gap: 16px;

  border-color: ${CB_COLORS.neutral300};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  transform: scale(1);
  transition: all 0.25s;

  &:hover {
    border-color: ${CB_COLORS.neutral500};
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.24);
    transform: scale(1.025);
    transition: all 0.25s;
  }
`;

const GettingStartedDescription = styled.div`
  padding-bottom: 16px;
`;

export default GettingStartedBanner;
