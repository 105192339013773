import React from 'react';
import chroma from 'chroma-js';
import { Tag } from '../../shared_components';

function stringToFunColor(str: string, prc?: number) {
  // Generate a Hash for the String
  const hash = (word: string) => {
    let h = 0;
    for (let i = 0; i < word.length; i++) {
      h = word.charCodeAt(i) + ((h << 5) - h);
    }
    return h;
  };

  // Generate a shade based with a darkness(prc) factor
  const shade = (color: string, prc: number) => {
    const num = parseInt(color, 16),
      amt = Math.round(2.55 * prc),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1);
  };

  // Convert init to an RGBA
  const intToRGBA = (i: number) => {
    const color =
      ((i >> 24) & 0xff).toString(16) +
      ((i >> 16) & 0xff).toString(16) +
      ((i >> 8) & 0xff).toString(16) +
      (i & 0xff).toString(16);
    return color;
  };

  return chroma(shade(intToRGBA(hash(str)), prc || -35)).hex();
}

const MethodTag = ({ str }: { str: string }) => {
  return (
    <Tag color={stringToFunColor(str)} style={{ minWidth: 100, textAlign: 'center' }}>
      {str}
    </Tag>
  );
};

export default MethodTag;
