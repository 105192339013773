import React, { ReactNode } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Container, Subtitle, InnerContainer, SquareButton, Title, TitleContainer, ActionContainer } from './styled';

interface IProps {
  title?: ReactNode;
  titlePlaceholder?: string;
  subTitle?: string;
  actions?: ReactNode[];
  onBackPress?(): void;
}

export const CommandDetailsHeader = ({ actions = [], title, titlePlaceholder, subTitle, onBackPress }: IProps) => {
  return (
    <Container>
      <Subtitle>{subTitle}</Subtitle>
      <InnerContainer>
        <SquareButton
          onClick={() => {
            if (onBackPress) {
              onBackPress();
            }
          }}
        >
          <ArrowLeftOutlined />
        </SquareButton>

        <TitleContainer>
          <Title placeholder={!title}>{title ? title : titlePlaceholder}</Title>
        </TitleContainer>

        {actions.map((action, index) => (
          <ActionContainer key={`header-action-${index}`}>{action}</ActionContainer>
        ))}
      </InnerContainer>
    </Container>
  );
};
