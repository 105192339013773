import React, { ReactNode, useState } from 'react';
import { Select } from '../../../../shared_components/Select';

import { DownOutlined } from '@ant-design/icons';

import { ITemplate } from '@commandbar/internal/middleware/types';
import ActionTypeSelectorOption from './components/ActionTypeSelectorOption';
import OptionWrapper from './components/OptionWrapper';
import { CurrentItemContainer, GroupHeading, IconContainer, Container } from './styled';

import {
  ApiOutlined,
  CodeOutlined,
  DeploymentUnitOutlined,
  FunctionOutlined,
  LinkOutlined,
  SelectOutlined,
  InteractionOutlined,
  PlaySquareOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { ZapFast } from '@commandbar/design-system/icons/react';

export type SelectorOption = {
  value: ITemplate['type'];
  label: string;
  description: string;
  icon: ReactNode;
  color: string;
  deprecated?: boolean;
};

const selectorOptions: SelectorOption[] = [
  {
    value: 'link',
    label: 'Link',
    description: 'Open a static or dynamic link.',
    icon: <LinkOutlined style={{ fontSize: 20 }} />,
    color: '#177ddc',
  },
  {
    value: 'callback',
    label: 'Callback',
    description: 'Trigger a function provided to CommandBar.',
    icon: <CodeOutlined style={{ fontSize: 20 }} />,
    color: '#49aa19',
  },
  {
    value: 'video',
    label: 'Video',
    description: 'Embed a video.',
    icon: <PlaySquareOutlined style={{ fontSize: 20 }} />,
    color: '#1ee5bd',
  },
  {
    value: 'helpdoc',
    label: 'Help Document',
    description: 'Embed an HTML help document.',
    icon: <FileTextOutlined style={{ fontSize: 20 }} />,
    color: '#e54c21',
  },
  {
    value: 'click',
    label: 'Click',
    description: `Simulate clicks in the user's DOM.`,
    icon: <SelectOutlined style={{ fontSize: 20 }} />,
    color: '#d89614',
  },
  {
    value: 'trigger',
    label: 'Trigger',
    description: 'Trigger a nudge or questlist.',
    icon: <ZapFast width={20} height={20} />,
    color: '#ff4a00',
  },
  {
    value: 'request',
    label: 'Web Request',
    description: 'Make HTTP requests.',
    icon: <InteractionOutlined style={{ fontSize: 20 }} />,
    color: '#b19cd9',
  },
  /** Deprecated command types.
   * Leave them here for labels & icons for old commands that have these types
   */
  {
    value: 'webhook',
    label: 'Webhook',
    description: `Post to a webook.`,
    icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} />,
    color: '#ff4a00',
    deprecated: true,
  },
  {
    value: 'script',
    label: 'Script',
    description: `Run a JS script.`,
    icon: <FunctionOutlined style={{ fontSize: 20 }} />,
    color: '#b19cd9',
    deprecated: true,
  },
  {
    value: 'appcues',
    label: 'Appcues',
    description: `Trigger an Appcues flow`,
    icon: <ApiOutlined style={{ fontSize: 20 }} />,
    color: '#5757f5',
    deprecated: true,
  },
];

type Props = {
  templateType: string;
  onCommandTypeChange: (nextType: ITemplate['type']) => void;
  isDisabled?: boolean;
};

const ActionTypeSelector = ({ templateType, onCommandTypeChange, isDisabled }: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const current: SelectorOption | undefined = selectorOptions.find((obj) => {
    let value = templateType;
    if (['click', 'clickBySelector', 'clickByXpath'].includes(templateType)) {
      value = 'click';
    }

    return obj.value === value;
  });

  if (!current) {
    throw new Error('Command type not found.');
  }

  const options: SelectorOption[] = selectorOptions.filter((o) => !o.deprecated);

  const handleChange = (option: SelectorOption) => {
    onCommandTypeChange(option.value);
    setDropdownVisible(false);
  };

  return (
    <Container tabIndex={0} onBlur={() => setDropdownVisible(false)}>
      <CurrentItemContainer
        dropdownVisible={dropdownVisible}
        onClick={() => {
          if (!isDisabled) {
            setDropdownVisible((prevState) => !prevState);
          }
        }}
      >
        <ActionTypeSelectorOption
          label={current.label}
          description={current.description}
          icon={current.icon}
          color={current.color}
          noBorder
        />
        <IconContainer>
          <DownOutlined rotate={dropdownVisible ? 180 : 0} />
        </IconContainer>
      </CurrentItemContainer>

      {dropdownVisible && (
        <Select
          value={current}
          options={options}
          components={{
            GroupHeading: (props) => <GroupHeading children={props.children} />,
            Option: OptionWrapper,
            Control: () => null,
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          isDisabled={isDisabled}
          menuIsOpen
          menuPlacement="bottom"
          captureMenuScroll={false}
          onChange={(value) => {
            if (value) {
              handleChange(value);
            }
          }}
          onBlur={() => setDropdownVisible(false)}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 5,
              margin: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              overflow: 'hidden',
              border: '1px solid #e6e6e8',
              borderTopWidth: 0,
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: 'none', // remove this to enable scroll once we add more options
              padding: 0,
            }),
            group: (provided) => ({
              ...provided,
              padding: 0,
            }),
          }}
        />
      )}
    </Container>
  );
};
export default ActionTypeSelector;
