import React, { useCallback, useEffect } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { useAsyncCallback } from '@commandbar/internal/util/useAsyncCallback';

import {
  Alert,
  Tooltip,
  Typography,
  Header,
  Input,
  Empty,
  Button,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
} from '../../shared_components';
import { useAppContext } from '../../Widget';

import { IChecklist, IEditorCommandType, INudgeType } from '@commandbar/internal/middleware/types';
import { defaults, getConditions, INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import { defaultConditionType } from '../commands/CommmandDetail/ConditionRulePanels/types';

import RuleExpressionEditor from '../commands/CommmandDetail/ConditionRulePanels/components/RuleExpressionEditor';
import { isEveryConditionRuleValid, mkNewRule } from '../commands/CommmandDetail/ConditionRulePanels/helpers';
import { useReportEvent } from '../../shared_components/useEventReporting';

type AsyncClickButtonProps = Omit<React.ComponentProps<typeof Button>, 'onClick'> & {
  onClick: () => Promise<void>;
  onError?: (e: any) => void;
};

/*
 * A Button that displays a loading spinner while the async onClick function is running
 */
const AsyncClickButton = (props: AsyncClickButtonProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const unmounted = React.useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const onClick = async () => {
    try {
      setLoading(true);
      await props.onClick();
    } catch (e) {
      if (props.onError) props.onError(e);
      else throw e;
    } finally {
      if (!unmounted.current) setLoading(false);
    }
  };

  return <Button {...props} onClick={onClick} loading={loading ? { delay: 250 } : undefined} />;
};

const EditingRule = ({
  initialRule,
  onCancel,
  onSave,
  isDuplicateName,
  allowAddingAndRemovingRules = true,
}: {
  initialRule: INamedRule;
  onCancel: () => void;
  onSave: (rule: INamedRule) => Promise<void>;
  isDuplicateName: (rule: INamedRule) => boolean;
  allowAddingAndRemovingRules?: boolean;
}) => {
  const [rule, setRule] = React.useState<INamedRule>(initialRule);

  const conditions = getConditions(rule.expression);
  const valid =
    conditions.length &&
    isEveryConditionRuleValid(conditions) &&
    rule.name !== '' &&
    !(isDuplicateName && isDuplicateName(rule));

  const save = useCallback(async () => {
    if (valid) await onSave(rule);
  }, [valid, rule, onSave]);

  const [containerNode, setContainerNode] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!containerNode) return;

    const handleUserKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        onCancel();
      }
      if (event.key === 'Enter' || event.keyCode === 13) {
        save();
      }
    };

    containerNode.addEventListener('keydown', handleUserKeyPress);
    return () => {
      containerNode.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [save, onCancel, containerNode]);

  const setName = (name: string) => setRule((rule) => rule && { ...rule, name });

  return (
    <div ref={setContainerNode}>
      <div style={{ display: 'flex', columnGap: 8 }}>
        <Input
          autoFocus
          placeholder="<Audience name e.g. 'Paid Subscribers'>"
          value={rule.name}
          onChange={(e) => setName(e.target.value)}
        />
        <AsyncClickButton onClick={save} disabled={!valid} type="primary">
          Save
        </AsyncClickButton>
      </div>
      <RuleExpressionEditor
        categories={['Activity', 'Custom', 'Integrations']}
        onChange={(expr) => {
          setRule((rule) => ({ ...rule, expression: expr }));
        }}
        expr={rule.expression}
        disabled={!allowAddingAndRemovingRules}
        omitNamedRuleType
      />
    </div>
  );
};

const Rule = ({
  rule,
  isDuplicateName,
  entitiesUsingThisRule = { nudges: [], checklists: [], commands: [] },
  onSave,
  onDelete,
  onEditing,
  initialEditing = false,
  allowAddingAndRemovingRules = true,
}: {
  rule: Partial<INamedRule>;
  isDuplicateName: (rule: INamedRule) => boolean;
  entitiesUsingThisRule?: { nudges: INudgeType[]; checklists: IChecklist[]; commands: IEditorCommandType[] };
  initialEditing?: boolean;
  allowAddingAndRemovingRules?: boolean;
  onSave: (rule: INamedRule) => Promise<void>;
  onDelete?: () => Promise<void>;
  onEditing?: (editing: boolean) => void;
}) => {
  const { expression = { type: 'AND', exprs: [] }, name } = rule;
  const [error, setError] = React.useState<string | null>(null);
  const [editing, setEditing] = React.useState<boolean>(initialEditing);

  const onSaveRule = useAsyncCallback(function* (rule: INamedRule) {
    try {
      yield onSave(rule);
      setError(null);
    } catch (e) {
      setError(String(e));
      throw e;
    }
    setEditing(false);
  });

  const numEntitiesUsingThisRule = Object.values(entitiesUsingThisRule)
    .map((entities) => entities.length)
    .reduce((a, b) => a + b, 0);

  const entitiesUsingThisRuleTitles = [
    ...entitiesUsingThisRule.commands.map((c) => `${c.text} (command)`),
    ...entitiesUsingThisRule.nudges.map((n) => `${n.slug} (nudge)`),
    ...entitiesUsingThisRule.checklists.map((c) => `${c.title} (checklist)`),
  ];

  useEffect(() => {
    onEditing && onEditing(editing);
  }, [onEditing, editing]);

  const isBuiltIn = typeof rule.id === 'string';

  if (!editing) {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ flex: 1, cursor: !isBuiltIn ? 'pointer' : 'not-allowed' }}
            onClick={!isBuiltIn ? () => setEditing(true) : undefined}
          >
            <Typography.Text>{name || '<name>'}</Typography.Text> {!isBuiltIn && <EditOutlined />}
          </div>
          {numEntitiesUsingThisRule > 0 ? (
            <Tooltip placement="top" content={'in use by: ' + entitiesUsingThisRuleTitles.join(', ')}>
              <Typography.Text type="secondary" style={{ cursor: 'help', marginRight: 12 }} italic>
                <div>
                  <Button disabled icon={<DeleteOutlined />} />
                </div>
              </Typography.Text>
            </Tooltip>
          ) : (
            onDelete && (
              <div>
                <AsyncClickButton onClick={onDelete} icon={<DeleteOutlined />} />
              </div>
            )
          )}
        </div>
        <RuleExpressionEditor expr={expression} disabled />
      </div>
    );
  }

  return (
    <>
      {error && (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Error saving rule:
              <Typography.Text code>{error.slice(0, 100)}</Typography.Text>
            </span>
          }
          type="error"
        />
      )}
      <EditingRule
        initialRule={{
          ...defaults,
          id: -1,
          name: '',
          expression: { type: 'AND', exprs: [] },
          ...rule,
        }}
        allowAddingAndRemovingRules={allowAddingAndRemovingRules}
        isDuplicateName={isDuplicateName}
        onCancel={() => {
          setEditing(false);
        }}
        onSave={onSaveRule}
      />
    </>
  );
};

const Rules = () => {
  const {
    rules,
    nudges,
    // TODO: handle `checklists` here too,
    dispatch: {
      rules: { addRule, changeRule, removeRule },
    },
  } = useAppContext();
  const audiences = rules.filter((rule) => rule.is_audience);
  const [showNewRule, setShowNewRule] = React.useState<boolean>(false);
  const { reportEvent } = useReportEvent();

  const isDuplicateName = (rule: INamedRule) =>
    !!audiences.filter(({ name, id }) => name.toLowerCase().trim() === rule.name.toLowerCase().trim() && id !== rule.id)
      .length;

  const entitiesByNamedRuleId: {
    [id: string]: { nudges: INudgeType[]; checklists: IChecklist[]; commands: IEditorCommandType[] } | undefined;
  } = (() => {
    const result: { [id: string]: { nudges: INudgeType[]; checklists: IChecklist[]; commands: IEditorCommandType[] } } =
      {};
    nudges.forEach((nudge) => {
      if (nudge.audience?.type === 'named_rule_reference') {
        result[nudge.audience.rule_reference.rule_id] ||= { nudges: [], checklists: [], commands: [] };
        result[nudge.audience.rule_reference.rule_id].nudges.push(nudge);
      }
    });
    return result;
  })();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }} className="RulesTab">
      <FeatureAnnouncementCard identifier="audiences" title={<Row align="middle">Introducing Audiences</Row>}>
        <span>
          Create user groups to craft personalized experiences that are maximally relevant and minimally annoying.
        </span>
      </FeatureAnnouncementCard>
      <SimplePanel>
        <div style={{ marginBottom: 48 }}>
          <Header
            style={{ marginTop: 0 }}
            text="Audiences"
            rightActions={
              <Button
                onClick={() => {
                  setShowNewRule(true);
                }}
                icon={<PlusOutlined width="12px" height="12px" />}
                type="primary"
              >
                Create Audience
              </Button>
            }
            leftActions={<div></div>}
          />
          {showNewRule && (
            <div style={{ marginBottom: 16, marginTop: 8 }}>
              <Rule
                initialEditing
                isDuplicateName={isDuplicateName}
                rule={{
                  ...defaults,
                  is_audience: true,
                  id: -1,
                  name: '',
                  expression: {
                    type: 'AND',
                    exprs: [{ type: 'CONDITION', condition: mkNewRule(defaultConditionType) }],
                  },
                }}
                onDelete={async () => {
                  setShowNewRule(false);
                }}
                onSave={async (rule) => {
                  await addRule(rule, false);
                  setShowNewRule(false);
                  reportEvent('audience created', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    eventProps: {
                      name: rule.name,
                    },
                  });
                }}
                onEditing={(editing) => {
                  if (!editing) {
                    setShowNewRule(false);
                  }
                }}
              />
            </div>
          )}

          {!showNewRule && !audiences.length ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"You don't have any Audiences. Create one by clicking the 'Create Audience' button above."}
            />
          ) : null}

          {audiences.map((rule, idx) => (
            <div key={idx} style={{ marginBottom: 16 }}>
              <Rule
                rule={rule}
                entitiesUsingThisRule={entitiesByNamedRuleId[rule.id]}
                onSave={changeRule(rule.id)}
                onDelete={removeRule(rule.id)}
                isDuplicateName={isDuplicateName}
              />
            </div>
          ))}
        </div>
      </SimplePanel>
    </div>
  );
};

const AudiencesList = () => {
  return <Rules />;
};

export default AudiencesList;
