import { useEffect } from 'react';
import useRouter from './useRouter';

/**
 * Hook for handling pages with tabs. It exposes the current tab and a
 * function to switch to a different tab.
 * @param defaultTab Default Tab to show when page loads
 */
export const useTabbedLayout = (defaultTab: string, validTabs: string[]): [string, Function] => {
  const { cbEditorParams, deeplink, pathname } = useRouter();
  /*
    Read the tab from the URLSearchParams if it exists. This is
    useful when we want to open page in a specific tab, for example,
    from a deep link.
  */
  useEffect(() => {
    if (!cbEditorParams.tab || !validTabs.includes(cbEditorParams.tab)) {
      deeplink({
        to: pathname,
        tab: defaultTab,
        from: cbEditorParams.from,
      });
    }
  }, [cbEditorParams]);

  const setTab = (newTab: string) => {
    if (cbEditorParams.tab !== newTab) {
      deeplink({
        to: pathname,
        tab: newTab,
        from: cbEditorParams.from,
      });
    }
  };

  return [cbEditorParams.tab, setTab];
};
