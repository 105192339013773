import React, { createContext, useContext, useState } from 'react';

import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';

export const IsEditorOpenContext = createContext<boolean>(false);

export const IsEditorOpenProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);

  usePortal({
    proxy: {
      onEditorVisibleChange: (msg: { data: { data: boolean } }) => {
        setOpen(msg.data.data);
        return respondSuccess();
      },
      waitForEditorToLoad: () => respondSuccess(),
    },
  });

  return <IsEditorOpenContext.Provider value={isOpen}>{children}</IsEditorOpenContext.Provider>;
};

export const useIsEditorOpen = () => useContext(IsEditorOpenContext);
