import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { WarningOutlined } from '@ant-design/icons';
import Icon, { commandDefault } from '@commandbar/internal/client/Icon';
import {
  IEditorCommandType,
  IEditorCommandTypeLite,
  IResourceSettings,
  IResourceSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import { useAppContext } from '../../../Widget';
import {
  Button,
  Header,
  IconPicker,
  Input,
  Tooltip,
  List,
  Modal,
  Select,
  Row,
  Col,
  Tag,
  Alert,
  StatusSwitch,
  Slider,
} from '../../../shared_components';
import { ContextDataObject } from './useContextPartition';
import { getColorsFromCommands } from '../../utils/ColorUtils';
import { useContextSettings } from './useContextSettings';
import * as Command from '@commandbar/internal/middleware/command';
import useAllSlashFilterKeywords from '../../../hooks/useAllSlashFilterKeywords';
import { CB_COLORS } from '@commandbar/design-system/components';
import slugify from '@commandbar/internal/util/slugify';
import SlashFilterCollisions from '../../components/SlashFilterCollisions';
import { useReportEvent } from '../../../shared_components/useEventReporting';
import useLinkedCommand from '../useLinkedCommand';

interface Props {
  data?: ContextDataObject;
  onClose: () => void;
  isRecord: boolean;
  createLinkedCommand?: (contextKey: string) => IEditorCommandType;
  //openLinkedCommand: (command: IEditorCommandTypeLite) => void;
}

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const TextButton = styled(Button)`
  padding: 0 !important;
`;

export const ContextSettingsModal = (props: Props) => {
  const { data, isRecord, onClose } = props;

  const linkedCommand = useLinkedCommand();
  const createLinkedCommand = props.createLinkedCommand ?? linkedCommand.createLinkedCommand;
  const { openExistingLinkedCommand, openNewLinkedCommand } = linkedCommand;

  const {
    isDirty,
    save: saveContextSettings,
    update: updateContextSettings,
    modified: modifiedContextSettings,
    isSetFromSDK,
    deleteKey,
  } = useContextSettings();
  const { reportEvent } = useReportEvent();

  const { organization } = useAppContext();
  if (!modifiedContextSettings || !data) {
    return null;
  }

  const isCommandDefaultForRecord = (command: IEditorCommandTypeLite, record: ContextDataObject) => {
    return modifiedContextSettings[record.key]?.default_command_id?.toString() === Command.commandUID(command);
  };

  const onCreateNewCommandClick = () => {
    if (isDirty) {
      Modal.confirm({
        icon: <WarningOutlined />,
        content: <span>You have unsaved changes. Please save them before creating a command for this record.</span>,
        onOk() {
          saveContextSettings(data.key);
          createNewCommand();
        },
        okText: 'Save changes and create command',
        cancelText: 'Cancel',
      });
    } else {
      createNewCommand();
    }
  };

  const createNewCommand = () => {
    const newLinkedCommand = createLinkedCommand(data.key);

    openNewLinkedCommand(newLinkedCommand);
    reportEvent('Record Action Added', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: data.key,
    });
  };

  const onSave = () => {
    saveContextSettings(data.key).then(() => onClose());
    reportEvent('Record Edited', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: data.key,
    });
  };

  const onDelete = (contextKey: string) =>
    Modal.confirm({
      icon: <WarningOutlined />,
      content: <span>Deleting this record will delete all settings associated with this record.</span>,
      onOk() {
        deleteKey(contextKey).then(() => {
          onClose();
          reportEvent('Record Deleted', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: contextKey,
          });
        });
      },
    });
  const hasLinkedCommands = data.commands.length > 0;
  const isDefaultCommandProgrammatic = (() => {
    if (!isRecord) return false;
    const defaultCommand = data.commands.find((c) => isCommandDefaultForRecord(c, data));
    return !!defaultCommand && Command.isProgrammatic(defaultCommand);
  })();

  const isViaAlgoliaIntegration = organization?.integrations?.algolia?.indexes?.hasOwnProperty(data.key);

  return (
    <Modal
      visible={true}
      closable={false}
      onCancel={onClose}
      footer={null}
      style={{ top: 30 }}
      width="90%"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{`Settings: ${data?.key}`}</span>
          {isViaAlgoliaIntegration && (
            <Tooltip key={'right-tooltip-third-party-source'} content={'Record created via Algolia integration'}>
              <Tag color={'geekblue'}>Algolia</Tag>
            </Tooltip>
          )}
          <Button onClick={onSave}>Save</Button>
        </div>
      }
    >
      {isRecord && (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '20px' }}
          >
            <span style={{ fontWeight: 600, fontSize: '18px' }}>Record actions:</span>
            <StyledButton type="dashed" onClick={onCreateNewCommandClick}>
              Create new record action
            </StyledButton>
          </div>
          <List
            dataSource={data.commands}
            bordered
            renderItem={(command) => {
              const isProgrammatic = Command.isProgrammatic(command);
              return (
                <List.Item
                  style={{
                    cursor: isProgrammatic ? 'not-allowed' : 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    opacity: isProgrammatic ? '60%' : '100%',
                  }}
                  onClick={() => {
                    if (!isProgrammatic) {
                      openExistingLinkedCommand(command);
                    }
                  }}
                  key={command.text}
                  actions={[
                    isCommandDefaultForRecord(command, data) ? (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: 8, fontWeight: 700, color: CB_COLORS.primary }}>Default</span>
                      </div>
                    ) : (
                      <TextButton
                        type="text"
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          await updateContextSettings(data.key, 'default_command_id', command.id);
                          reportEvent('Default Record Action Set', {
                            segment: true,
                            highlight: true,
                            slack: true,
                            payloadMessage: `${data.key} - ${command.text}`,
                          });
                        }}
                        /* if default command is programmatic, don't allow making other commands the default from editor */
                        disabled={isDefaultCommandProgrammatic || isProgrammatic}
                      >
                        Make default
                      </TextButton>
                    ),
                  ]}
                >
                  <Icon
                    icon={command.icon || commandDefault(command)}
                    style={{ color: 'rgba(0, 0, 0, 0.85)', minWidth: 18, marginRight: '5px' }}
                    allowDefaultSVGColorOverride
                    useDefaultSVGColor
                  />
                  <span style={{ display: 'flex', flex: 1 }}>{command.text}</span>
                </List.Item>
              );
            }}
          />
        </>
      )}
      <SettingsList
        isRecord={isRecord}
        contextSettings={modifiedContextSettings}
        createLinkedCommand={createLinkedCommand}
        onSettingsChange={updateContextSettings}
        isSetFromSDK={isSetFromSDK}
        contextKey={data.key}
      />
      <Row justify="center">
        <Tooltip
          content={
            hasLinkedCommands ? (
              `Delete any referenced commands before you delete this ${isRecord ? 'record' : 'argument'}.`
            ) : isViaAlgoliaIntegration ? (
              <span>
                This record is set from the Algolia integration. Edit the existing integration to remove this record.
              </span>
            ) : (
              `This ${organization?.branding ? 'record' : 'argument'} is set from the sdk. Remove calls to ${
                isRecord ? 'addRecords' : 'addArgumentChoices'
              } in order to delete.`
            )
          }
          disabled={!hasLinkedCommands && (!isSetFromSDK(data.key) || isViaAlgoliaIntegration)}
        >
          <Button
            danger
            type="primary"
            disabled={hasLinkedCommands || (isSetFromSDK(data.key) && !isViaAlgoliaIntegration)}
            onClick={() => onDelete(data.key)}
          >
            Delete {isRecord ? 'record' : 'argument'}
          </Button>
        </Tooltip>
      </Row>
    </Modal>
  );
};

/*******************************************************************************/
/* Settings page
/*******************************************************************************/

interface ISettingsProps {
  isRecord: boolean;
  contextSettings: IResourceSettingsByContextKey;
  createLinkedCommand: (contextKey: string) => void;
  onSettingsChange: (contextKey: string, setting: keyof IResourceSettings, value: any) => void;
  contextKey: string;
  isSetFromSDK: (contextKey: string, setting?: keyof IResourceSettings) => boolean;
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const FormField = ({
  title,
  description,
  children,
}: {
  title: string | React.ReactElement;
  description?: string;
  children?: React.ReactNode;
}) => (
  <Row gutter={24} style={{ padding: '0px 15px', marginBottom: 28 }} align="middle">
    <Col span={12}>
      <div style={{ display: 'flex', marginBottom: 3 }}>{title}</div>
      <div style={{ fontSize: 11, opacity: 0.8 }}>{description}</div>
    </Col>
    <Col span={12}>{children}</Col>
  </Row>
);

const SettingsList = (props: ISettingsProps) => {
  const { organization, commands } = useAppContext();
  /**
   * Detail settings page
   */

  const allSlashFilters = useAllSlashFilterKeywords(props.contextKey);

  const FormTitleTag = (title: string, setting: keyof IResourceSettings) => {
    return (
      <Fragment>
        <span style={{ fontWeight: 500, fontSize: 14 }}>{title}</span>
        {isReadOnly(setting) && (
          <Tooltip content={`This field (${setting}) has been overridden by the SDK.`}>
            <Tag style={{ marginLeft: 10 }}>Read-only</Tag>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  const isReadOnly = (setting: keyof IResourceSettings) => props.isSetFromSDK(props.contextKey, setting);

  const thisResource = {
    key: props.contextKey,
    ...props.contextSettings[props.contextKey],
  };

  const genericContextSettings = (
    <>
      <div style={{ marginTop: 20 }}>
        <Header text={'Search options'} />
      </div>

      <FormField
        title={FormTitleTag('Searchable fields', 'search_fields')}
        description="Fields of the object that should be searchable. Search matches will be shown underneath the label."
      >
        <Select
          mode="tags"
          style={{ width: '100%' }}
          value={thisResource.search_fields}
          onChange={(values: any) => {
            props.onSettingsChange(thisResource.key, 'search_fields', values as string[]);
          }}
          dropdownStyle={{ display: 'none' }}
          placeholder="Type an object field and press enter"
          disabled={isReadOnly('search_fields')}
          autoFocus={false}
        />
      </FormField>

      <FormField
        title="Custom search function"
        description="Use your own custom search function instead of the default CommandBar search algorithm, e.g., connect your backend search endpoint."
      >
        <Alert
          message={
            <a href="https://www.commandbar.com/sdk#addcontext-options" target="_blank" rel="noopener noreferrer">
              <span>Custom search functions are defined via the SDK. Click here to learn more.</span>
            </a>
          }
          type="info"
          showIcon
        />
      </FormField>

      <div style={{ marginTop: 20 }}>
        <Header text="Render options" />
      </div>

      <FormField title={FormTitleTag('Icon', 'icon')} description="The icon to show next to each object of this type">
        <IconPicker
          color={thisResource.icon_color || null}
          icon={thisResource.icon || null}
          colors={getColorsFromCommands(commands)}
          onIconSelect={(newIcon: string) => props.onSettingsChange(thisResource.key, 'icon', newIcon)}
          onColorSelect={(newColor: string) => props.onSettingsChange(thisResource.key, 'icon_color', newColor)}
          disabled={isReadOnly('icon')}
        />
      </FormField>

      <FormField
        title={FormTitleTag('Label field key', 'label_field')}
        description="The field of the object used in the option title text shown in the Bar."
      >
        <Input
          value={thisResource.label_field}
          placeholder={`[Default] 'label'`}
          onChange={(e) => {
            props.onSettingsChange(thisResource.key, 'label_field', e.target.value);
          }}
          disabled={isReadOnly('label_field')}
        />
      </FormField>

      <FormField
        title={FormTitleTag('Description field key', 'description_field')}
        description="The field of the object used in the option description text shown in the bar"
      >
        <Input
          onKeyDown={(e) => e.stopPropagation()}
          value={thisResource.description_field}
          onChange={(e) => {
            props.onSettingsChange(thisResource.key, 'description_field', e.target.value);
          }}
          disabled={isReadOnly('description_field')}
        />
      </FormField>
    </>
  );

  if (!props.isRecord) {
    return genericContextSettings;
  }

  // TODO: most of this is duplicated in CategorySettings.tsx -- create a shared component and use it in both places
  const recordSettings = (
    <>
      <div style={{ marginTop: 20 }}>
        <Header text={'QuickFind options'} />
      </div>
      <FormField title={FormTitleTag('Group name', 'name')} description="The title of this group shown in the bar.">
        <Input
          value={thisResource.name}
          placeholder={`[Default] ${capitalize(thisResource.key)}`}
          onChange={(e) => {
            props.onSettingsChange(thisResource.key, 'name', e.target.value);
          }}
          disabled={isReadOnly('name')}
        />
      </FormField>
      <FormField title="Slash filter keyword" description="Users can type /keyword to filter from this category.">
        {(() => {
          const isInputDisabled = thisResource.slash_filter_enabled === false || !thisResource.search;
          const defaultKeyword = slugify(thisResource.key);

          return (
            <Row align="middle" gutter={12}>
              <Col span={22}>
                <Input
                  onChange={(e) => {
                    props.onSettingsChange(thisResource.key, 'slash_filter_keyword', slugify(e.target.value));
                  }}
                  placeholder={`[Default] ${defaultKeyword}`}
                  value={thisResource.slash_filter_keyword || ''}
                  disabled={isInputDisabled}
                />
              </Col>
              <Col span={1}>
                <SlashFilterCollisions
                  allFilters={allSlashFilters}
                  currentFilter={thisResource.slash_filter_keyword || defaultKeyword}
                />
              </Col>
            </Row>
          );
        })()}
      </FormField>
      <FormField
        title={FormTitleTag('Unfurl', 'unfurl')}
        description="Let users search for “object command —> object” instead of just objects (allows for subject-verb-search queries)"
      >
        {(() => {
          const isActive = thisResource.search;

          return (
            <Tooltip
              content={'Object must be searchable to turn on this setting'}
              disabled={isActive}
              placement="bottom"
            >
              <StatusSwitch
                checked={thisResource.unfurl ?? false}
                onChange={() => {
                  props.onSettingsChange(thisResource.key, 'unfurl', !thisResource.unfurl);
                }}
                offLabel="Off"
                onLabel="On"
                disabled={isReadOnly('unfurl')}
              />
            </Tooltip>
          );
        })()}
      </FormField>
      {organization?.end_user_recents_enabled && (
        <FormField
          title={FormTitleTag('Include in Recents', 'track_recents')}
          description="Show options create from these record in Recents."
        >
          {(() => {
            const isActive = thisResource.search;

            return (
              <Tooltip
                content={'Object must be searchable to turn on this setting'}
                disabled={isActive}
                placement="bottom"
              >
                <StatusSwitch
                  checked={thisResource.track_recents ?? true}
                  onChange={(checked) => {
                    props.onSettingsChange(thisResource.key, 'track_recents', checked);
                  }}
                  offLabel="Off"
                  onLabel="On"
                  disabled={isReadOnly('track_recents')}
                />
              </Tooltip>
            );
          })()}
        </FormField>
      )}
      <FormField
        title={FormTitleTag('Option list limit', 'max_options_count')}
        description="During quickFind, set a limit on the amount of options that can be shown in the category. This limit does not apply to the list when a command is selected."
      >
        {(() => {
          const { max_options_count } = thisResource;

          const noLimit = max_options_count === null || max_options_count === undefined;
          const onChange = (value: number | string | undefined) => {
            if (typeof value === 'number') {
              const newValue = value > 10 ? null : value;
              props.onSettingsChange(thisResource.key, 'max_options_count', newValue);
            }
          };

          return (
            <Row align="middle" gutter={25}>
              <Col span={12}>
                <Slider
                  min={1}
                  max={11}
                  onChange={onChange}
                  value={noLimit ? 11 : max_options_count}
                  disabled={isReadOnly('max_options_count')}
                />
              </Col>
              <Col span={4}>
                <div>
                  <Tag>{noLimit ? 'No limit' : `${max_options_count} row limit`}</Tag>
                </div>
              </Col>
            </Row>
          );
        })()}
      </FormField>

      <div key="display_options_header" style={{ marginTop: 20 }}>
        <Header text={'Display options'} />
      </div>

      <FormField
        title={FormTitleTag('Always show, even with no results', 'show_with_no_results')}
        description='Show the header (with "No results" text) if there are no records that match the query. By default, headers are not shown if there are no records that match the query.'
      >
        {(() => {
          const isActive = thisResource.search;

          return (
            <Tooltip
              content={'Object must have quickfind on to turn on this setting'}
              disabled={isActive}
              placement="bottom"
            >
              <StatusSwitch
                checked={thisResource.show_with_no_results ?? false}
                onChange={(checked) => {
                  props.onSettingsChange(thisResource.key, 'show_with_no_results', checked);
                }}
                offLabel="Off"
                onLabel="On"
                disabled={!isActive || isReadOnly('show_with_no_results')}
              />
            </Tooltip>
          );
        })()}
      </FormField>

      <div key="search_tab" style={{ marginTop: 20 }}>
        <Header text={'Major category'} />
      </div>

      <FormField
        title={FormTitleTag('Major category', 'search_tab_enabled')}
        description="Allow user to filter from this category via a tab below the input."
      >
        {(() => {
          // disable if quickfind is off
          const isToggleDisabled = !thisResource.search;

          return (
            <Tooltip
              content={'To edit this setting, first turn on quickfind.'}
              disabled={!isToggleDisabled}
              placement="bottom"
            >
              <StatusSwitch
                checked={thisResource.search_tab_enabled ?? false}
                onChange={(checked) => {
                  props.onSettingsChange(thisResource.key, 'search_tab_enabled', checked);
                }}
                offLabel="Off"
                onLabel="On"
                disabled={isToggleDisabled || isReadOnly('search_tab_enabled')}
              />
            </Tooltip>
          );
        })()}
      </FormField>

      <FormField
        title={FormTitleTag('Major category name', 'search_tab_name')}
        description="Custom category name to be shown in the tab below the input (defaults to category name)."
      >
        {(() => {
          const isInputDisabled = !thisResource.search_tab_enabled || !thisResource.search;

          return (
            <Tooltip
              content={'To edit this setting, first make the category a major category.'}
              disabled={!isInputDisabled}
              placement="bottom"
            >
              <Input
                value={thisResource.search_tab_name || ''}
                placeholder={`[Default] ${capitalize(thisResource.key)}`}
                onChange={(e) => {
                  props.onSettingsChange(thisResource.key, 'search_tab_name', e.target.value);
                }}
                disabled={isInputDisabled || isReadOnly('search_tab_name')}
              />
            </Tooltip>
          );
        })()}
      </FormField>

      <FormField
        title={FormTitleTag('Major category placeholder', 'search_tab_instruction')}
        description="Custom placeholder to be shown when the user filters for this major category."
      >
        {(() => {
          const isInputDisabled = !thisResource.search_tab_enabled || !thisResource.search;

          return (
            <Tooltip
              content={'To edit this setting, first make the category a major category.'}
              disabled={!isInputDisabled}
              placement="bottom"
            >
              <Input
                value={thisResource.search_tab_instruction || ''}
                placeholder={`Type to search`}
                onChange={(e) => {
                  props.onSettingsChange(thisResource.key, 'search_tab_instruction', e.target.value);
                }}
                disabled={isInputDisabled || isReadOnly('search_tab_instruction')}
              />
            </Tooltip>
          );
        })()}
      </FormField>

      <FormField
        title={FormTitleTag('Pin to bottom', 'setting_pin_to_bottom')}
        description="Pinning a category to the bottom, puts it below all other categories."
      >
        {(() => {
          const isActive = thisResource.search;

          return (
            <Tooltip
              content={'Object must have quickfind on to turn on this setting'}
              disabled={isActive}
              placement="left"
            >
              <StatusSwitch
                checked={thisResource.setting_pin_to_bottom ?? false}
                onChange={(checked) => {
                  props.onSettingsChange(thisResource.key, 'setting_pin_to_bottom', checked);
                }}
                offLabel="Off"
                onLabel="On"
                disabled={!isActive || isReadOnly('setting_pin_to_bottom')}
              />
            </Tooltip>
          );
        })()}
      </FormField>

      <FormField title={FormTitleTag('Render as', 'render_as')} description="Render options as a list or a grid.">
        <div>
          <Select
            onChange={(value: string) => {
              props.onSettingsChange(thisResource.key, 'render_as', value);
            }}
            value={thisResource.render_as || 'list'}
            disabled={isReadOnly('render_as')}
          >
            <Select.Option value="list">list</Select.Option>
            <Select.Option value="grid">grid</Select.Option>
          </Select>
        </div>
      </FormField>

      {thisResource.render_as === 'grid' && (
        <FormField
          title={FormTitleTag('Image', 'image')}
          description="The image to show above each grid item of this type"
        >
          <IconPicker
            color={thisResource.image_color ?? null}
            icon={thisResource.image ?? null}
            colors={getColorsFromCommands(commands)}
            onIconSelect={(newImage: string) => props.onSettingsChange(thisResource.key, 'image', newImage)}
            onColorSelect={(newColor: string) => props.onSettingsChange(thisResource.key, 'image_color', newColor)}
            disabled={isReadOnly('image') || !thisResource.search_tab_enabled}
            clearable
          />
        </FormField>
      )}
    </>
  );

  return (
    <>
      {recordSettings}
      {genericContextSettings}
    </>
  );
};
