import React from 'react';
import styled from '@emotion/styled';
import { WarningBannerBase } from './WarningBannerBase';

const Anchor = styled.a`
  text-decoration: underline;
  font-weight: 600;
  color: #583900;
`;

export const NoExtensionWarning = () => (
  <WarningBannerBase>
    CommandBar isn't installed in your app yet. Our{' '}
    <Anchor
      href="https://chrome.google.com/webstore/detail/commandbar-editor/diahhhkcbpeijifhedabbjgepdgdpkcj"
      target="_blank"
      rel="noreferrer"
    >
      Chrome extension↗
    </Anchor>{' '}
    will be needed to view this share link.
  </WarningBannerBase>
);
