import {
  INudgeContentSurveyRatingBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import React from 'react';
import { SurveyRatingInputBlock } from './SurveyRatingInputBlock';
import { SurveyTextInputBlockForm } from './SurveyTextInputBlockForm';

interface IMediaBlocks {
  step: INudgeStepType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

export const SurveyBlocks = ({ step, onBlockChange }: IMediaBlocks) => {
  const textInputBlock = step.content.find((content) => content.type === 'survey_text');
  const ratingBlock = step.content.find((content) => content.type === 'survey_rating');

  if (!!textInputBlock) {
    return (
      <SurveyTextInputBlockForm
        block={textInputBlock as INudgeStepContentSurveyTextBlockType}
        onBlockChange={onBlockChange}
      />
    );
  }

  if (!!ratingBlock) {
    return (
      <SurveyRatingInputBlock block={ratingBlock as INudgeContentSurveyRatingBlockType} onBlockChange={onBlockChange} />
    );
  }

  return null;
};
