import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';

export const Row = styled.div`
  display: flex;
  padding: 4px 0;
`;

export const Col = styled(Row)`
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${CB_COLORS.neutral500};
`;

export const IconContainer = styled.div<{ visible?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;
