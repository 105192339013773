// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const CursorClick01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.817 13.3728c.5287-.2847.793-.427.8598-.5982a.5.5 0 0 0-.044-.4506c-.0988-.155-.3857-.2434-.9595-.4201L9.4453 8.4452c-.4688-.1444-.7031-.2166-.8614-.1585a.5.5 0 0 0-.2972.2972c-.058.1583.0141.3926.1585.8614l3.4586 11.228c.1768.5738.2652.8607.4202.9595a.5.5 0 0 0 .4505.0441c.1713-.0669.3136-.3312.5983-.8598l2.4917-4.6275c.0423-.0786.0635-.1178.0906-.1522a.5085.5085 0 0 1 .0823-.0823c.0344-.0271.0736-.0483.1522-.0906l4.6274-2.4917Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 3.5V2M5.0607 5.0607 4 4m1.0607 9L4 14.0607m9-9L14.0607 4M3.5 9H2m13.8645 7.1896-2.4918 4.6274c-.2846.5287-.427.793-.5982.8599a.5.5 0 0 1-.4505-.0441c-.1551-.0988-.2434-.3857-.4202-.9595L8.4452 9.4453c-.1444-.4688-.2166-.7032-.1585-.8615a.5.5 0 0 1 .2971-.2971c.1583-.058.3927.0141.8614.1585l11.228 3.4586c.5738.1768.8607.2652.9595.4202a.4998.4998 0 0 1 .0441.4505c-.0668.1713-.3312.3136-.8598.5983l-4.6274 2.4917c-.0786.0423-.1179.0634-.1522.0906a.493.493 0 0 0-.0823.0823c-.0272.0343-.0483.0736-.0906.1522Z"
    />
  </svg>
);
export default CursorClick01;
