import React from 'react';

import {
  canonicalize,
  IEditorAvailabilityRule,
  isCompoundExpression,
} from '@commandbar/internal/middleware/helpers/rules';
import { IRuleExpression, IRuleExpressionAnd, IRuleExpressionOr } from '@commandbar/internal/middleware/types';
import { mkNewRule } from '../helpers';
import ConditionRuleComponent from './ConditionRuleComponent';
import { Button, Col, Modal, Row, Select, DropdownMenu } from '../../../../../shared_components';
import { getDefaultConditionType } from '../types';
import { DeleteOutlined, GroupOutlined, PlusOutlined } from '@ant-design/icons';
import { RulePrefix } from './styled';

const CompoundRuleExpressionEditor = (props: {
  expr: IRuleExpressionAnd | IRuleExpressionOr;
  disabled?: boolean;
  nestingLevel?: number;
  onChange?: (expr: IRuleExpression) => void;
  omitNamedRuleType?: boolean;
  categories?: ('Activity' | 'Custom' | 'Page' | 'Integrations')[];
}) => {
  const {
    expr,
    disabled = false,
    nestingLevel = 0,
    onChange = () => {
      return null;
    },
    omitNamedRuleType = false,
  } = props;

  const addNewRule = () => {
    onChange({
      ...expr,
      exprs: [
        ...expr.exprs,
        { type: 'CONDITION', condition: mkNewRule(getDefaultConditionType(props?.categories?.[0])) },
      ],
    });
  };

  const addConditionGroup = () => {
    onChange({
      ...expr,
      exprs: [
        ...expr.exprs,
        {
          type: 'AND',
          exprs: [{ type: 'CONDITION', condition: mkNewRule(getDefaultConditionType(props?.categories?.[0])) }],
        },
      ],
    });
  };

  const deleteRuleByIndex = (index: number) => {
    const exprs = [...expr.exprs.slice(0, index), ...expr.exprs.slice(index + 1)];

    // when 0 conditions, change type to "AND" so that it evaluates to "true" by default
    const type = exprs.length < 1 ? 'AND' : expr.type;

    onChange({
      ...expr,
      type,
      exprs,
    });
  };

  const updateRuleByIndex = (index: number) => (newRule: IEditorAvailabilityRule) => {
    const toUpdate = expr.exprs[index];
    if (toUpdate.type !== 'CONDITION') return;

    onChange({
      ...expr,
      exprs: [
        ...expr.exprs.slice(0, index),
        { ...toUpdate, condition: { ...toUpdate.condition, ...newRule } },
        ...expr.exprs.slice(index + 1),
      ],
    });
  };

  const updateExprByIndex = (index: number) => (newExpr: IRuleExpression) => {
    onChange({
      ...expr,
      exprs: [...expr.exprs.slice(0, index), newExpr, ...expr.exprs.slice(index + 1)],
    });
  };

  const updateBooleanOperator = (newOperator: 'AND' | 'OR') => {
    onChange({
      ...expr,
      type: newOperator,
    });
  };

  return (
    <div
      style={
        nestingLevel > 0
          ? {
              border: '1px solid #E6E6E8',
              padding: '12px 16px',
              margin: '2px 0px',
              background: '#EEEEEE60',
              borderRadius: 3,
            }
          : {}
      }
    >
      <div style={{ marginBottom: 16 }}>
        {expr.exprs.map((rule, index) => {
          if (isCompoundExpression(rule)) {
            return (
              <div key={index} style={{ marginTop: 8, marginBottom: 8 }}>
                <Row gutter={4} align="middle" wrap={false}>
                  <Col>
                    {index !== 0 ? (
                      index === 1 ? (
                        <Select disabled={disabled} value={expr.type} onChange={updateBooleanOperator} size="small">
                          <Select.Option value={'AND'}>AND</Select.Option>
                          <Select.Option value={'OR'}>OR</Select.Option>
                        </Select>
                      ) : (
                        <RulePrefix>{expr.type}</RulePrefix>
                      )
                    ) : null}
                  </Col>
                  <Col flex="auto">
                    <CompoundRuleExpressionEditor
                      {...props}
                      nestingLevel={nestingLevel + 1}
                      expr={rule}
                      onChange={updateExprByIndex(index)}
                    />
                  </Col>
                  {!disabled && (
                    <Col>
                      <Button
                        type="text"
                        ghost
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: 'Delete this condition group?',
                            onOk: () => {
                              deleteRuleByIndex(index);
                            },
                          });
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          }
          if (rule.type === 'CONDITION') {
            return (
              <ConditionRuleComponent
                key={`${index}-${expr.exprs.length}`}
                disabled={disabled}
                rule={rule.condition}
                onChange={updateRuleByIndex(index)}
                onChangeBooleanOperator={index === 1 ? updateBooleanOperator : undefined}
                onDelete={() => deleteRuleByIndex(index)}
                booleanOperator={index !== 0 ? expr.type : undefined}
                omitNamedRuleType={omitNamedRuleType}
                categories={props.categories}
              />
            );
          }

          return null;
        })}
      </div>
      {!disabled && (
        <div style={{ marginBottom: 16 }}>
          <DropdownMenu
            keyName="add-condition-actions"
            placement="bottomLeft"
            items={[
              {
                name: 'Add condition',
                icon: <PlusOutlined style={{ marginRight: 6 }} />,
                onClick: addNewRule,
              },

              ...(nestingLevel < 1
                ? [
                    {
                      name: 'Add condition group',
                      icon: <GroupOutlined style={{ marginRight: 6 }} />,
                      onClick: addConditionGroup,
                    },
                  ]
                : []),
            ]}
          >
            <Button>+ Add condition</Button>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};

const RuleExpressionEditor = (props: {
  expr: IRuleExpression;
  disabled?: boolean;
  nestingLevel?: number;
  onChange?: (expr: IRuleExpression) => void;
  omitNamedRuleType?: boolean;
  categories?: ('Activity' | 'Custom' | 'Page' | 'Integrations')[];
}) => {
  const { expr, disabled } = props;

  const onChange = (e: IRuleExpression) => {
    if (!props.onChange) return;
    return props.onChange(canonicalize(e));
  };

  if (isCompoundExpression(expr)) {
    return <CompoundRuleExpressionEditor {...props} onChange={onChange} expr={expr} />;
  }

  if (expr.type === 'CONDITION') {
    return <CompoundRuleExpressionEditor {...props} onChange={onChange} expr={{ type: 'AND', exprs: [expr] }} />;
  }

  const addNewRule = () => {
    const newRule = mkNewRule(getDefaultConditionType(props?.categories?.[0]));

    onChange({
      type: 'AND',
      exprs: [{ type: 'CONDITION', condition: newRule }],
    });
  };

  const addConditionGroup = () => {
    onChange({
      type: 'AND',
      exprs: [
        {
          type: 'AND',
          exprs: [{ type: 'CONDITION', condition: mkNewRule(getDefaultConditionType(props?.categories?.[0])) }],
        },
      ],
    });
  };

  if (disabled) return null;

  return (
    <div>
      <DropdownMenu
        keyName="add-condition-actions"
        placement="bottomLeft"
        items={[
          {
            name: 'Add condition',
            icon: <PlusOutlined style={{ marginRight: 6 }} />,
            onClick: addNewRule,
          },

          {
            name: 'Add condition group',
            icon: <GroupOutlined style={{ marginRight: 6 }} />,
            onClick: addConditionGroup,
          },
        ]}
      >
        <Button>+ Add condition</Button>
      </DropdownMenu>
    </div>
  );
};

export default RuleExpressionEditor;
