import styled, { ComponentSelector } from '@emotion/styled';
import { CSSProperties } from 'react';
import { Input, Select, Collapse, Typography } from '../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';

export const OverlayContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '168px')};

  overflow: hidden;
  font-weight: 500;
`;

export const DropDownOption = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  background-color: ${(props) => (props.active ? 'transparent' : CB_COLORS['neutral0'])};
  color: ${(props) => (props.active ? CB_COLORS.neutral1000 : CB_COLORS.neutral600)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  transition: 0.3s all;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)`
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.34);
  overflow: hidden;

  & .ant-collapse-content {
    border-top: 1px solid ${CB_COLORS.neutral300};
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
`;

export const TypographyCode = styled(Typography.Text)`
  code {
    white-space: pre-wrap;
  
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
  color: ${CB_COLORS.blue1000};
`;

export const NudgeContentFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.34);
  overflow: hidden;
`;

export const SectionContainer = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: flex-end;
  padding: 0;
  gap: 8px;
`;

export const InputContainer = styled.div<{ width?: CSSProperties['width'] }>`
  width: ${(props) => props?.width ?? '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${CB_COLORS.neutral800};
  user-select: none;
`;
export const StyledSubLabel = styled.label`
  font-weight: 500;
  line-height: 14px;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  color: #555d72;
`;

export const MediaBlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;
  background: #ffffff;
`;

export const StyledSelect = styled(Select)`
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;

  & .ant-select-selector {
    border: none !important;
  }

  & .ant-select-selection-item {
    top: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  & .ant-select-selection-placeholder {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
` as unknown as typeof Select & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

export const MediaBlockInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 16px;
  background: ${CB_COLORS.neutral0};
  border-radius: 8px;
`;

export const MediaBlockStyledInput = styled(Input)`
  height: 32px;
  padding: 0 9px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;

  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

export const MediaBlockStyledTextArea = styled(Input.TextArea)`
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  resize: none;
  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

export const DeleteIconContainer = styled.div`
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${CB_COLORS.neutral500};
  border-radius: 4px;

  transition: 0.3s all;

  &:hover {
    color: ${CB_COLORS.neutral1000};
    background-color: ${CB_COLORS.neutral0};
  }
`;

export const CombinationInput = styled.div<{ lightBorder?: boolean }>`
  width: 100%;
  display: flex;
  border: 1px solid ${(props) => (props.lightBorder ? CB_COLORS.neutral300 : CB_COLORS.neutral500)};
  border-radius: 4px;
`;

export const DropdownLabelDetail = styled.span`
  margin-left: 4px;
`;

export const StyledCommandSelect = styled(Select)`
  & .ant-select-selector {
    border: none !important;
    border-radius: 4px;
  }

  & .ant-select-selection-item {
    top: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  & .ant-select-selection-placeholder {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
` as unknown as typeof Select & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

export const SuffixInput = styled(Input)`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${CB_COLORS.white};
  border: none;
  border-left: 1px solid ${CB_COLORS.neutral300};
  border-radius: 0 4px 4px 0;
  box-shadow: none !important;

  &.ant-input,
  & .ant-input {
    top: 1px;
    height: 30px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  &.ant-input:placeholder-shown,
  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }

  &:hover {
    border-left-color: ${CB_COLORS.neutral300} !important;
  }

  & .ant-input-affix-wrapper {
    border: none;
  }
`;
