import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { IUserContext } from '@commandbar/internal/middleware/types';
import { CheckCircleFilled, InfoCircleFilled, ReloadOutlined } from '@ant-design/icons';

import { Button, Tooltip, Modal, Tag } from '../../../shared_components';
import { useAppContext } from '../../../Widget';
import JSONEditor from '../../components/JSONEditor';
import { ContextPanelHeader } from './ContextPanelHeader';
import { getCommandsByContextKey } from '../../context/helpers';
import { CommandsByContextKeyTable } from './ContextPanel';
import useWindowInfo from '../../../hooks/useWindowInfo';

type VariableTagsProps = {
  missingArgs: string[];
  onMissingButtonClick: () => void;
};

const VariableTags = (props: VariableTagsProps) => {
  const { missingArgs, onMissingButtonClick } = props;

  return (
    <span key="context-tags" style={{ marginTop: '-3px' }} onClick={onMissingButtonClick}>
      {missingArgs.length > 0 ? (
        <Tooltip
          content={`There are ${missingArgs.length} key${
            missingArgs.length > 1 ? 's' : ''
          } referenced by commands that are missing in this context. Click to learn more.`}
        >
          <Tag color="red" style={{ cursor: 'pointer' }} icon={<InfoCircleFilled />}>
            {`${missingArgs.length} missing`}
          </Tag>
        </Tooltip>
      ) : (
        <Tag color="green" style={{ cursor: 'pointer' }} icon={<CheckCircleFilled />}>
          All context keys included
        </Tag>
      )}
    </span>
  );
};

export const InspectContext = () => {
  const { context: windowContext, reload: reloadWindowContext } = useWindowInfo();
  const { commands } = useAppContext();
  const [context, setContext] = useState<IUserContext>({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setContext(windowContext);
  }, [windowContext]);

  const onChange = (obj: any) => {
    const newContext = obj.jsObject;

    setContext(newContext);
  };

  const commandsByContextKey = getCommandsByContextKey(commands);
  const missingArgs = Object.keys(commandsByContextKey).filter((arg: string) => !get(context, arg));

  return (
    <>
      <ContextPanelHeader
        id={0}
        name="Current context (readonly)"
        canEdit={false}
        onNameChange={() => {
          return {};
        }}
        showRadio={false}
        action={[
          <VariableTags
            key="variable-tags"
            missingArgs={missingArgs}
            onMissingButtonClick={() => setShowModal(true)}
          />,
          <ReloadOutlined key="context-reload" onClick={reloadWindowContext} />,
        ]}
      />
      <JSONEditor contextID={0} json={context} onChange={onChange} readOnly height={600} />
      <Modal
        visible={showModal}
        closable={false}
        footer={[
          <Button
            key="footer-button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>,
        ]}
        okText={null}
        mask={false}
        width={400}
      >
        <CommandsByContextKeyTable commandsByContextKey={commandsByContextKey} context={context} />
      </Modal>
    </>
  );
};
