// AntDesign components should be imported/exported this way
// because babel-plugin-import doesn't bundle antd styles using export declaration like:
// export { Component } from 'antd';

import {
  Alert as AntAlert,
  Space as AntSpace,
  Form as AntForm,
  Modal as AntModal,
  Col as AntCol,
  Divider as AntDivider,
  Radio as AntRadio,
  Row as AntRow,
  Typography as AntTypography,
  Collapse as AntCollapse,
  Popconfirm as AntPopconfirm,
  List as AntList,
  Table as AntTable,
  Empty as AntEmpty,
  Select as AntSelect,
  Switch as AntSwitch,
  Steps as AntSteps,
  Card as AntCard,
  Dropdown as AntDropdown,
  InputNumber as AntInputNumber,
  Image as AntImage,
  Spin as AntSpin,
  Checkbox as AntCheckbox,
  Badge as AntBadge,
  Result as AntResult,
  Timeline as AntTimeline,
  Skeleton as AntSkeleton,
  Avatar as AntAvatar,
  Menu as AntMenu,
  message as antMessage,
  Popover as AntPopover,
} from 'antd';

export type { InputRef, DropdownProps, RadioGroupProps } from 'antd';

export const Alert = AntAlert;
export const Space = AntSpace;
export const Form = AntForm;
export const Modal = AntModal;
export const Col = AntCol;
export const Divider = AntDivider;
export const Radio = AntRadio;
export const Row = AntRow;
export const Typography = AntTypography;
export const Collapse = AntCollapse;
export const Popconfirm = AntPopconfirm;
export const List = AntList;
export const Table = AntTable;
export const Empty = AntEmpty;
export const Select = AntSelect;
export const Switch = AntSwitch;
export const Steps = AntSteps;
export const Card = AntCard;
export const Dropdown = AntDropdown;
export const InputNumber = AntInputNumber;
export const Image = AntImage;
export const Spin = AntSpin;
export const Checkbox = AntCheckbox;
export const Badge = AntBadge;
export const Result = AntResult;
export const Timeline = AntTimeline;
export const Skeleton = AntSkeleton;
export const Avatar = AntAvatar;
export const Menu = AntMenu;
export const message = antMessage;
export const Popover = AntPopover;
