import React from 'react';
import chroma from 'chroma-js';
import { CirclePicker } from 'react-color';
import { Divider, DragUpload, FormRow, Input, message } from '../../../shared_components';
import { DEFAULTS_LIGHT, DEFAULTS_DARK, IPartialTheme } from '@commandbar/internal/client/theme';
import { CB_COLORS } from '@commandbar/design-system/components';
import { PrebuiltSkinSelector } from '../PrebuiltSkinSelector';
import { FormFactor } from '@commandbar/internal/client/CommandBarClientSDK';
import { CardRowContainer, DarkModeCard, Header, LightModeCard } from './styled-components';
import { RcFile } from '../../../shared_components/DragUpload';
import { DeleteOutlined } from '@ant-design/icons';

const accent_options = [
  '#f44336',
  '#e91e63',
  '#aeb1dd',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

const BasicStyleEditor = (props: {
  formFactor: FormFactor;
  theme: IPartialTheme;
  updateTheme: (theme: IPartialTheme) => void;
  resetTheme: (theme: IPartialTheme) => void;
  isBasicTier: boolean;
  updateLogo: (logo: string) => void;
  updateChatAvatar: (chatAvatar: string) => void;
  logo: string | null | undefined;
  chatAvatar: string | null | undefined;
}) => {
  const { formFactor, theme, updateTheme } = props;
  const [accent, setAccent] = React.useState(theme.base?.primary ?? accent_options[0]);

  const isLightMode = React.useCallback(() => {
    const background = theme?.base?.background;
    if (!background) {
      return true;
    }

    try {
      return chroma(background).luminance() > 0.8;
    } catch (_) {
      return true;
    }
  }, [props.theme]);

  const changeMode = async (mode: 'light' | 'dark') => {
    updateTheme({
      base: {
        background: mode === 'light' ? DEFAULTS_LIGHT.background : DEFAULTS_DARK.background,
        fontColor: mode === 'light' ? DEFAULTS_LIGHT.fontColor : DEFAULTS_DARK.fontColor,
        primary: accent,
      },
    });
  };

  const isCustomAccent = !accent_options.includes(accent);
  return (
    <div>
      {formFactor.type !== 'inline' && (
        <div>
          <Header>Start with a template</Header>
          <PrebuiltSkinSelector resetTheme={props.resetTheme} isBasicTier={props.isBasicTier} />
          <Divider />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Header>Base styles</Header>
        <FormRow
          title="Theme:"
          input={
            <CardRowContainer>
              <LightModeCard onClick={() => changeMode('light')} active={isLightMode()}>
                Light mode
              </LightModeCard>
              <DarkModeCard onClick={() => changeMode('dark')} active={!isLightMode()}>
                Dark mode
              </DarkModeCard>
            </CardRowContainer>
          }
        />
        <FormRow
          title="Accent color:"
          input={
            <CirclePicker
              color={accent}
              onChange={async (color) => {
                setAccent(color.hex);
                updateTheme({ base: { primary: color.hex } });
              }}
              circleSize={20}
              width={'100%'}
              colors={accent_options}
            />
          }
          description={
            <Input
              defaultValue={isCustomAccent ? accent : undefined}
              onBlur={async (e) => {
                setAccent(e.target.value);
                updateTheme({ base: { primary: e.target.value } });
              }}
              placeholder={'Custom hex value, e.g., #FFFFFF'}
            />
          }
        />
        <FormRow
          title="Font family:"
          input={
            <Input
              defaultValue={theme.base?.fontFamily}
              onBlur={async (e) => {
                updateTheme({ base: { fontFamily: e.target.value } });
              }}
              placeholder={'Avenir Next'}
            />
          }
        />
        <FormRow
          title="Bar Logo:"
          input={<LogoUpload currentLogo={props.logo || null} updateLogo={props.updateLogo} />}
        />
        <FormRow
          title="Chat Avatar:"
          input={<LogoUpload currentLogo={props.chatAvatar || null} updateLogo={props.updateChatAvatar} />}
        />
      </div>
    </div>
  );
};

const LogoUpload = (props: { currentLogo: string | null | undefined; updateLogo: (logo: string) => void }) => {
  const { currentLogo, updateLogo } = props;
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>(undefined);

  const removeLogo = () => updateLogo('');

  return currentLogo ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: CB_COLORS.neutral100,
        borderRadius: '4px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          alt={'Logo for skin'}
          height="43px"
          src={'data:image/svg+xml;base64,' + btoa(currentLogo)}
          style={{ margin: '8px' }}
        />
      </div>
      <DeleteOutlined
        style={{
          paddingRight: '23px',
          color: CB_COLORS.neutral600,
          fontSize: '16px',
        }}
        width={16}
        height={16}
        onClick={() => {
          setIsLoading(false);
          removeLogo();
        }}
      />
    </div>
  ) : (
    <div style={{ textAlign: 'center', padding: '9px 8px', borderRadius: '4px', background: CB_COLORS.neutral100 }}>
      <DragUpload
        supportedFilesHelperText="Upload an SVG. 40×40px size recommended."
        name="logo"
        accept="image/svg+xml"
        loading={isLoading}
        customRequest={(e) => {
          setIsLoading(true);
          const file = e.file as RcFile;
          const fileToUpload = new File([file], file.name);

          const reader = new FileReader();

          reader.onload = (progressEvent) => {
            try {
              if (progressEvent.target !== null) {
                const dataURL = progressEvent.target.result;

                if (typeof dataURL === 'string') {
                  if (file.type === 'image/svg+xml' && !dataURL.startsWith('<svg')) {
                    throw new Error('Please add a file that begins with <svg');
                  }

                  if (e.onSuccess) e.onSuccess({ ...file, url: dataURL });
                }
              }
            } catch (err) {
              setIsLoading(false);
              message.error('Invalid svg file');
              if (e.onError) e.onError(err as any);
            }
          };

          reader.readAsText(fileToUpload);
        }}
        onChange={(e) => {
          if (e.file.status === 'done') {
            updateLogo(e.file.response.url);
          }
        }}
        beforeUpload={(file) => {
          const isValidSize = file.size / 1024 / 1024 < 10;

          if (!isValidSize) {
            message.error('Image must smaller than 10MB.');
          }

          const isValidType = file.type === 'image/svg+xml';

          if (!isValidType) {
            message.error('Image must be an svg.');
          }

          return isValidSize && isValidType;
        }}
        maxCount={1}
        listType="picture"
        showUploadList={{
          showRemoveIcon: false,
        }}
      />
    </div>
  );
};

export default BasicStyleEditor;
