/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { createContext, useContext, useMemo } from 'react';

import { useWindowEventListener } from './hooks';

import 'rc-drawer/assets/index.css';

import Editor from './editor/Editor';

import useEditor, { ICommandTableDispatch, ICommandTableState } from './editor/useEditor';
import useHotkey from '@commandbar/internal/util/useHotkey';
import useIsStandaloneEditor from './hooks/useIsStandaloneEditor';
import { LOCAL_SETTINGS } from './shared_components/LocalSettings';
import Sender from './management/Sender';

import type { IUserType } from '@commandbar/internal/middleware/types';

const addScript = (src: string, options?: { async: boolean }) => {
  const newScript = document.createElement('script');
  newScript.async = options?.async ?? false;
  newScript.src = src;

  const firstScriptInDocument = document.getElementsByTagName('script')[0];
  firstScriptInDocument.parentNode?.insertBefore(newScript, firstScriptInDocument);
};

const startBeamer = () => {
  addScript('https://app.getbeamer.com/js/beamer-embed.js');
};

/*******************************************************************************/
/* Render
/*******************************************************************************/

export type AppState = ICommandTableState & {
  dispatch: ICommandTableDispatch;
  isStandaloneEditor: boolean | undefined;
  unsavedChangesRef: React.MutableRefObject<'true' | 'false' | 'backModalShown'>;
};

const AppStateContext = createContext<null | AppState>(null);

export const useAppContext = () => {
  const appStateContext = useContext(AppStateContext);
  if (!appStateContext) {
    throw new Error('useAppContext must be used within an <AppStateContext.Provider />');
  }
  return appStateContext;
};

const AppStateContextProvider = ({ user }: { user: IUserType }) => {
  const editorState = useEditor(user);
  const isStandaloneEditor = useIsStandaloneEditor();
  const unsavedChangesRef = React.useRef<'true' | 'false' | 'backModalShown'>('false');

  const dispatch = editorState ? editorState.dispatch : null,
    state = editorState ? editorState.state : null;

  const providerValue = useMemo(
    () =>
      dispatch && state
        ? {
            ...state,
            dispatch,
            isStandaloneEditor,
            unsavedChangesRef,
          }
        : null,
    [dispatch, state],
  );
  if (!providerValue) return null;

  return (
    <AppStateContext.Provider value={providerValue}>
      <Widget />
    </AppStateContext.Provider>
  );
};

const Widget = () => {
  const { activeCommand, isStandaloneEditor } = useAppContext();

  useHotkey('k', Sender.onCMDK);

  // Add handler to save Editor's open/close state and active command in localStorage if window is reloading/closing
  useWindowEventListener(
    'beforeunload',
    () => {
      activeCommand.state === 'editing' && LOCAL_SETTINGS.activeCommand.setter(activeCommand.command.id);
    },
    [activeCommand],
  );

  React.useEffect(() => {
    if (isStandaloneEditor === false) {
      startBeamer();
    }
  }, [isStandaloneEditor]);

  return (
    <div
      className="commandbar-editor-root"
      style={{ height: '100vh', width: '100vw', padding: 0, backgroundColor: 'white', border: '0px solid blue' }}
    >
      <div
        style={{
          backgroundColor: 'rgb(242, 242, 242)',
          borderRadius: isStandaloneEditor ? 0 : 12,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Editor />
      </div>
    </div>
  );
};

export default AppStateContextProvider;
