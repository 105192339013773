import React from 'react';
import styled from '@emotion/styled';
import { IChecklist, INudgeType } from '@commandbar/internal/middleware/types';

import { Row, Typography } from '../../../shared_components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SquareButton } from '../CommandDetailsHeader/styled';

import { CB_COLORS } from '@commandbar/design-system/components';
import { Template } from './templates';

interface WidgetTemplateProps {
  createFromTemplate: (template?: any) => void;
  onBack: () => void;
  header: string;
  templates: Template<IChecklist | INudgeType>[];
}

const HoverStyle = styled.div`
  margin: 8px 8px 0 0;
  cursor: pointer;
  width: 150px;

  .template-cards {
    border-radius: 4px;
    margin-bottom: 5px;
    transform: scale(1);
    transition: all 0.2s;
  }

  &:hover {
    .template-cards {
      transform: scale(1.02);
      transition: all 0.2s;
      opacity: 0.9;
    }
  }
`;

const WidgetTemplate = ({ createFromTemplate, onBack, header, templates }: WidgetTemplateProps) => {
  return (
    <>
      <Row justify="space-between">
        <div style={{ display: 'flex' }}>
          <SquareButton onClick={onBack}>
            <ArrowLeftOutlined />
          </SquareButton>
          <div style={{ padding: '0 5px', display: 'grid' }}>
            <Typography.Text
              style={{ fontWeight: 500, fontSize: '10px', lineHeight: '12px', color: CB_COLORS.neutral700 }}
              type="secondary"
            >
              {header}
            </Typography.Text>
            <Typography.Text
              style={{ fontWeight: 500, fontSize: '16px', lineHeight: '16px', color: CB_COLORS.neutral1000 }}
            >
              Templates
            </Typography.Text>
          </div>
        </div>

        <Typography.Paragraph
          style={{
            cursor: 'pointer',
            display: 'flex',
            marginBottom: 0,
            alignItems: 'center',
            fontSize: '12px',
            lineHeight: '16px',
            color: CB_COLORS.neutral1000,
            gap: '4px',
          }}
          onClick={() => createFromTemplate()}
        >
          Or start from scratch <span>&rarr;</span>
        </Typography.Paragraph>
      </Row>
      <Row style={{ margin: '15px 0' }}>
        {templates.map((template) => (
          <HoverStyle key={template.category} onClick={() => createFromTemplate(template.template)}>
            <div className="template-cards">
              {React.createElement(template.svg, { style: { height: '105px', width: '100%' } })}
            </div>
            <Typography.Paragraph>{template.title}</Typography.Paragraph>
          </HoverStyle>
        ))}
      </Row>
    </>
  );
};

export default WidgetTemplate;
