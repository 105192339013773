import React, { ReactNode } from 'react';
import { CommandDetailsHeader } from '../../components/CommandDetailsHeader';
import { UpgradeCTA } from '../../components/UpgradeCTA';

interface IProps {
  onGoBack: (() => void) | undefined;
  goBackText?: string;
  actions: ReactNode[];
  content: ReactNode;
  title: ReactNode;
  isRecordAction: boolean;
}

const DetailLayout = (props: IProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', background: 'white', gap: 16 }}>
      <CommandDetailsHeader
        subTitle={props.isRecordAction ? 'Action details' : 'Command details'}
        title={props.title}
        titlePlaceholder={props.isRecordAction ? 'New action...' : 'New command...'}
        onBackPress={props.onGoBack}
        actions={props.actions}
      />

      <div style={{ padding: '0px 16px' }}>
        <UpgradeCTA product="commands" />
      </div>

      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

export default DetailLayout;
