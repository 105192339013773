import React from 'react';
import { Typography } from '../../../../shared_components';
import { defaultOperator, IAvailabilityDependency, IAvailabilityRule, OperatorType } from './types';
import {
  getConditions,
  IEditorAvailabilityRule,
  IEditorRule,
  isCompoundExpression,
} from '@commandbar/internal/middleware/helpers/rules';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';

export const numericOperatorTypes: OperatorType[] = ['isLessThan', 'isGreaterThan'];
export const dateOperatorTypes: OperatorType[] = ['isBefore', 'isAfter'];
export const trueFalseOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const booleanOperatorTypes: OperatorType[] = [
  'isTrue',
  'isFalse',
  'isTruthy',
  'isFalsy',
  'isDefined',
  'isNotDefined',
];
export const heapOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const elementOperatorTypes: OperatorType[] = [
  'classnameOnPage',
  'idOnPage',
  'selectorOnPage',
  'classnameNotOnPage',
  'idNotOnPage',
  'selectorNotOnPage',
];

export const getListOfAvailabilityDependencies = (command: IEditorCommandType): IAvailabilityDependency[] => {
  const dependencies: IAvailabilityDependency[] = [];

  // Click commands
  if (['click', 'clickBySelector', 'clickByXpath'].includes(command.template.type)) {
    if (Array.isArray(command.template.value)) {
      for (const elem in command.template.value) {
        dependencies.push({
          type: 'element',
          field: elem,
          operator: 'exists on the page',
          message: (
            <span>
              Element {<Typography.Text code>{elem}</Typography.Text>} must be present on the DOM for the command to be
              available.
            </span>
          ),
        });
      }
    }
  }

  if (command.template.type === 'callback') {
    dependencies.push({
      type: 'callback',
      field: String(command.template.value),
      operator: 'is defined',
      message: (
        <span>
          Callback {<Typography.Text code>{command.template.value}</Typography.Text>} must be provided for the command
          to be available.
        </span>
      ),
    });
  }

  for (const argName in command.arguments) {
    const argConfig = command.arguments[argName];

    if (argConfig?.type === 'context') {
      dependencies.push({
        type: 'context',
        field: argConfig?.value,
        operator: 'is an array',
        message: (
          <span>
            Context value {<Typography.Text code>{argConfig?.value}</Typography.Text>} must be defined and be a valid
            array of records for this command to be available.
          </span>
        ),
      });
    }
  }
  return dependencies;
};

export const isConditionRuleValid = (rule: IEditorRule) => {
  if (rule.type === 'always') {
    return true;
  }

  if (rule.type === 'named_rule') {
    return typeof rule.rule_id === 'string' || rule.rule_id >= 0;
  }

  const isTypeValid =
    (rule.type === 'context' && rule.field) ||
    rule.type === 'url' ||
    rule.type === 'element' ||
    rule.type === 'executions' ||
    rule.type === 'shortcuts' ||
    rule.type === 'last_seen' ||
    rule.type === 'first_seen' ||
    rule.type === 'sessions' ||
    rule.type === 'opens' ||
    rule.type === 'deadends' ||
    rule.type === 'heap';
  const isBooleanOperator = rule.operator && booleanOperatorTypes.includes(rule.operator);

  return isTypeValid && isValidRuleValue(rule) && rule.operator && (isBooleanOperator || rule.value);
};

export const isUrlRuleValid = (rule: IEditorRule) =>
  rule.type === 'url' && rule.value ? !rule.value.includes('https://') && !rule.value.startsWith('http://') : true;

export const isEveryConditionRuleValid = (rules: IEditorRule[]) =>
  rules.every(isConditionRuleValid) && rules.every(isUrlRuleValid);

export const isValidRuleValue = (rule: IEditorRule) => {
  if (rule.type === 'named_rule') return true;

  const { operator, value } = rule;

  const isNumericOperator = operator && numericOperatorTypes.includes(operator);
  const isNumber = !isNaN(Number(value));

  // Numeric operators need numeric values
  if (isNumericOperator) {
    return isNumber;
  }

  return true;
};

export const camelCaseToRegular = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');

  return (result.charAt(0) + result.slice(1)).toLowerCase();
};

export const negativeOperators: { [key in IAvailabilityRule['operator']]: string } = {
  is: 'is not',
  isNot: 'is',
  isTrue: 'is false',
  isFalse: 'is true',
  isTruthy: 'is falsy',
  isFalsy: 'is truthy',
  startsWith: 'does not start with',
  endsWith: 'does not end with',
  includes: 'does not include',
  doesNotInclude: 'includes',
  matchesRegex: 'does not match regex',
  isGreaterThan: 'is less than',
  isLessThan: 'is greater than',
  isAfter: 'is before',
  isBefore: 'is after',
  isDefined: 'is not defined',
  isNotDefined: 'is defined',
  classnameOnPage: 'classname',
  idOnPage: 'id',
  selectorOnPage: 'selector',
  classnameNotOnPage: 'classname not',
  idNotOnPage: 'id not',
  selectorNotOnPage: 'selector not',
};

export const invertAvailabilityRuleOperator = (operator: IAvailabilityRule['operator']) => negativeOperators[operator];

export const trimQuotes = (str: string) => str.replace(/(^["']|["']$)/gm, '');

export const countAvailabilityConditionsAndDependencies = (command: IEditorCommandType) => {
  const { availability_expression } = command;
  const alwaysAvailable =
    (isCompoundExpression(availability_expression) && availability_expression.exprs.length === 0) ||
    (availability_expression.type === 'LITERAL' && availability_expression.value === true);

  const dependencies = getListOfAvailabilityDependencies(command);

  return {
    alwaysAvailable: dependencies.length <= 0 && alwaysAvailable,
    dependencies,
    count: dependencies.length + getConditions(availability_expression).length,
  };
};

export const countRecommendationConditions = (command: IEditorCommandType) => {
  const { recommend_expression, always_recommend } = command;
  const conditions = getConditions(recommend_expression);

  const neverRecommend =
    !always_recommend && recommend_expression.type === 'LITERAL' && recommend_expression.value === false;

  const alwaysRecommend =
    always_recommend ||
    (recommend_expression.type === 'LITERAL' && recommend_expression.value) ||
    (isCompoundExpression(recommend_expression) && recommend_expression.exprs.length === 0);

  return {
    neverRecommend,
    alwaysRecommend,
    count: conditions.length,
  };
};

export const mkNewRule = (type: IEditorAvailabilityRule['type']): IEditorAvailabilityRule => {
  if (type === 'named_rule') {
    return { type, rule_id: -1 };
  }

  const operator = defaultOperator(type);
  return { type, operator };
};
