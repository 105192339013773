import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Progress as AntProgress } from 'antd';

import { Loading01 } from '../icons/react';

import { CB_COLORS } from './index';

const ProgressContainer = styled.div<{ large: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 24px;
  width: 100%;
  height: 379px;
  background: linear-gradient(107.8deg, #ebddff 2.8%, rgba(238, 227, 255, 0) 29.5%, #ebddff 76.2%, #ebddff 96.08%);
  border: 1px solid #dfdfe2;
  border-radius: ${(props) => (props.large ? '8px' : 'none')};
`;

const ProgressContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation-name: ${spin};
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  color: #000;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 14px;
  font-weight: 400;
  color: ${CB_COLORS.neutral700};
`;

const ProgressText = styled.div`
  font-weight: 500;
  font-size: 11px;
  color: ${CB_COLORS.neutral500};
  text-transform: uppercase;
`;

const ProgressBar = styled(AntProgress)`
  width: 274px;
  border-radius: 100px;
  height: 6px;

  & .ant-progress-outer {
    display: block;
  }

  & .ant-progress-inner {
    display: block;
  }

  & .ant-progress-bg {
    height: 6px !important;
    transition: width 1s ease-out;
    will-change: width;
  }
`;

interface SyncProgressProps {
  progress: number;
  numFetched: number;
  numProcessed: number;
  large?: boolean;
}

const SyncProgress = ({ progress, numFetched, numProcessed, large = false }: SyncProgressProps) => {
  const renderProgressStatusText = () => {
    if (numFetched > 0) {
      return `Processing ${numFetched} ${numFetched === 1 ? 'link' : 'links'}`;
    }

    return 'Fetching content';
  };

  return (
    <ProgressContainer large={large}>
      <Spinner>
        <Loading01 width={32} />
      </Spinner>
      <ProgressContentContainer>
        <Text>
          <Title>Hang tight</Title>
          <Description>{numProcessed > 0 ? 'Syncing content' : 'We’re scanning your content'}</Description>
        </Text>

        <Bar>
          {!!numFetched && !!numProcessed && (
            <ProgressBar
              percent={progress}
              showInfo={false}
              strokeColor="linear-gradient(87.97deg, #4f27f0 -1.66%, #7e27ec 64.2%, #d964ec 81.24%, #6727f0 103.43%)"
            />
          )}
          <ProgressText>
            {numProcessed > 0 ? (
              <span style={{ textTransform: 'uppercase' }}>{`${numProcessed} of ${numFetched} items synced`}</span>
            ) : (
              <span style={{ textTransform: 'uppercase' }}>{renderProgressStatusText()}</span>
            )}
          </ProgressText>
        </Bar>
      </ProgressContentContainer>
    </ProgressContainer>
  );
};

export default SyncProgress;
